import { createEmptyTemplateContent, emptyTemplateContentSearchResult, TemplateContent, TemplateContentSearchResult } from '@/features/template-content/template-content-model';
import { TemplateContentMap, TemplateContentOverallStatistics } from '@/features/template-content/template-content-store';
import { TemplateContentService } from '@/features/template-content/template-content-service';
import { checkUndefined, toMap } from '@/utils/util';
import { v4 as uuidv4 } from 'uuid';

export class TemplateContentServiceMock implements TemplateContentService {

    public static readonly TEMPLATE_CONTENT_1 = 'template-content-1';
    public static readonly TEMPLATE_CONTENT_2 = 'template-content-2';
    public static readonly TEMPLATE_CONTENT_3 = 'template-content-3';
    public static readonly TEMPLATE_CONTENT_4 = 'template-content-4';

    static templateContents: TemplateContent[] = [];
    static searchResult: TemplateContentSearchResult = emptyTemplateContentSearchResult();

    static init() {
        TemplateContentServiceMock.templateContents = TemplateContentServiceMock.createTemplateContents();
        TemplateContentServiceMock.searchResult = {
            templateContents: TemplateContentServiceMock.templateContents,
            templates: [
                {
                    artifactId: 'template-1',
                    versionId: 'template-1-v1',
                    version: 1,
                    releaseState: 'DRAFT',
                    documentKey: 'TMP-1',
                    name: 'Template 1',
                },
                {
                    artifactId: 'template-2',
                    versionId: 'template-2-v1',
                    version: 1,
                    releaseState: 'DRAFT',
                    documentKey: 'TMP-2',
                    name: 'Template 2',
                },
                {
                    artifactId: 'template-3',
                    versionId: 'template-3-v1',
                    version: 1,
                    releaseState: 'DRAFT',
                    documentKey: 'TMP-3',
                    name: 'Template 3',
                }],
            blocks: [
                {
                    artifactId: 'sop-a-block-1',
                    versionId: 'sop-a-block-1-v1',
                    version: 1,
                    name: 'Block 1',
                },
                {
                    artifactId: 'sop-a-block-2',
                    versionId: 'sop-a-block-2-v1',
                    version: 1,
                    name: 'Block 2',
                },
                {
                    artifactId: 'sop-b-block-1',
                    versionId: 'sop-b-block-1-v1',
                    version: 1,
                    name: 'SOP 2 - Block 1',
                }],
            sops: [
                {
                    artifactId: 'sop-a',
                    requirementType: 'QMS',
                    versionId: 'sop-a-v1',
                    version: 1,
                    releaseState: 'DRAFT',
                    name: 'SOP 1',
                    description: '',
                    templateContentIds: [],
                    srProcessIds: [],
                },
                {
                    artifactId: 'sop-b',
                    requirementType: 'QMS',
                    versionId: 'sop-b-v1',
                    version: 1,
                    releaseState: 'DRAFT',
                    name: 'SOP 2',
                    description: '',
                    templateContentIds: [],
                    srProcessIds: [],
                }],
            templateContentInfos: {
                [this.TEMPLATE_CONTENT_1]: {
                    createdByTemplateVersionIds: ['template-1-v1', 'template-2-v1'],
                    validations: ['NOT_CREATED_BY_PROCESS', 'CREATED_BY_MULTIPLE_TEMPLATES'],
                    reportedByTemplateVersionIds: [],
                    createdBySopVersionIds: [],
                },
                [this.TEMPLATE_CONTENT_2]: {
                    createdByTemplateVersionIds: [],
                    validations: [],
                    reportedByTemplateVersionIds: [],
                    createdBySopVersionIds: ['sop-a-v1'],
                },
                [this.TEMPLATE_CONTENT_3]: {
                    createdByTemplateVersionIds: ['template-1-v1', 'template-2-v1'],
                    validations: [],
                    reportedByTemplateVersionIds: ['template-3-v1'],
                    createdBySopVersionIds: ['sop-a-v1', 'sop-b-v1'],
                },
            },
            sopToBlocks: {
                'sop-a-v1': ['sop-a-block-1-v1', 'sop-a-block-2-v1'],
                'sop-b-v1': ['sop-b-block-1-v1'],
            },
        };
    }

    public static createTemplateContents() {
        return [
            {
                ...createEmptyTemplateContent(),
                id: this.TEMPLATE_CONTENT_1,
                name: 'Template Content 1',
                description: 'Template Content 1 Description',
            },
            {
                ...createEmptyTemplateContent(),
                id: this.TEMPLATE_CONTENT_2,
                name: 'Template Content 2',
                srId: 'sr-template-content-2',
                description: 'Template Content 2 Description',
            },
            {
                ...createEmptyTemplateContent(),
                id: this.TEMPLATE_CONTENT_3,
                name: 'Template Content 3',
                srId: 'not-existing-id'
            },
            {
                ...createEmptyTemplateContent(),
                id: this.TEMPLATE_CONTENT_4,
                name: 'Filter Test Case',
                description: 'Should prove the filter',
                exampleText: 'Filtering is cool!',
            },
        ];
    }

    searchTemplateContents(): Promise<TemplateContentSearchResult> {
        return Promise.resolve({
            ...TemplateContentServiceMock.searchResult,
            templateContents: [...TemplateContentServiceMock.templateContents]
        });
    }

    loadTemplateContents(): Promise<TemplateContentMap> {
        return Promise.resolve(toMap(
            [...TemplateContentServiceMock.templateContents], t => t.id ? t.id : ''));
    }

    statistics(): Promise<TemplateContentOverallStatistics> {
        return Promise.reject('not implemented yet');
    }

    createTemplateContent(templateContent: Omit<TemplateContent, 'id'>): Promise<TemplateContent> {
        const newTemplateContent = {
            ...templateContent,
            id: uuidv4()
        }
        TemplateContentServiceMock.templateContents.push(newTemplateContent);
        return Promise.resolve({ ...newTemplateContent });
    }

    updateTemplateContent(templateContent: TemplateContent): Promise<TemplateContent> {
        const index = TemplateContentServiceMock.templateContents.findIndex(t => t.id === templateContent.id);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing template-content by its id');
        }
        TemplateContentServiceMock.templateContents.splice(index, 1, { ...templateContent });
        return Promise.resolve({ ...templateContent });
    }

    deleteTemplateContent(templateContentId: string): Promise<void> {
        const index = TemplateContentServiceMock.templateContents.findIndex(t => t.id === templateContentId);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing template-content by its id');
        }
        TemplateContentServiceMock.templateContents.splice(index, 1);
        return Promise.resolve();
    }

    static findTemplateContentById(tcId: string) {
        return checkUndefined(TemplateContentServiceMock.templateContents.find(tc => tc.id === tcId));
    }
}