import { TemplateSearchAllResult, TemplateSearchBySopResult, TemplateSearchService } from '@/services/template-search-service';

export class TemplateSearchServiceMock implements TemplateSearchService {

    static result: TemplateSearchBySopResult & TemplateSearchAllResult = {
        templates: [],
        templateToBlocksVersionIds: {},
        templatesToSopVersionIdsLinkedDirectly: {},
        templatesToSopVersionIdsLinkedByTemplateContent: {},
        templatesToSopArtifactIds: {},
        blocks: [],
        sops: [],
    }

    searchAllTemplates(): Promise<TemplateSearchAllResult> {
        return Promise.resolve({
            ...TemplateSearchServiceMock.result,
            templates: [...TemplateSearchServiceMock.result.templates]
        });
    }

    searchTemplatesForSop(sopVersionId: string): Promise<TemplateSearchBySopResult> {
        return Promise.resolve(TemplateSearchServiceMock.result);
    }
}