import moment from 'moment';

import { restClient } from '@/store/rest-client';
import { Comment } from '@/features/comments/model';
import { CommentServiceMock } from '@/features/comments/comment-service-mock';
import { ChildrenCommentCount } from '@/features/comments/store';

export interface CommentService {

    loadCommentsForReference(refId: string): Promise<Comment[]>;

    loadChildrenCommentCount(refId: string): Promise<ChildrenCommentCount>;

    createCommentForReference(comment: Comment): Promise<Comment>;

    updateComment(comment: Comment): Promise<void>;

    deleteComment(id: string): Promise<void>;
}

const API_COMMENTS = process.env.VUE_APP_QMS_BASE_URL + '/qms-api/comments';

class CommentBackendService implements CommentService {

    loadCommentsForReference(refId: string): Promise<Comment[]> {
        return restClient.get<Comment[]>(`${ API_COMMENTS }/${ refId }`)
            .then(response => response.data)
            .then(comments => comments.map(c => ({ ...c, createdAt: moment(c.createdAt) })));
    }

    loadChildrenCommentCount(refId: string): Promise<ChildrenCommentCount> {
        return restClient.get<ChildrenCommentCount>(`${ API_COMMENTS }/${ refId }/children-comment-count`)
            .then(response => response.data);
    }

    createCommentForReference(comment: Comment): Promise<Comment> {
        return restClient.post<Comment>(API_COMMENTS, comment)
            .then(response => response.data)
            .then(comment => ({ ...comment, createdAt: moment(comment.createdAt) }));
    }

    updateComment(comment: Comment): Promise<void> {
        return restClient.put<void>(`${ API_COMMENTS }/${ comment.id }`, comment).then(() => undefined);
    }

    deleteComment(id: string): Promise<void> {
        return restClient.delete<void>(`${ API_COMMENTS }/${ id }`).then(() => undefined);
    }

}

export const commentService: CommentService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new CommentServiceMock() : new CommentBackendService();