import { toMap } from '@/utils/util';
import { SrService } from '@/services/sr-service';
import { Process } from '@/model/Process';
import { SrProcessContentMap, SrProcessMap, SrRoleMap, SrTemplateContentMap } from '@/store/sr';
import { SrProcessContent, SrTemplateContent } from '@/features/sr-model/SrTemplateContent';
import { SrRole } from '@/features/sr-model/SrRole';

export class SrServiceMock implements SrService {

    static processes: Process[];
    static srTemplateContents: SrTemplateContent[] = [];
    static srProcessContents: SrProcessContent[] = [];
    static srRoles: SrRole[] = [];

    public static init() {
        SrServiceMock.processes = [
            {
                id: 'a3d8253c-94bc-45d5-9185-acca3383f5d0',
                name: 'Process A',
                description: 'Desc A',
                templateContentIds: [],
                sopContentIds: [],
                termIds: [],
            },
            {
                id: 'b3d8253c-94bc-45d5-9185-acca3383f5d0',
                name: 'Process B',
                description: 'Desc B with linked contents and requirements',
                templateContentIds: ['sr-template-content-a', 'sr-template-content-b'],
                sopContentIds: [],
                termIds: [],
            },
            {
                id: 'c3d8253c-94bc-45d5-9185-acca3383f5d0',
                name: 'Process C',
                description: 'Desc C',
                templateContentIds: [],
                sopContentIds: [],
                termIds: [],
            },
            {
                id: 'd3d8253c-94bc-45d5-9185-acca3383f5d0',
                name: 'Process D',
                description: 'Desc D',
                templateContentIds: ['content-does-not-exist'],
                sopContentIds: [],
                termIds: ['sr-term-a', 'sr-term-b'],
            },
        ];
        SrServiceMock.srTemplateContents = [
            {
                id: 'sr-template-content-a',
                name: 'SR Template Content A',
                description: 'Desc of SR Template Content A'
            },
            {
                id: 'sr-template-content-b',
                name: 'SR Template Content B',
                description: 'Desc of SR Template Content B'
            }
        ];
        SrServiceMock.srProcessContents = [
            {
                id: 'sr-process-content-a',
                name: 'SR Process Content A',
                description: 'Desc of SR Process Content A'
            },
            {
                id: 'sr-process-content-b',
                name: 'SR Process Content B',
                description: 'Desc of SR Process Content B'
            }
        ];
    }

    loadProcesses(): Promise<SrProcessMap> {
        if (!SrServiceMock.processes) {
            SrServiceMock.init();
        }
        return Promise.resolve(toMap(SrServiceMock.processes, t => t.id ? t.id : ''));
    }

    loadTemplateContents(): Promise<SrTemplateContentMap> {
        return Promise.resolve(toMap(SrServiceMock.srTemplateContents, t => t.id ? t.id : ''));
    }

    loadProcessContents(): Promise<SrProcessContentMap> {
        return Promise.resolve(toMap(SrServiceMock.srProcessContents, t => t.id ? t.id : ''));
    }

    loadRoles(): Promise<SrRoleMap> {
        return Promise.resolve(toMap(SrServiceMock.srRoles, t => t.id ? t.id : ''));
    }


}
