
import Vue from 'vue';
import Component from 'vue-class-component';
import { Requirement, RequirementContentType, RequirementMap } from '@/features/sr-model/Requirement';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { ImpactsGetter } from '@/features/impacts/store';
import { QmsRequirement } from '@/services/model';

@Component({
  computed: {
    ...mapState<RootState>({
      toRegulationId: (state: RootState) => state.impacts.toRegulation.id,
      fromRegulationId: (state: RootState) => state.impacts.fromRegulation.id,
      toRegulationRequirement: (state: RootState) => state.impacts.toRegulationRequirements,
      fromRegulationRequirement: (state: RootState) => state.impacts.fromRegulationRequirements,
    }),
    ...mapGetters({
      toRequirementsByIds: ImpactsGetter.TO_REQUIREMENTS_BY_IDS,
      fromRequirementsByIds: ImpactsGetter.FROM_REQUIREMENTS_BY_IDS,
      toQmsRequirementByRequirementId: ImpactsGetter.TO_QMS_REQUIREMENT_BY_REQUIREMENT_ID,
      fromQmsRequirementByRequirementId: ImpactsGetter.FROM_QMS_REQUIREMENT_BY_REQUIREMENT_ID,
    })
  }
})
export default class ImpactsMixin extends Vue {

  toRequirementsByIds: (requirementIds: string[]) => Requirement[];
  fromRequirementsByIds: (requirementIds: string[]) => Requirement[];
  toRegulationRequirement: RequirementMap;
  fromRegulationRequirement: RequirementMap;

  toQmsRequirementByRequirementId: (requirementId: string) => QmsRequirement;
  fromQmsRequirementByRequirementId: (requirementId: string) => QmsRequirement;

  isHeading(requirement: Requirement) {
    return requirement.type === RequirementContentType.HEADING;
  }
}
