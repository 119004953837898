













































































































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { Requirement, RequirementContentType } from '@/features/sr-model/Requirement';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import { Prop } from 'vue-property-decorator';
import RequirementItem from '@/components/common/RequirementItem.vue';
import { GapListWidgetActions, GapListWidgetGetters } from '@/features/gap-list/gap-list-widget/gap-list-widget-store';
import AuthMixin from '@/components/common/AuthMixin.vue';
import { ImpactDto } from '@/features/impacts/model';
import { ImpactsActions } from '@/features/impacts/store';

@Component({
  components: { RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      selectedRegulationId: (state: RootState) => state.gapListWidget.selectedRegulationId,
      selectedSopBlockArtifactId: (state: RootState) => state.gapListWidget.selectedSopBlockArtifactId,
      srRequirementsById: (state: RootState) => state.gapListWidget.srRequirements,
      selectedQmsRequirementId: (state: RootState) => state.gapListWidget.selectedQmsRequirementId,
      sopRequirementType: (state: RootState) => state.sopDetail.sopArtifact.requirementType,
    }),
    ...mapGetters({
      isRequirementImplementationDeletable: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_DELETABLE,
      isRequirementImplementationChangeable: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_CHANGEABLE,
      isRequirementImplemented: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IMPLEMENTED,
      isRequirementMarkedBySelectedBlock: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_SELECTED_BLOCK,
      isRequirementMarkedByAnyBlock: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_ANY_BLOCK,
      calculateRequirementLevel: GapListWidgetGetters.GAP_LIST_WIDGET_CALCULATE_REQUIREMENT_LEVEL,
      isRequirementLinkedToImpact: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_LINKED_TO_IMPACT,
      impactByRequirementId: GapListWidgetGetters.GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED,
      isRequirementInSelectedImpact: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IN_SELECTED_IMPACT,
      isFromRequirementInSelectedImpact: GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_AS_FROM_IN_SELECTED_IMPACT,
    })
  },
  methods: {
    ...mapActions({
      changeRequirementImplemented: GapListWidgetActions.GAP_LIST_WIDGET_CHANGE_REQUIREMENT_IMPL,
      changeRequirementMarked: GapListWidgetActions.GAP_LIST_WIDGET_CHANGE_REQUIREMENT_MARKED,
      selectQmsRequirement: GapListWidgetActions.GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID,
      highlightImpact: GapListWidgetActions.GAP_LIST_WIDGET_SELECT_IMPACT_ID,
    })
  }
})
export default class GapListWidgetRequirement extends mixins<AuthMixin>(AuthMixin) {

  @Prop()
  srRequirement: Requirement;

  selectedQmsRequirementId: string;
  impactByRequirementId: (reqId: string) => ImpactDto | undefined;

  isHeading() {
    return this.srRequirement.type === RequirementContentType.HEADING;
  }

  get isRequirementSelectedByQmsRequirementId(): boolean {
    return this.selectedQmsRequirementId === this.srRequirement.id;
  }

  get impact() {
    return this.impactByRequirementId(this.srRequirement.id);
  }

  goToImpact() {

    const impact = this.impact;
    if (!impact) {
      return;
    }

    this.$store.dispatch(ImpactsActions.IMPACT_LOAD, impact.impactAnalysisId)
        .then(() => this.$store.dispatch(ImpactsActions.EDIT_UI_IMPACT, { impactId: impact.id }));
  }

}
