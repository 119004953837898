











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';

@Component({
  computed: {
    ...mapState<RootState>({
      templateContents: (state: RootState) => Object.values(state.templateContents.templateContents),
    }),
  }
})
export default class TemplateContentSelection extends Vue {

  @Prop()
  selectedTemplateContentIds: string[];

  @Prop({ default: 'Template Contents' })
  label: string;

  set templateContentIds(values: string[]) {
    this.$emit('selected-template-content-ids', values);
  }

  get templateContentIds(): string[] {
    return this.selectedTemplateContentIds;
  }

}
