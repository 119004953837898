


















































import Component, { mixins } from 'vue-class-component';
import SopBlockEntryLayout from '@/features/sop-block/sop-block-list/SopBlockEntryLayout.vue';
import EditorHtmlWrapper from '@/components/common/EditorHtmlWrapper.vue';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';
import { mapGetters } from 'vuex';
import { RegulationDetailGetters } from '@/store/regulation-detail';

@Component({
  components: { EditorHtmlWrapper, SopBlockEntryLayout },
  computed: {
    ...mapGetters({
      getProductByIdOrEmpty: RegulationDetailGetters.PRODUCT_BY_ID_OR_EMPTY
    }),
  },
})
export default class SopBlockEntryTiptap extends mixins<SopBlockEntryMixin>(SopBlockEntryMixin) {

}
