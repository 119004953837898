















































import Component from 'vue-class-component';
import Vue from 'vue'
import { RequirementSearchResult, RequirementSearchResultEntry } from '@/features/sr-requirement-search/model/RequirementSearch';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import { RequirementContentType, RequirementMap } from '@/features/sr-model/Requirement';
import RequirementItem from '@/components/common/RequirementItem.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { toMap } from '@/utils/util';
import { LicenseTypeView } from '@/features/regulations/model';
import { User } from '@/store/auth';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { hasUserSrViewRights } from '@/utils/UserUtils';

@Component({
  components: { RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      currentUser: (state: RootState) => state.auth.user,
    }),
  },
})
export default class RequirementSearchResultPanels extends Vue {

  currentUser: User;

  @Prop()
  searchResult: RequirementSearchResult;
  searchResultRequirementsById: RequirementMap;

  @Watch('searchResult', { immediate: true })
  updateRequirementMap() {
    this.searchResultRequirementsById = toMap(this.searchResult.requirements, req => req.id);
  }

  isHeading(requirement: RequirementSearchResultEntry) {
    return requirement.type === RequirementContentType.HEADING;
  }

  getRequirementsOfRegulation(regulationId: string) {
    return this.searchResult.requirements.filter(requirement => requirement.regulationId === regulationId);
  }

  hasViewRights(licenseTypeView: LicenseTypeView) {
    return hasUserSrViewRights(licenseTypeView, this.currentUser);
  }
}

