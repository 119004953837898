
























import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { SopDetailActions } from '@/store/sop-detail';

@Component({
  computed: {
    ...mapState<RootState>({
      sopSummaries: (state: RootState) => state.sopDetail.sopSummaries.summaries,
    })
  }
})
export default class LastSopsTile extends Vue {

  created() {
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_LOAD_SOP_SUMMARIES);
  }
}
