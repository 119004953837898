import { restClient } from '@/store/rest-client';
import { toMap } from '@/utils/util';
import { Template, TemplateOverallStatistics } from '@/model/Template';
import { TemplateServiceMock } from '@/services/template-service-mock';
import { ReleaseState } from '@/model/ReleaseState';
import { TemplateMap } from '@/store/templates';

export interface TemplateService {

    loadTemplates(): Promise<TemplateMap>;

    statistics(): Promise<TemplateOverallStatistics>;

    createTemplate(templateContent: Template): Promise<Template>;

    updateTemplate(templateContent: Template): Promise<Template>;

    deleteTemplate(templateContentId: string): Promise<void>;

    loadTemplate(templateVersionId: string): Promise<Template>;

    changeReleaseState(template: Template, releaseState: ReleaseState): Promise<void>;

    createNewVersion(template: Template): Promise<Template>;
}

const API_TEMPLATE = '/qms-api/templates';

class TemplateBackendService implements TemplateService {

    loadTemplates(): Promise<TemplateMap> {
        return restClient.get<Template[]>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE)
            .then(response => response.data)
            .then(templates => toMap(templates, t => t.versionId));
    }

    statistics(): Promise<TemplateOverallStatistics> {
        return restClient.get<TemplateOverallStatistics>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE + '/statistics')
            .then(response => response.data);
    }

    createTemplate(template: Template): Promise<Template> {
        return restClient.post<Template>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE, template)
            .then(response => response.data);
    }

    updateTemplate(template: Template): Promise<Template> {
        return restClient.put<Template>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE + '/v/' + template.versionId, template)
            .then(response => response.data);
    }

    deleteTemplate(templateVersionId: string): Promise<void> {
        return restClient.delete<Template>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE + '/v/' + templateVersionId)
            .then();
    }

    loadTemplate(templateVersionId: string): Promise<Template> {
        return restClient.get<Template>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE + '/v/' + templateVersionId)
            .then(response => response.data);
    }

    changeReleaseState(template: Template, releaseState: ReleaseState): Promise<void> {
        return restClient.put<void>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE + '/v/' + template.versionId + '/release-state', { releaseState })
            .then();
    }

    createNewVersion(template: Template): Promise<Template> {
        return restClient.post<Template>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE + '/a/' + template.artifactId + '/v')
            .then(t => t.data);
    }

}

export const templateService: TemplateService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new TemplateServiceMock() : new TemplateBackendService();