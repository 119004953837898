import { User } from '@/store/auth';
import { AuthService, AuthStateChangedCallback, QMS_ROLE, Unsubscribe } from '@/services/auth-service';

export class AuthServiceMock implements AuthService {

    static users: Map<string, User> = new Map<string, User>();
    static callbacks: AuthStateChangedCallback[] = [];
    static lastPasswordResetRequest?: string;

    public static initMock() {
        const anna: User = {
            email: 'anna@test.colabon.com',
            userId: '2c619c1d-37a5-4e06-abb8-4f22ce3e7fcc',
            userName: 'Anna Test',
            userInitials: 'AT',
            tenantId: 'c9c0a08c-acf4-47c3-b3a6-cdad2f2653a7',
            tenantName: 'Colabon Test AG',
            roles: [QMS_ROLE.QMS_ADMIN, QMS_ROLE.QMS_VIEWER],
        };
        this.users = new Map<string, User>();
        this.users.set(anna.email + ':test123', anna);
        this.lastPasswordResetRequest = undefined;
    }

    hasNoAuthStateChangedCallback() {
        if (AuthServiceMock.callbacks.length > 0) {
            throw new Error('There are still ' + AuthServiceMock.callbacks.length + ' callback listeners, but non were expected');
        }
    }

    loginWithEmailAndPassword(email: string, password: string): Promise<void> {
        const loginUser = AuthServiceMock.users.get(email + ':' + password);
        if (!loginUser) {
            return Promise.reject('Error: There is no user record corresponding to this identifier. The user may have been deleted.');
        }
        AuthServiceMock.callbacks.forEach(callback => callback(loginUser))
        return Promise.resolve();
    }

    logout(): Promise<void> {
        AuthServiceMock.callbacks.forEach(callback => callback(null))
        return Promise.resolve();
    }

    onAuthStateChanged(callback: (a: (User | null)) => any): Unsubscribe {
        AuthServiceMock.callbacks = [...AuthServiceMock.callbacks, callback];
        return () => {
            const indexOf = AuthServiceMock.callbacks.indexOf(callback);
            if (indexOf < 0) {
                throw new Error('Can\'t find / unsubscribe AuthStateListener');
            }
            AuthServiceMock.callbacks.splice(indexOf, 1);
        }
    }

    resetPassword(email: string): Promise<void> {
        AuthServiceMock.lastPasswordResetRequest = email;
        return Promise.resolve();
    }

}