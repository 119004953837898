import { ChangeControl, ChangeControlDecisionType, ChangeControlParticipant, ChangeControlWrite, createEmptyChangeControl } from '@/model';
import { SopChangeControlService } from '@/services/sop-change-control-service';
import moment from 'moment';

type Callback = (ccp: ChangeControl) => ChangeControl;

export class SopChangeControlServiceMock implements SopChangeControlService {

    static changeControls: ChangeControl[] = [];

    loadRelease(sopVersionId: string): Promise<ChangeControl> {
        return SopChangeControlServiceMock.find(sopVersionId)
            .catch(() => createEmptyChangeControl());
    }

    loadAllReleases(sopVersionId: string): Promise<ChangeControl[]> {
        return Promise.resolve(SopChangeControlServiceMock.changeControls.filter(cc => cc.artifactVersionId === sopVersionId));
    }

    createRelease(sopVersionId: string, changeControlWrite: ChangeControlWrite): Promise<ChangeControl> {
        const index = SopChangeControlServiceMock.changeControls.findIndex(c => c.artifactVersionId === sopVersionId);
        if (index >= 0) {
            return Promise.reject();
        }

        const changeControl: ChangeControl = {
            id: 'new-change-control-' + sopVersionId,
            dbVersion: 0,
            artifactVersionId: sopVersionId,
            initiatedBy: '2c619c1d-37a5-4e06-abb8-4f22ce3e7fcc',
            initiatedAt: moment('20111031', 'YYYYMMDD'),
            initiatedByDisplayName: 'Anna',
            initiatedComment: changeControlWrite.comment,
            state: 'RUNNING',
            expectedDecider: changeControlWrite.expectedDecider,
            expectedDeciderDisplayName: 'Bob',
            authors: changeControlWrite.authorUserIds.map(userId => ({
                userId,
                userDisplayName: 'test-user-' + userId,
                decision: 'OPEN',
                id: 'new-author-' + userId,
                comment: '',
                dbVersion: 0
            })),
            approvers: changeControlWrite.approverUserIds.map(userId => ({
                userId,
                userDisplayName: 'test-user-' + userId,
                decision: 'OPEN',
                id: 'new-approver-' + userId,
                comment: '',
                dbVersion: 0
            }))
        };
        SopChangeControlServiceMock.changeControls.push(changeControl);
        return Promise.resolve({ ...changeControl });
    }


    public static createChangeControl(sopVersionId: string, changeControlWrite: ChangeControlWrite): ChangeControl {
        return {
            id: 'new-change-control-' + sopVersionId + '-' + SopChangeControlServiceMock.changeControls.length,
            dbVersion: 0,
            artifactVersionId: sopVersionId,
            initiatedBy: '2c619c1d-37a5-4e06-abb8-4f22ce3e7fcc',
            initiatedAt: moment('20111031', 'YYYYMMDD'),
            initiatedByDisplayName: 'Anna',
            initiatedComment: changeControlWrite.comment,
            state: 'RUNNING',
            expectedDecider: changeControlWrite.expectedDecider,
            expectedDeciderDisplayName: 'Bob',
            authors: changeControlWrite.authorUserIds.map(userId => ({
                userId,
                userDisplayName: 'test-user-' + userId,
                decision: 'OPEN',
                id: 'new-author-' + userId,
                comment: '',
                dbVersion: 0
            })),
            approvers: changeControlWrite.approverUserIds.map(userId => ({
                userId,
                userDisplayName: 'test-user-' + userId,
                decision: 'OPEN',
                id: 'new-approver-' + userId,
                comment: '',
                dbVersion: 0
            }))
        };
    }


    cancelRelease(sopVersionId: string): Promise<ChangeControl> {
        return SopChangeControlServiceMock.find(sopVersionId)
            .then(c => {
                c.state = 'CANCELLED';
                return c;
            });
    }

    completeRelease(sopVersionId: string): Promise<ChangeControl> {
        return SopChangeControlServiceMock.find(sopVersionId)
            .then(c => {
                c.state = 'COMPLETED';
                return c;
            });
    }

    approveRelease(sopVersionId: string, comment: string): Promise<ChangeControl> {
        return this.changeDecisionForFirstApprover(sopVersionId, 'APPROVED', comment);
    }

    declineRelease(sopVersionId: string, comment: string): Promise<ChangeControl> {
        return this.changeDecisionForFirstApprover(sopVersionId, 'DECLINED', comment);
    }

    revertDecision(sopVersionId: string, comment: string): Promise<ChangeControl> {
        return this.changeDecisionForFirstApprover(sopVersionId, 'OPEN', comment);
    }

    private changeDecisionForFirstApprover(sopVersionId: string, decision: ChangeControlDecisionType, comment: string) {
        return SopChangeControlServiceMock.apply(sopVersionId, (c: ChangeControl) => {
            const firstParticipantUpdated: ChangeControlParticipant = {
                ...c.approvers[0],
                decision,
                decidedAt: moment('20111031', 'YYYYMMDD'),
                comment
            };
            c.approvers.splice(0, 1, firstParticipantUpdated);
            return c;
        });
    }

    private static find(sopVersionId: string): Promise<ChangeControl> {
        const index = SopChangeControlServiceMock.changeControls.findIndex(c => c.artifactVersionId === sopVersionId);
        if (index < 0) {
            return Promise.reject(`no changeControl for sop version ${ sopVersionId }`);
        }
        return Promise.resolve({ ...SopChangeControlServiceMock.changeControls[index] });
    }

    private static apply(sopVersionId: string, callback: Callback): Promise<ChangeControl> {
        let returnValue: ChangeControl = createEmptyChangeControl();
        SopChangeControlServiceMock.changeControls =
            SopChangeControlServiceMock.changeControls.map(changeControl => {
                if (changeControl.artifactVersionId === sopVersionId) {
                    returnValue = callback({
                        ...changeControl,
                        authors: changeControl.authors.map(p => ({ ...p })),
                        approvers: changeControl.approvers.map(p => ({ ...p }))
                    })
                    return returnValue;
                }
                return changeControl;
            });
        return Promise.resolve(returnValue);
    }
}