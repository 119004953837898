import { restClient } from '@/store/rest-client';
import { toMap, toMapWithValue } from '@/utils/util';
import { QmsRequirementServiceMock } from '@/services/qms-requirement-service-mock';
import { QmsRequirementMap } from '@/store/regulation-detail';
import { API_QMS_REGULATIONS } from '@/features/regulations/qms-regulation-service';
import { createEmptyQmsRequirement, QmsRequirement } from '@/services/model';

export type QmsRequirementApplicability = 'NOT_DEFINED' | 'NOT_APPLICABLE' | 'INFORMATION' | 'APPLICABLE';
export type ApplicabilityWarning = 'NOT_DEFINED' | 'NOT_APPLICABLE_WITH_EVIDENCE' | 'NOT_APPLICABLE_WITHOUT_REASON' | 'APPLICABLE_WITHOUT_EVIDENCE';
export type EvidenceImplementationState = 'INFORMATION_ONLY' | 'NOT_IMPLEMENTED' | 'IMPLEMENTED' | 'IMPLEMENTED_BUT_NOT_REQUIRED';

export type RequirementImplType = 'Sop' | 'Template';

export interface QmsRequirementService {

    findQmsRequirementsBySopVersionId(sopVersionId: string): Promise<QmsRequirementMap>;

    findQmsRequirementsByTargetSopBlockArtifactId(sopBlockArtifactId: string): Promise<QmsRequirementMap>;

    findQmsRequirementsByTemplateContentId(templateContentId: string): Promise<QmsRequirementMap>;

    loadQmsRequirementsForRegulation(regulationId: string): Promise<QmsRequirementMap>;

    changeQmsRequirement(entry: QmsRequirement): Promise<QmsRequirement>;

    deleteQmsRequirement(qmsRequirementId: string): Promise<void>;

    loadRegulationIdsForGivenArtifactId(sopArtifactId: string, type: RequirementImplType): Promise<string[]>;

    changeRequirementImplementation(artifactId: string, requirementId: string, implemented: boolean, type: RequirementImplType): Promise<QmsRequirement>;

    changeRequirementMarked(artifactId: string, regulationId: string, requirementId: string, implemented: boolean): Promise<QmsRequirement>;


}

const API_QMS_REQUIREMENT = '/qms-api/qms-requirements'
const API_QMS_REQUIREMENT_BY_TEMPLATE_CONTENT_ID = API_QMS_REQUIREMENT + '/by-template-content-id'
const API_QMS_REQUIREMENT_BY_SOP_BLOCK_ARTIFACT_ID = API_QMS_REQUIREMENT + '/by-target-sop-block-artifact-id'
const API_QMS_REQUIREMENT_BY_SOP_VERSION_ID = API_QMS_REQUIREMENT + '/by-sop-version-id'

class QmsRequirementBackendService implements QmsRequirementService {

    findQmsRequirementsBySopVersionId(sopVersionId: string): Promise<QmsRequirementMap> {
        return restClient.get<QmsRequirement[]>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT_BY_SOP_VERSION_ID, { params: { sopVersionId } })
            .then(response => response.data)
            .then(qmsRequirements => toMapWithValue(qmsRequirements, r => r.requirementId, r => createEmptyQmsRequirement(r)));
    }

    findQmsRequirementsByTargetSopBlockArtifactId(sopBlockArtifactId: string): Promise<QmsRequirementMap> {
        return restClient.get<QmsRequirement[]>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT_BY_SOP_BLOCK_ARTIFACT_ID, { params: { sopBlockArtifactId } })
            .then(response => response.data)
            .then(qmsRequirements => toMapWithValue(qmsRequirements, r => r.requirementId, r => createEmptyQmsRequirement(r)));
    }

    findQmsRequirementsByTemplateContentId(templateContentId: string): Promise<QmsRequirementMap> {
        return restClient.get<QmsRequirement[]>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT_BY_TEMPLATE_CONTENT_ID, { params: { templateContentId } })
            .then(response => response.data)
            .then(qmsRequirements => toMapWithValue(qmsRequirements, r => r.requirementId, r => createEmptyQmsRequirement(r)));
    }

    loadQmsRequirementsForRegulation(regulationId: string): Promise<QmsRequirementMap> {
        return restClient.get<QmsRequirement[]>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT, { params: { regulationId } })
            .then(response => response.data)
            .then(qmsRequirements => toMapWithValue(qmsRequirements, r => r.requirementId, r => createEmptyQmsRequirement(r)));
    }

    changeQmsRequirement(qmsRequirement: QmsRequirement): Promise<QmsRequirement> {
        return restClient.put<QmsRequirement>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT, qmsRequirement)
            .then(r => r.data);
    }

    deleteQmsRequirement(qmsRequirementId: string): Promise<void> {
        return restClient.delete<QmsRequirement>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT + '/' + qmsRequirementId)
            .then();
    }

    loadRegulationIdsForGivenArtifactId(artifactId: string, type: RequirementImplType): Promise<string[]> {
        const queryFieldName = type.toLowerCase() + 'ArtifactId';
        return restClient.get<string[]>(process.env.VUE_APP_QMS_BASE_URL + API_QMS_REQUIREMENT + `/regulation-ids-by-${ type.toLowerCase() }-artifact-id`, { params: { [queryFieldName]: artifactId } })
            .then(response => response.data);
    }

    changeRequirementImplementation(artifactId: string, requirementId: string, implemented: boolean, type: RequirementImplType): Promise<QmsRequirement> {
        if (implemented) {
            return restClient.put<QmsRequirement>(`${ process.env.VUE_APP_QMS_BASE_URL }${ API_QMS_REQUIREMENT }/${ requirementId }/actual-${ type.toLowerCase() }-artifact-ids/${ artifactId }`)
                .then(response => response.data);
        }
        return restClient.delete<QmsRequirement>(`${ process.env.VUE_APP_QMS_BASE_URL }${ API_QMS_REQUIREMENT }/${ requirementId }/actual-${ type.toLowerCase() }-artifact-ids/${ artifactId }`)
            .then(response => response.data);
    }

    changeRequirementMarked(artifactId: string, regulationId: string, requirementId: string, implemented: boolean): Promise<QmsRequirement> {
        if (implemented) {
            return restClient.put<QmsRequirement>(`${ API_QMS_REGULATIONS }/${ regulationId }/qms-requirements/${ requirementId }/referenced-block-artifact-ids/${ artifactId }`)
                .then(response => response.data);
        }
        return restClient.delete<QmsRequirement>(`${ process.env.VUE_APP_QMS_BASE_URL }${ API_QMS_REQUIREMENT }/${ requirementId }/referenced-block-artifact-ids/${ artifactId }`)
            .then(response => response.data);
    }

}

export const qmsRequirementService: QmsRequirementService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new QmsRequirementServiceMock() : new QmsRequirementBackendService();

export function bulkChangeQmsRequirements(qmsRequirements: QmsRequirement[]): Promise<{ changedQmsRequirementMap: QmsRequirementMap; failedQmsRequirementIds: string[] }> {
    return Promise.allSettled(qmsRequirements.map(qmsRequirement => qmsRequirementService.changeQmsRequirement(qmsRequirement)))
        .then(data => {
            const changedQmsRequirements: QmsRequirement[] = [];
            const failedQmsRequirementIds: string[] = [];

            data.forEach((res, i) => {
                if (res.status === 'fulfilled') {
                    changedQmsRequirements.push(res.value);
                } else if (res.status === 'rejected') {
                    failedQmsRequirementIds.push(qmsRequirements[i].requirementId);
                }
            });

            return Promise.resolve({
                changedQmsRequirementMap: toMap(changedQmsRequirements, r => r.requirementId),
                failedQmsRequirementIds
            });
        });
}