













import Component, { mixins } from 'vue-class-component';
import SopBlockEntryLayout from '@/features/sop-block/sop-block-list/SopBlockEntryLayout.vue';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';
import DrawioMixin from '@/features/drawio-integration/DrawioMixin.vue';

@Component({
  components: { SopBlockEntryLayout },
})
export default class SopBlockEntryDrawio extends mixins<SopBlockEntryMixin, DrawioMixin>(SopBlockEntryMixin, DrawioMixin) {

  get svgString() {
    return this.block.description;
  }

}
