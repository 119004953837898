import { User } from '@/store/auth';
import firebase from 'firebase/app';
import 'firebase/auth';
import { AuthServiceMock } from '@/services/auth-service-mock';
import { restClient } from '@/store/rest-client';

export type AuthStateChangedCallback = (a: User | null) => Promise<void>;
export type Unsubscribe = () => void;

export enum QMS_ROLE {
    'QMS_VIEWER' = 'QMS_VIEWER',
    'QMS_ADMIN' = 'QMS_ADMIN',
}

export interface AuthService {

    logout(): Promise<void>;

    loginWithEmailAndPassword(username: string, password: string): Promise<void>;

    onAuthStateChanged(callback: AuthStateChangedCallback): Unsubscribe;

    resetPassword(email: string): Promise<void>;

}

class AuthServiceFirebase implements AuthService {

    loginWithEmailAndPassword(email: string, password: string): Promise<void> {
        return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => undefined);
    }

    logout(): Promise<void> {
        return firebase.auth().signOut();
    }

    onAuthStateChanged(callback: (a: (User | null)) => Promise<void>): Unsubscribe {
        return firebase.auth().onAuthStateChanged((currentUser => {
            if (!currentUser) {
                callback(null);
                return;
            }
            currentUser.getIdTokenResult().then((tokenResult) => {
                const currentUser: User = {
                    email: tokenResult.claims['email'],
                    userId: tokenResult.claims['co-user-id'],
                    userName: tokenResult.claims['co-user-name'],
                    userInitials: tokenResult.claims['co-user-initials'],
                    tenantId: tokenResult.claims['co-tenant-id'],
                    tenantName: tokenResult.claims['co-tenant-name'],
                    roles: tokenResult.claims['co-roles'].split(' '),
                }
                return callback(currentUser);
            }).then(() => this.updateDocCookie());
        }));
    }

    updateDocCookie() {
        restClient.get<void>(process.env.VUE_APP_QMS_BASE_URL + '/qms-api/doc-access');
    }

    resetPassword(email: string): Promise<void> {
        return firebase.auth()
            .sendPasswordResetEmail(email)
            .catch(_ => Promise.resolve());
    }

}

export const authService: AuthService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new AuthServiceMock() : new AuthServiceFirebase();