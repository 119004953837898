



























































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import { RootState } from '@/store';
import GapListWidgetRequirements from '@/features/gap-list/gap-list-widget/GapListWidgetRequirements.vue';
import { GapListWidgetActions, GapListWidgetGetters, GapListWidgetMutations, HighlightMode } from '@/features/gap-list/gap-list-widget/gap-list-widget-store';
import { SopBlockListActions } from '@/features/sop-block/sop-block-list/store';
import { CombinedRegulation } from '@/features/regulations/model';
import { RegulationGetters } from '@/features/regulations/regulation-store';

@Component({
  components: { GapListWidgetRequirements, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      isLoading: (state: RootState) => state.gapListWidget.gapListLoading,
      isBlockSelected: (state: RootState) => state.gapListWidget.selectedSopBlockArtifactId.length > 0,
      highlightMode: (state: RootState) => state.gapListWidget.highlightMode,
      setupType: (state: RootState) => state.gapListWidget.setup.type,
      selectedImpactId: (state: RootState) => state.gapListWidget.selectedImpactId,
      selectedRegulationId: (state: RootState) => state.gapListWidget.selectedRegulationId,
    }),
    ...mapGetters({
      regulations: GapListWidgetGetters.GAP_LIST_RELEVANT_REGULATIONS,
      selectedImpactAnalysis: GapListWidgetGetters.GAP_LIST_WIDGET_SELECTED_IMPACT_ANALYSIS_OR_UNDEFINED,
      viewAllowedByRegulationId: RegulationGetters.VIEW_ALLOWED_BY_REGULATION_ID,
    }),
  },
  methods: {
    ...mapActions({
      selectRegulation: GapListWidgetActions.GAP_LIST_WIDGET_REGULATION_SELECT,
      removeBlockSelection: SopBlockListActions.SOP_BLOCK_LIST_REMOVE_BLOCK_SELECTION,
    }),
    ...mapMutations({
      toggleHighlightMode: GapListWidgetMutations.GAP_LIST_WIDGET_HIGHLIGHT_MODE_REPLACE,
    })
  }
})
export default class GapListWidget extends Vue {

  readonly none: HighlightMode = 'NONE';
  readonly highlight: HighlightMode = 'HIGHLIGHT';
  readonly highlightNotLinked: HighlightMode = 'HIGHLIGHT_NOT_LINKED';
  readonly exclusiveHighlight: HighlightMode = 'EXCLUSIVE_HIGHLIGHT';

  regulations: CombinedRegulation[];
  selectedRegulationId: string;

  get indexOfSelectedRegulation() {
    return this.regulations.findIndex(reg => reg.regulationId === this.selectedRegulationId)
  }

}
