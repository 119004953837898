import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { safeVueSet } from '@/utils/util';

export enum UiLayoutActions {
    SIDEBAR_RIGHT_SELECTION_CHANGE = 'SIDEBAR_RIGHT_SELECTION_CHANGE',
}

export enum UiLayoutEvents {
    SIDEBAR_RIGHT_SELECTION_CHANGED = 'SIDEBAR_RIGHT_SELECTION_CHANGED',
}

export class UiLayoutState {
    sidebarRightSelectedWidgetName = '';
}

export enum Mutations {
    SIDEBAR_RIGHT_SELECTION_CHANGE_REPLACE = 'SIDEBAR_RIGHT_SELECTION_CHANGE_REPLACE',
}

const getters = {}

const actions = {
    [UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE]: ({ commit, dispatch }: ActionContext<UiLayoutState, RootState>, selectedWidgetName: string) => {
        commit(Mutations.SIDEBAR_RIGHT_SELECTION_CHANGE_REPLACE, selectedWidgetName);
        return dispatch(UiLayoutEvents.SIDEBAR_RIGHT_SELECTION_CHANGED, selectedWidgetName);
    },
}

const mutations = {
    [Mutations.SIDEBAR_RIGHT_SELECTION_CHANGE_REPLACE]: (state: UiLayoutState, selectedWidgetName: string) =>
        safeVueSet(state, 'sidebarRightSelectedWidgetName', selectedWidgetName),
}

export const UI_LAYOUT_MODULE: Module<UiLayoutState, RootState> = {
    state: new UiLayoutState(),
    getters,
    actions,
    mutations
};