




































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Comment } from '@/features/comments/model';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { User } from '@/store/auth';

@Component({
  computed: {
    ...mapState<RootState>({
      currentUser: (state: RootState) => state.auth.user,
    })
  },
})
export default class CommentEntry extends Vue {

  @Prop() comment: Comment;

  currentUser: User;

  editing = false;
  editingComment = '';

  get canChangeComment() {
    return this.comment.createdUserId === this.currentUser.userId;
  }

  startEditing() {
    this.editing = true;
    this.editingComment = this.comment.comment;
  }

  cancelEditing() {
    this.editing = false;
    this.editingComment = '';
  }

  public saveEditedEntry() {
    const editedComment = {
      ...this.comment,
      comment: this.editingComment
    }
    this.editing = false;
    this.$emit('save', editedComment);
  }

  deleteComment() {
    this.$emit('delete', this.comment);
  }
}
