import { restClient } from '@/store/rest-client';
import { RelationMap } from '@/store/regulation-detail';
import { toMap } from '@/utils/util';
import { RelationRelease } from '@/features/sr-model/RelationRelease';
import { Relation } from '@/features/sr-model/Relation';
import { SrRelationServiceMock } from '@/services/sr-relation-service-mock';

export type RelationReleaseDetailDto = { release: RelationRelease; relations: Relation[] };
export type RelationReleaseDetail = { release: RelationRelease; relations: RelationMap };

export interface SrRelationService {

    loadReleases(regulationId: string): Promise<RelationRelease[]>;

    loadReleaseDetail(relationReleaseId: string): Promise<RelationReleaseDetail>;

}

const API_RELATIONS = '/sr-api/relations';

class RelationServiceBackend implements SrRelationService {

    loadReleases(regulationId: string): Promise<RelationRelease[]> {
        return restClient.get<RelationRelease[]>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_RELATIONS, { params: { regulationId } })
            .then(response => response.data);
    }

    loadReleaseDetail(relationReleaseId: string): Promise<RelationReleaseDetail> {
        return restClient.get<RelationReleaseDetailDto>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_RELATIONS + '/' + relationReleaseId)
            .then(response => response.data)
            .then(dto => this.toModel(dto));
    }

    private toModel(dto: RelationReleaseDetailDto): RelationReleaseDetail {
        return {
            release: dto.release,
            relations: toMap(dto.relations, r => r.requirementId)
        };
    }

}

export const relationService: SrRelationService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new SrRelationServiceMock() : new RelationServiceBackend();
