





















import Component from 'vue-class-component';
import Vue from 'vue'
import { Prop } from 'vue-property-decorator';
import { TemplateContentValidation } from '@/features/template-content/template-content-model';

@Component({})
export default class TemplateContentValidationIcons extends Vue {

  @Prop()
  templateContentValidations: TemplateContentValidation[];

  get isCreatedByMultipleTemplates(): boolean {
    return this.templateContentValidations.includes('CREATED_BY_MULTIPLE_TEMPLATES');
  }

  get hasGap(): boolean {
    return this.templateContentValidations.some(w => w === 'NOT_CREATED_BY_PROCESS' || w === 'NOT_CREATED_BY_TEMPLATE');
  }
}

