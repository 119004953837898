






































import { Component } from 'vue-property-decorator';
import CoLayout from '@/layouts/CoLayout.vue';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import TemplateContentsOverview from '@/features/template-content/template-contents-overview/TemplateContentsOverview.vue';
import { mapActions } from 'vuex';
import { SrActions } from '@/store/sr';
import CoHeader from '@/components/common/CoHeader.vue';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import TemplateContentFilterSidebar from '@/features/template-content/template-contents-overview/TemplateContentFilterSidebar.vue';
import { mixins } from 'vue-class-component';
import AuthMixin from '@/components/common/AuthMixin.vue';
import { SopActions } from '@/features/sops';

@Component({
  components: { TemplateContentFilterSidebar, CoHeader, TemplateContentsOverview, CoLayout, TemplateContentDetailWidget },
  methods: {
    ...mapActions({
      execSyncTemplateContents: SrActions.SYNCHRONIZE_TEMPLATE_CONTENTS_WITH_SR,
    })
  }
})
export default class TemplateContentsOverviewView extends mixins<AuthMixin>(AuthMixin) {

  execSyncTemplateContents: () => Promise<void>;
  isSyncRunning = false;

  created() {
    this.$store.dispatch(SrActions.SR_TEMPLATE_CONTENTS_LOAD);
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);
    this.$store.dispatch(SopActions.SOPS_LOAD);
  }

  destroyed() {
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH_CLEAR);
  }

  syncTemplateContents() {
    this.isSyncRunning = true;
    this.execSyncTemplateContents().finally(() => this.isSyncRunning = false)
  }
}
