import { v4 as uuidv4 } from 'uuid';
import { ComparatorReturnType } from '@/utils/util';
import { SopServiceMock } from '@/services/sop-service-mock';
import { SopBlockService } from '@/features/sop-block/sop-block-service';
import { SopBlock, SopBlockWrite } from '@/features/sop-block/model';

export class SopBlockServiceMock implements SopBlockService {

    public static readonly SOP_BLOCK_1_ARTIFACT_ID = '1a719f5f-fb4d-49e7-82a0-041515b3f53c';
    public static readonly SOP_BLOCK_1_VERSION_ID = '1c719f5f-fb4d-49e7-82a0-041515b3f53c';

    public static readonly SOP_BLOCK_2_ARTIFACT_ID = '2a719f5f-fb4d-49e7-82a0-041515b3f53c';
    public static readonly SOP_BLOCK_2_VERSION_ID = '2c719f5f-fb4d-49e7-82a0-041515b3f53c';

    public static readonly SOP_BLOCK_3_ARTIFACT_ID = '3a719f5f-fb4d-49e7-82a0-041515b3f53c';
    public static readonly SOP_BLOCK_3_VERSION_ID = '3c719f5f-fb4d-49e7-82a0-041515b3f53c';

    public static readonly SOP_BLOCK_4_ARTIFACT_ID = '4a719f5f-fb4d-49e7-82a0-041515b3f53c';
    public static readonly SOP_BLOCK_4_VERSION_ID = '4a719f5f-fb4d-49e7-82a0-041515b3f53c';

    public static readonly SOP_BLOCK_5_ARTIFACT_ID = '5a719f5f-fb4d-49e7-82a0-041515b3f53c';
    public static readonly SOP_BLOCK_5_VERSION_ID = '5c719f5f-fb4d-49e7-82a0-041515b3f53c';

    public static readonly SOP_BLOCK_6_ARTIFACT_ID = '6a719f5f-fb4d-49e7-82a0-041515b3f53c';
    public static readonly SOP_BLOCK_6_VERSION_ID = '6c719f5f-fb4d-49e7-82a0-041515b3f53c';

    createSopBlock(sopVersionId: string, index: number, block: SopBlockWrite): Promise<SopBlock> {
        SopServiceMock.assertSopInDraft(sopVersionId);
        const sop = SopServiceMock.findSopByVersionId(sopVersionId);
        if (!sop) {
            return Promise.reject('sop not found')
        }
        const createdSopBlock: SopBlock = {
            ...block,
            artifactId: uuidv4(),
            versionId: uuidv4(),
            version: 1,
            versionState: 'CREATED',
            sopArtifactId: sop.artifactId,
        };
        if (index >= 0) {
            sop.sopBlocks.splice(index, 0, createdSopBlock);
        } else {
            sop.sopBlocks.push(createdSopBlock);
        }
        return Promise.resolve({ ...createdSopBlock });
    }

    createNewSopBlockVersion(sopVersionId: string, blockVersionId: string): Promise<SopBlock> {
        SopServiceMock.assertSopInDraft(sopVersionId);
        const sop = SopServiceMock.findSopByVersionId(sopVersionId);
        if (!sop) {
            return Promise.reject('sop not found')
        }
        const index = sop.sopBlocks.findIndex(sb => sb.versionId === blockVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the existing block by its id');
        }
        const previousSopBlockVersion = sop.sopBlocks[index];
        const createdSopBlockVersion: SopBlock = {
            ...previousSopBlockVersion,
            versionId: uuidv4(),
            version: previousSopBlockVersion.version + 1,
            versionState: 'UPDATED'
        };
        sop.sopBlocks[index] = createdSopBlockVersion;
        return Promise.resolve({ ...createdSopBlockVersion });
    }

    loadSopBlock(sopVersionId: string, blockVersionId: string): Promise<SopBlock> {
        const block = SopServiceMock.sops.find(s => s.versionId === sopVersionId)?.sopBlocks?.find(sb => sb.versionId === blockVersionId)
        return block ? Promise.resolve({ ...block }) : Promise.reject('not found');
    }

    updateSopBlock(sopVersionId: string, blockVersionId: string, block: SopBlockWrite): Promise<SopBlock> {
        const sop = SopServiceMock.sops.find(s => s.versionId === sopVersionId);
        if (!sop) {
            return Promise.reject('sop not found');
        }
        const index = sop.sopBlocks.findIndex(sb => sb.versionId === blockVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the deleting block by its id');
        }
        sop.sopBlocks[index] = { ...sop.sopBlocks[index], ...block };
        return Promise.resolve({ ...sop.sopBlocks[index] });
    }

    deleteSopBlock(sopVersionId: string, blockVersionId: string): Promise<void> {
        SopServiceMock.assertSopInDraft(sopVersionId);
        const sop = SopServiceMock.sops.find(s => s.versionId === sopVersionId);
        if (!sop) {
            return Promise.reject('sop not found');
        }
        const index = sop.sopBlocks.map(b => b.versionId).indexOf(blockVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the deleting block by its id');
        }
        const sopBlocks = [...sop.sopBlocks];
        const block = sopBlocks.splice(index, 1)[0];
        sop.sopBlocks = sopBlocks;
        if (block.versionState === 'UNCHANGED') {
            sop.sopBlocks.push({ ...block, versionState: 'DELETED' })
        }
        return Promise.resolve();
    }

    undoDeletedSopBlock(sopVersionId: string, blockVersionId: string): Promise<void> {
        SopServiceMock.assertSopInDraft(sopVersionId);
        const sop = SopServiceMock.sops.find(s => s.versionId === sopVersionId);
        if (!sop) {
            return Promise.reject('sop not found');
        }
        const index = sop.sopBlocks.findIndex(b => b.versionId === blockVersionId);
        if (index < 0) {
            return Promise.reject('sop block not found');
        }
        sop.sopBlocks[index] = { ...sop.sopBlocks[index], versionState: 'UNCHANGED' };
        return Promise.resolve();
    }

    reorderBlocks(sopVersionId: string, sopBlockArtifactIds: string[]): Promise<void> {
        SopServiceMock.assertSopInDraft(sopVersionId);
        const sop = SopServiceMock.sops.find(s => s.versionId === sopVersionId);
        if (!sop) {
            return Promise.reject('sop not found');
        }
        sop.sopBlocks = [...sop.sopBlocks].sort(SopBlockServiceMock.sorter(b => sopBlockArtifactIds.indexOf(b.artifactId)));
        return Promise.resolve();
    }

    static sorter<T>(cb: (t: T) => number): ComparatorReturnType<T> {
        return (a, b) => {
            const aIndex = cb(a);
            const bIndex = cb(b);
            return (aIndex == bIndex) ? 0 : ((aIndex > bIndex) ? 1 : -1);
        }
    }

    public static findBlockByVersionId(sopVersionId: string, blockVersionId: string): SopBlock {
        const sop = SopServiceMock.sops.find(s => s.versionId === sopVersionId);
        if (!sop) {
            throw Error('sop not found');
        }
        const sopBlock = SopServiceMock.findSopByVersionId(sopVersionId).sopBlocks.find(sopBlock => sopBlock.versionId === blockVersionId);
        if (!sopBlock) {
            throw new Error('Sop block with following version not found: ' + blockVersionId);
        }
        return sopBlock;
    }

    public static findBlockByName(sopVersionId: string, name: string): SopBlock {
        const sopBlock = SopServiceMock.findSopByVersionId(sopVersionId).sopBlocks.find(sopBlock => sopBlock.name === name);
        if (!sopBlock) {
            throw new Error('Sop block with following name not found: ' + name);
        }
        return sopBlock;
    }
}
