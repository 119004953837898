























































import Component, { mixins } from 'vue-class-component';
import { TemplateActions } from '@/store/templates';
import { Template } from '@/model/Template';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import CoHeader from '@/components/common/CoHeader.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';

@Component({
  components: { CoHeader },
  computed: {
    ...mapState<RootState>({
      templateContents: (state: RootState) => Object.values(state.templateContents.templateContents),
      roles: (state: RootState) => [{ id: '', name: '# No Role' }, ...Object.values(state.roles.roles)],
    })
  }
})
export default class TemplateOverviewHeader extends mixins<AuthMixin>(AuthMixin) {
  static emptyTemplate: Partial<Template> = { name: '', description: '', version: 1 };
  rules = [
    (value: string) => (!!value && value.length >= 1) || 'Minimum 1 character',
  ];
  versionNumberRule = (value: string) => {
    if (Number.parseInt(value).toString() == value.toString() && Number.parseInt(value) > 0) {
      return true;
    }
    return 'Initial Version must be a positive number.';
  };
  createDialog = false;
  valid = false;
  template: Partial<Template> = { ...TemplateOverviewHeader.emptyTemplate };

  confirmSave() {
    this.$store.dispatch(TemplateActions.TEMPLATE_CREATE, this.template)
        .then(() => this.cancel());
  }

  cancel() {
    this.template = { ...TemplateOverviewHeader.emptyTemplate };
    this.createDialog = false;
  }

}
