export class UrlHelper {

    public static createObjectURL(blob: Blob): string {
        if (!window.URL.createObjectURL) {
            return '';
        }
        return window.URL.createObjectURL(blob);
    }

    public static revokeObjectURL(objectUrlReference: string) {
        if (!window.URL.revokeObjectURL) {
            return;
        }
        window.URL.revokeObjectURL(objectUrlReference);
    }

    public static setupTestCallbacks(create: (blob: Blob) => string, revoke: (objectUrlReference: string) => void) {
        window.URL.createObjectURL = create;
        window.URL.revokeObjectURL = revoke;
    }
}