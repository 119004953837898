







import Vue from 'vue';
import { mapState } from 'vuex';
import Component from 'vue-class-component';
import EditorWrapper from '@/components/common/EditorWrapper.vue';
import TemplateContentSelection from '@/components/common/TemplateContentSelection.vue';
import { RootState } from '@/store';
import { SopBlock, SopBlockTypeUi } from '@/features/sop-block/model';
import SopBlockDetailStep from '@/features/sop-block/sop-block-detail/SopBlockDetailStep.vue';
import SopBlockDetailHeader from '@/features/sop-block/sop-block-detail/SopBlockDetailHeader.vue';
import SopBlockDetailTiptap from '@/features/sop-block/sop-block-detail/SopBlockDetailTiptap.vue';
import SopBlockDetailDrawio from '@/features/sop-block/sop-block-detail/SopBlockDetailDrawio.vue';

@Component({
  components: { EditorWrapper, TemplateContentSelection, SopBlockDetailStep, SopBlockDetailHeader, SopBlockDetailTiptap, SopBlockDetailDrawio },
  computed: {
    ...mapState<RootState>({
      block: (state: RootState) => state.sopBlockDetail.selectedSopBlock,
    }),
  },
})
export default class SopBlockDetail extends Vue {

  block: SopBlock;

  get componentName() {
    return SopBlockTypeUi.getDetailComponentName(this.block.type);
  }

}
