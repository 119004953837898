























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CoHeader extends Vue {

  @Prop({
    default: () => ({ rightSidebarExpanded: true, sidebarRightWidthPx: 600 })
  })
  layoutOptions: { rightSidebarExpanded: boolean; sidebarRightWidthPx: number };

  get maxWidth() {
    const headerElement = (this.$refs as any).header;
    return this.layoutOptions.rightSidebarExpanded && headerElement ?
        { 'max-width': headerElement['clientWidth'] - this.layoutOptions.sidebarRightWidthPx + 'px' } :
        {};
  }

}
