


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { Sop } from '@/model';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import { TemplateContent } from '@/features/template-content/template-content-model';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';
import { QmsRequirement } from '@/services/model';

@Component<QmsRequirementProposal>({
  components: { TemplateContentChip, TemplateContentValidationIcons },
  methods: {
    ...mapActions({
      selectTemplateContentDetailWidgetId: TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID,
    }),
  }
})
export default class QmsRequirementProposal extends Vue {

  @Prop() qmsRequirement: QmsRequirement;

  @Prop({ default: () => () => [] })
  proposedSopsByRequirementId: (requirementId: string) => Sop[];
  @Prop({ default: () => () => [] })
  proposedTemplateContentByRequirementId: (requirementId: string) => TemplateContent[];
  @Prop({ required: true })
  dispatchQmsRequirementChange: (changingFields: Partial<QmsRequirement>) => Promise<void>;
  @Prop({ default: 'Proposals:' })
  proposalLabel: string;
  @Prop({ default: true })
  quickApply: boolean;

  expandedTemplateContents = false;

  isSopAssigned(sopArtifactId: string): boolean {
    return this.qmsRequirement.targetSopArtifactIds.includes(sopArtifactId);
  }

  assignSop(sopArtifactId: string): Promise<any> {
    const targetSopArtifactIds = [...this.qmsRequirement.targetSopArtifactIds];
    const index = this.qmsRequirement.targetSopArtifactIds.indexOf(sopArtifactId)
    if (index >= 0) {
      targetSopArtifactIds.splice(index, 1);
    } else {
      targetSopArtifactIds.push(sopArtifactId);
    }
    return this.dispatchQmsRequirementChange({ targetSopArtifactIds });
  }

  isTemplateContentAssigned(templateContentId: string): boolean {
    return this.qmsRequirement.templateContentIds.includes(templateContentId);
  }

  assignTemplateContent(templateContentId: string): Promise<any> {
    const templateContentIds = [...this.qmsRequirement.templateContentIds];
    const index = this.qmsRequirement.templateContentIds.indexOf(templateContentId)
    if (index >= 0) {
      templateContentIds.splice(index, 1);
    } else {
      templateContentIds.push(templateContentId);
    }
    return this.dispatchQmsRequirementChange({ templateContentIds });
  }

  get proposedSops(): Sop[] {
    return this.proposedSopsByRequirementId(this.qmsRequirement.requirementId);
  }

  get proposedTemplateContents(): TemplateContent[] {
    return this.proposedTemplateContentByRequirementId(this.qmsRequirement.requirementId);
  }
}
