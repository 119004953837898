import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { EvidenceImplementationState, qmsRequirementService } from '@/services/qms-requirement-service';
import { LoadingType, safeVueSet, toMapWithValue } from '@/utils/util';
import { QmsRequirementMap } from '@/store/regulation-detail';
import { UiFeedback } from '@/store/ui-feedback';
import { calculateRequirementLevel, RequirementMap } from '@/features/sr-model/Requirement';
import { ArtifactChangedEvent, ArtifactVersionIdentifier } from '@/model/Events';
import { TemplateDetailEvents } from '@/store/templates/template-detail';
import { UiLayoutEvents } from '@/store/ui-layout';
import { SopDetailEvents } from '@/store/sop-detail/sop-detail-events';
import { SopBlockListEvents } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { SopBlockDetailEvents } from '@/features/sop-block/sop-block-detail/sop-block-detail-events';
import { ImpactAnalysisMap, ImpactDto, ImpactMap } from '@/features/impacts/model';
import { impactService, ImpactsSearchResult } from '@/features/impacts/impact-service';
import { srRegulationService } from '@/services/sr-regulation-service';
import { RegulationGetters } from '@/features/regulations/regulation-store';
import { CombinedRegulation } from '@/features/regulations/model';
import { ImpactAnalysis } from '@/features/impact-analysis/model';
import { SopBlockListGetters } from '@/features/sop-block/sop-block-list/store';
import { QmsRequirement } from '@/services/model';

export type ChangeRequirementCommand = { requirementId: string; implemented: boolean };
export type ChangeMarkedCommand = { requirementId: string; marked: boolean };

type GapListWidgetSetup = {
    type: 'NA' | 'SOP' | 'TEMPLATE';
    detailId: string; // e.g. the id of the sop-artifact or template-artifact
    targetArtifactIdsFieldName: keyof QmsRequirement & ('targetSopArtifactIds' | 'targetTemplateArtifactIds');
    actualArtifactIdsFieldName: keyof QmsRequirement & ('actualSopArtifactIds' | 'actualTemplateArtifactIds');
    evidenceFieldName: keyof QmsRequirement & ('sopEvidenceStates' | 'templateEvidenceStates');
}

export function sopDetailSetup(sopArtifactId: string): GapListWidgetSetup {
    return {
        type: 'SOP',
        detailId: sopArtifactId,
        targetArtifactIdsFieldName: 'targetSopArtifactIds',
        actualArtifactIdsFieldName: 'actualSopArtifactIds',
        evidenceFieldName: 'sopEvidenceStates',
    }
}

export function templateDetailSetup(templateArtifactId: string): GapListWidgetSetup {
    return {
        type: 'TEMPLATE',
        detailId: templateArtifactId,
        targetArtifactIdsFieldName: 'targetTemplateArtifactIds',
        actualArtifactIdsFieldName: 'actualTemplateArtifactIds',
        evidenceFieldName: 'templateEvidenceStates',
    }
}

function emptySetup(): GapListWidgetSetup {
    return {
        type: 'NA',
        detailId: '',
        targetArtifactIdsFieldName: 'targetSopArtifactIds',
        actualArtifactIdsFieldName: 'actualSopArtifactIds',
        evidenceFieldName: 'sopEvidenceStates',
    }
}

export type HighlightMode = 'HIGHLIGHT' | 'EXCLUSIVE_HIGHLIGHT' | 'HIGHLIGHT_NOT_LINKED' | 'NONE';

export class GapListWidgetState {
    gapListLoading: LoadingType = 'NOT_LOADED';
    isWidgetActive = false;
    setup: GapListWidgetSetup = emptySetup();
    regulationIds: string[] = [];
    selectedRegulationId: string;
    srRequirements: RequirementMap = {};
    qmsRequirements: QmsRequirementMap = {};
    selectedSopBlockArtifactId = '';
    loadedSopBlockArtifactIds: string[] = [];
    selectedQmsRequirementId = '';
    impactAnalyses: ImpactAnalysisMap = {};
    impacts: ImpactMap = {};
    selectedImpactId = '';
    highlightMode: HighlightMode = 'NONE';
}

export enum GapListWidgetGetters {
    GAP_LIST_RELEVANT_REGULATIONS = 'GAP_LIST_RELEVANT_REGULATIONS',
    GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_DELETABLE = 'GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_DELETABLE',
    GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_CHANGEABLE = 'GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_CHANGEABLE',
    GAP_LIST_WIDGET_IS_REQUIREMENT_IMPLEMENTED = 'GAP_LIST_WIDGET_IS_REQUIREMENT_IMPLEMENTED',
    GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_SELECTED_BLOCK = 'GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_SELECTED_BLOCK',
    GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_ANY_BLOCK = 'GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_ANY_BLOCK',
    GAP_LIST_WIDGET_CALCULATE_REQUIREMENT_LEVEL = 'GAP_LIST_WIDGET_CALCULATE_REQUIREMENT_LEVEL',

    GAP_LIST_WIDGET_HAS_SOP_BLOCK_ANY_LINKED_REQUIREMENTS = 'GAP_LIST_WIDGET_HAS_SOP_BLOCK_ANY_LINKED_REQUIREMENTS',

    GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REQUIREMENT = 'GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REQUIREMENT',
    GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REGULATION = 'GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REGULATION',
    GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED = 'GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED',
    GAP_LIST_WIDGET_IS_REQUIREMENT_LINKED_TO_IMPACT = 'GAP_LIST_WIDGET_IS_REQUIREMENT_LINKED_TO_IMPACT',
    GAP_LIST_WIDGET_SELECTED_IMPACT_ANALYSIS_OR_UNDEFINED = 'GAP_LIST_WIDGET_SELECTED_IMPACT_ANALYSIS_OR_UNDEFINED',
    GAP_LIST_WIDGET_SELECTED_IMPACT_OR_UNDEFINED = 'GAP_LIST_WIDGET_SELECTED_IMPACT_OR_UNDEFINED',
    GAP_LIST_WIDGET_IS_REQUIREMENT_IN_SELECTED_IMPACT = 'GAP_LIST_WIDGET_IS_REQUIREMENT_IN_SELECTED_IMPACT',
    GAP_LIST_WIDGET_IS_REQUIREMENT_AS_FROM_IN_SELECTED_IMPACT = 'GAP_LIST_WIDGET_IS_REQUIREMENT_AS_FROM_IN_SELECTED_IMPACT',
    GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_FROM = 'GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_FROM',
    GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_TO = 'GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_TO',
}

enum GapListWidgetGettersInternal {
    GAP_LIST_WIDGET_REQUIREMENT_IDS_TO_IMPACTS = 'GAP_LIST_WIDGET_REQUIREMENT_IDS_TO_IMPACTS',
}

export enum GapListWidgetActions {
    GAP_LIST_WIDGET_REGULATIONS_LOAD = 'GAP_LIST_WIDGET_REGULATIONS_LOAD',
    GAP_LIST_WIDGET_REGULATION_SELECT = 'GAP_LIST_WIDGET_REGULATION_SELECT',
    GAP_LIST_WIDGET_CHANGE_REQUIREMENT_IMPL = 'GAP_LIST_WIDGET_CHANGE_REQUIREMENT_IMPL',
    GAP_LIST_WIDGET_CHANGE_REQUIREMENT_MARKED = 'GAP_LIST_WIDGET_CHANGE_REQUIREMENT_MARKED',

    GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID = 'GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID',
    GAP_LIST_WIDGET_SELECT_IMPACT_ID = 'GAP_LIST_WIDGET_SELECT_IMPACT_ID',
}

enum Mutations {
    GAP_LIST_WIDGET_LOADING_REPLACE = 'GAP_LIST_WIDGET_LOADING_REPLACE',
    GAP_LIST_WIDGET_SETUP = 'GAP_LIST_WIDGET_SETUP',
    GAP_LIST_WIDGET_REGULATION_IDS_REPLACE = 'GAP_LIST_WIDGET_REGULATION_IDS_REPLACE',
    GAP_LIST_WIDGET_QMS_REQUIREMENTS_REPLACE = 'GAP_LIST_WIDGET_QMS_REQUIREMENTS_REPLACE',
    GAP_LIST_WIDGET_SELECTED_REGULATION_REPLACE = 'GAP_LIST_WIDGET_SELECTED_REGULATION_REPLACE',
    GAP_LIST_WIDGET_QMS_REQUIREMENT_REPLACE = 'GAP_LIST_WIDGET_QMS_REQUIREMENT_REPLACE',
    GAP_LIST_WIDGET_SR_REQUIREMENTS_REPLACE = 'GAP_LIST_WIDGET_SR_REQUIREMENTS_REPLACE',
    GAP_LIST_WIDGET_SELECTED_SOP_BLOCK_ARTIFACT_ID_REPLACE = 'GAP_LIST_WIDGET_SELECTED_SOP_BLOCK_ARTIFACT_ID_REPLACE',
    GAP_LIST_WIDGET_LOADED_SOP_BLOCK_ARTIFACT_IDS = 'GAP_LIST_WIDGET_LOADED_SOP_BLOCK_ARTIFACT_IDS',
    GAP_LIST_WIDGET_IS_ACTIVE_REPLACE = 'GAP_LIST_WIDGET_IS_ACTIVE_REPLACE',
    GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID_REPLACE = 'GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID_REPLACE',
    GAP_LIST_WIDGET_IMPACTS_REPLACE = 'GAP_LIST_WIDGET_IMPACTS_REPLACE',
    GAP_LIST_WIDGET_SELECT_IMPACT_ID = 'GAP_LIST_WIDGET_SELECT_IMPACT_ID',
}

export enum GapListWidgetMutations {
    GAP_LIST_WIDGET_HIGHLIGHT_MODE_REPLACE = 'GAP_LIST_WIDGET_HIGHLIGHT_MODE_REPLACE',
}

function targetArtifactIds(qmsRequirement: QmsRequirement, state: GapListWidgetState) {
    if (!qmsRequirement) {
        return [];
    }
    return qmsRequirement[state.setup.targetArtifactIdsFieldName] || [];
}

function actualArtifactIds(qmsRequirement: QmsRequirement, state: GapListWidgetState) {
    if (!qmsRequirement) {
        return [];
    }
    return qmsRequirement[state.setup.actualArtifactIdsFieldName] || [];
}

function evidenceStateForRequirementId(qmsRequirementId: string, state: GapListWidgetState): EvidenceImplementationState {
    const qmsRequirement = state.qmsRequirements[qmsRequirementId];
    if (!qmsRequirement) {
        return 'INFORMATION_ONLY'
    }
    return qmsRequirement[state.setup.evidenceFieldName][state.setup.detailId] || 'INFORMATION_ONLY';
}

function loadRegulationIdsForArtifactId(state: GapListWidgetState): Promise<string[]> {
    switch (state.setup.type) {
        case 'NA':
            return Promise.resolve([]);
        case 'SOP':
            return qmsRequirementService.loadRegulationIdsForGivenArtifactId(state.setup.detailId, 'Sop');
        case 'TEMPLATE':
            return qmsRequirementService.loadRegulationIdsForGivenArtifactId(state.setup.detailId, 'Template');
    }
}

function loadImpactsForArtifactId(setup: GapListWidgetSetup): Promise<ImpactsSearchResult> {
    switch (setup.type) {
        case 'SOP':
            return impactService.loadImpactsBySopArtifactId(setup.detailId);
        default:
            return Promise.resolve({ impactAnalyses: {}, impacts: {} })
    }
}


function changeRequirementImplementation(changeRequirementCommand: ChangeRequirementCommand, state: GapListWidgetState): Promise<QmsRequirement> {
    switch (state.setup.type) {
        case 'NA':
            return Promise.reject();
        case 'SOP':
            return qmsRequirementService.changeRequirementImplementation(state.setup.detailId, changeRequirementCommand.requirementId, changeRequirementCommand.implemented, 'Sop')
        case 'TEMPLATE':
            return qmsRequirementService.changeRequirementImplementation(state.setup.detailId, changeRequirementCommand.requirementId, changeRequirementCommand.implemented, 'Template')
    }
}

function isSopBlockMarkedByImpact(state: GapListWidgetState, fieldname: keyof ImpactDto & ('requirementIdsFrom' | 'requirementIdsTo')) {
    return (sopBlockArtifactId: string) => {
        if (state.selectedImpactId === '') {
            return false;
        }
        const selectedImpact = state.impacts[state.selectedImpactId];
        return selectedImpact[fieldname]
            .flatMap(reqId => state.qmsRequirements[reqId]?.referencedSopBlockArtifactIds || [])
            .includes(sopBlockArtifactId);
    };
}

function isMarkedByRequirement(state: GapListWidgetState, sopBlockArtifactId: string) {
    if (state.selectedQmsRequirementId.length === 0) {
        return false;
    }
    const qmsRequirement = state.qmsRequirements[state.selectedQmsRequirementId];
    if (!qmsRequirement) {
        return false;
    }
    return qmsRequirement.referencedSopBlockArtifactIds.includes(sopBlockArtifactId);
}

function isLinkedByAnyRequirement(state: GapListWidgetState, sopBlockArtifactId: string) {
    return Object.values(state.qmsRequirements)
        .some(qmsReq => qmsReq.referencedSopBlockArtifactIds.includes(sopBlockArtifactId));
}

function isMarkedByRegulation({ qmsRequirements, selectedRegulationId, highlightMode }: GapListWidgetState, sopBlockArtifactId: string) {
    const isReferencedByAnyQmsRequirementOfSelectedRegulation = () => {
        return Object.values(qmsRequirements)
            .filter(qmsReq => qmsReq.regulationId === selectedRegulationId)
            .findIndex(qmsReq => qmsReq.referencedSopBlockArtifactIds.includes(sopBlockArtifactId)) >= 0;
    }

    const isNotReferencedByAnyQmsRequirementOfAnyUnselectedRegulation = () => {
        return Object.values(qmsRequirements)
            .filter(qmsReq => qmsReq.regulationId !== selectedRegulationId)
            .findIndex(qmsReq => qmsReq.referencedSopBlockArtifactIds.includes(sopBlockArtifactId)) < 0;
    }

    const isNotReferencedByAnyQmsRequirement = () => {
        return Object.values(qmsRequirements)
            .findIndex(qmsReq => qmsReq.referencedSopBlockArtifactIds.includes(sopBlockArtifactId)) < 0;
    }

    switch (highlightMode) {
        case 'HIGHLIGHT':
            return isReferencedByAnyQmsRequirementOfSelectedRegulation();
        case 'EXCLUSIVE_HIGHLIGHT':
            return isReferencedByAnyQmsRequirementOfSelectedRegulation() && isNotReferencedByAnyQmsRequirementOfAnyUnselectedRegulation();
        case 'HIGHLIGHT_NOT_LINKED':
            return isNotReferencedByAnyQmsRequirement();
        default:
            return false;
    }
}

const getters = {
    [GapListWidgetGetters.GAP_LIST_RELEVANT_REGULATIONS]: (state: GapListWidgetState, gapListWidgetGetters: GapListWidgetGetters, rootState: RootState, rootGetters: any) => {
        const allRegulationsForTenant: CombinedRegulation[] = rootGetters[RegulationGetters.COMBINED_REGULATIONS];
        return allRegulationsForTenant.filter((regulation: CombinedRegulation) => state.regulationIds.includes(regulation.regulationId));
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_DELETABLE]: (state: GapListWidgetState) => (qmsRequirementId: string) => {
        return evidenceStateForRequirementId(qmsRequirementId, state) === 'IMPLEMENTED_BUT_NOT_REQUIRED'
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IMPL_CHANGEABLE]: (state: GapListWidgetState) => (qmsRequirementId: string) => {
        return evidenceStateForRequirementId(qmsRequirementId, state) !== 'INFORMATION_ONLY'
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IMPLEMENTED]: (state: GapListWidgetState) => (qmsRequirementId: string) => {
        return evidenceStateForRequirementId(qmsRequirementId, state) === 'IMPLEMENTED'
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_SELECTED_BLOCK]: (state: GapListWidgetState) => (qmsRequirementId: string) => {
        const qmsRequirement = state.qmsRequirements[qmsRequirementId];
        if (!qmsRequirement) {
            return false;
        }
        return qmsRequirement.referencedSopBlockArtifactIds.includes(state.selectedSopBlockArtifactId);
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_MARKED_BY_ANY_BLOCK]: (state: GapListWidgetState) => (qmsRequirementId: string) => {
        const qmsRequirement = state.qmsRequirements[qmsRequirementId];
        if (!qmsRequirement) {
            return false;
        }
        return !!qmsRequirement.referencedSopBlockArtifactIds.find(artifactId => state.loadedSopBlockArtifactIds.includes(artifactId));
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_CALCULATE_REQUIREMENT_LEVEL]: (state: GapListWidgetState) => (qmsRequirementId: string) =>
        calculateRequirementLevel(state.srRequirements[qmsRequirementId], state.srRequirements),
    [GapListWidgetGetters.GAP_LIST_WIDGET_HAS_SOP_BLOCK_ANY_LINKED_REQUIREMENTS]: (state: GapListWidgetState) => (sopBlockArtifactId: string) => {
        return isLinkedByAnyRequirement(state, sopBlockArtifactId);
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REQUIREMENT]: (state: GapListWidgetState) => (sopBlockArtifactId: string) => {
        return isMarkedByRequirement(state, sopBlockArtifactId);
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_REGULATION]: (state: GapListWidgetState) => (sopBlockArtifactId: string) => {
        return isMarkedByRegulation(state, sopBlockArtifactId);
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_LINKED_TO_IMPACT]: (state: GapListWidgetState, getters: any) => (requirementId: string) => {
        return !!(getters[GapListWidgetGetters.GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED](requirementId));
    },
    [GapListWidgetGettersInternal.GAP_LIST_WIDGET_REQUIREMENT_IDS_TO_IMPACTS]: (state: GapListWidgetState) => {
        const toReqMap = (x: any) => toMapWithValue(x, (x: any) => x.reqId, (x: any) => x.impact)
        return toReqMap(Object.values(state.impacts)
            .flatMap(el => [...el.requirementIdsTo, ...el.requirementIdsFrom]
                .map(reqId => ({ reqId, impact: el }))))
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_SELECTED_IMPACT_OR_UNDEFINED]: (state: GapListWidgetState) => state.impacts[state.selectedImpactId],
    [GapListWidgetGetters.GAP_LIST_WIDGET_SELECTED_IMPACT_ANALYSIS_OR_UNDEFINED]: (state: GapListWidgetState): ImpactAnalysis =>
        state.impactAnalyses[state.impacts[state.selectedImpactId]?.impactAnalysisId],
    [GapListWidgetGetters.GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED]: (state: GapListWidgetState, getters: any) => (requirementId: string) => {
        return getters[GapListWidgetGettersInternal.GAP_LIST_WIDGET_REQUIREMENT_IDS_TO_IMPACTS][requirementId];
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_IN_SELECTED_IMPACT]: (state: GapListWidgetState, getters: any) => (requirementId: string) => {
        return getters[GapListWidgetGetters.GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED](requirementId)?.id === state.selectedImpactId;
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_REQUIREMENT_AS_FROM_IN_SELECTED_IMPACT]: (state: GapListWidgetState, getters: any) => (requirementId: string) => {
        return (getters[GapListWidgetGetters.GAP_LIST_WIDGET_IMPACT_BY_REQUIREMENT_ID_OR_UNDEFINED](requirementId)?.requirementIdsFrom || []).includes(requirementId);
    },
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_TO]: (state: GapListWidgetState) => isSopBlockMarkedByImpact(state, 'requirementIdsTo'),
    [GapListWidgetGetters.GAP_LIST_WIDGET_IS_SOP_BLOCK_MARKED_BY_IMPACT_FROM]: (state: GapListWidgetState) => isSopBlockMarkedByImpact(state, 'requirementIdsFrom'),
};

const actions = {
    [GapListWidgetActions.GAP_LIST_WIDGET_REGULATIONS_LOAD]: ({ state, commit, dispatch }: ActionContext<GapListWidgetState, RootState>) => {
        if (state.setup.type === 'NA' || !state.isWidgetActive) {
            return Promise.resolve();
        }
        commit(Mutations.GAP_LIST_WIDGET_LOADING_REPLACE, 'LOADING' as LoadingType);
        return Promise
            .all([
                loadRegulationIdsForArtifactId(state),
                loadImpactsForArtifactId(state.setup),
            ])
            .then(results => {
                const regulationIds: string[] = results[0];
                commit(Mutations.GAP_LIST_WIDGET_REGULATION_IDS_REPLACE, regulationIds);
                commit(Mutations.GAP_LIST_WIDGET_IMPACTS_REPLACE, results[1]);
                return Promise.all(regulationIds.map(regId => qmsRequirementService.loadQmsRequirementsForRegulation(regId)))
                    .then(nestedQmsRequirements => {
                        const mergedQmsRequirements = nestedQmsRequirements.reduce((m1, m2) => ({ ...m1, ...m2 }), {});
                        commit(Mutations.GAP_LIST_WIDGET_QMS_REQUIREMENTS_REPLACE, mergedQmsRequirements);
                    })
            })
            .catch(err => UiFeedback.showError(dispatch, `Regulation list couldn't be fetched. Please try again.`, err))
            .finally(() => commit(Mutations.GAP_LIST_WIDGET_LOADING_REPLACE, 'COMPLETED' as LoadingType));
    },
    [GapListWidgetActions.GAP_LIST_WIDGET_REGULATION_SELECT]: ({ state, commit, dispatch, rootGetters }: ActionContext<GapListWidgetState, RootState>, regulationId: string) => {
        if (!regulationId || state.selectedRegulationId === regulationId) {
            return Promise.resolve();
        }
        commit(Mutations.GAP_LIST_WIDGET_SELECTED_REGULATION_REPLACE, regulationId);
        const qmsRequirementsOfSelectedRegulation = Object.values(state.qmsRequirements).filter(qmsRequirement => qmsRequirement.regulationId === regulationId);
        const applicableRequirementIds = qmsRequirementsOfSelectedRegulation
            .filter(qmsRequirement => targetArtifactIds(qmsRequirement, state).includes(state.setup.detailId) || actualArtifactIds(qmsRequirement, state).includes(state.setup.detailId))
            .map(qmsRequirement => qmsRequirement.requirementId);
        const additionalApplicableRequirementIds = qmsRequirementsOfSelectedRegulation
            .filter(qmsRequirement => qmsRequirement.requirementType === 'INCLUDED_IN_ANCESTOR' && applicableRequirementIds.includes(qmsRequirement.interpretedInRequirementId ?? ''))
            .map(qmsRequirement => qmsRequirement.requirementId);
        const sopBlockArtifactIds = rootGetters[SopBlockListGetters.ORDERED_BLOCK_ARTIFACT_IDS];
        const linkedToSopBlocks = qmsRequirementsOfSelectedRegulation
            .filter(qmsRequirement => qmsRequirement.referencedSopBlockArtifactIds
                .some(referencedSopBlockArtifactId => sopBlockArtifactIds.includes(referencedSopBlockArtifactId)))
            .map(qmsRequirement => qmsRequirement.requirementId);
        const infoRequirementIds = qmsRequirementsOfSelectedRegulation
            .filter(qmsRequirement => qmsRequirement.applicability === 'INFORMATION')
            .map(qmsRequirement => qmsRequirement.requirementId);
        return srRegulationService.loadFilteredRequirements(regulationId, [...applicableRequirementIds, ...additionalApplicableRequirementIds, ...linkedToSopBlocks], infoRequirementIds)
            .then(srRequirements => commit(Mutations.GAP_LIST_WIDGET_SR_REQUIREMENTS_REPLACE, srRequirements))
            .catch(err => UiFeedback.showError(dispatch, `Requirements for regulation ${ regulationId } couldn't be fetched. Please try again.`, err));
    },
    [GapListWidgetActions.GAP_LIST_WIDGET_CHANGE_REQUIREMENT_IMPL]: ({ state, commit, dispatch }: ActionContext<GapListWidgetState, RootState>, requirementImpl: ChangeRequirementCommand) => changeRequirementImplementation(requirementImpl, state)
        .then(qmsRequirement => commit(Mutations.GAP_LIST_WIDGET_QMS_REQUIREMENT_REPLACE, qmsRequirement))
        .catch(err => UiFeedback.showError(dispatch, `Requirement implementation for requirement ${ requirementImpl.requirementId } couldn't be updated. Please try again.`, err)),
    [GapListWidgetActions.GAP_LIST_WIDGET_CHANGE_REQUIREMENT_MARKED]: ({ state, commit, dispatch }: ActionContext<GapListWidgetState, RootState>, requirementMarked: ChangeMarkedCommand) => qmsRequirementService.changeRequirementMarked(state.selectedSopBlockArtifactId, state.selectedRegulationId, requirementMarked.requirementId, requirementMarked.marked)
        .then(qmsRequirement => commit(Mutations.GAP_LIST_WIDGET_QMS_REQUIREMENT_REPLACE, qmsRequirement))
        .catch(err => UiFeedback.showError(dispatch, `Requirement marked for requirement ${ requirementMarked.requirementId } couldn't be updated. Please try again.`, err)),
    [GapListWidgetActions.GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID]: ({ state, commit }: ActionContext<GapListWidgetState, RootState>, selectedQmsRequirementId: string) => {
        commit(Mutations.GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID_REPLACE, state.selectedQmsRequirementId === selectedQmsRequirementId ? '' : selectedQmsRequirementId);
    },
    [GapListWidgetActions.GAP_LIST_WIDGET_SELECT_IMPACT_ID]: ({ state, commit }: ActionContext<GapListWidgetState, RootState>, selectedImpactId: string) => {
        commit(Mutations.GAP_LIST_WIDGET_SELECT_IMPACT_ID, state.selectedImpactId === selectedImpactId ? '' : selectedImpactId);
    },
};

const eventListeners = {
    [UiLayoutEvents.SIDEBAR_RIGHT_SELECTION_CHANGED]: ({ commit, dispatch }: ActionContext<GapListWidgetState, RootState>, selectedWidgetName: string) => {
        const isWidgetActive = selectedWidgetName === 'GapListWidget';
        commit(Mutations.GAP_LIST_WIDGET_IS_ACTIVE_REPLACE, isWidgetActive);
        return dispatch(GapListWidgetActions.GAP_LIST_WIDGET_REGULATIONS_LOAD);
    },
    [SopDetailEvents.SOP_DETAIL_CHANGED]: ({ dispatch, commit }: ActionContext<GapListWidgetState, RootState>, sopDetailChangedEvent: ArtifactChangedEvent) => {
        commit(Mutations.GAP_LIST_WIDGET_SETUP, !sopDetailChangedEvent ? emptySetup() : sopDetailSetup(sopDetailChangedEvent.artifactId));
        commit(Mutations.GAP_LIST_WIDGET_SELECTED_SOP_BLOCK_ARTIFACT_ID_REPLACE);
        commit(Mutations.GAP_LIST_WIDGET_LOADED_SOP_BLOCK_ARTIFACT_IDS);
        return dispatch(GapListWidgetActions.GAP_LIST_WIDGET_REGULATIONS_LOAD);
    },
    [TemplateDetailEvents.TEMPLATE_DETAIL_CHANGED]: ({ dispatch, commit }: ActionContext<GapListWidgetState, RootState>, templateDetailChangedEvent: ArtifactChangedEvent) => {
        commit(Mutations.GAP_LIST_WIDGET_SETUP, !templateDetailChangedEvent ? emptySetup() : templateDetailSetup(templateDetailChangedEvent.artifactId));
        return dispatch(GapListWidgetActions.GAP_LIST_WIDGET_REGULATIONS_LOAD);
    },
    [SopDetailEvents.SOP_BLOCKS_LOADED]: ({ dispatch, commit }: ActionContext<GapListWidgetState, RootState>, loadedBlockIdentifiers: ArtifactVersionIdentifier[]) => {
        commit(Mutations.GAP_LIST_WIDGET_LOADED_SOP_BLOCK_ARTIFACT_IDS, loadedBlockIdentifiers.map(({ artifactId }) => artifactId) as string[]);
        return Promise.resolve();
    },
    [SopBlockListEvents.SOP_BLOCK_SELECTED]: ({ commit }: ActionContext<GapListWidgetState, RootState>, blockSelectionEvent: ArtifactChangedEvent) => {
        commit(Mutations.GAP_LIST_WIDGET_SELECTED_SOP_BLOCK_ARTIFACT_ID_REPLACE, blockSelectionEvent?.artifactId);
        return Promise.resolve();
    },
    [SopBlockDetailEvents.SOP_BLOCK_DETAIL_CLOSED]: ({ commit }: ActionContext<GapListWidgetState, RootState>) => {
        commit(Mutations.GAP_LIST_WIDGET_SELECTED_SOP_BLOCK_ARTIFACT_ID_REPLACE);
        return Promise.resolve();
    },
};

const mutations = {
    [Mutations.GAP_LIST_WIDGET_LOADING_REPLACE]: (state: GapListWidgetState, loading: LoadingType) =>
        safeVueSet(state, 'gapListLoading', loading),
    [Mutations.GAP_LIST_WIDGET_SETUP]: (state: GapListWidgetState, setup: GapListWidgetSetup) => {
        safeVueSet(state, 'setup', setup);
        safeVueSet(state, 'regulationIds', []);
        safeVueSet(state, 'selectedRegulationId', '');
        safeVueSet(state, 'qmsRequirements', {});
        safeVueSet(state, 'srRequirements', {});
    },
    [Mutations.GAP_LIST_WIDGET_IS_ACTIVE_REPLACE]: (state: GapListWidgetState, isWidgetActive: boolean) =>
        safeVueSet(state, 'isWidgetActive', isWidgetActive),
    [Mutations.GAP_LIST_WIDGET_REGULATION_IDS_REPLACE]: (state: GapListWidgetState, regulationIds: string[]) =>
        safeVueSet(state, 'regulationIds', regulationIds),
    [Mutations.GAP_LIST_WIDGET_SELECTED_REGULATION_REPLACE]: (state: GapListWidgetState, regulationId: string) => {
        safeVueSet(state, 'selectedRegulationId', regulationId);
        safeVueSet(state, 'selectedQmsRequirementId', '');
        safeVueSet(state, 'highlightMode', 'NONE');
    },
    [Mutations.GAP_LIST_WIDGET_QMS_REQUIREMENTS_REPLACE]: (state: GapListWidgetState, qmsRequirements: QmsRequirementMap) =>
        safeVueSet(state, 'qmsRequirements', qmsRequirements),
    [Mutations.GAP_LIST_WIDGET_QMS_REQUIREMENT_REPLACE]: (state: GapListWidgetState, qmsRequirement: QmsRequirement) =>
        safeVueSet(state.qmsRequirements, qmsRequirement.requirementId, qmsRequirement),
    [Mutations.GAP_LIST_WIDGET_SR_REQUIREMENTS_REPLACE]: (state: GapListWidgetState, srRequirements: RequirementMap) =>
        safeVueSet(state, 'srRequirements', srRequirements),
    [Mutations.GAP_LIST_WIDGET_SELECTED_SOP_BLOCK_ARTIFACT_ID_REPLACE]: (state: GapListWidgetState, selectedSopBlockArtifactId: string) => {
        safeVueSet(state, 'selectedQmsRequirementId', '');
        safeVueSet(state, 'selectedSopBlockArtifactId', selectedSopBlockArtifactId || '');
    },
    [Mutations.GAP_LIST_WIDGET_LOADED_SOP_BLOCK_ARTIFACT_IDS]: (state: GapListWidgetState, loadedSopBlockArtifactIds: string[]) =>
        safeVueSet(state, 'loadedSopBlockArtifactIds', loadedSopBlockArtifactIds || []),
    [Mutations.GAP_LIST_WIDGET_SELECT_QMS_REQUIREMENT_ID_REPLACE]: (state: GapListWidgetState, selectedQmsRequirementId: string) => {
        safeVueSet(state, 'selectedQmsRequirementId', selectedQmsRequirementId || '');
        safeVueSet(state, 'highlightMode', 'NONE');
        safeVueSet(state, 'selectedImpactId', '');
    }
    ,
    [Mutations.GAP_LIST_WIDGET_IMPACTS_REPLACE]: (state: GapListWidgetState, impactsSearchResult: ImpactsSearchResult) => {
        safeVueSet(state, 'impactAnalyses', impactsSearchResult.impactAnalyses);
        safeVueSet(state, 'impacts', impactsSearchResult.impacts);
    },
    [Mutations.GAP_LIST_WIDGET_SELECT_IMPACT_ID]: (state: GapListWidgetState, impactId: string) => {
        safeVueSet(state, 'selectedImpactId', impactId);
        safeVueSet(state, 'highlightMode', 'NONE');
        safeVueSet(state, 'selectedQmsRequirementId', '');
    },
    [GapListWidgetMutations.GAP_LIST_WIDGET_HIGHLIGHT_MODE_REPLACE]: (state: GapListWidgetState, highlightMode: HighlightMode | undefined) => {
        if (state.highlightMode === highlightMode) {
            highlightMode = 'NONE';
        }
        safeVueSet(state, 'highlightMode', highlightMode || 'NONE');
        safeVueSet(state, 'selectedQmsRequirementId', '');
        safeVueSet(state, 'selectedImpactId', '');
    },
};

export const GAP_LIST_WIDGET_MODULE: Module<GapListWidgetState, RootState> = {
    state: new GapListWidgetState(),
    getters,
    actions: { ...actions, ...eventListeners },
    mutations
};