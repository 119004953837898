var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex",staticStyle:{"height":"calc(100vh - 228px)"}},[(this.$slots['left-sidebar'])?_c('aside',{class:{
           'co-sidebar-left-expanded': _vm.leftSidebarExpanded,
           'co-sidebar-left-collapsed': !_vm.leftSidebarExpanded,
           'co-border-right': !_vm.leftSidebarExpanded,
           'px-2': !_vm.leftSidebarExpanded
         },staticStyle:{"z-index":"90"}},[_c('div',{staticClass:"toggle-button"},[_c('v-btn',{staticClass:"toggle-icon",attrs:{"fab":"","elevation":"2"},on:{"click":function($event){_vm.leftSidebarExpanded = !_vm.leftSidebarExpanded}}},[(_vm.leftSidebarExpanded)?_c('v-icon',[_vm._v("mdi-chevron-left")]):_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leftSidebarExpanded),expression:"leftSidebarExpanded"}],staticClass:"co-sidebar-left-content-part co-border-right"},[_vm._t("left-sidebar")],2)]):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"co-content-header px-5 py-3",staticStyle:{"z-index":"80"}},[_vm._t("content-header",null,{"options":{ rightSidebarExpanded: _vm.rightSidebarExpanded, sidebarRightWidthPx: 600 }})],2),_c('div',{staticClass:"d-flex fill-height"},[_c('div',{staticClass:"flex-fill",class:{ 'co-scroller': _vm.contentScrollable },staticStyle:{"width":"0"},attrs:{"data-test-layout-content-part":""}},[_vm._t("content")],2),(_vm.rightSidebarComponents.length > 0)?_c('aside',{class:{
               'co-sidebar-right-expanded': _vm.rightSidebarExpanded,
               'co-sidebar-right-collapsed': !_vm.rightSidebarExpanded,
             },staticStyle:{"z-index":"70"}},[(_vm.rightSidebarExpanded)?_c('div',{staticClass:"toggle-button",staticStyle:{"z-index":"71"}},[_c('v-btn',{staticClass:"toggle-icon",attrs:{"fab":"","elevation":"2"},on:{"click":_vm.closeRightSidebar}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex fill-height"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.rightSidebarExpanded),expression:"rightSidebarExpanded"}],staticClass:"co-scroller co-sidebar-right-content-part co-border-left flex-grow-1"},[_c('sidebar-right',{attrs:{"right-sidebar-component":_vm.activeComponentName}})],1),_c('div',{staticClass:"d-flex flex-column align-end co-sidebar-right-nav",staticStyle:{"z-index":"71"}},_vm._l((_vm.rightSidebarComponents),function(c,i){return _c('v-tooltip',{key:c.componentName,attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
             var on = ref.on;
             var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-5",class:{ active: i === _vm.rightSidebarComponentIndex && _vm.rightSidebarExpanded },attrs:{"icon":"","tile":""},on:{"click":function($event){return _vm.onSidebarTabClick(i)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v(_vm._s(c.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(c.displayName))])])}),1)])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }