import { createEmptyTemplate, Template } from '@/model/Template';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import Vue from 'vue';
import { templateService } from '@/services/template-service';
import { templateDetailActions, templateDetailGetters, templateDetailMutations } from '@/store/templates/template-detail';
import { UiFeedback } from '@/store/ui-feedback';
import { emptyTemplateSearchAllResult, sopTemplatesService, TemplateSearchAllResult } from '@/services/template-search-service';
import { createComparator, safeVueSet, toMap } from '@/utils/util';

export type TemplateMap = { [key: string]: Template };

export class TemplateState {
    templates: TemplateMap = {};
    template: Template;
    search: TemplateSearchAllResult = emptyTemplateSearchAllResult();
    selectedSopArtifactId = '';
}

export enum TemplateActions {
    TEMPLATES_SEARCH = 'TEMPLATES_SEARCH',
    TEMPLATES_SEARCH_CLEAR = 'TEMPLATES_SEARCH_CLEAR',
    TEMPLATE_CREATE = 'TEMPLATE_CREATE',
    TEMPLATE_DELETE = 'TEMPLATE_DELETE',
    TEMPLATES_FILTER_BY_SOP_ARTIFACT_ID = 'TEMPLATES_FILTER_BY_SOP_ARTIFACT_ID',
}

export enum TemplateGetters {
    TEMPLATE_BY_VERSION_ID_OR_EMPTY = 'TEMPLATE_BY_VERSION_ID_OR_EMPTY',
    SOPS_USED_BY_TEMPLATE_ID = 'SOPS_USED_BY_TEMPLATE_ID',
    VALID_TEMPLATE_ARTIFACTS = 'VALID_TEMPLATE_ARTIFACTS',
    TEMPLATE_SEARCH_RESULT_FILTERED_BY_SOP = 'TEMPLATE_SEARCH_RESULT_FILTERED_BY_SOP',
}

export enum Mutations {
    TEMPLATES_SEARCH_REPLACE = 'TEMPLATES_SEARCH_REPLACE',
    TEMPLATE_REPLACE = 'TEMPLATE_REPLACE',
    TEMPLATE_DELETE = 'TEMPLATE_DELETE',
    TEMPLATES_FILTERED_BY_SOP_ARTIFACT_ID = 'TEMPLATES_FILTERED_BY_SOP_ARTIFACT_ID',
}

export function createLoading(versionId: string): Template {
    return {
        ...createEmptyTemplate(),
        versionId,
        name: 'Loading...'
    }
}

const getters = {
    [TemplateGetters.TEMPLATE_BY_VERSION_ID_OR_EMPTY]: (state: TemplateState) => (templateVersionId: string) => {
        return state.templates[templateVersionId] || createLoading(templateVersionId)
    },
    [TemplateGetters.SOPS_USED_BY_TEMPLATE_ID]: (state: TemplateState) => (templateVersionId: string) => {
        const sopVersionIdsLinkedDirectly = state.search.templatesToSopVersionIdsLinkedDirectly[templateVersionId] || [];
        const sopVersionIdsLinkedByTemplateContent = (state.search.templatesToSopVersionIdsLinkedByTemplateContent[templateVersionId] || [])
            .filter(sopVersionId => sopVersionIdsLinkedDirectly.indexOf(sopVersionId) === -1);
        const sopVersionIds = [...sopVersionIdsLinkedDirectly, ...sopVersionIdsLinkedByTemplateContent];
        return sopVersionIds.flatMap(sopVersionId => state.search.sops.filter(sop => sop.versionId === sopVersionId) || []);
    },
    [TemplateGetters.VALID_TEMPLATE_ARTIFACTS]: (state: TemplateState) => {
        const templateToSop = Object.values(state.templates)
            .filter(template => template.releaseState !== 'OBSOLETE')
            .reduce((artifacts, template) => {
                const existingIndex = artifacts.findIndex(artifact => artifact.artifactId === template.artifactId);
                if (existingIndex >= 0) {
                    if (artifacts[existingIndex].releaseState !== 'RELEASED') {
                        artifacts.splice(existingIndex, 1, template);
                    }
                } else {
                    artifacts.push(template);
                }
                return artifacts;
            }, [] as Template[]);
        return templateToSop.sort(createComparator('name'));
    },
    [TemplateGetters.TEMPLATE_SEARCH_RESULT_FILTERED_BY_SOP]: (state: TemplateState) => {
        if (!state.selectedSopArtifactId || state.selectedSopArtifactId === '') {
            return state.search.templates;
        }
        return state.search.templates.filter(t => state.search.templatesToSopArtifactIds[t.versionId]?.includes(state.selectedSopArtifactId));
    }
}

type TemplateSearchReplace = { templates: TemplateMap; searchResult: TemplateSearchAllResult };

const actions = {
    [TemplateActions.TEMPLATES_SEARCH]: ({ commit, dispatch }: ActionContext<TemplateState, RootState>) =>
        sopTemplatesService.searchAllTemplates()
            .then(templateSearchAllResult => {
                const templateSearchReplace: TemplateSearchReplace = {
                    searchResult: templateSearchAllResult,
                    templates: toMap(templateSearchAllResult.templates, t => t.versionId)
                };
                commit(Mutations.TEMPLATES_SEARCH_REPLACE, templateSearchReplace);
            })
            .catch(err => UiFeedback.showError(dispatch, `Template search couldn't be executed. Please try again.`, err)),
    [TemplateActions.TEMPLATES_SEARCH_CLEAR]: ({ commit }: ActionContext<TemplateState, RootState>) => {
        const templateSearchReplace: TemplateSearchReplace = {
            searchResult: emptyTemplateSearchAllResult(),
            templates: {}
        };
        commit(Mutations.TEMPLATES_SEARCH_REPLACE, templateSearchReplace);
        commit(Mutations.TEMPLATES_FILTERED_BY_SOP_ARTIFACT_ID, '');
    },
    [TemplateActions.TEMPLATE_CREATE]: ({ commit, dispatch }: ActionContext<TemplateState, RootState>, template: Template) =>
        templateService.createTemplate(template)
            .then(c => commit(Mutations.TEMPLATE_REPLACE, c))
            .then(() => dispatch(TemplateActions.TEMPLATES_SEARCH))
            .catch(err => UiFeedback.showError(dispatch, `Template couldn't be created. Please try again.`, err)),
    [TemplateActions.TEMPLATE_DELETE]: ({ commit, dispatch }: ActionContext<TemplateState, RootState>, templateId: string) =>
        templateService.deleteTemplate(templateId)
            .then(() => commit(Mutations.TEMPLATE_DELETE, templateId))
            .then(() => dispatch(TemplateActions.TEMPLATES_SEARCH)),
    [TemplateActions.TEMPLATES_FILTER_BY_SOP_ARTIFACT_ID]: ({ commit }: ActionContext<TemplateState, RootState>, sopArtifactId: string) => {
        commit(Mutations.TEMPLATES_FILTERED_BY_SOP_ARTIFACT_ID, sopArtifactId);
        return Promise.resolve();
    }
}

const mutations = {
    [Mutations.TEMPLATES_SEARCH_REPLACE]: (state: TemplateState, { searchResult, templates }: TemplateSearchReplace) => {
        safeVueSet(state, 'search', searchResult);
        safeVueSet(state, 'templates', templates);
    },
    [Mutations.TEMPLATE_REPLACE]: (state: TemplateState, template: Template) =>
        safeVueSet(state.templates, template.versionId, template),
    [Mutations.TEMPLATE_DELETE]: (state: TemplateState, templateId: string) =>
        Vue.delete(state.templates, templateId),
    [Mutations.TEMPLATES_FILTERED_BY_SOP_ARTIFACT_ID]: (state: TemplateState, sopArtifactId: string) =>
        safeVueSet(state, 'selectedSopArtifactId', sopArtifactId),
}

export const TEMPLATE_MODULE: Module<TemplateState, RootState> = {
    state: new TemplateState(),
    getters: { ...getters, ...templateDetailGetters },
    actions: { ...actions, ...templateDetailActions },
    mutations: { ...mutations, ...templateDetailMutations }
};