var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isImpactMode)?_c('div',{staticClass:"d-flex qms-requirements"},[(_vm.qmsRequirement.requirementType === 'INTERPRETATION')?[_c('impact-qms-requirement-applicability',{staticClass:"my-2",attrs:{"qms-requirement":_vm.qmsRequirement,"editable":_vm.editQmsRequirements}}),_c('v-spacer'),(_vm.editQmsRequirements && _vm.requirement.level > 0)?_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"elevation":"2","fab":"","x-small":"","data-test-copy-assessment-btn":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-minus-outline")])],1)]}}],null,false,2081187182)},[_c('v-card',[_c('v-card-subtitle',[_vm._v("Do you like to delete this interpretation?")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":_vm.deleteInterpretation}},[_vm._v("Yes, please")])],1)],1)],1):_vm._e(),(_vm.editQmsRequirements)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"elevation":"2","fab":"","x-small":"","data-test-edit-requirement-btn":""},on:{"click":function($event){return _vm.editRequirement(_vm.requirement.id)}}},on),[(_vm.isEditingRequirement)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)]}}],null,false,83517720)},[(_vm.isEditingRequirement)?_c('span',[_vm._v("Close extended edit mode.")]):_c('span',[_vm._v("Open extended edit mode.")])]):_vm._e()]:[_c('div',{staticClass:"mr-auto"},[_c('div',[_c('span',{staticClass:"co-subheader"},[_vm._v("This interpretation is part of")]),_c('span',{staticClass:"requirement-text",domProps:{"innerHTML":_vm._s(_vm.interpretedInRequirementInfo)}})])]),(_vm.editQmsRequirements)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"elevation":"2","fab":"","x-small":"","data-test-copy-assessment-btn":""},on:{"click":_vm.createInterpretation}},on),[_c('v-icon',[_vm._v("mdi-pencil-plus-outline")])],1)]}}],null,false,3032105265)},[_c('span',[_vm._v("Create interpretation")])]):_vm._e()]],2):_vm._e(),(_vm.qmsRequirement.requirementType === 'INTERPRETATION')?[(_vm.isEditingRequirement && _vm.isImpactMode)?_c('div',{staticClass:"my-2"},[_c('impact-qms-requirement-edit',{attrs:{"qms-requirement":_vm.qmsRequirement,"proposed-sops":_vm.proposedSops,"proposed-template-contents":_vm.proposedTemplateContents}})],1):(_vm.isImpactMode)?_c('div',{staticClass:"qms-requirements"},[_c('qms-requirement-current',{staticClass:"my-2",attrs:{"qms-requirement":_vm.qmsRequirement}}),(_vm.editQmsRequirements)?_c('impact-qms-requirement-proposal',{attrs:{"qms-requirement":_vm.qmsRequirement,"proposed-sops":_vm.proposedSops,"proposed-template-contents":_vm.proposedTemplateContents}}):_vm._e()],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }