










































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import CoHeader from '@/components/common/CoHeader.vue';
import { RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import FabButton from '@/components/common/FabButton.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';
import { definedApplicabilityWithWarningCount, evidenceCount, evidenceWarningCount, QmsRegulationStatistics, SrRegulation } from '@/features/regulations/model';
import DownloadButton from '@/components/common/DownloadButton.vue';

@Component({
  components: { DownloadButton, FabButton, CoHeader },
  computed: {
    ...mapState<RootState>({
      selectedRegulation: (state: RootState) => state.regulationDetail.regulation,
      editQmsRequirements: (state: RootState) => state.regulationDetail.editQmsRequirements
    }),
    ...mapGetters({
      statistics: RegulationDetailGetters.REGULATION_DETAIL_STATISTICS,
      viewAllowed: RegulationDetailGetters.VIEW_ALLOWED,
    }),
  },
  methods: {
    ...mapActions({
      toggleEditQmsRequirements: RegulationDetailActions.TOGGLE_QMS_REQUIREMENTS_EDITABLE,
      interpretationAsCsv: RegulationDetailActions.INTERPRETATION_AS_CSV,
    })
  }
})
export default class RegulationDetailHeader extends mixins<AuthMixin>(AuthMixin) {

  selectedRegulation: SrRegulation;
  statistics: QmsRegulationStatistics;

  get evidenceCount() {
    return evidenceCount(this.statistics);
  }

  get evidenceWarningCount() {
    return evidenceWarningCount(this.statistics);
  }

  get totalRequirements() {
    return this.statistics.totalRequirements;
  }

  get applicabilityWithWarningCount() {
    return definedApplicabilityWithWarningCount(this.statistics) + this.statistics.notDefinedCount;
  }
}
