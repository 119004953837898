export enum RequirementContentType {
    HEADING = 'HEADING',
    TEXT = 'TEXT',
}

export function createEmptyRequirement(id = '', partial: Partial<Requirement> = {}): Requirement {
    return {
        id,
        key: '',
        parentId: '',
        type: RequirementContentType.TEXT,
        interpretable: true,
        text: '',
        level: 0,
        paragraph: '',
        requirementIds: [],
        ...partial
    }
}

export interface Requirement {
    id: string;
    key: string;
    parentId?: string;
    type: RequirementContentType;
    interpretable: boolean;
    text: string;
    level: number;
    paragraph?: string;
    requirementIds: string[];
}

export type RequirementMap = { [key: string]: Requirement };

export const calculateRequirementLevel = (requirement: Requirement, requirementsById: RequirementMap): number => {
    if (!requirement?.parentId || requirement.type === RequirementContentType.HEADING) {
        return 0;
    }
    let marginLevel = 0;
    let parent: Requirement | null = requirementsById[requirement.parentId];
    while (!!parent && parent.type !== RequirementContentType.HEADING) {
        marginLevel++;
        parent = parent.parentId ? requirementsById[parent.parentId] : null;
    }
    return marginLevel;
}