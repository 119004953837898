import Vue from 'vue';
import Vuex from 'vuex';
import { SOP_MODULE, SopState } from '@/features/sops';
import { AUTH_MODULE, AuthState } from '@/store/auth';
import { SOP_DETAIL_MODULE, SopDetailState } from '@/store/sop-detail';
import { SR_MODULE, SrState } from '@/store/sr';
import { REGULATION_DETAIL_MODULE, RegulationDetailState } from '@/store/regulation-detail';
import { TEMPLATE_CONTENT_MODULE, TemplateContentState } from '@/features/template-content/template-content-store';
import { ROLE_MODULE, RoleState } from '@/store/roles';
import { USER_MODULE, UserState } from '@/store/users';
import { TEMPLATE_MODULE, TemplateState } from '@/store/templates';
import { UI_FEEDBACK_MODULE, UiFeedbackState } from '@/store/ui-feedback';
import { SOP_CHANGE_CONTROL_MODULE, SopChangeControlState } from '@/store/sop-change-control';
import { FILE_STORAGE_MODULE, FileStorageState } from '@/store/file-storage';
import { TERM_MODULE, TermState } from '@/store/terms';
import { SOP_TEMPLATE_MODULE, SopTemplatesState } from '@/store/sop-templates';
import { GAP_LIST_WIDGET_MODULE, GapListWidgetState } from '@/features/gap-list/gap-list-widget/gap-list-widget-store';
import { UI_LAYOUT_MODULE, UiLayoutState } from '@/store/ui-layout';
import { QMS_CONFIG_MODULE, QmsConfigState } from '@/store/qms-config';
import { COMMENT_MODULE, CommentsWidgetState } from '@/features/comments/store';
import { IMPACT_ANALYSIS_MODULE, ImpactAnalysisState } from '@/features/impact-analysis/store';
import { IMPACTS_MODULE, ImpactsState } from '@/features/impacts/store';
import { SOP_BLOCK_LIST_MODULE, SopBlockListState } from '@/features/sop-block/sop-block-list/store';
import { SOP_BLOCK_DETAIL_MODULE, SopBlockDetailState } from '@/features/sop-block/sop-block-detail/store';
import { REGULATIONS_MODULE, RegulationState } from '@/features/regulations/regulation-store';
import { PRODUCT_REQUIREMENTS_MODULE, ProductRequirementsState } from '@/features/product-requirements/product-requirements-store';

Vue.use(Vuex);

export interface RootState {
    qmsConfig: QmsConfigState;
    auth: AuthState;
    uiFeedback: UiFeedbackState;
    sops: SopState;
    sopDetail: SopDetailState;
    sopChangeControl: SopChangeControlState;
    sopTemplates: SopTemplatesState;
    sopBlockList: SopBlockListState;
    sopBlockDetail: SopBlockDetailState;
    sr: SrState;
    regulations: RegulationState;
    regulationDetail: RegulationDetailState;
    templates: TemplateState;
    templateContents: TemplateContentState;
    roles: RoleState;
    users: UserState;
    terms: TermState;
    gapListWidget: GapListWidgetState;
    uiLayout: UiLayoutState;
    fileStorage: FileStorageState;
    comments: CommentsWidgetState;
    impactAnalysis: ImpactAnalysisState;
    impacts: ImpactsState;
    productRequirements: ProductRequirementsState;
}

export default new Vuex.Store<RootState>({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        qmsConfig: QMS_CONFIG_MODULE,
        auth: AUTH_MODULE,
        uiFeedback: UI_FEEDBACK_MODULE,
        sops: SOP_MODULE,
        sopDetail: SOP_DETAIL_MODULE,
        sopChangeControl: SOP_CHANGE_CONTROL_MODULE,
        sopTemplates: SOP_TEMPLATE_MODULE,
        sopBlockList: SOP_BLOCK_LIST_MODULE,
        sopBlockDetail: SOP_BLOCK_DETAIL_MODULE,
        sr: SR_MODULE,
        regulations: REGULATIONS_MODULE,
        regulationDetail: REGULATION_DETAIL_MODULE,
        templates: TEMPLATE_MODULE,
        templateContents: TEMPLATE_CONTENT_MODULE,
        roles: ROLE_MODULE,
        users: USER_MODULE,
        terms: TERM_MODULE,
        gapListWidget: GAP_LIST_WIDGET_MODULE,
        uiLayout: UI_LAYOUT_MODULE,
        fileStorage: FILE_STORAGE_MODULE,
        comments: COMMENT_MODULE,
        impactAnalysis: IMPACT_ANALYSIS_MODULE,
        impacts: IMPACTS_MODULE,
        productRequirements: PRODUCT_REQUIREMENTS_MODULE
    },
});
