























import Vue from 'vue';
import Component from 'vue-class-component';
import { Requirement, RequirementContentType } from '@/features/sr-model/Requirement';
import { Prop } from 'vue-property-decorator';
import { toMap } from '@/utils/util';
import RequirementItem from '@/components/common/RequirementItem.vue';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';

@Component({
  components: { RequirementItem, AnchoredHeading },
})
export default class ProductRequirements extends Vue {

  @Prop({})
  private requirements: Requirement[];
  @Prop({})
  private regulationId: string;

  isHeading(requirement: Requirement) {
    return requirement.type === RequirementContentType.HEADING;
  }

  get srRequirementsById() {
    return toMap(this.requirements, req => req.id);
  }

}
