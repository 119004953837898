







































import Vue from 'vue';
import Component from 'vue-class-component';
import LoginView from '@/views/LoginView.vue';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { Watch } from 'vue-property-decorator';
import CoVerticalNavBar from '@/layouts/CoVerticalNavBar.vue';
import { AuthGetters } from '@/store/auth';
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';

@Component({
  components: { Breadcrumbs, CoVerticalNavBar, LoginView },
  computed: {
    ...mapGetters({
      isLoggedIn: AuthGetters.IS_LOGGED_IN,
    }),
    ...mapState<RootState>({
      logoSrc: (state: RootState) => state.auth.tenantLogoSrc,
      uiFeedback: (state: RootState) => state.uiFeedback.current,
    })
  }
})
export default class App extends Vue {

  showSnackbar = false;

  @Watch('uiFeedback')
  onUiFeedbackChange() {
    this.showSnackbar = (this as any).uiFeedback.message && (this as any).uiFeedback.message.length > 0;
  }

}
