import { ArtifactType, createEmptyFileStorage, FileStorage } from '@/model/FileStorage';
import { ActionContext, Commit, Dispatch, Module } from 'vuex';
import { RootState } from '@/store';
import { FileStorageSearch, fileStorageService } from '@/services/file-storage-service';
import { UiFeedback } from '@/store/ui-feedback';
import { ArtifactChangedEvent } from '@/model/Events';
import { TemplateDetailEvents } from '@/store/templates/template-detail';
import { SopDetailEvents } from '@/store/sop-detail/sop-detail-events';

export type FileStorageMap = { [key: string]: FileStorage };

export class FileStorageState {
    search: FileStorageSearch = {
        artifactType: 'SOP',
        artifactVersionId: ''
    };
    fileStorages: FileStorageMap = {};
}

export enum FileStorageActions {
    FILE_STORAGE_LOAD = 'FILE_STORAGE_LOAD',
    FILE_STORAGE_RELOAD = 'FILE_STORAGE_RELOAD',
    FILE_STORAGE_UPLOAD_BY_NAME = 'FILE_STORAGE_UPLOAD_BY_NAME',
    FILE_STORAGE_DELETE_BY_ID = 'FILE_STORAGE_DELETE_BY_ID',
    FILE_STORAGE_RESET = 'FILE_STORAGE_RESET',
}

export enum FileStorageGetters {
    FILE_STORAGE_BY_NAME_OR_UNDEFINED = 'FILE_STORAGE_BY_NAME_OR_UNDEFINED'
}

export enum Mutations {
    FILE_STORAGE_SEARCH_REPLACE = 'FILE_STORAGE_SEARCH_REPLACE',
    FILE_STORAGES_REPLACE = 'FILE_STORAGES_REPLACE',
    FILE_STORAGES_RESET = 'FILE_STORAGES_RESET',
}

export function createLoading(id: string): FileStorage {
    return {
        ...createEmptyFileStorage(),
        id,
        name: 'Loading...'
    }
}

const getters = {
    [FileStorageGetters.FILE_STORAGE_BY_NAME_OR_UNDEFINED]: (state: FileStorageState) => (fileStorageName: string) => Object.values(state.fileStorages).find(fs => fs.name === fileStorageName)
}

class FileStorageHelper {
    static loadFileStorages(search: FileStorageSearch, commit: Commit, dispatch: Dispatch): Promise<void> {
        return fileStorageService.loadFileStorages(search)
            .then(fileStorages => commit(Mutations.FILE_STORAGES_REPLACE, fileStorages))
            .catch(err => UiFeedback.showError(dispatch, `File storages couldn't be fetched. Please try again.`, err));
    }
}

export type CreateFileStorage = {
    fileStorage: FileStorage;
    file: File;
}

function equalsByName(fs1: FileStorage, fs2: FileStorage) {
    return fs1.artifactType === fs2.artifactType
        && fs1.artifactVersionId === fs2.artifactVersionId
        && fs1.name === fs2.name;
}

function handleChangeEvent(artifactType: ArtifactType, artifactChangedEvent: ArtifactChangedEvent, dispatch: Dispatch): Promise<void> {
    if (!artifactChangedEvent) {
        return dispatch(FileStorageActions.FILE_STORAGE_RESET);
    }
    const search: FileStorageSearch = {
        artifactType,
        artifactVersionId: artifactChangedEvent.versionId
    }
    return dispatch(FileStorageActions.FILE_STORAGE_LOAD, search)
}

const actions = {
    [FileStorageActions.FILE_STORAGE_LOAD]: ({ commit, dispatch }: ActionContext<FileStorageState, RootState>, search: FileStorageSearch) => {
        commit(Mutations.FILE_STORAGE_SEARCH_REPLACE, search);
        return FileStorageHelper.loadFileStorages(search, commit, dispatch);
    },
    [FileStorageActions.FILE_STORAGE_RELOAD]: ({ state, commit, dispatch }: ActionContext<FileStorageState, RootState>) => {
        return FileStorageHelper.loadFileStorages(state.search, commit, dispatch);
    },
    [FileStorageActions.FILE_STORAGE_UPLOAD_BY_NAME]: ({ state, dispatch }: ActionContext<FileStorageState, RootState>, createFileStorage: CreateFileStorage) => {
        const existingFileStorage = Object.values(state.fileStorages).find(fs => equalsByName(fs, createFileStorage.fileStorage));
        const fileStoragePromise = existingFileStorage ? Promise.resolve(existingFileStorage) : fileStorageService.create(createFileStorage.fileStorage);
        fileStoragePromise.then(backedFileStorage => {
            fileStorageService.upload(backedFileStorage.id, createFileStorage.file)
                .then(() => UiFeedback.showMessage(dispatch, `File has been successfully uploaded.`))
                .catch(err => UiFeedback.showError(dispatch, `File could not be uploaded. Please try again.`, err))
                .finally(() => dispatch(FileStorageActions.FILE_STORAGE_RELOAD));
        })
    },
    [FileStorageActions.FILE_STORAGE_DELETE_BY_ID]: ({ dispatch }: ActionContext<FileStorageState, RootState>, fileStorageId: string) => {
        fileStorageService.deleteById(fileStorageId)
            .then(() => UiFeedback.showMessage(dispatch, `File has been successfully deleted.`))
            .catch(err => UiFeedback.showError(dispatch, `File could not be deleted. Please try again.`, err))
            .finally(() => dispatch(FileStorageActions.FILE_STORAGE_RELOAD));
    },
    [FileStorageActions.FILE_STORAGE_RESET]: ({ commit }: ActionContext<FileStorageState, RootState>) => {
        commit(Mutations.FILE_STORAGES_RESET);
    },
    [SopDetailEvents.SOP_DETAIL_CHANGED]: ({ dispatch, commit }: ActionContext<FileStorageState, RootState>, sopDetailChangedEvent: ArtifactChangedEvent) => {
        return handleChangeEvent('SOP', sopDetailChangedEvent, dispatch);
    },
    [TemplateDetailEvents.TEMPLATE_DETAIL_CHANGED]: ({ dispatch, commit }: ActionContext<FileStorageState, RootState>, templateDetailChangedEvent: ArtifactChangedEvent) => {
        return handleChangeEvent('TEMPLATE', templateDetailChangedEvent, dispatch);
    },
}

const mutations = {
    [Mutations.FILE_STORAGE_SEARCH_REPLACE]: (state: FileStorageState, search: FileStorageSearch) =>
        state.search = search,
    [Mutations.FILE_STORAGES_REPLACE]: (state: FileStorageState, fileStorages: FileStorageMap) =>
        state.fileStorages = fileStorages,
    [Mutations.FILE_STORAGES_RESET]: (state: FileStorageState) =>
        state.fileStorages = {},
}

export const FILE_STORAGE_MODULE: Module<FileStorageState, RootState> = {
    state: new FileStorageState(),
    getters,
    actions,
    mutations
};