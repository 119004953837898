









import Vue from 'vue';
import Component from 'vue-class-component';
import SopTile from '@/features/dashboard/SopTile.vue';
import TemplateTile from '@/features/dashboard/TemplateTile.vue';
import ApplicabilityTile from '@/features/dashboard/ApplicabilityTile.vue';
import TemplateContentTile from '@/features/dashboard/TemplateContentTile.vue';
import LastSopsTile from '@/features/dashboard/LastSopsTile.vue';

@Component({
  components: {
    SopTile,
    TemplateTile,
    TemplateContentTile,
    ApplicabilityTile,
    LastSopsTile,
  }
})
export default class Dashboard extends Vue {

}
