import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import SopOverviewView from '@/views/SopOverviewView.vue';
import SopDetailView from '@/views/SopDetailView.vue';
import RegulationsOverviewView from '@/views/RegulationsOverviewView.vue';
import RegulationsDetailView from '@/views/RegulationsDetailView.vue';
import TemplateContentsOverviewView from '@/views/TemplateContentsOverviewView.vue';
import RolesOverviewView from '@/views/RolesOverviewView.vue';
import TemplatesOverviewView from '@/views/TemplatesOverviewView.vue';
import TemplateDetailView from '@/views/TemplateDetailView.vue';
import TermsOverviewView from '@/views/TermsOverviewView.vue';
import ImpactAnalysisOverviewView from '@/features/impact-analysis/ImpactAnalysisOverviewView.vue';
import ImpactAnalysisDetailView from '@/features/impacts/ImpactAnalysisDetailView.vue';
import DashboardView from '@/features/dashboard/DashboardView.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/processes',
        meta: {
            docPath: 'manual/process-sop',
        }
    },
    {
        path: '/dashboard',
        component: DashboardView,
        name: 'Dashboard',
        meta: {
            docPath: 'manual/dashboard',
        }
    },
    {
        path: '/sops',
        redirect: '/processes',
        meta: {
            docPath: 'manual/process-sop',
        }
    },
    {
        path: '/sr-list',
        name: 'Gaplist',
        component: RegulationsOverviewView,
        meta: {
            breadcrumb: 'List of applied S&R',
            docPath: 'manual/sr-list',
        }
    },
    {
        path: '/sr-list/:id',
        name: 'RegulationsDetailView',
        component: RegulationsDetailView,
        meta: {
            breadcrumb: 'Gap list',
            docPath: 'manual/gap-analysis',
        }
    },
    {
        path: '/processes',
        name: 'Sops',
        component: SopOverviewView,
        meta: {
            breadcrumb: 'Process Overview',
            docPath: 'manual/process-overview',
        }
    },
    {
        path: '/processes/:id',
        name: 'SopDetailView',
        component: SopDetailView,
        meta: {
            breadcrumb: 'Process Detail',
            docPath: 'manual/process-sop/',
        }
    },
    {
        path: '/processes/:artifactId/current',
        name: 'SopDetailView',
        component: SopDetailView,
        meta: {
            breadcrumb: 'Current',
            docPath: 'manual/process-sop/',
        }
    },
    {
        path: '/template-contents',
        name: 'TemplateContents',
        component: TemplateContentsOverviewView,
        meta: {
            breadcrumb: 'Template Contents',
            docPath: 'manual/template-contents',
        }
    },
    {
        path: '/roles',
        name: 'Roles',
        component: RolesOverviewView,
        meta: {
            breadcrumb: 'Roles',
            docPath: 'manual/roles',
        }
    },
    {
        path: '/glossary',
        name: 'Glossary',
        component: TermsOverviewView,
        meta: {
            breadcrumb: 'Glossary',
            docPath: 'manual/glossary',
        }
    },
    {
        path: '/templates',
        name: 'Templates',
        component: TemplatesOverviewView,
        meta: {
            breadcrumb: 'Template Overview',
            docPath: 'manual/templates',
        }
    },
    {
        path: '/templates/:id',
        name: 'TemplateDetailView',
        component: TemplateDetailView,
        meta: {
            breadcrumb: 'Template Detail',
            docPath: 'manual/templates',
        }
    },
    {
        path: '/impact-analysis',
        name: 'ImpactAnalysisOverview',
        component: ImpactAnalysisOverviewView,
        meta: {
            breadcrumb: 'Impact Analysis Overview',
            docPath: 'manual/impact-analysis',
        }
    },
    {
        path: '/impact-analysis/:impactAnalysisId',
        name: 'ImpactAnalysisDetail',
        component: ImpactAnalysisDetailView,
        meta: {
            breadcrumb: 'Impact Analysis Detail'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
