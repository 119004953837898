import { toHumanReadable } from '@/utils/StringUtils';
import { ImpactAnalysis } from '@/features/impact-analysis/model';

export type ImpactType = 'NOT_DEFINED' | 'NO_IMPACT' | 'MINOR_IMPACT' | 'MAJOR_IMPACT';
export type ChangeType = 'NOT_DEFINED' | 'NO_CHANGE' | 'SIMILAR' | 'CHANGE' | 'NEW_REQUIREMENT' | 'DELETED_REQUIREMENT';

export const impactTypes: ImpactType[] = ['NOT_DEFINED', 'NO_IMPACT', 'MINOR_IMPACT', 'MAJOR_IMPACT'];
export const changeTypes: ChangeType[] = ['NOT_DEFINED', 'NO_CHANGE', 'SIMILAR', 'CHANGE', 'NEW_REQUIREMENT', 'DELETED_REQUIREMENT'];

export function humanReadableChangeType(type: ChangeType): string {
    switch (type) {
        case 'NOT_DEFINED':
            return 'undefined';
        case 'NEW_REQUIREMENT':
            return 'new';
        case 'DELETED_REQUIREMENT':
            return 'deleted';
        case 'NO_CHANGE':
            return 'none';
    }
    return toHumanReadable(type);
}

export function humanReadableImpactType(type: ImpactType): string {
    switch (type) {
        case 'NOT_DEFINED':
            return 'undefined';
        case 'NO_IMPACT':
            return 'none';
    }
    return toHumanReadable(type);
}

export interface ImpactDto {
    id: string;
    dbVersion: number;
    impactAnalysisId: string;
    impactType: ImpactType;
    impactDescription: string;
    changeType: ChangeType;
    changeDescription: string;
    requirementIdsFrom: string[];
    requirementIdsTo: string[];
}

export type ImpactAnalysisMap = { [key: string]: ImpactAnalysis };
export type ImpactMap = { [key: string]: ImpactDto };


export const NEW_IMPACT_ID = 'NEW_IMPACT_ID';

export function createLoadingImpact(id: string): ImpactDto {
    return {
        id,
        dbVersion: 0,
        impactAnalysisId: '',
        impactType: 'NOT_DEFINED',
        impactDescription: 'Loading...',
        changeType: 'NOT_DEFINED',
        changeDescription: 'Loading...',
        requirementIdsFrom: [],
        requirementIdsTo: [],
    };
}

export function createNewImpact(impactAnalysisId: string, partial: Partial<ImpactDto> = {}): ImpactDto {
    return {
        id: NEW_IMPACT_ID,
        dbVersion: 0,
        impactAnalysisId,
        impactType: 'NOT_DEFINED',
        impactDescription: '',
        changeType: 'NOT_DEFINED',
        changeDescription: '',
        requirementIdsFrom: [],
        requirementIdsTo: [],
        ...partial,
    };
}

export const isNew = (impact: ImpactDto) => impact.id === NEW_IMPACT_ID;
