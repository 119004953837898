


































import Vue from 'vue';
import Component from 'vue-class-component';
import { ChangeControlParticipant } from '@/model';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class ParticipantList extends Vue {
  @Prop()
  title: string;
  @Prop()
  participants: ChangeControlParticipant[];
}
