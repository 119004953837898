import { ImpactAnalysis, ImpactAnalysisSearch, ImpactAnalysisStatistics } from '@/features/impact-analysis/model';
import { restClient } from '@/store/rest-client';
import { ImpactAnalysisServiceMock } from '@/features/impact-analysis/impact-analysis-service-mock';

type ImpactAnalysisSearchResultDto = {
    results: ImpactAnalysisSearchDto[];
}

export type ImpactAnalysisSearchDto = {
    impactAnalysis: ImpactAnalysis;
    statistics: ImpactAnalysisStatistics;
}

export interface ImpactAnalysisService {

    loadAllImpactAnalyses(): Promise<ImpactAnalysisSearch>;

    loadImpactAnalysis(id: string): Promise<ImpactAnalysis>;

    deleteImpactAnalysis(id: string): Promise<void>;

    createImpactAnalysis(impactAnalysis: ImpactAnalysis): Promise<ImpactAnalysis>;

    updateImpactAnalysis(impactAnalysis: ImpactAnalysis): Promise<ImpactAnalysis>;
}

const API_IMPACT_ANALYSIS = process.env.VUE_APP_QMS_BASE_URL + '/qms-api/impact-analyses';
const API_IMPACT_ANALYSIS_SEARCH = process.env.VUE_APP_QMS_BASE_URL + '/qms-api/impact-analyses/search';

class ImpactAnalysisBackendService implements ImpactAnalysisService {

    loadAllImpactAnalyses(): Promise<ImpactAnalysisSearch> {
        return restClient.get<ImpactAnalysisSearchResultDto>(API_IMPACT_ANALYSIS_SEARCH)
            .then(response => response.data)
            .then(dto => ({ impactAnalyses: dto.results.map(e => e.impactAnalysis), statistics: dto.results.map(e => e.statistics) }) as ImpactAnalysisSearch);
    }

    loadImpactAnalysis(id: string): Promise<ImpactAnalysis> {
        return restClient.get<ImpactAnalysis>(API_IMPACT_ANALYSIS + '/' + id)
            .then(response => response.data);
    }

    createImpactAnalysis(impactAnalysis: ImpactAnalysis): Promise<ImpactAnalysis> {
        return restClient.post<ImpactAnalysis>(API_IMPACT_ANALYSIS, impactAnalysis)
            .then(response => response.data);
    }

    updateImpactAnalysis(impactAnalysis: ImpactAnalysis): Promise<ImpactAnalysis> {
        return restClient.put<ImpactAnalysis>(API_IMPACT_ANALYSIS + '/' + impactAnalysis.id, impactAnalysis)
            .then(response => response.data);
    }

    deleteImpactAnalysis(id: string): Promise<void> {
        return restClient.delete<void>(API_IMPACT_ANALYSIS + '/' + id).then();
    }

}

export const impactAnalysisService: ImpactAnalysisService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new ImpactAnalysisServiceMock() : new ImpactAnalysisBackendService();