




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class EditorHtmlWrapper extends Vue {

  @Prop() html: string;

}
