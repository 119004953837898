






import Vue from 'vue';
import Component from 'vue-class-component';
import StatisticPie from '@/components/common/StatisticPie.vue';
import { Prop } from 'vue-property-decorator';
import { QmsRegulationStatistics } from '@/features/regulations/model';

@Component({
  components: { StatisticPie },
})
export default class GapStatisticApplicability extends Vue {

  @Prop()
  statistics: QmsRegulationStatistics;
  @Prop({ default: '400px' })
  size: string;

  entrySelected(index: number) {
    this.$emit('entry-selected', index);
  }

  get applicabilityStatus() {
    return {
      labels: [
        'Applicable with target evidence (' + (this.statistics.applicableWithoutWarningCount) + ')',
        'Applicable with warnings (' + this.statistics.applicableWarningCount + ')',
        'Not applicable (' + (this.statistics.notApplicableWithoutWarningCount) + ')',
        'Not applicable with warnings (' + this.statistics.notApplicableWarningCount + ')',
        'Not defined (' + this.statistics.notDefinedCount + ')',
        'Information (' + this.statistics.informationCount + ')',
      ],
      datasets: [{
        label: '',
        data: [
          this.statistics.applicableWithoutWarningCount,
          this.statistics.applicableWarningCount,
          this.statistics.notApplicableCount,
          this.statistics.notApplicableWarningCount,
          this.statistics.notDefinedCount,
          this.statistics.informationCount,
        ],
        backgroundColor: ['#69c88c', '#f49f0a', '#d8e2dc', '#fbd99d', '#fce7c2', '#e3ded9',
        ],
        hoverOffset: 4,
      }],

    };
  }
}
