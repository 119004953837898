














































import Component, { mixins } from 'vue-class-component';
import { AuthActions } from '@/store/auth';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { authService, Unsubscribe } from '@/services/auth-service';
import TimingMixin from '@/components/common/TimingMixin.vue';

@Component({
  computed: {
    ...mapState<RootState>({
      error: (state: RootState) => state.auth.error
    })
  }
})
export default class LoginView extends mixins<TimingMixin>(TimingMixin) {

  email = '';
  password = '';
  resetPassword = false;
  resetPasswordRequested = false;
  pageReloadSec = 10;

  onAuthStateChangeUnsubscribe: Unsubscribe;

  created() {
    this.onAuthStateChangeUnsubscribe = authService.onAuthStateChanged((currentUser) => this.$store.dispatch(AuthActions.AUTH_STATE_CHANGED, currentUser));
  }

  destroyed() {
    this.onAuthStateChangeUnsubscribe();
  }

  login() {
    this.$store.dispatch(AuthActions.LOGIN, { email: this.email, password: this.password });
  }

  performPasswordReset() {
    authService.resetPassword(this.email)
        .then(() => this.resetPasswordRequested = true)
        .then(() => this.createTimeout(() => location.reload(), this.pageReloadSec * 1000));
  }

}
