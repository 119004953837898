import { ImpactAnalysis, ImpactAnalysisStatistics } from '@/features/impact-analysis/model';
import { ImpactDto, ImpactMap } from '@/features/impacts/model';
import { ImpactServiceMock } from '@/features/impacts/impact-service-mock';
import { restClient } from '@/store/rest-client';
import { toMap } from '@/utils/util';
import { ImpactAnalysisSearchDto } from '@/features/impact-analysis/impact-analysis-service';

export type LoadImpactAnalysisResponse = {
    impactAnalysis: ImpactAnalysis;
    impacts: ImpactMap;
    statistics: ImpactAnalysisStatistics;
}

export type ImpactsSearchResult = {
    impactAnalyses: { [key: string]: ImpactAnalysis };
    impacts: ImpactMap;
}

type ImpactsSearchResultDto = {
    impactAnalyses: ImpactAnalysis[];
    impacts: ImpactDto[];

}

export interface ImpactService {
    loadImpactAnalysis(impactAnalysisId: string): Promise<LoadImpactAnalysisResponse>;

    createImpact(impact: ImpactDto): Promise<ImpactDto>;

    updateImpact(impact: ImpactDto): Promise<ImpactDto>;

    deleteImpact(impactAnalysisId: string, impactId: string): Promise<void>;

    loadImpactsBySopArtifactId(sopArtifactId: string): Promise<ImpactsSearchResult>;
}


const API_IMPACT_ANALYSIS = process.env.VUE_APP_QMS_BASE_URL + '/qms-api/impact-analyses';
const API_IMPACTS_SUFFIX = '/impacts';

class ImpactBackendService implements ImpactService {

    loadImpactAnalysis(impactAnalysisId: string): Promise<LoadImpactAnalysisResponse> {
        return Promise.all([
            restClient.get<ImpactAnalysisSearchDto>(API_IMPACT_ANALYSIS + '/search/' + impactAnalysisId).then(response => response.data),
            restClient.get<ImpactDto[]>(API_IMPACT_ANALYSIS + '/' + impactAnalysisId + API_IMPACTS_SUFFIX).then(response => response.data),
        ]).then(result => ({
            impactAnalysis: result[0].impactAnalysis,
            statistics: result[0].statistics,
            impacts: toMap(result[1], r => r.id)
        }))
    }

    createImpact(impact: ImpactDto): Promise<ImpactDto> {
        return restClient.post<ImpactDto>(API_IMPACT_ANALYSIS + '/' + impact.impactAnalysisId + API_IMPACTS_SUFFIX, impact)
            .then(response => response.data);
    }

    updateImpact(impact: ImpactDto): Promise<ImpactDto> {
        return restClient.put<ImpactDto>(API_IMPACT_ANALYSIS + '/' + impact.impactAnalysisId + API_IMPACTS_SUFFIX + '/' + impact.id, impact)
            .then(response => response.data);
    }

    deleteImpact(impactAnalysisId: string, impactId: string): Promise<void> {
        return restClient.delete<void>(API_IMPACT_ANALYSIS + '/' + impactAnalysisId + API_IMPACTS_SUFFIX + '/' + impactId)
            .then(response => response.data);
    }

    loadImpactsBySopArtifactId(sopArtifactId: string): Promise<ImpactsSearchResult> {
        return restClient.get<ImpactsSearchResultDto>(`${ API_IMPACT_ANALYSIS }/search/by-sop-artifact/${ sopArtifactId }`)
            .then(response => ({
                impactAnalyses: toMap(response.data.impactAnalyses, impact => impact.id),
                impacts: toMap(response.data.impacts, impact => impact.id)
            }))
    }
}

export const impactService: ImpactService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new ImpactServiceMock() : new ImpactBackendService();