























import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopDetailActions, SopDetailGetters, SopDetailHelper } from '@/store/sop-detail';
import { UiFeedback } from '@/store/ui-feedback';

@Component({
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE,
    }),
  },
  methods: {
    ...mapMutations({
      ...SopDetailHelper.setterForSopFields('changeDescription'),
    }),
    ...mapActions({
      cancelSop: SopDetailActions.SOP_DETAIL_CANCEL,
    })
  }
})
export default class ChangeControlEntry extends Vue {

  saveSop() {
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_SAVE)
        .then(() => UiFeedback.showMessage(this.$store.dispatch, 'Change Description successfully saved.'));
  }

}
