

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Sop, SopParentArtifactMap } from '@/model';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { SopGetters } from '@/features/sops';
import { ReleaseState } from '@/model/ReleaseState';
import { Prop } from 'vue-property-decorator';

interface Item {
  id: string;
  versionId: string;
  artifactId: string;
  name: string;
  releaseState: ReleaseState;
  children: Item[];
}

function createNoSelectionLabelEntry(noSelectionLabel?: string): Item {
  return {
    id: '',
    versionId: '',
    artifactId: '',
    name: noSelectionLabel || 'No Selection',
    releaseState: 'DRAFT',
    children: [],
  };
}

function convert(sops: Sop[], parentArtifactIds: SopParentArtifactMap, sopArtifactId?: string, noSelectionLabel?: string): Item[] {
  if (!sops) {
    return [];
  }
  const convertedSops = sops
      .filter(s => parentArtifactIds[s.artifactId] === sopArtifactId)
      .map(sop => ({
        name: sop.name,
        id: sop.artifactId,
        versionId: sop.versionId,
        artifactId: sop.artifactId,
        releaseState: sop.releaseState,
        children: convert(sops, parentArtifactIds, sop.artifactId)
      }));
  const noSelectionLabelEntry = sopArtifactId ? [] : [createNoSelectionLabelEntry(noSelectionLabel)];
  return [...noSelectionLabelEntry, ...convertedSops];
}

@Component<SopHierarchy>({
  computed: {
    sops() {
      return convert(this.$store.getters[SopGetters.SELECTABLE_SOPS], (this.$store.state as RootState).sops.sopParentArtifactIds, undefined, this.noSelectionLabel)
    },
    ...mapState<RootState>({
      openSopsKey: (state: RootState) => state.auth.user.tenantId + '-open-sops',
    })
  }
})
export default class SopHierarchy extends Vue {

  @Prop()
  selectedSopArtifactId: string;
  @Prop()
  noSelectionLabel: string;

  openSopsKey: string;

  changeSelection(selection: string[]) {
    const sopArtifactId = selection.length > 0 ? selection[0] : '';
    this.$emit('selected-sop-version-id', sopArtifactId);
  }

  getOpenSops() {
    const openSopsValue = localStorage.getItem(this.openSopsKey);
    return openSopsValue ? JSON.parse(openSopsValue) : [];
  }

  setOpenSops(value: string[]) {
    localStorage.setItem(this.openSopsKey, JSON.stringify(value));
  }

}
