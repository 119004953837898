













import Vue from 'vue';
import Component from 'vue-class-component';
import { TermGetters } from '@/store/terms';
import { mapGetters } from 'vuex';
import { Prop } from 'vue-property-decorator';
import { Term } from '@/model';

@Component({
  computed: {
    ...mapGetters({
      redundantTerms: TermGetters.TERMS_BY_NAME_IGNORE_CASE
    }),
  }
})
export default class RedundantTerms extends Vue {

  @Prop() term: Term;

}
