import Vue from 'vue';
import { ActionContext, Commit, Module } from 'vuex';
import { RootState } from '@/store';
import { EMPTY_SOP, Sop, SopArtifact, SopArtifactAndVersion, SopDocumentHistoryEntry, SopSearchPart, Term, VersionState } from '@/model/Sop';
import { SopArtifactSummaries, SopDocumentHistory, sopService } from '@/services/sop-service';
import { sopBlockService } from '@/features/sop-block/sop-block-service';
import { SopActions, SopGetters } from '@/features/sops';
import { Process } from '@/model/Process';
import { termService } from '@/services/term-service';
import { ReleaseState, releaseState } from '@/model/ReleaseState';
import { UiFeedback } from '@/store/ui-feedback';
import { SopChangeControlActions } from '@/store/sop-change-control';
import { SopTemplatesActions } from '@/store/sop-templates';
import { ArtifactChangedEvent, ArtifactVersionIdentifier } from '@/model/Events';
import { safeVueSet, toMap } from '@/utils/util';
import { AuthGetters } from '@/store/auth';
import { SopDetailEvents } from '@/store/sop-detail/sop-detail-events';
import { SopBlockDetailEvents } from '@/features/sop-block/sop-block-detail/sop-block-detail-events';
import { SopBlockListEvents, SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { setterForFields } from '@/utils/VuexUtil';
import { SopBlock } from '@/features/sop-block/model';
import { SopBlockListMutations } from '@/features/sop-block/sop-block-list/store';

export type TermMap = { [key: string]: Term };
export type BlockMap = { [key: string]: SopBlock };

export const NEW_SOP_BLOCK_VERSION_ID = 'NEW_SOP_BLOCK_VERSION_ID';

export class SopDetailState {
    sopSummaries: SopArtifactSummaries = { summaries: [] };
    sopArtifact: SopArtifact;
    sop: Sop;
    terms: TermMap = {};
    termsDeleted: TermMap = {};
    blocksDeleted: BlockMap = {};
    sopDocumentHistory: SopDocumentHistoryEntry[] = [];
    unreleasedDocumentHistory?: SopDocumentHistoryEntry;

    // TODO: is this right here? probably not.
    contentStack = ['SopContent'];
}

export enum SopDetailActions {
    SOP_DETAIL_LOAD_CURRENT = 'SOP_DETAIL_LOAD_CURRENT',
    SOP_DETAIL_LOAD = 'SOP_DETAIL_LOAD',
    SOP_DETAIL_CANCEL = 'SOP_DETAIL_CANCEL',
    SOP_DETAIL_RESET = 'SOP_DETAIL_RESET',
    SOP_DETAIL_SAVE = 'SOP_DETAIL_SAVE',
    SOP_DETAIL_RELEASE_STATE_CHANGE = 'SOP_DETAIL_RELEASE_STATE_CHANGE',
    SOP_DETAIL_CREATE_NEW_VERSION = 'SOP_DETAIL_CREATE_NEW_VERSION',

    SOP_DETAIL_TERMS_LOAD = 'SOP_DETAIL_TERMS_LOAD',
    SOP_DETAIL_TERMS_DELETED_LOAD = 'SOP_DETAIL_TERMS_DELETED_LOAD',
    SOP_DETAIL_TERM_CREATE_ARTIFACT = 'SOP_DETAIL_TERM_CREATE_ARTIFACT',
    SOP_DETAIL_TERM_CREATE_VERSION = 'SOP_DETAIL_TERM_CREATE_VERSION',
    SOP_DETAIL_TERM_SAVE = 'SOP_DETAIL_TERM_SAVE',
    SOP_DETAIL_TERM_DELETE = 'SOP_DETAIL_TERM_DELETE',
    SOP_DETAIL_TERM_DELETE_UNDO = 'SOP_DETAIL_TERM_DELETE_UNDO',

    SOP_DETAIL_BLOCK_DELETE = 'SOP_DETAIL_BLOCK_DELETE',
    SOP_DETAIL_BLOCK_DELETE_UNDO = 'SOP_DETAIL_BLOCK_DELETE_UNDO',

    SOP_DETAIL_LOAD_DOCUMENT_HISTORY = 'SOP_DETAIL_LOAD_DOCUMENT_HISTORY',
    SOP_DETAIL_LOAD_SOP_SUMMARIES = 'SOP_DETAIL_LOAD_SOP_SUMMARIES',
    SOP_DETAIL_AS_CSV = 'SOP_DETAIL_AS_CSV'
}

export enum Mutations {
    SOP_DETAIL_REPLACE = 'SOP_DETAIL_REPLACE',
    SOP_DETAIL_FIELD_REPLACE = 'SOP_DETAIL_FIELD_REPLACE',
    SOP_ARTIFACT_DETAIL_FIELD_REPLACE = 'SOP_ARTIFACT_DETAIL_FIELD_REPLACE',
    SOP_DETAIL_DELETE = 'SOP_DETAIL_DELETE',

    SOP_DETAIL_TERMS_REPLACE = 'SOP_DETAIL_TERMS_REPLACE',
    SOP_DETAIL_TERM_REPLACE = 'SOP_DETAIL_TERM_REPLACE',
    SOP_DETAIL_TERM_DELETE = 'SOP_DETAIL_TERM_DELETE',
    SOP_DETAIL_TERMS_DELETED_REPLACE = 'SOP_DETAIL_TERMS_DELETED_REPLACE',

    SOP_DETAIL_BLOCKS_DELETED_REPLACE = 'SOP_DETAIL_BLOCKS_DELETED_REPLACE',

    SOP_DETAIL_DOCUMENT_HISTORY_REPLACE = 'SOP_DETAIL_DOCUMENT_HISTORY_REPLACE',

    SOP_DETAIL_CONTENT_STACK_REPLACE = 'SOP_DETAIL_CONTENT_STACK_REPLACE',

    SOP_DETAIL_SOP_SUMMARIES_REPLACE = 'SOP_DETAIL_SOP_SUMMARIES_REPLACE',
}

export class SopDetailHelper {

    static setterForSopFields(...fields: Array<keyof Sop>) {
        return setterForFields<Sop>(Mutations.SOP_DETAIL_FIELD_REPLACE, fields);
    }

    static setterForSopArtifactFields(...fields: Array<keyof SopArtifact>) {
        return setterForFields<SopArtifact>(Mutations.SOP_ARTIFACT_DETAIL_FIELD_REPLACE, fields);
    }

    static extractProcessFieldsByName(rootState: RootState, processFieldName: keyof Process) {
        if (!rootState.sopDetail.sop) {
            return [];
        }
        const allValuesOfSelectedFieldOfProcesses = rootState.sopDetail.sop.srProcessIds
            .map(processId => rootState.sr.processes[processId])
            .filter(process => !!process)
            .flatMap(process => process[processFieldName]);
        return [...new Set(allValuesOfSelectedFieldOfProcesses)];
    }

}

const LATEST_SOPS_KEY = 'latest-sops';


function loadRememberedSops(): Promise<SopArtifactSummaries> {
    const latestSopArtifactIds: string[] = JSON.parse(localStorage.getItem(LATEST_SOPS_KEY) ?? '[]');
    if (latestSopArtifactIds.length === 0) {
        return Promise.resolve({ summaries: [] });
    }
    return sopService.loadSopSummaries(latestSopArtifactIds);
}

export enum SopDetailGetters {
    TEMPLATE_CONTENT_IDS_OF_SELECTED_SR_PROCESSES = 'TEMPLATE_CONTENT_IDS_OF_SELECTED_SR_PROCESSES',
    PROCESS_CONTENT_IDS_OF_SELECTED_SR_PROCESSES = 'PROCESS_CONTENT_IDS_OF_SELECTED_SR_PROCESSES',
    TERM_IDS_OF_SELECTED_SR_PROCESSES = 'TERM_IDS_OF_SELECTED_SR_PROCESSES',
    SOP_IS_EDITABLE = 'SOP_IS_EDITABLE',
    CURRENT_RELEASE_STATE = 'CURRENT_RELEASE_STATE',
    SOP_TERM_VERSION_STATE = 'SOP_TERM_VERSION_STATE',
    SR_TEMPLATE_CONTENT_IDS_OF_LINKED_TEMPLATE_CONTENTS = 'SR_TEMPLATE_CONTENT_IDS_OF_LINKED_TEMPLATE_CONTENTS',
}

const getters = {
    [SopDetailGetters.TEMPLATE_CONTENT_IDS_OF_SELECTED_SR_PROCESSES]: (state: SopDetailState, getter: SopDetailGetters, rootState: RootState) =>
        SopDetailHelper.extractProcessFieldsByName(rootState, 'templateContentIds'),
    [SopDetailGetters.PROCESS_CONTENT_IDS_OF_SELECTED_SR_PROCESSES]: (state: SopDetailState, getter: SopDetailGetters, rootState: RootState) =>
        SopDetailHelper.extractProcessFieldsByName(rootState, 'sopContentIds'),
    [SopDetailGetters.TERM_IDS_OF_SELECTED_SR_PROCESSES]: (state: SopDetailState, getter: SopDetailGetters, rootState: RootState) =>
        SopDetailHelper.extractProcessFieldsByName(rootState, 'termIds'),
    [SopDetailGetters.SOP_IS_EDITABLE]: (state: SopDetailState, _2: SopDetailGetters, _1: RootState, rootGetters: any) => {
        return state.sop && state.sop.releaseState === 'DRAFT' && rootGetters[AuthGetters.IS_QMS_ADMIN];
    },
    [SopDetailGetters.CURRENT_RELEASE_STATE]: (state: SopDetailState) => releaseState(state.sop.releaseState),
    [SopDetailGetters.SOP_TERM_VERSION_STATE]: (state: SopDetailState) => (term: Term): VersionState => {
        if (state.termsDeleted[term.versionId]) {
            return 'deleted';
        }
        if (term.sopVersionCreated === state.sop.versionId && releaseState(state.sop.releaseState).isInWork()) {
            return term.version === 1 ? 'new' : 'changed';
        }
        return 'unchanged';
    },
    [SopDetailGetters.SR_TEMPLATE_CONTENT_IDS_OF_LINKED_TEMPLATE_CONTENTS]: (state: SopDetailState, getter: SopDetailGetters, rootState: RootState) =>
        Object.values(rootState.sopBlockList.blocks)
            .flatMap(block => block.templateContentIds)
            .map(tcId => rootState.templateContents.templateContents[tcId]?.srId)
            .filter(srTcId => !!srTcId),
};

const sopActions = {
    [SopDetailActions.SOP_DETAIL_LOAD_CURRENT]: ({ rootGetters, dispatch }: ActionContext<SopDetailState, RootState>, sopArtifactId: string) => {
        const sop: SopSearchPart = rootGetters[SopGetters.SOP_BY_ARTIFACT_ID](sopArtifactId);
        return dispatch(SopDetailActions.SOP_DETAIL_LOAD, sop.versionId);
    },
    [SopDetailActions.SOP_DETAIL_LOAD]: ({ commit, dispatch }: ActionContext<SopDetailState, RootState>, sopVersionId: string) => {
        return Promise.all([
            sopService.loadSopDetail(sopVersionId)
                .then((sopArtifactAndVersion) => {
                    commit(Mutations.SOP_DETAIL_REPLACE, sopArtifactAndVersion);
                    commit(SopBlockListMutations.SOP_BLOCKS_REPLACE, toMap(sopArtifactAndVersion.sop.sopBlocks.filter(block => block.versionState !== 'DELETED'), b => b.versionId));
                    commit(Mutations.SOP_DETAIL_BLOCKS_DELETED_REPLACE, toMap(sopArtifactAndVersion.sop.sopBlocks.filter(block => block.versionState === 'DELETED'), b => b.versionId));
                    const loadedBlockIdentifiers: ArtifactVersionIdentifier[] = Object.values(sopArtifactAndVersion.sop.sopBlocks)
                        .map(({ artifactId, versionId }) => ({ artifactId, versionId }));
                    const event: ArtifactChangedEvent = { versionId: sopVersionId, artifactId: sopArtifactAndVersion.sop.artifactId };
                    return Promise.all([
                        dispatch(SopDetailEvents.SOP_DETAIL_CHANGED, event),
                        dispatch(SopDetailEvents.SOP_BLOCKS_LOADED, loadedBlockIdentifiers)
                    ]);
                })
                .then(() => {
                    return Promise.all([
                        dispatch(SopDetailActions.SOP_DETAIL_TERMS_DELETED_LOAD, sopVersionId),
                        dispatch(SopTemplatesActions.SOP_TEMPLATES_LOAD, sopVersionId),
                        dispatch(SopChangeControlActions.SOP_RELEASE_LOAD),
                        dispatch(SopDetailActions.SOP_DETAIL_LOAD_DOCUMENT_HISTORY),
                    ]);
                }),
            dispatch(SopDetailActions.SOP_DETAIL_TERMS_LOAD, sopVersionId),
        ])
            .catch(err => UiFeedback.showError(dispatch, `SOP detail for ${ sopVersionId } couldn't be fetched. Please try again.`, err));
    },
    [SopDetailActions.SOP_DETAIL_CANCEL]: ({ state, dispatch }: ActionContext<SopDetailState, RootState>) => {
        return dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId);
    },
    [SopDetailActions.SOP_DETAIL_SAVE]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>) => {
        return sopService.updateSop(state.sopArtifact, state.sop)
            .then(loadResult => {
                commit(Mutations.SOP_DETAIL_REPLACE, loadResult);
                return dispatch(SopActions.SOPS_LOAD);
            })
            .catch(err => {
                UiFeedback.showError(dispatch, `SOP couldn't be updated. Please try again.`, err);
                return dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId);
            });
    },
    [SopDetailActions.SOP_DETAIL_RELEASE_STATE_CHANGE]: ({ state, dispatch }: ActionContext<SopDetailState, RootState>, nextState: ReleaseState) => {
        return sopService.changeReleaseState(state.sop, nextState)
            .catch(err => UiFeedback.showError(dispatch, `SOP couldn't change release-state. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId));
    },
    [SopDetailActions.SOP_DETAIL_CREATE_NEW_VERSION]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>) => {
        return sopService.createNewVersion(state.sop)
            .then(sop => {
                const artifactAndVersion: SopArtifactAndVersion = {
                    sopArtifact: state.sopArtifact,
                    sop
                };
                commit(Mutations.SOP_DETAIL_REPLACE, artifactAndVersion);
                return artifactAndVersion;
            })
            .catch(err => {
                UiFeedback.showError(dispatch, `SOP couldn't create / load release-state. Please try again.`, err);
                return dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId);
            });
    },
    [SopDetailActions.SOP_DETAIL_RESET]: ({ dispatch, commit }: ActionContext<SopDetailState, RootState>) => {
        commit(Mutations.SOP_DETAIL_DELETE);
        return dispatch(SopDetailEvents.SOP_DETAIL_CHANGED);
    },
    [SopDetailActions.SOP_DETAIL_LOAD_DOCUMENT_HISTORY]: ({ state, commit, dispatch }: ActionContext<SopDetailState, RootState>) => {
        if (!state.sop) {
            return Promise.resolve();
        }
        return sopService.loadSopDocumentHistory(state.sop.artifactId)
            .then(sopDocumentHistory => commit(Mutations.SOP_DETAIL_DOCUMENT_HISTORY_REPLACE, sopDocumentHistory))
            .catch(err => UiFeedback.showError(dispatch, 'Could not load version history of SOP. Please try again.', err))
    },
    [SopDetailActions.SOP_DETAIL_LOAD_SOP_SUMMARIES]: ({ commit }: ActionContext<SopDetailState, RootState>) => {
        return loadRememberedSops().then(sopSummaries => commit(Mutations.SOP_DETAIL_SOP_SUMMARIES_REPLACE, sopSummaries));
    },
    [SopDetailActions.SOP_DETAIL_AS_CSV]: ({state}: ActionContext<SopDetailState, RootState>) => {
      return sopService.asCsv(state.sop.versionId)
    },
};

const sopTermActions = {
    [SopDetailActions.SOP_DETAIL_TERMS_LOAD]: ({ commit, dispatch }: ActionContext<SopDetailState, RootState>, sopVersionId: string) =>
        termService.loadTerms(sopVersionId)
            .then(terms => commit(Mutations.SOP_DETAIL_TERMS_REPLACE, terms))
            .catch(err => UiFeedback.showError(dispatch, `Terms for sop version ${ sopVersionId } couldn't be fetched. Please try again.`, err)),
    [SopDetailActions.SOP_DETAIL_TERMS_DELETED_LOAD]: ({ commit, dispatch, state }: ActionContext<SopDetailState, RootState>, sopVersionId: string) => {
        if (releaseState(state.sop.releaseState).isInWork()) {
            termService.loadDeletedTerms(sopVersionId)
                .then(deletedTerms => commit(Mutations.SOP_DETAIL_TERMS_DELETED_REPLACE, deletedTerms))
                .catch(err => UiFeedback.showError(dispatch, `deleted SOP terms for ${ sopVersionId } couldn't be fetched. Please try again.`, err));
        } else {
            commit(Mutations.SOP_DETAIL_TERMS_DELETED_REPLACE, {});
        }
    },
    [SopDetailActions.SOP_DETAIL_TERM_CREATE_ARTIFACT]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>, term: Term) =>
        termService.createTerm(state.sop.versionId, term)
            .then(createdTerm => commit(Mutations.SOP_DETAIL_TERM_REPLACE, createdTerm))
            .catch(err => UiFeedback.showError(dispatch, 'Term couldn\'t be created. Please try again.', err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId)),
    [SopDetailActions.SOP_DETAIL_TERM_CREATE_VERSION]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>, termVersionId: string) => {
        return termService.createTermVersion(state.sop.versionId, termVersionId)
            .then(createdTermVersion => {
                commit(Mutations.SOP_DETAIL_TERM_REPLACE, createdTermVersion);
                return createdTermVersion;
            })
            .catch(err => UiFeedback.showError(dispatch, 'Term version couldn\'t be created. Please try again.', err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId));
    },
    [SopDetailActions.SOP_DETAIL_TERM_SAVE]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>, term: Term) =>
        termService.updateTerm(state.sop.versionId, term.versionId, term)
            .then(updatedTerm => commit(Mutations.SOP_DETAIL_TERM_REPLACE, updatedTerm))
            .catch(err => UiFeedback.showError(dispatch, `Term couldn't be updated. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId)),
    [SopDetailActions.SOP_DETAIL_TERM_DELETE]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>, termId: string) =>
        termService.deleteTerm(state.sop.versionId, termId)
            .then(() => commit(Mutations.SOP_DETAIL_TERM_DELETE, termId))
            .catch(err => UiFeedback.showError(dispatch, `Term couldn't be deleted. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId)),
    [SopDetailActions.SOP_DETAIL_TERM_DELETE_UNDO]: ({ dispatch, state }: ActionContext<SopDetailState, RootState>, termVersionId: string) => {
        return termService.undoDeletedTerm(state.sop.versionId, termVersionId)
            .catch(err => UiFeedback.showError(dispatch, `Deleted SopBlock couldn't be reverted. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId));
    },
};

const sopBlockActions = {
    [SopDetailActions.SOP_DETAIL_BLOCK_DELETE]: ({ dispatch, state }: ActionContext<SopDetailState, RootState>, blockVersionId: string) => {
        return sopBlockService.deleteSopBlock(state.sop.versionId, blockVersionId)
            .catch(err => UiFeedback.showError(dispatch, `SopBlock couldn't be deleted. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId));
    },
    [SopDetailActions.SOP_DETAIL_BLOCK_DELETE_UNDO]: ({ dispatch, state }: ActionContext<SopDetailState, RootState>, blockVersionId: string) => {
        return sopBlockService.undoDeletedSopBlock(state.sop.versionId, blockVersionId)
            .catch(err => UiFeedback.showError(dispatch, `Deleted SopBlock couldn't be reverted. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId));
    },
}

function removeSopBlockDetail(state: SopDetailState, commit: Commit) {
    if (state.contentStack[state.contentStack.length - 1] === 'SopBlockDetail') {
        const contentStack = [...state.contentStack];
        contentStack.pop();
        commit(Mutations.SOP_DETAIL_CONTENT_STACK_REPLACE, contentStack);
    }
    return Promise.resolve();
}

const eventListeners = {
    [SopBlockListEvents.SOP_BLOCK_SELECTED]: ({ commit, state }: ActionContext<SopDetailState, RootState>, sopBlockSelectedEvent: SopBlockSelectedEvent) => {
        if (!sopBlockSelectedEvent.forEditing) {
            return Promise.resolve();
        }
        if (state.contentStack[state.contentStack.length - 1] === 'SopBlockDetail') {
            return Promise.resolve();
        }
        commit(Mutations.SOP_DETAIL_CONTENT_STACK_REPLACE, [...state.contentStack, 'SopBlockDetail']);
        return Promise.resolve();
    },
    [SopBlockDetailEvents.SOP_BLOCK_DETAIL_CLOSED]: ({ commit, state, dispatch }: ActionContext<SopDetailState, RootState>) =>
        removeSopBlockDetail(state, commit)
            .then(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, state.sop.versionId)),
    [SopDetailEvents.SOP_DETAIL_CHANGED]: ({ commit, state }: ActionContext<SopDetailState, RootState>, event: ArtifactChangedEvent) => {
        if (event) {
            const latestSopArtifactIds: string[] = JSON.parse(localStorage.getItem(LATEST_SOPS_KEY) ?? '[]');
            const updatedLatestSopArtifactIds = latestSopArtifactIds.filter(i => i !== event.artifactId);
            updatedLatestSopArtifactIds.splice(0, 0, event.artifactId);
            localStorage.setItem(LATEST_SOPS_KEY, JSON.stringify(updatedLatestSopArtifactIds.slice(0, 5)));
        }
        return removeSopBlockDetail(state, commit);
    }
}

const mutations = {
    [Mutations.SOP_DETAIL_REPLACE]: (state: SopDetailState, sop: SopArtifactAndVersion) => {
        safeVueSet(state, 'sopArtifact', sop.sopArtifact);
        safeVueSet(state, 'sop', sop.sop);
    },
    [Mutations.SOP_DETAIL_FIELD_REPLACE]: (state: SopDetailState, sop: Partial<Sop>) => {
        if (!state.sop) {
            safeVueSet(state, 'sop', {});
        }
        Object.assign(state.sop, sop);
    },
    [Mutations.SOP_ARTIFACT_DETAIL_FIELD_REPLACE]: (state: SopDetailState, sopArtifact: Partial<SopArtifact>) => {
        if (!state.sopArtifact) {
            safeVueSet(state, 'sopArtifact', {});
        }
        Object.assign(state.sopArtifact, sopArtifact);
    },
    [Mutations.SOP_DETAIL_DELETE]: (state: SopDetailState) => {
        state.blocksDeleted = {};
        state.terms = {};
        state.sop = EMPTY_SOP
    },

    [Mutations.SOP_DETAIL_TERMS_REPLACE]: (state: SopDetailState, terms: TermMap) => safeVueSet(state, 'terms', terms),
    [Mutations.SOP_DETAIL_TERMS_DELETED_REPLACE]: (state: SopDetailState, terms: TermMap) => safeVueSet(state, 'termsDeleted', terms),
    [Mutations.SOP_DETAIL_TERM_REPLACE]: (state: SopDetailState, term: Term) => safeVueSet(state.terms, term.versionId, term),
    [Mutations.SOP_DETAIL_TERM_DELETE]: (state: SopDetailState, termVersionId: string) => Vue.delete(state.terms, termVersionId),

    [Mutations.SOP_DETAIL_BLOCKS_DELETED_REPLACE]: (state: SopDetailState, blocks: BlockMap) => safeVueSet(state, 'blocksDeleted', blocks),

    [Mutations.SOP_DETAIL_DOCUMENT_HISTORY_REPLACE]: (state: SopDetailState, sopDocumentHistory: SopDocumentHistory) => {
        safeVueSet(state, 'sopDocumentHistory', sopDocumentHistory.released);
        safeVueSet(state, 'unreleasedDocumentHistory', sopDocumentHistory.unreleased);
    },
    [Mutations.SOP_DETAIL_CONTENT_STACK_REPLACE]: (state: SopDetailState, contentStack: string[]) =>
        safeVueSet(state, 'contentStack', contentStack),
    [Mutations.SOP_DETAIL_SOP_SUMMARIES_REPLACE]: (state: SopDetailState, sopSummaries: SopArtifactSummaries) =>
        safeVueSet(state, 'sopSummaries', sopSummaries),
}

export const SOP_DETAIL_MODULE: Module<SopDetailState, RootState> = {
    state: new SopDetailState(),
    getters,
    actions: { ...sopActions, ...sopTermActions, ...sopBlockActions, ...eventListeners },
    mutations
};
