



































































































import Component, { mixins } from 'vue-class-component';
import CoLayout from '@/layouts/CoLayout.vue';
import CoHeader from '@/components/common/CoHeader.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import ImpactEntry from '@/features/impacts/components/ImpactEntry.vue';
import ImpactDetailWidget from '@/features/impacts/components/ImpactDetailWidget.vue';
import ImpactsStatisticWidget from '@/features/impacts/components/ImpactsStatisticWidget.vue';
import { Watch } from 'vue-property-decorator';
import { ImpactsActions, ImpactsGetter, ImpactsMutations, ImpactUiEntry } from '@/features/impacts/store';
import { SopActions } from '@/features/sops';
import { TemplateActions } from '@/store/templates';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import AuthMixin from '@/components/common/AuthMixin.vue';
import FabButton from '@/components/common/FabButton.vue';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import IncrementalList from '@/components/common/incremental-list/IncrementalList.vue';
import ImpactLeftSidebar from '@/features/impacts/components/ImpactLeftSidebar.vue';
import { RegulationActions, RegulationGetters } from '../regulations/regulation-store';
import { CommentRef, CommentsWidgetActions } from '@/features/comments/store';
import { RegulationDetailActions } from '@/store/regulation-detail';
import { ImpactCsvExporter } from '@/features/impacts/ImpactCsvExporter';

@Component({
  components: { ImpactLeftSidebar, CoLayout, IncrementalList, FabButton, ImpactEntry, CoHeader, ImpactDetailWidget, TemplateContentDetailWidget, ImpactsStatisticWidget },
  computed: {
    ...mapState<RootState>({
      isMarked: (state: RootState) => (entry: ImpactUiEntry) => !!state.impacts.selectedImpact && (state.impacts.selectedImpact?.uiId === entry.uiId || state.impacts.selectedImpact?.id === entry.impactId),
      impactUiEntryLoadingState: (state: RootState) => state.impacts.impactUiEntryLoadingState,
      impactAnalysis: (state: RootState) => state.impacts.impactAnalysis,
      impactUiEntries: (state: RootState) => state.impacts.impactUiEntries,
      editQmsRequirements: (state: RootState) => state.impacts.editQmsRequirements,
    }),
    ...mapGetters({
      uiImpactsWithoutToRequirements: ImpactsGetter.UI_IMPACTS_WITHOUT_TO_REQUIREMENTS,
      srRegulationById: RegulationGetters.SR_REGULATION_BY_ID_OR_LOADING,
      hasSrViewRights: ImpactsGetter.HAS_VIEW_RIGHTS,
    }),
    impactMode: {
      get(): boolean {
        return this.$store.state.impacts.isImpactMode;
      },
      set(newValue: boolean) {
        return this.$store.dispatch(ImpactsActions.IMPACT_MODE_REPLACE, newValue);
      }
    }
  },
  methods: {
    ...mapActions({
      selectEmptyImpact: ImpactsActions.SELECT_EMPTY_IMPACT_OF_TYPE_DELETED_REQUIREMENT,
    }),
    ...mapMutations({
      setImpactMode: ImpactsMutations.IMPACT_MODE_REPLACE,
    }),
  }
})
export default class ImpactAnalysisDetailView extends mixins<AuthMixin>(AuthMixin) {

  private static readonly IMPACT_HASH_KEY = '#impact=';

  anchoredImpactId = '';

  toggleEditQmsRequirements() {
    this.$store.commit(ImpactsMutations.TOGGLE_QMS_REQUIREMENTS_EDITABLE);
  }

  created() {
    this.$store.dispatch(ImpactsActions.IMPACT_MODE_LOAD);
    this.$store.dispatch(RegulationActions.REGULATIONS_WITH_STATISTICS_LOAD);
    this.$store.dispatch(SopActions.SOPS_LOAD);
    this.$store.dispatch(TemplateActions.TEMPLATES_SEARCH);
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);

    this.$store.dispatch(ImpactsActions.IMPACT_LOAD, this.$route.params.impactAnalysisId);

    const commentRef = { refType: 'IMPACT_ANALYSIS', refId: this.$route.params.impactAnalysisId } as CommentRef;
    this.$store.dispatch(CommentsWidgetActions.COMMENTS_LOAD_FOR_REF, commentRef);
    this.$store.dispatch(CommentsWidgetActions.COMMENTS_LOAD_CHILDREN_COUNT, commentRef);
    this.$store.dispatch(RegulationDetailActions.PRODUCT_REQUIREMENT_LOAD);
  }

  @Watch('$route.hash', { immediate: true, deep: true })
  hashChanged() {
    if (this.$route.hash.startsWith(ImpactAnalysisDetailView.IMPACT_HASH_KEY)) {
      this.anchoredImpactId = this.$route.hash.substring(ImpactAnalysisDetailView.IMPACT_HASH_KEY.length);
    }
  }

  public exportCsv() {
    ImpactCsvExporter.export((this.$store.state as RootState).impacts, this.$store.getters);
  }

  destroyed() {
    this.$store.dispatch(ImpactsActions.IMPACT_RESET);
  }
}
