import { restClient } from '@/store/rest-client';

import { createComparator, toMap, toMapWithValue } from '@/utils/util';
import { SrRegulation, SrRegulationMap } from '@/features/regulations/model';
import { Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import { SrRegulationServiceMock } from '@/services/sr-regulation-service-mock';

export type RegulationDto = { regulation: SrRegulation; requirements: Requirement[] };
export type RegulationDetail = { regulation: SrRegulation; requirements: RequirementMap };

export interface SrRegulationService {

    loadSrRegulations(): Promise<SrRegulationMap>;

    loadRegulationDetail(regulationId: string): Promise<RegulationDetail>;

    loadFilteredRequirements(regulationId: string, applicableRequirementIds: string[], infoRequirementIds: string[]): Promise<RequirementMap>;

}

const API_REGULATIONS = '/sr-api/regulations';

class RegulationServiceBackend implements SrRegulationService {

    loadSrRegulations(): Promise<SrRegulationMap> {
        return restClient.get<SrRegulation[]>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_REGULATIONS)
            .then(r => r.data.sort(createComparator('title')))
            .then(regulations => toMap(regulations, r => r.id));
    }

    loadRegulationDetail(regulationId: string): Promise<RegulationDetail> {
        return restClient.get<RegulationDto>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_REGULATIONS + '/' + regulationId)
            .then(r => r.data)
            .then(dto => ({
                regulation: dto.regulation,
                requirements: Object.freeze(toMapWithValue(dto.requirements, r => r.id, r => Object.freeze(r)))
            }));
    }

    loadFilteredRequirements(regulationId: string, applicableRequirementIds: string[], infoRequirementIds: string[]): Promise<RequirementMap> {
        return restClient.post<RegulationDto>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_REGULATIONS + '/' + regulationId + '/filter', { applicableRequirementIds, infoRequirementIds })
            .then(r => r.data)
            .then(dto => Object.freeze(toMapWithValue(dto.requirements, r => r.id, r => Object.freeze(r))));
    }

}

export const srRegulationService: SrRegulationService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new SrRegulationServiceMock() : new RegulationServiceBackend();
