


















import Vue from 'vue';
import Component from 'vue-class-component';
import { qmsRegulationService } from '@/features/regulations/qms-regulation-service';
import { QmsRegulationOverallStatistics } from '@/features/regulations/model';
import DoughnutChart, { DoughnutChartData } from '@/features/dashboard/DoughnutChart.vue';
import KeyNumbers from '@/features/dashboard/KeyNumbers.vue';

@Component({
  components: {
    DoughnutChart,
    KeyNumbers,
  }
})
export default class ApplicabilityTile extends Vue {

  keyNumbers: { label: string; value: number }[] = [];
  applicabilityChartData: DoughnutChartData = { title: '', entries: [] };
  evidenceChartData: DoughnutChartData = { title: '', entries: [] };

  created() {
    qmsRegulationService.loadOverallStatistics()
        .then(stats => this.renderData(stats));
  }

  renderData(stats: QmsRegulationOverallStatistics) {
    this.keyNumbers = [
      { label: 'Regulations', value: stats.regulationCount },
      { label: 'Requirements', value: stats.requirementCount },
      { label: 'Evidences', value: stats.evidenceCount },
    ]
    const applicableWithoutWarningCount = stats.overallStatistics.applicableWithoutWarningCount;
    const applicableWarningCount = stats.overallStatistics.applicableWarningCount;
    const notApplicableWithoutWarningCount = stats.overallStatistics.notApplicableWithoutWarningCount;
    const notApplicableWarningCount = stats.overallStatistics.notApplicableWarningCount;
    const notDefinedCount = stats.overallStatistics.notDefinedCount;
    const informationCount = stats.overallStatistics.informationCount;
    this.applicabilityChartData = {
      title: 'Applicability Assessments',
      entries: [
        {
          label: 'Applicable with target evidence (' + (applicableWithoutWarningCount) + ')',
          value: applicableWithoutWarningCount,
          color: '#69c88c',
        },
        {
          label: 'Applicable with warnings (' + applicableWarningCount + ')',
          value: applicableWarningCount,
          color: '#f49f0a',
        },
        {
          label: 'Not applicable (' + (notApplicableWithoutWarningCount) + ')',
          value: notApplicableWithoutWarningCount,
          color: '#d8e2dc',
        },
        {
          label: 'Not applicable with warnings (' + notApplicableWarningCount + ')',
          value: notApplicableWarningCount,
          color: '#fbd99d',
        },
        {
          label: 'Not defined (' + notDefinedCount + ')',
          value: notDefinedCount,
          color: '#fce7c2',
        },
        {
          label: 'Information (' + informationCount + ')',
          value: informationCount,
          color: '#e3ded9',
        },
      ]
    }
    const confirmedEvidenceCount = stats.overallStatistics.confirmedEvidenceCount;
    const deprecatedEvidenceCount = stats.overallStatistics.deprecatedEvidenceCount;
    const unconfirmedEvidenceCount = stats.overallStatistics.unconfirmedEvidenceCount;
    this.evidenceChartData = {
      title: 'Evidences',
      entries: [
        {
          label: 'Confirmed evidences (' + confirmedEvidenceCount + ')',
          value: confirmedEvidenceCount,
          color: '#69c88c',
        }, {
          label: 'Deprecated evidences / gaps (' + deprecatedEvidenceCount + ')',
          value: deprecatedEvidenceCount,
          color: '#f49f0a',
        },
        {
          label: 'Unconfirmed evidences / gaps (' + unconfirmedEvidenceCount + ')',
          value: unconfirmedEvidenceCount,
          color: '#fbd99d',
        }
      ]
    };
  }
}

