import { ProductRequirement } from '@/features/sop-block/model';
import { restClient } from '@/store/rest-client';

export interface ProductRequirementService {

    all(): Promise<ProductRequirement[]>;

    interpretationAsCsv(regulationId: string): Promise<Blob>;

}

class ProductRequirementBackendService implements ProductRequirementService {
    all(): Promise<ProductRequirement[]> {
        return restClient.get<ProductRequirement[]>(`${ process.env.VUE_APP_QMS_BASE_URL }/qms-api/product-requirements`)
            .then(resp => resp.data);
    }

    interpretationAsCsv(regulationId: string): Promise<Blob> {
        return restClient.get<string>(`${ process.env.VUE_APP_QMS_BASE_URL }/qms-api/qms-regulations/csv-export/${ regulationId }`, { headers: { accept: 'text/csv' } })
            .then(r => r.data)
            .then(resp => new Blob([resp], { type: 'text/csv' }));
    }


}

export const productRequirementService: ProductRequirementService = new ProductRequirementBackendService();