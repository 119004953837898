






















import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import SopHierarchy from '@/features/sops/sop-hierarchiy/SopHierarchy.vue';
import { TemplateActions } from '@/store/templates';

/**
 * Current this component is only used by templates. Refactor it when used on multiple places.
 * Maybe the field 'selectedSopArtifactId' could be stored on the 'sops'-store... (instead on the templates-store)
 */
@Component({
  components: { SopHierarchy },
  computed: {
    ...mapState<RootState>({
      selectedSopArtifactId: (state: RootState) => state.templates.selectedSopArtifactId,
    })
  }
})
export default class SopFilterTree extends Vue {

  changeSelection(sopArtifactId: string) {
    this.$store.dispatch(TemplateActions.TEMPLATES_FILTER_BY_SOP_ARTIFACT_ID, sopArtifactId);
  }

}
