






















import Component, { mixins } from 'vue-class-component';
import SopBlockDetailMixin from '@/features/sop-block/sop-block-detail/SopBlockDetailMixin.vue';

@Component
export default class SopBlockDetailLayout extends mixins<SopBlockDetailMixin>(SopBlockDetailMixin) {

}
