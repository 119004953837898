import { TemplateContent, TemplateContentSearchResult } from '@/features/template-content/template-content-model';
import { TemplateContentMap, TemplateContentOverallStatistics } from '@/features/template-content/template-content-store';
import { TemplateContentServiceMock } from '@/features/template-content/template-content-service-mock';
import { restClient } from '@/store/rest-client';
import { toMap } from '@/utils/util';


export interface TemplateContentService {

    searchTemplateContents(): Promise<TemplateContentSearchResult>;

    loadTemplateContents(): Promise<TemplateContentMap>;

    statistics(): Promise<TemplateContentOverallStatistics>;

    createTemplateContent(templateContent: Omit<TemplateContent, 'id'>): Promise<TemplateContent>;

    updateTemplateContent(templateContent: TemplateContent): Promise<TemplateContent>;

    deleteTemplateContent(templateContentId: string): Promise<void>;
}

const API_TEMPLATE_CONTENT = '/qms-api/template-contents';
const API_TEMPLATE_CONTENT_SEARCH = API_TEMPLATE_CONTENT + '/search';

class TemplateContentBackendService implements TemplateContentService {

    searchTemplateContents(): Promise<TemplateContentSearchResult> {
        return restClient.get<TemplateContentSearchResult>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT_SEARCH)
            .then(response => response.data);
    }

    statistics(): Promise<TemplateContentOverallStatistics> {
        return restClient.get<TemplateContentOverallStatistics>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT + '/statistics')
            .then(response => response.data);
    }

    loadTemplateContents(): Promise<TemplateContentMap> {
        return restClient.get<TemplateContent[]>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT)
            .then(response => response.data)
            .then(templateContents => toMap(templateContents, c => c.id));
    }

    createTemplateContent(templateContent: Omit<TemplateContent, 'id'>): Promise<TemplateContent> {
        return restClient.post<TemplateContent>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT, templateContent)
            .then(t => t.data);
    }

    updateTemplateContent(templateContent: TemplateContent): Promise<TemplateContent> {
        return restClient.put<TemplateContent>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT + '/' + templateContent.id, templateContent)
            .then(t => t.data);
    }

    deleteTemplateContent(templateContentId: string): Promise<void> {
        return restClient.delete<TemplateContent>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT + '/' + templateContentId)
            .then();
    }

}

export const templateContentService: TemplateContentService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new TemplateContentServiceMock() : new TemplateContentBackendService();