






































import Component from 'vue-class-component';
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { SopActions, SopGetters } from '@/features/sops';
import { Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { SopOrderChange } from '@/services/sop-service';
import { SopSearchPart } from '@/model';

@Component({
  components: { draggable },
  computed: {
    ...mapGetters({
      sopByArtifactId: SopGetters.SOP_BY_ARTIFACT_ID,
    }),
  },
})
export default class SopOrdering extends Vue {

  @Prop() parentArtifactId: string;

  sopByArtifactId: (artifactId: string) => SopSearchPart;
  selectedItem: any = {};
  orderedSopArtifactIds: string[] = [];

  @Watch('parentArtifactId', { immediate: true })
  updateOrderList() {
    this.orderedSopArtifactIds = this.$store.getters[SopGetters.ORDERED_SOP_ARTIFACT_IDS_BY_PARENT_ARTIFACT_ID](this.parentArtifactId);
  }

  getSopByArtifactIds(): SopSearchPart[] {
    if (!this.orderedSopArtifactIds) {
      return [];
    }
    return this.orderedSopArtifactIds.map(this.sopByArtifactId);
  }

  confirmOrder() {
    const orderChange: SopOrderChange = {
      parentArtifactId: this.parentArtifactId,
      orderedArtifactIds: this.orderedSopArtifactIds
    }

    this.$store.dispatch(SopActions.SOP_ORDER_CHANGE, orderChange)
        .then(this.closeOrder);
  }

  closeOrder() {
    this.$emit('ordered-artifact-ids-changed', this.orderedSopArtifactIds);
  }

}

