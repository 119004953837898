export interface SrTemplateContent {
    id: string;
    name: string;
    description: string;
}

export const EMPTY_SR_TEMPLATE_CONTENT: SrTemplateContent = {
    id: '', name: '', description: ''
}

export interface SrProcessContent {
    id: string;
    name: string;
    description: string;
}

export const EMPTY_SR_PROCESS_CONTENT: SrProcessContent = {
    id: '', name: '', description: ''
}