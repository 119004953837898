





































































import Vue from 'vue';
import Component from 'vue-class-component';
import UserSelection from '@/components/common/UserSelection.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopChangeControlActions, SopChangeControlGetters } from '@/store/sop-change-control';
import ParticipantList from '@/components/sop-change-control/ParticipantList.vue';

@Component({
  components: { ParticipantList, UserSelection },
  computed: {
    ...mapState<RootState>({
      changeControl: (state: RootState) => state.sopChangeControl.changeControl,
    }),
    ...mapGetters({
      currentUserIsReleaseParticipant: SopChangeControlGetters.CURRENT_USER_IS_PARTICIPANT,
      currentUserHasParticipated: SopChangeControlGetters.CURRENT_USER_HAS_PARTICIPATED,
      currentUserChangeControlParticipant: SopChangeControlGetters.CURRENT_USER_CHANGE_CONTROL_PARTICIPANT,
      haveAllParticipantsApproved: SopChangeControlGetters.HAVE_ALL_PARTICIPANTS_APPROVED
    }),
  },
  methods: {
    ...mapActions({
      cancelRelease: SopChangeControlActions.SOP_CANCEL_RELEASE,
      completeRelease: SopChangeControlActions.SOP_COMPLETE_RELEASE,
      approveRelease: SopChangeControlActions.SOP_APPROVE_RELEASE,
      declineRelease: SopChangeControlActions.SOP_DECLINE_RELEASE,
      revertDecision: SopChangeControlActions.SOP_REVERT_RELEASE_DECISION
    })
  }
})
export default class ChangeControlInRelease extends Vue {

  comment = '';

  approveReleaseWithComment() {
    (this as any).approveRelease(this.comment).then(() => {
      this.comment = ''
    });
  }

  declineReleaseWithComment() {
    (this as any).declineRelease(this.comment).then(() => {
      this.comment = ''
    });
  }

  revertDecisionWithComment() {
    const currentComment = (this as any).currentUserChangeControlParticipant.comment;
    (this as any).revertDecision(this.comment).then(() => {
      this.comment = currentComment
    });
  }


}
