






import Vue from 'vue';
import Component from 'vue-class-component';
import StatisticPie from '@/components/common/StatisticPie.vue';
import { Prop } from 'vue-property-decorator';
import { QmsRegulationStatistics } from '@/features/regulations/model';

@Component({
  components: { StatisticPie }
})
export default class GapStatisticEvidence extends Vue {

  @Prop()
  statistics: QmsRegulationStatistics;
  @Prop({ default: '400px' })
  size: string;

  entrySelected(index: number) {
    this.$emit('entry-selected', index);
  }

  get evidenceStatus() {
    return {
      labels: [
        'Confirmed evidences (' + this.statistics.confirmedEvidenceCount + ')',
        'Deprecated evidences / gaps (' + this.statistics.deprecatedEvidenceCount + ')',
        'Unconfirmed evidences / gaps (' + this.statistics.unconfirmedEvidenceCount + ')',
      ],
      datasets: [{
        label: '',
        data: [
          this.statistics.confirmedEvidenceCount,
          this.statistics.deprecatedEvidenceCount,
          this.statistics.unconfirmedEvidenceCount,
        ],
        backgroundColor: [
          '#69c88c',
          '#f49f0a',
          '#fbd99d',
        ],
        hoverOffset: 4,
      }]
    };
  }
}
