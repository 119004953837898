import { ApplicabilityWarning, EvidenceImplementationState, QmsRequirementApplicability } from '@/services/qms-requirement-service';

export type QmsRequirement = {
    requirementType: 'INTERPRETATION' | 'INCLUDED_IN_ANCESTOR';
    dbVersion: number;
    regulationId: string;
    requirementId: string;
    applicability: QmsRequirementApplicability;
    applicabilityReason: string;
    notes: string;
    targetSopArtifactIds: string[];
    actualSopArtifactIds: string[];
    sopEvidenceStates: { [key: string]: EvidenceImplementationState };

    targetTemplateArtifactIds: string[];
    actualTemplateArtifactIds: string[];
    templateEvidenceStates: { [key: string]: EvidenceImplementationState };

    templateContentIds: string[];
    referencedSopBlockArtifactIds: string[];
    targetSopBlockArtifactIds: string[];

    applicabilityWarnings: ApplicabilityWarning[];
    interpretedInRequirementId?: string;
}

export function createEmptyQmsRequirement(partial: Partial<QmsRequirement> = {}): QmsRequirement {
    return {
        requirementType: 'INTERPRETATION',
        dbVersion: 0,
        regulationId: '',
        requirementId: '',
        applicability: 'NOT_DEFINED',
        applicabilityReason: '',
        notes: '',
        targetSopArtifactIds: [],
        actualSopArtifactIds: [],
        sopEvidenceStates: {},
        targetTemplateArtifactIds: [],
        actualTemplateArtifactIds: [],
        templateEvidenceStates: {},
        referencedSopBlockArtifactIds: [],
        targetSopBlockArtifactIds: [],
        templateContentIds: [],
        applicabilityWarnings: [],
        ...partial
    };
}