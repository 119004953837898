


























































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { ActualEvidenceStatus, ApplicabilityAssessmentStatus, emptyFilter, Filter, RegulationDetailActions } from '@/store/regulation-detail';
import RegulationToc from '@/components/common/RegulationToc.vue';
import { Watch } from 'vue-property-decorator';

@Component({
  components: { RegulationToc },
  computed: {
    ...mapState<RootState>({
      filter: (state: RootState) => state.regulationDetail.filter,
      requirements: (state: RootState) => Object.values(state.regulationDetail.requirements)
    })
  }
})
export default class RequirementSidebar extends Vue {

  filter: Filter;
  localFilter: Filter = emptyFilter();


  @Watch('filter', { immediate: true })
  updateFilter() {
    this.localFilter = { ...this.filter };
  }

  applyFilter() {
    const filter: Filter = {
      ...emptyFilter(),
      ...this.localFilter,
    }
    this.$store.dispatch(RegulationDetailActions.REQUIREMENTS_FILTER, filter);
    this.scrollToRequirementKey();
  }

  resetFilter() {
    this.localFilter = emptyFilter();
    this.applyFilter();
  }

  scrollToRequirementKey(requirementKey = '') {
    this.$store.dispatch(RegulationDetailActions.ANCHORED_REQUIREMENT_KEY_UPDATE, requirementKey);
  }

  applicabilityAssessmentStatusEntries: ApplicabilityAssessmentStatusEntry[] = [
    {
      key: 'ALL',
      label: 'All',
    }, {
      key: 'APPLICABLE',
      label: 'Applicable',
    }, {
      key: 'APPLICABLE_WITH_WARNING',
      label: 'Applicable with warnings',
    }, {
      key: 'NOT_APPLICABLE',
      label: 'Not applicable',
    }, {
      key: 'NOT_APPLICABLE_WITH_WARNING',
      label: 'Not applicable with warnings',
    }, {
      key: 'NOT_DEFINED',
      label: 'Not defined',
    }, {
      key: 'INFORMATION',
      label: 'Information',
    },];

  actualEvidenceStatusEntries: ActualEvidenceStatusEntry[] = [
    {
      key: 'ALL',
      label: 'All',
    }, {
      key: 'CONFIRMED',
      label: 'Confirmed evidences',
    }, {
      key: 'GAPS',
      label: 'Unconformed evidences / gaps',
    }, {
      key: 'DEPRECATED',
      label: 'Deprecated evidences',
    },];
}

type ApplicabilityAssessmentStatusEntry = {
  key: ApplicabilityAssessmentStatus;
  label: string;
}

type ActualEvidenceStatusEntry = {
  key: ActualEvidenceStatus;
  label: string;
}

