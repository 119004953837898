










import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { SopBlockTypeUi } from '@/features/sop-block/model';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';
import SopBlockEntryHeader from '@/features/sop-block/sop-block-list/SopBlockEntryHeader.vue';
import SopBlockEntryTiptap from '@/features/sop-block/sop-block-list/SopBlockEntryTiptap.vue';
import SopBlockEntryStep from '@/features/sop-block/sop-block-list/SopBlockEntryStep.vue';
import SopBlockEntryDrawio from '@/features/sop-block/sop-block-list/SopBlockEntryDrawio.vue';

@Component({
  components: { SopBlockEntryHeader, SopBlockEntryTiptap, SopBlockEntryStep, SopBlockEntryDrawio }
})
export default class SopBlockEntry extends mixins<SopBlockEntryMixin>(SopBlockEntryMixin) {

  @Prop() blockVersionId: string;

  get componentName() {
    return SopBlockTypeUi.getEntryComponentName(this.block.type);
  }

}
