import { createEmptyUser, User } from '@/model/User';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import Vue from 'vue';
import { userService } from '@/services/user-service';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet } from '@/utils/util';

export type UserMap = { [key: string]: User };

export class UserState {
    users: UserMap = {};
}

export enum UserActions {
    USERS_LOAD = 'USERS_LOAD',
}

export enum UserGetters {
    USER_BY_ID_OR_EMPTY = 'USER_BY_ID_OR_EMPTY'
}

export enum Mutations {
    USERS_REPLACE = 'USERS_REPLACE',
    USER_REPLACE = 'USER_REPLACE',
    USER_DELETE = 'USER_DELETE',
}

export function createLoading(userId: string): User {
    return {
        ...createEmptyUser(),
        userId,
        displayName: 'Loading...'
    }
}

const getters = {
    [UserGetters.USER_BY_ID_OR_EMPTY]: (state: UserState) => (userId: string) => {
        if (!userId || userId.length === 0) {
            return createEmptyUser();
        }
        return state.users[userId] || createLoading(userId)
    }
}

const actions = {
    [UserActions.USERS_LOAD]: ({ commit, dispatch }: ActionContext<UserState, RootState>) => {
        return userService.loadUsers()
            .then(users => commit(Mutations.USERS_REPLACE, users))
            .catch(err => UiFeedback.showError(dispatch, `Users couldn't be fetched. Please try again.`, err));
    },
}

const mutations = {
    [Mutations.USERS_REPLACE]: (state: UserState, users: UserMap) =>
        safeVueSet(state, 'users', users),
    [Mutations.USER_REPLACE]: (state: UserState, user: User) =>
        safeVueSet(state.users, user.userId, user),
    [Mutations.USER_DELETE]: (state: UserState, userId: string) =>
        Vue.delete(state.users, userId)
}

export const USER_MODULE: Module<UserState, RootState> = {
    state: new UserState(),
    getters,
    actions,
    mutations
};