import { TermMap } from '@/store/sop-detail';
import { Term, TermWrite } from '@/model';
import { restClient } from '@/store/rest-client';
import { toMap } from '@/utils/util';
import { TermServiceMock } from '@/services/term-service-mock';
import { TermSearchDto } from '@/store/terms';

export interface TermService {

    allTerms(): Promise<TermSearchDto[]>;

    loadTerms(sopVersionId: string): Promise<TermMap>;

    loadDeletedTerms(sopVersionId: string): Promise<TermMap>;

    createTerm(sopVersionId: string, term: TermWrite): Promise<Term>;

    createTermVersion(sopVersionId: string, termVersionId: string): Promise<Term>;

    updateTerm(sopVersionId: string, termVersionId: string, term: TermWrite): Promise<Term>;

    deleteTerm(sopVersionId: string, termVersionId: string): Promise<void>;

    undoDeletedTerm(sopVersionId: string, termVersionId: string): Promise<void>;

}

const ALL_TERMS_URL = process.env.VUE_APP_QMS_BASE_URL + `/qms-api/terms/search`;

function getTermsUrl(sopVersionId: string) {
    return process.env.VUE_APP_QMS_BASE_URL + `/qms-api/sops/sop-artifact-uuid/versions/${ sopVersionId }/terms`;
}

function getTermVersionUrl(sopVersionId: string, versionId?: string) {
    const termsUrl = getTermsUrl(sopVersionId);
    return `${ termsUrl }/term-artifact-uuid/versions/${ versionId }`;
}

class TermBackendService implements TermService {

    allTerms(): Promise<TermSearchDto[]> {
        return restClient.get<TermSearchDto[]>(ALL_TERMS_URL)
            .then(response => response.data);
    }

    loadTerms(sopVersionId: string): Promise<TermMap> {
        return restClient.get<Term[]>(getTermsUrl(sopVersionId))
            .then(response => response.data)
            .then(terms => toMap(terms, s => s.versionId));
    }

    loadDeletedTerms(sopVersionId: string): Promise<TermMap> {
        return restClient.get<Term[]>(getTermsUrl(sopVersionId) + '/deleted')
            .then(response => response.data)
            .then(terms => toMap(terms, s => s.versionId));
    }

    createTerm(sopVersionId: string, term: Term): Promise<Term> {
        return restClient.post<Term>(getTermsUrl(sopVersionId), term)
            .then(response => response.data);
    }

    createTermVersion(sopVersionId: string, termVersionId: string): Promise<Term> {
        return restClient.post<Term>(getTermVersionUrl(sopVersionId, termVersionId))
            .then(response => response.data);
    }

    updateTerm(sopVersionId: string, termVersionId: string, term: Term): Promise<Term> {
        return restClient.put<Term>(getTermVersionUrl(sopVersionId, termVersionId), term)
            .then(response => response.data);
    }

    deleteTerm(sopVersionId: string, termVersionId: string): Promise<void> {
        return restClient.delete<void>(getTermVersionUrl(sopVersionId, termVersionId))
            .then();
    }

    undoDeletedTerm(sopVersionId: string, termVersionId: string): Promise<void> {
        return restClient.delete<void>(getTermsUrl(sopVersionId) + '/deleted/' + termVersionId)
            .then();
    }

}

export const termService: TermService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new TermServiceMock() : new TermBackendService();
