import moment from 'moment';

export interface ChangeControl {
    id: string;
    dbVersion: number;
    artifactVersionId: string;
    initiatedBy: string;
    initiatedByDisplayName: string;
    initiatedAt?: moment.Moment;
    initiatedComment: string;
    expectedDecider: string;
    expectedDeciderDisplayName: string;
    decidedBy?: string;
    decidedByDisplayName?: string;
    decidedAt?: moment.Moment;
    decidedComment?: string;
    state: ChangeControlState;
    authors: ChangeControlParticipant[];
    approvers: ChangeControlParticipant[];
}

export interface ChangeControlParticipant {
    id: string;
    dbVersion: number;
    userId: string;
    userDisplayName: string;
    decision: ChangeControlDecisionType;
    decidedAt?: moment.Moment;
    comment: string;
}

export type ChangeControlState = 'RUNNING' | 'COMPLETED' | 'CANCELLED';
export type ChangeControlDecisionType = 'OPEN' | 'APPROVED' | 'DECLINED';

export function createEmptyChangeControl(): ChangeControl {
    return {
        id: '',
        dbVersion: 0,
        artifactVersionId: '',
        initiatedBy: '',
        initiatedByDisplayName: '',
        initiatedAt: moment(),
        initiatedComment: '',
        expectedDecider: '',
        expectedDeciderDisplayName: '',
        state: 'RUNNING',
        authors: [],
        approvers: [],
    };
}

export interface ChangeControlWrite {
    comment: string;
    expectedDecider: string;
    authorUserIds: string[];
    approverUserIds: string[];
}

export function createEmptyChangeControlWrite(): ChangeControlWrite {
    return {
        comment: '',
        expectedDecider: '',
        authorUserIds: [],
        approverUserIds: []
    }
}