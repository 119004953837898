










import Vue from 'vue';
import Component from 'vue-class-component';
import { SopOverallStatistics, sopService } from '@/services/sop-service';
import SimpleTile from '@/features/dashboard/SimpleTile.vue';
import { DoughnutChartData } from '@/features/dashboard/DoughnutChart.vue';

@Component({ components: { SimpleTile } })
export default class SopTile extends Vue {

  keyNumbers: { label: string; value: number }[] = [];
  chartData: DoughnutChartData = { title: '', entries: [] };

  created() {
    sopService.statistics().then(stats => this.renderData(stats));
  }

  renderData(stats: SopOverallStatistics) {
    const released = stats.countByReleaseState['RELEASED'] ?? 0;
    const inReview = stats.countByReleaseState['IN_RELEASE'] ?? 0;
    const inDraft = stats.countByReleaseState['DRAFT'] ?? 0;
    const obsolete = stats.countByReleaseState['OBSOLETE'] ?? 0;
    this.keyNumbers = [
      { label: 'Released Processes', value: stats.latestReleasedCount },
      { label: 'Released Steps', value: stats.latestReleasedStepCount },
    ];
    this.chartData = {
      title: 'Processes by Release State',
      entries: [
        { label: `Released (${ released })`, value: released, color: '#69c88c' },
        { label: `In review (${ inReview })`, value: inReview, color: '#f49f0a' },
        { label: `In draft (${ inDraft })`, value: inDraft, color: '#fbd99d' },
        { label: `Obsolete (${ obsolete })`, value: obsolete, color: '#fce7c2' },
      ],
    };
  }
}
