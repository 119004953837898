



































































































import Vue from 'vue';

import Component from 'vue-class-component';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { Bold, BulletList, Heading, History, Image, Italic, Link, ListItem, OrderedList, Table, TableCell, TableHeader, TableRow, Underline } from 'tiptap-extensions';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: { EditorContent, EditorMenuBar }
})
export default class EditorWrapper extends Vue {

  @Prop() value: string;

  internalValue: string;
  editor: any = null;

  setLink() {
    const previousUrl = this.editor.isActive.link() ? this.editor.activeMarkAttrs['link'].href || '' : '';
    const url = window.prompt('URL', previousUrl)
    if (url === null) {
      // cancelled
      return
    }
    this.editor.commands.link({ href: url })
  }

  mounted() {
    this.editor = new Editor({
      extensions: [
        new Bold(),
        new Italic(),
        new Underline(),
        new Image(),
        new History(),
        new Heading(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Table({
          resizable: true,
        }),
        new TableRow(),
        new TableCell(),
        new TableHeader(),
        new Link({ openOnClick: false }),
      ],

      onUpdate: (state: any) => {
        this.internalValue = state.getHTML();
        this.$emit('input', this.internalValue)
      }
    })
    if (this.value) {
      this.editor.setContent(this.value);
    }
  }

  @Watch('value')
  setContent() {
    if (this.value !== this.internalValue) {
      this.internalValue = this.value;
      this.editor.setContent(this.internalValue);
    }
  }

  beforeDestroy() {
    this.editor.destroy();
  }

}
