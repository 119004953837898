













import Component, { mixins } from 'vue-class-component';
import UserSelection from '@/components/common/UserSelection.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopDetailActions, SopDetailGetters } from '@/store/sop-detail';
import ParticipantList from '@/components/sop-change-control/ParticipantList.vue';
import ChangeControlEntry from '@/components/sop-change-control/ChangeControlEntry.vue';

@Component({
  components: { ChangeControlEntry, UserSelection, ParticipantList },
  computed: {
    ...mapState<RootState>({
      changeControl: (state: RootState) => state.sopChangeControl.changeControl,
    }),
    ...mapGetters({
      currentReleaseState: SopDetailGetters.CURRENT_RELEASE_STATE,
    }),
  },
  methods: {
    ...mapActions({
      changeStateTo: SopDetailActions.SOP_DETAIL_RELEASE_STATE_CHANGE
    })
  }
})
export default class ChangeControlReleased extends mixins<AuthMixin>(AuthMixin) {

}
