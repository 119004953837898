










import Vue from 'vue';
import Component from 'vue-class-component';
import { templateService } from '@/services/template-service';
import { TemplateOverallStatistics } from '@/model/Template';
import SimpleTile from '@/features/dashboard/SimpleTile.vue';
import { DoughnutChartData } from '@/features/dashboard/DoughnutChart.vue';


@Component({ components: { SimpleTile } })
export default class TemplateTile extends Vue {

  keyNumbers: { label: string; value: number }[] = [];
  chartData: DoughnutChartData = { title: '', entries: [] };

  created() {
    templateService.statistics().then(stats => this.renderData(stats));
  }

  renderData(stats: TemplateOverallStatistics) {
    const released = stats.countByReleaseState['RELEASED'] ?? 0;
    const inReview = stats.countByReleaseState['IN_RELEASE'] ?? 0;
    const inDraft = stats.countByReleaseState['DRAFT'] ?? 0;
    const obsolete = stats.countByReleaseState['OBSOLETE'] ?? 0;
    this.keyNumbers = [{ label: 'Released Processes', value: stats.latestReleasedCount }];
    this.chartData = {
      title: 'Templates by Release State',
      entries: [
        { label: `Released (${ released })`, value: released, color: '#69c88c' },
        { label: `In review (${ inReview })`, value: inReview, color: '#f49f0a' },
        { label: `In draft (${ inDraft })`, value: inReview, color: '#fbd99d' },
        { label: `Obsolete (${ obsolete })`, value: inReview, color: '#fce7c2' },
      ],
    };
  }
}
