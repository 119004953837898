





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import StackedContent from '@/components/common/StackedContent.vue';
import SopTemplateContents from '@/features/template-content/sop-template-content-widget/SopTemplateContents.vue';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import { TemplateContentActions } from '@/features/template-content/template-content-store';

@Component({
  components: { StackedContent, SopTemplateContents, TemplateContentDetailWidget },
  computed: {
    ...mapState<RootState>({
      selectedTemplateContentId: (state: RootState) => state.templateContents.detailWidgetTemplateContentId,
    }),
  },
})
export default class SopTemplateContentWidget extends Vue {

  static readonly TEMPLATE_CONTENT_DETAIL_WIDGET = 'TemplateContentDetailWidget';
  contentStack = ['SopTemplateContents'];
  selectedTemplateContentId: string;

  closeTemplateContentDetailWidget() {
    this.contentStack.pop();
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SELECT_ID, '');
  }

  @Watch('selectedTemplateContentId', { immediate: true })
  onTemplateContentSelected(newVal: string, oldVal: string) {
    if (!!newVal && !!oldVal) {
      return;
    }

    const index = this.contentStack.indexOf(SopTemplateContentWidget.TEMPLATE_CONTENT_DETAIL_WIDGET);
    let stack = [...this.contentStack];
    if (index > 0) {
      stack = this.contentStack.slice(0, index - 1);
    }
    if (this.selectedTemplateContentId) {
      stack.push(SopTemplateContentWidget.TEMPLATE_CONTENT_DETAIL_WIDGET);
    }
    this.contentStack = stack;
  }

}
