










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class KeyNumbers extends Vue {

  @Prop({required: true})
  keyNumbers: { label: string; value: number }[];
}
