

















import Component from 'vue-class-component';
import Vue from 'vue'
import { mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopTemplatesActions, SopTemplatesGetters } from '@/store/sop-templates';
import { SopDetailActions, SopDetailGetters, SopDetailHelper } from '@/store/sop-detail';
import TemplateArtifactSelection from '@/components/common/TemplateArtifactSelection.vue';
import { Sop } from '@/model';

@Component({
  components: { TemplateArtifactSelection },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE,
      templatesByArtifactIds: SopTemplatesGetters.TEMPLATES_BY_ARTIFACT_IDS,
    })
  },
  methods: {
    ...mapMutations({
      ...SopDetailHelper.setterForSopFields('templateArtifactIds'),
    })
  }
})
export default class SopTemplateSelection extends Vue {

  setTemplateArtifactIds: (templateArtifactIds: string[]) => void;

  updateTemplateArtifactIds(directLinkedTemplateArtifactIds: string[]) {
    this.setTemplateArtifactIds(directLinkedTemplateArtifactIds);
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_SAVE)
        .then(() => this.$store.dispatch(SopTemplatesActions.SOP_TEMPLATES_LOAD, ((this as any).sop as Sop).versionId));
  }

}

