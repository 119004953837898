import { ReleaseState } from '@/model/ReleaseState';
import moment from 'moment';
import { SopBlock } from '@/features/sop-block/model';

export const FILTER_DUPLICATED_ENTRIES = (item: any, i: any, ar: any) => ar.indexOf(item) === i;

export type SopSearchPartMap = { [key: string]: SopSearchPart };
export type SopSearchPart = SopArtifact & Pick<Sop, 'name' | 'versionId' | 'version' | 'releaseState' | 'description' | 'templateContentIds' | 'srProcessIds'>;

export type SopParentArtifactMap = { [key: string]: string };
export type RequirementType = 'QMS' | 'PRODUCT';
export const allRequirementTypes: () => RequirementType[] = () => ['QMS', 'PRODUCT'];
export type SopArtifact = { artifactId: string; parentArtifactId?: string; requirementType: RequirementType };

export type CreateSop = Pick<Sop, 'version' | 'name' | 'description'> & Pick<SopArtifact, 'parentArtifactId' | 'requirementType'>;

export interface Sop extends SopSearchPart {
    artifactId: string;
    versionId: string;
    version: number;
    releaseState: ReleaseState;

    sopAsPdf: boolean;
    changeDescription: string;
    name: string;
    description: string;
    templateArtifactIds: string[];
    templateContentIds: string[];

    srProcessIds: string[];

    sopBlocks: SopBlock[];
}

export type SopArtifactAndVersion = {
    sopArtifact: SopArtifact;
    sop: Sop;
};

export type VersionState = 'new' | 'changed' | 'unchanged' | 'deleted';

export interface TermWrite {
    term: string;
    abbreviation?: string;
    definition: string;
}

export interface Term extends TermWrite {
    artifactId: string;
    versionId: string;
    version: number;
    sopArtifactId: string;
    sopVersionCreated: string;
    sopVersionReplaced?: string;
}

export interface SopDocumentHistoryEntry {
    versionId: string;
    version: number;
    changeDescription: string;
    releasedAt: moment.Moment;
    authorDisplayNames: string[];
}

export const EMPTY_SOP: Sop = {
    artifactId: '',
    versionId: '',
    requirementType: 'QMS',
    version: 0,
    releaseState: 'DRAFT',
    changeDescription: '',
    sopAsPdf: false,
    name: '',
    description: '',
    templateArtifactIds: [],
    templateContentIds: [],
    srProcessIds: [],
    sopBlocks: [],
}

const EMPTY_SOP_TERM: Term = {
    artifactId: '',
    versionId: '',
    version: 1,
    sopArtifactId: '',
    sopVersionCreated: '',
    term: '',
    abbreviation: '',
    definition: ''
}

export function createEmptySopTerm(): Term {
    return { ...EMPTY_SOP_TERM };
}