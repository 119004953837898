export type LoadSvgCallback = () => string;
export type SaveSvgCallback = (svg: string) => Promise<void>;
export type ExitDrawioCallback = () => void;

export class DrawIoIntegration {
    // https://github.com/jgraph/www.diagrams.net-source/blob/3f356b3349da99d21e9551823c8b823e8b8f1413/doc/faq/embed-mode.md
    static readonly URL = 'https://embed.diagrams.net/?embed=1&ui=atlas&spin=1&modified=1&proto=json&saveAndExit=1&noSaveBtn=0&svg-warning=0';

    private static handleMessageFunction: any;
    private closeAfterExport = false;

    constructor(
        private loadSvgCallback: LoadSvgCallback,
        private saveSvgCallback: SaveSvgCallback,
        private instanceReference: any,
        private exitDrawioCallback: ExitDrawioCallback = () => ({})) {
    }

    openDrawIo() {
        if (this.instanceReference.drawIoWindow == null || this.instanceReference.drawIoWindow.closed) {
            this.registerMessageHandler();
            this.openEditor();
        } else {
            this.focusEditor();
        }
    }

    private focusEditor() {
        this.instanceReference.drawIoWindow.focus();
    }

    private openEditor() {
        this.instanceReference.drawIoWindow = window.open(DrawIoIntegration.URL);
    }

    private registerMessageHandler() {
        DrawIoIntegration.removeEventListener();
        DrawIoIntegration.handleMessageFunction = this.handleMessage.bind(this);
        window.addEventListener('message', DrawIoIntegration.handleMessageFunction);
    }

    private handleMessage(evt: any) {

        if (evt.data.length > 0 && evt.source == this.instanceReference.drawIoWindow) {
            const msg = JSON.parse(evt.data);
            if (msg.event == 'init') { // Received if the editor is ready
                this.load();
            } else if (msg.event == 'autosave') {
                this.performExport();
            } else if (msg.event == 'save') { // Received if the user clicks 'save' or 'save and exit'
                this.closeAfterExport = msg.exit;
                this.performExport();
            } else if (msg.event == 'export') { // Received if the export request was processed
                this.handleExport(msg)
                    .then(() => {
                        this.sendSuccessMessage();
                        if (this.closeAfterExport) {
                            this.closeEditor();
                        }
                    })
                    .catch(() => this.sendErrorDialog());
            } else if (msg.event == 'exit') { // Received if the user clicks exit
                this.closeEditor();
            }
        }
    }

    private sendSuccessMessage() {
        if (!this.instanceReference.drawIoWindow) {
            return;
        }
        this.instanceReference.drawIoWindow.postMessage(JSON.stringify({ action: 'status', message: 'Successfully saved...', modified: false }), '*');
    }

    private sendErrorDialog() {
        if (!this.instanceReference.drawIoWindow) {
            return;
        }
        this.instanceReference.drawIoWindow.postMessage(JSON.stringify({
            action: 'dialog',
            title: 'Error: Document could not be saved... ',
            message: 'To prevent data loss, export this document via: <br>File > Export as > SVG... <br><br>...and/or try it again later.',
            ok: 'close'
        }), '*');
    }

    private closeEditor() {
        this.exitDrawioCallback();
        DrawIoIntegration.removeEventListener();
        this.instanceReference.drawIoWindow.close();
        this.instanceReference.drawIoWindow = null;
    }

    private static removeEventListener() {
        if (!DrawIoIntegration.handleMessageFunction) {
            return;
        }
        window.removeEventListener('message', DrawIoIntegration.handleMessageFunction);
        DrawIoIntegration.handleMessageFunction = null;
    }

    private load() {
        this.instanceReference.drawIoWindow.postMessage(JSON.stringify({ action: 'load', xml: this.calculateDrawIoSource(), autosave: 0 }), '*');
    }

    private calculateDrawIoSource() {
        const svgString = this.loadSvgCallback();
        if (svgString.length > 0) {
            return 'data:image/svg+xml;base64,' + this.b64EncodeUnicode(svgString);
        }
        return '';
    }

    private performExport() {
        this.instanceReference.drawIoWindow.postMessage(JSON.stringify({ action: 'export', format: 'xmlsvg' }), '*');
    }

    private handleExport(msg: any): Promise<void> {
        const svgString = this.convertDrawIoDataToSvg(msg.data as string);
        return this.saveSvgCallback(svgString);
    }

    private convertDrawIoDataToSvg(data: string) {
        return this.b64DecodeUnicode(data.substring('data:image/svg+xml;base64,'.length));
    }

    /*
     * source of the following hack (due to encoding bug with special characters):
     * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
     *
     * explanation in MDN dev doc:
     * https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
     */

    // Encoding UTF8 ⇢ base64
    private b64EncodeUnicode(str: string) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }

    // Decoding base64 ⇢ UTF8
    private b64DecodeUnicode(str: string) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

}