









import Component from 'vue-class-component';
import Vue from 'vue'
import SopTemplateSelection from '@/components/sop-templates/SopTemplateSelection.vue';
import SopUsedTemplates from '@/components/sop-templates/SopUsedTemplates.vue';

@Component({
  components: { SopUsedTemplates, SopTemplateSelection },
})
export default class SopTemplatesWidget extends Vue {
}

