





























import Component from 'vue-class-component';
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { Prop, Watch } from 'vue-property-decorator';
import { RequirementSearchFilter, requirementSearchService } from '@/features/sr-requirement-search/service/requirement-search-service';
import { SrGetters } from '@/store/sr';
import RequirementSearchResultPanels from '@/features/sr-requirement-search/RequirementSearchResultPanels.vue';
import { RequirementSearchResult } from '@/features/sr-requirement-search/model/RequirementSearch';

@Component({
  components: { RequirementSearchResultPanels },
  computed: {
    ...mapGetters({
      processContentById: SrGetters.SR_PROCESS_CONTENT_BY_ID,
      templateContentById: SrGetters.SR_TEMPLATE_CONTENT_BY_ID,
    })
  }
})
export default class RequirementSearch extends Vue {

  tags = ['Glossary', 'Process Contents', 'Template Contents'];
  selectedTag = -1;
  selectedProcessContentId = '';
  selectedTemplateContentId = '';

  TERMS_IDX = 0;
  PROCESS_CONTENTS_IDX = 1;
  TEMPLATE_CONTENTS_IDX = 2;

  @Prop()
  processContentIds: string[];
  @Prop()
  templateContentIds: string[];
  @Prop()
  termIds: string[];
  @Prop({ default: () => [] })
  markAsCheckedIds: string[];

  searchResult: RequirementSearchResult = {
    requirements: [],
    regulations: []
  };

  @Watch('selectedTag')
  @Watch('termIds')
  @Watch('processContentIds')
  @Watch('templateContentIds')
  @Watch('selectedProcessContentId')
  @Watch('selectedTemplateContentId')
  watchDependencies(newValue: Array<any>, oldValue: Array<any>) {
    if (JSON.stringify(oldValue) == JSON.stringify(newValue)) {
      return;
    }
    this.executeSearch();
  }

  isHighlighted(elementId: string) {
    return this.markAsCheckedIds?.findIndex(id => id === elementId) >= 0;
  }

  mounted() {
    return this.executeSearch();
  }

  executeSearch() {
    return requirementSearchService.search(this.createSearchFilter()).then(this.updateSearchResult)
  }

  private updateSearchResult(searchResult: RequirementSearchResult) {
    this.searchResult = searchResult;
  }

  private createSearchFilter(): RequirementSearchFilter {
    const showAll = this.selectedTag == undefined || this.selectedTag === -1;
    return {
      sopContentIds: this.getSelectedProcessContentIds(showAll),
      templateContentIds: this.getSelectedTemplateContentIds(showAll),
      termIds: showAll || this.selectedTag === this.TERMS_IDX ? this.termIds : [],
    };
  }

  private getSelectedProcessContentIds(showAll: boolean): string[] {
    if (showAll) return this.processContentIds;
    if (this.selectedTag !== this.PROCESS_CONTENTS_IDX) return [];
    if (this.selectedProcessContentId?.length > 0) return [this.selectedProcessContentId];
    return this.processContentIds;
  }

  private getSelectedTemplateContentIds(showAll: boolean): string[] {
    if (showAll) return this.templateContentIds;
    if (this.selectedTag !== this.TEMPLATE_CONTENTS_IDX) return [];
    if (this.selectedTemplateContentId?.length > 0) return [this.selectedTemplateContentId];
    return this.templateContentIds;
  }
}

