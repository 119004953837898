import { ReleaseState } from '@/model/ReleaseState';

export type TemplateSearchPart = Pick<Template, 'artifactId' | 'versionId' | 'version' | 'releaseState' | 'documentKey' | 'name'>

export interface Template {
    artifactId: string;
    versionId: string;
    version: number;
    releaseState: ReleaseState;
    name: string;
    description: string;
    documentKey: string;
    reviewerRoleId: string;
    releaserRoleId: string;
    location: string;
    createdTemplateContentIds: string[];
    reportedTemplateContentIds: string[];
}

export interface TemplateOverallStatistics {
    latestReleasedCount: number;
    countByReleaseState: { [key: string]: number };
}

export const NEW_TEMPLATE_ID = 'NEW_TEMPLATE_ID';

export function createEmptyTemplate(): Template {
    return {
        artifactId: '',
        versionId: '',
        version: 1,
        releaseState: 'DRAFT',
        name: '',
        description: '',
        documentKey: '',
        location: '',
        reviewerRoleId: '',
        releaserRoleId: '',
        createdTemplateContentIds: [],
        reportedTemplateContentIds: []
    };
}

export function createNewTemplate(): Template {
    return { ...createEmptyTemplate(), versionId: NEW_TEMPLATE_ID };
}