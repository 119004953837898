import { ChangeControl, ChangeControlParticipant, ChangeControlWrite, createEmptyChangeControl } from '@/model';
import { restClient } from '@/store/rest-client';
import { SopChangeControlServiceMock } from '@/services/sop-change-control-service-mock';
import moment from 'moment';

export interface SopChangeControlService {

    loadRelease(sopVersionId: string): Promise<ChangeControl>;

    loadAllReleases(sopVersionId: string): Promise<ChangeControl[]>;

    createRelease(sopVersionId: string, changeControlWrite: ChangeControlWrite): Promise<ChangeControl>;

    cancelRelease(sopVersionId: string): Promise<ChangeControl>;

    completeRelease(sopVersionId: string): Promise<ChangeControl>;

    approveRelease(sopVersionId: string, comment: string): Promise<ChangeControl>;

    declineRelease(sopVersionId: string, comment: string): Promise<ChangeControl>;

    revertDecision(sopVersionId: string, comment: string): Promise<ChangeControl>;

}

function getChangeControlUrl(sopVersionId: string) {
    return process.env.VUE_APP_QMS_BASE_URL + `/qms-api/sops/v/${ sopVersionId }/change-control`
}

function getReleaseUrl(sopVersionId: string, actionPath: string) {
    return getChangeControlUrl(sopVersionId) + `/release${ actionPath }`
}

type ReleaseWorkflowClose = {
    comment: string;
}

export class SopChangeControlBackendService implements SopChangeControlService {

    loadRelease(sopVersionId: string): Promise<ChangeControl> {
        return restClient.get<ChangeControl[]>(getReleaseUrl(sopVersionId, ''))
            .then(response => response.data.length !== 1 ? createEmptyChangeControl() : this.mapMoment(response.data[0]));
    }

    loadAllReleases(sopVersionId: string): Promise<ChangeControl[]> {
        const mapper = this.mapMoment;
        return restClient.get<ChangeControl[]>(getChangeControlUrl(sopVersionId))
            .then(response => response.data.map(mapper));
    }

    createRelease(sopVersionId: string, changeControlWrite: ChangeControlWrite): Promise<ChangeControl> {
        return restClient.post<ChangeControl>(getReleaseUrl(sopVersionId, ''), changeControlWrite)
            .then(response => this.mapMoment(response.data));
    }

    cancelRelease(sopVersionId: string): Promise<ChangeControl> {
        const payload: ReleaseWorkflowClose = {
            comment: ''
        };
        return restClient.put<ChangeControl>(getReleaseUrl(sopVersionId, '/cancel'), payload)
            .then(response => this.mapMoment(response.data));
    }

    completeRelease(sopVersionId: string): Promise<ChangeControl> {
        const payload: ReleaseWorkflowClose = {
            comment: ''
        };
        return restClient.put<ChangeControl>(getReleaseUrl(sopVersionId, '/complete'), payload)
            .then(response => this.mapMoment(response.data));
    }

    approveRelease(sopVersionId: string, comment: string): Promise<ChangeControl> {
        const payload: ReleaseWorkflowClose = {
            comment
        };
        return restClient.put<ChangeControl>(getReleaseUrl(sopVersionId, '/approve'), payload)
            .then(response => this.mapMoment(response.data));
    }

    declineRelease(sopVersionId: string, comment: string): Promise<ChangeControl> {
        const payload: ReleaseWorkflowClose = {
            comment
        };
        return restClient.put<ChangeControl>(getReleaseUrl(sopVersionId, '/decline'), payload)
            .then(response => this.mapMoment(response.data));
    }

    revertDecision(sopVersionId: string, comment: string): Promise<ChangeControl> {
        const payload: ReleaseWorkflowClose = {
            comment
        };
        return restClient.put<ChangeControl>(getReleaseUrl(sopVersionId, '/revert'), payload)
            .then(response => this.mapMoment(response.data));
    }

    mapMoment(changeControl: ChangeControl): ChangeControl {
        return {
            ...changeControl,
            initiatedAt: moment(changeControl.initiatedAt),
            decidedAt: changeControl.decidedAt ? moment(changeControl.decidedAt) : changeControl.decidedAt,
            authors: changeControl.authors.map(SopChangeControlBackendService.mapParticipantMoment),
            approvers: changeControl.approvers.map(SopChangeControlBackendService.mapParticipantMoment)
        };
    }

    static mapParticipantMoment(participant: ChangeControlParticipant): ChangeControlParticipant {
        return {
            ...participant,
            decidedAt: participant.decidedAt ? moment(participant.decidedAt) : participant.decidedAt,
        };
    }
}

export const sopChangeControlService: SopChangeControlService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new SopChangeControlServiceMock() : new SopChangeControlBackendService();
