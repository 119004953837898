import { TemplateContentService } from '@/features/template-content/template-content-service';
import { SrService } from '@/services/sr-service';
import { TemplateContentMap } from '@/features/template-content/template-content-store';
import { SrTemplateContentMap } from '@/store/sr';
import { createEmptyTemplateContent, NEW_TEMPLATE_CONTENT_ID, TemplateContent } from '@/features/template-content/template-content-model';

export class SrSyncService {

    public static async syncTemplateContents(templateContentService: TemplateContentService, srService: SrService): Promise<void> {
        return Promise.all([templateContentService.loadTemplateContents(), srService.loadTemplateContents()])
            .then((result) => {
                const tenantTemplateContents: TemplateContentMap = result[0];
                const srTemplateContents: SrTemplateContentMap = result[1];

                const existingSrTemplateContentIds = Object.values(tenantTemplateContents)
                    .map(templateContent => templateContent.srId)
                    .filter(srTemplateContentIds => srTemplateContentIds && srTemplateContentIds.length > 0);
                const srTemplateContentIds = Object.keys(srTemplateContents);

                const allMissingIds = srTemplateContentIds.filter(id => !existingSrTemplateContentIds.includes(id))

                return allMissingIds
                    .map(id => srTemplateContents[id])
                    .map((srTemplateContent): TemplateContent => (
                        {
                            ...srTemplateContent,
                            id: NEW_TEMPLATE_CONTENT_ID,
                            srId: srTemplateContent.id
                        }))
                    .reduce((promise, newTemplateContent) => {
                        return promise.then(() => templateContentService.createTemplateContent(newTemplateContent));
                    }, Promise.resolve(createEmptyTemplateContent()))
            })
            .then(() => undefined);
    }

}
