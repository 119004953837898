import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { safeVueSet } from '@/utils/util';
import { BlockMap, SopDetailActions } from '@/store/sop-detail';
import { sopBlockService } from '@/features/sop-block/sop-block-service';
import { UiFeedback } from '@/store/ui-feedback';
import { createEmptyNewSopBlock, SopBlock, SopBlockType } from '@/features/sop-block/model';
import { createEmptySopBlockSelectedEvent, SopBlockListEvents, SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { SopBlockDetailEvents } from '@/features/sop-block/sop-block-detail/sop-block-detail-events';

export class SopBlockListState {
    blocks: BlockMap = {};
    selectedSopBlockVersionId = '';
}

export type CreateNewBlockArtifact = {
    type: SopBlockType;
    index: number;
}

export enum SopBlockListActions {
    SOP_BLOCK_LIST_CREATE_NEW_VERSION = 'SOP_BLOCK_LIST_CREATE_NEW_VERSION',
    SOP_BLOCK_LIST_CREATE_NEW_ARTIFACT = 'SOP_BLOCK_LIST_CREATE_NEW_ARTIFACT',
    SOP_BLOCK_LIST_REORDER_BY_VERSION_IDS = 'SOP_BLOCK_LIST_REORDER_BY_VERSION_IDS',
    SOP_BLOCK_LIST_SELECT_BLOCK = 'SOP_BLOCK_LIST_SELECT_BLOCK',
    SOP_BLOCK_LIST_REMOVE_BLOCK_SELECTION = 'SOP_BLOCK_LIST_REMOVE_BLOCK_SELECTION',
}

export enum SopBlockListGetters {
    ORDERED_BLOCK_VERSION_IDS = 'ORDERED_BLOCK_VERSION_IDS',
    ORDERED_BLOCK_ARTIFACT_IDS = 'ORDERED_BLOCK_ARTIFACT_IDS',
    SELECTED_SOP_BLOCK = 'SELECTED_SOP_BLOCK',
}

export enum SopBlockListMutations {
    SOP_BLOCKS_REPLACE = 'SOP_BLOCKS_REPLACE',
    SOP_BLOCK_REPLACE = 'SOP_BLOCK_REPLACE',
    SOP_BLOCK_DELETE = 'SOP_BLOCK_DELETE',
    SOP_BLOCK_SELECTED_VERSION_ID = 'SOP_BLOCK_SELECTED_VERSION_ID',
}

const getters = {
    [SopBlockListGetters.SELECTED_SOP_BLOCK]: (state: SopBlockListState) => {
        return state.blocks[state.selectedSopBlockVersionId];
    },
    [SopBlockListGetters.ORDERED_BLOCK_VERSION_IDS]: (state: SopBlockListState) => {
        return Object.values(state.blocks).map(b => b.versionId);
    },
    [SopBlockListGetters.ORDERED_BLOCK_ARTIFACT_IDS]: (state: SopBlockListState) => {
        return Object.values(state.blocks).map(b => b.artifactId);
    },
};

const actions = {
    [SopBlockListActions.SOP_BLOCK_LIST_CREATE_NEW_VERSION]: ({ rootState, dispatch }: ActionContext<SopBlockListState, RootState>, sopBlockVersionId: string) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        return sopBlockService.createNewSopBlockVersion(sopVersionId, sopBlockVersionId)
            .catch(err => UiFeedback.showError(dispatch, `New block version could not be created. Please try again.`, err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId));
    },
    [SopBlockListActions.SOP_BLOCK_LIST_CREATE_NEW_ARTIFACT]: ({ rootState, dispatch }: ActionContext<SopBlockListState, RootState>, create: CreateNewBlockArtifact) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        const newBlock = createEmptyNewSopBlock(create.type);
        return sopBlockService.createSopBlock(sopVersionId, create.index, newBlock)
            .catch(err => UiFeedback.showError(dispatch, 'Block could not be created. Please try again.', err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId));
    },
    [SopBlockListActions.SOP_BLOCK_LIST_REORDER_BY_VERSION_IDS]: ({ rootState, state, dispatch }: ActionContext<SopBlockListState, RootState>, orderedBlockVersionIds: string[]) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        const orderedBlockArtifactIds = orderedBlockVersionIds
            .map(blockVersionId => state.blocks[blockVersionId]?.artifactId)
            .filter(artifactId => !!artifactId);
        return sopBlockService.reorderBlocks(sopVersionId, orderedBlockArtifactIds)
            .catch(err => UiFeedback.showError(dispatch, 'Blocks could not be reordered. Please try again.', err))
            .finally(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId));
    },
    [SopBlockListActions.SOP_BLOCK_LIST_SELECT_BLOCK]: ({ state, commit, dispatch }: ActionContext<SopBlockListState, RootState>, sopBlockSelectedEvent: SopBlockSelectedEvent) => {
        commit(SopBlockListMutations.SOP_BLOCK_SELECTED_VERSION_ID, sopBlockSelectedEvent.versionId);
        return dispatch(SopBlockListEvents.SOP_BLOCK_SELECTED, sopBlockSelectedEvent);
    },
    [SopBlockListActions.SOP_BLOCK_LIST_REMOVE_BLOCK_SELECTION]: ({ commit, dispatch }: ActionContext<SopBlockListState, RootState>, sopBlockSelectedEvent: SopBlockSelectedEvent) => {
        commit(SopBlockListMutations.SOP_BLOCK_SELECTED_VERSION_ID, '');
        return dispatch(SopBlockListEvents.SOP_BLOCK_SELECTED, createEmptySopBlockSelectedEvent());
    },
};


const eventListeners = {
    [SopBlockDetailEvents.SOP_BLOCK_DETAIL_CLOSED]: ({ commit, dispatch }: ActionContext<SopBlockListState, RootState>) => {
        commit(SopBlockListMutations.SOP_BLOCK_SELECTED_VERSION_ID);
        return dispatch(SopBlockListEvents.SOP_BLOCK_SELECTED, createEmptySopBlockSelectedEvent());
    },
}


const mutations = {
    [SopBlockListMutations.SOP_BLOCKS_REPLACE]: (state: SopBlockListState, blocks: BlockMap) =>
        safeVueSet(state, 'blocks', blocks),
    [SopBlockListMutations.SOP_BLOCK_REPLACE]: (state: SopBlockListState, block: SopBlock) =>
        safeVueSet(state.blocks, block.versionId, block),
    [SopBlockListMutations.SOP_BLOCK_DELETE]: (state: SopBlockListState, blockVersionId: string) =>
        Vue.delete(state.blocks, blockVersionId),
    [SopBlockListMutations.SOP_BLOCK_SELECTED_VERSION_ID]: (state: SopBlockListState, blockVersionId: string) =>
        safeVueSet(state, 'selectedSopBlockVersionId', blockVersionId || ''),
};

export const SOP_BLOCK_LIST_MODULE: Module<SopBlockListState, RootState> = {
    state: new SopBlockListState(),
    getters,
    actions: { ...actions, ...eventListeners },
    mutations
};