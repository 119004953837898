
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { AuthGetters } from '@/store/auth';

@Component({
  computed: {
    ...mapGetters({
      isQmsAdmin: AuthGetters.IS_QMS_ADMIN,
    })
  }
})
export default class AuthMixin extends Vue {
  isQmsAdmin: boolean;
}
