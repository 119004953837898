import { QmsRequirementService, RequirementImplType } from '@/services/qms-requirement-service';
import { checkUndefined, toMap } from '@/utils/util';
import { SOP_A_ARTIFACT_ID, SOP_B_ARTIFACT_ID } from '@/services/sop-service-mock';
import { QmsRequirementMap } from '@/store/regulation-detail';
import { TemplateContentServiceMock } from '@/features/template-content/template-content-service-mock';
import { SopBlockServiceMock } from '@/features/sop-block/sop-block-service-mock';
import { createEmptyQmsRequirement, QmsRequirement } from '@/services/model';

export class QmsRequirementServiceMock implements QmsRequirementService {

    public static readonly FAIL_ON_CALL_QMS_REQUIREMENT_ID = 'FAIL_ON_CALL________________________';
    static qmsRequirements: QmsRequirement[] = [];

    public static init() {
        QmsRequirementServiceMock.qmsRequirements = [
            createEmptyQmsRequirement({
                regulationId: 'mdr-2017-745',
                requirementId: 'CHAPTER-I_2_1_______________________',
                applicability: 'APPLICABLE',
                notes: 'implemented in XYZ',
                targetSopArtifactIds: [SOP_A_ARTIFACT_ID, SOP_B_ARTIFACT_ID],
                actualSopArtifactIds: [SOP_A_ARTIFACT_ID],
                sopEvidenceStates: {
                    [SOP_A_ARTIFACT_ID]: 'IMPLEMENTED',
                    [SOP_B_ARTIFACT_ID]: 'NOT_IMPLEMENTED'
                },
                templateContentIds: [TemplateContentServiceMock.TEMPLATE_CONTENT_1], // has gap
                referencedSopBlockArtifactIds: [SopBlockServiceMock.SOP_BLOCK_1_ARTIFACT_ID],
            }),
            createEmptyQmsRequirement({
                regulationId: 'mdr-2017-745',
                requirementId: 'CHAPTER-X_1_________________________',
                applicability: 'NOT_APPLICABLE',
                applicabilityReason: 'we do not care',
                targetSopArtifactIds: [],
                actualSopArtifactIds: [SOP_A_ARTIFACT_ID],
                sopEvidenceStates: {
                    [SOP_A_ARTIFACT_ID]: 'IMPLEMENTED_BUT_NOT_REQUIRED'
                },
                templateContentIds: [TemplateContentServiceMock.TEMPLATE_CONTENT_3], // has no gap
            }),
            createEmptyQmsRequirement({
                regulationId: 'mdr-2017-745',
                requirementId: 'CHAPTER-X_2_________________________',
                applicability: 'NOT_DEFINED',
                referencedSopBlockArtifactIds: [SopBlockServiceMock.SOP_BLOCK_6_ARTIFACT_ID],
                applicabilityWarnings: ['NOT_DEFINED'],
            }),
            createEmptyQmsRequirement({
                regulationId: 'mdr-2017-745',
                requirementId: 'CHAPTER-X_3_________________________',
                applicability: 'INFORMATION',
                referencedSopBlockArtifactIds: [SopBlockServiceMock.SOP_BLOCK_2_ARTIFACT_ID],
            }),
            createEmptyQmsRequirement({
                regulationId: 'iso-1',
                requirementId: 'CHAPTER-I_1_________________________',
                applicability: 'APPLICABLE',
                targetSopArtifactIds: [SOP_A_ARTIFACT_ID],
                sopEvidenceStates: {
                    [SOP_A_ARTIFACT_ID]: 'NOT_IMPLEMENTED'
                },
                referencedSopBlockArtifactIds: [SopBlockServiceMock.SOP_BLOCK_2_ARTIFACT_ID],
            }),
            createEmptyQmsRequirement({
                regulationId: 'mdr-2017-745',
                requirementId: 'CHAPTER-I_3________________________',
                applicability: 'NOT_APPLICABLE',
                actualSopArtifactIds: [SOP_A_ARTIFACT_ID],
                sopEvidenceStates: {
                    [SOP_A_ARTIFACT_ID]: 'IMPLEMENTED_BUT_NOT_REQUIRED'
                },
                templateContentIds: [TemplateContentServiceMock.TEMPLATE_CONTENT_3], // has no gap
                applicabilityWarnings: ['NOT_APPLICABLE_WITHOUT_REASON'],
            }),
        ];
    }

    findQmsRequirementsBySopVersionId(sopVersionId: string): Promise<QmsRequirementMap> {
        return Promise.resolve({});
    }

    findQmsRequirementsByTargetSopBlockArtifactId(sopBlockArtifactId: string): Promise<QmsRequirementMap> {
        return Promise.resolve(toMap([...QmsRequirementServiceMock.qmsRequirements.filter(req => req.targetSopBlockArtifactIds.includes(sopBlockArtifactId))], r => r.requirementId));
    }

    findQmsRequirementsByTemplateContentId(templateContentId: string): Promise<QmsRequirementMap> {
        return Promise.resolve(toMap([...QmsRequirementServiceMock.qmsRequirements.filter(req => req.templateContentIds.includes(templateContentId))], r => r.requirementId));
    }

    loadQmsRequirementsForRegulation(regulationId: string): Promise<QmsRequirementMap> {
        return Promise.resolve(toMap([...QmsRequirementServiceMock.qmsRequirements.filter(req => req.regulationId === regulationId)], r => r.requirementId));
    }

    changeQmsRequirement(qmsRequirement: QmsRequirement): Promise<QmsRequirement> {
        if (qmsRequirement.requirementId === QmsRequirementServiceMock.FAIL_ON_CALL_QMS_REQUIREMENT_ID) {
            return Promise.reject('FAIL_ON_CALL_QMS_REQUIREMENT_ID was received.');
        }
        const index = QmsRequirementServiceMock.qmsRequirements.findIndex(r => r.requirementId === qmsRequirement.requirementId);
        if (index < 0) {
            QmsRequirementServiceMock.qmsRequirements.push(qmsRequirement);
            return Promise.resolve({ ...qmsRequirement });
        }

        QmsRequirementServiceMock.qmsRequirements.splice(index, 1, { ...qmsRequirement });
        return Promise.resolve({ ...qmsRequirement });
    }

    deleteQmsRequirement(qmsRequirementId: string): Promise<void> {
        throw new Error('Not implemented operation');
        return Promise.resolve();
    }

    loadRegulationIdsForGivenArtifactId(sopArtifactId: string, type: RequirementImplType): Promise<string[]> {
        const fieldName = `target${ type }ArtifactIds` as keyof QmsRequirement;
        return Promise.resolve([...QmsRequirementServiceMock.qmsRequirements
            .filter(qmsRequirement => (qmsRequirement[fieldName] as string[]).findIndex(id => id === sopArtifactId) >= 0)
            .map(qmsRequirement => qmsRequirement.regulationId)
        ]);
    }

    changeRequirementImplementation(sopArtifactId: string, requirementId: string, implemented: boolean, type: RequirementImplType): Promise<QmsRequirement> {
        const qmsRequirement = QmsRequirementServiceMock.qmsRequirements.find(r => r.requirementId === requirementId);
        if (!qmsRequirement) {
            return Promise.reject('qms requirement not found');
        }
        const fieldName = `actual${ type }ArtifactIds` as keyof QmsRequirement;
        const ids = [...(qmsRequirement[fieldName] as string[])];
        const i = ids.findIndex(id => id === sopArtifactId);
        if (i >= 0) {
            ids.splice(i, 1);
        }
        if (implemented) {
            ids.push(sopArtifactId);
        }

        return Promise.resolve({ ...qmsRequirement, [fieldName]: ids });
    }

    changeRequirementMarked(sopArtifactId: string, regulationId: string, requirementId: string, implemented: boolean): Promise<QmsRequirement> {
        const qmsRequirement = QmsRequirementServiceMock.qmsRequirements.find(r => r.requirementId === requirementId);
        if (!qmsRequirement) {
            return Promise.reject('qms requirement not found');
        }
        const ids = [...qmsRequirement.referencedSopBlockArtifactIds];
        const i = ids.findIndex(id => id === sopArtifactId);
        if (i >= 0) {
            ids.splice(i, 1);
        }
        if (implemented) {
            ids.push(sopArtifactId);
        }
        return Promise.resolve({ ...qmsRequirement, referencedSopBlockArtifactIds: ids });
    }

    static findQmsRequirementByRequirementId(requirementId: string): QmsRequirement {
        return checkUndefined(QmsRequirementServiceMock.qmsRequirements.find(req => req.requirementId == requirementId));
    }
}