





















import { Component } from 'vue-property-decorator';
import RolesOverview from '@/components/roles-overview/RolesOverview.vue';
import { RoleActions } from '@/store/roles';
import { SrActions } from '@/store/sr';
import CoHeader from '@/components/common/CoHeader.vue';
import { mixins } from 'vue-class-component';
import AuthMixin from '@/components/common/AuthMixin.vue';
import CoLayout from '@/layouts/CoLayout.vue';


@Component({
  components: { CoLayout, CoHeader, RolesOverview },
})
export default class RolesOverviewView extends mixins<AuthMixin>(AuthMixin) {

  created() {
    this.$store.dispatch(SrActions.SR_ROLES_LOAD);
    this.$store.dispatch(RoleActions.ROLES_LOAD);
  }

}
