


















import Vue from 'vue';
import Component from 'vue-class-component';
import TemplateContentSelection from '@/components/common/TemplateContentSelection.vue';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { TemplateContentActions, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { SopDetailActions, SopDetailGetters, SopDetailHelper } from '@/store/sop-detail';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';

@Component({
  components: { TemplateContentChip, TemplateContentValidationIcons, TemplateContentSelection },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
      templateContents: (state: RootState) => Object.values(state.templateContents.templateContents),
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE,
      directlyLinkedTemplateContents: TemplateContentGetters.TEMPLATE_CONTENTS_LINKED_DIRECTLY_TO_SOP,
      templateContentValidationsById: TemplateContentGetters.TEMPLATE_CONTENT_VALIDATIONS_BY_ID,
    })
  },
  methods: {
    ...mapActions({
      selectTemplateContentId: TemplateContentActions.TEMPLATE_CONTENT_SELECT_ID,
    }),
    ...mapMutations({
      ...SopDetailHelper.setterForSopFields('templateContentIds'),
    })
  }
})
export default class SopTemplateContents extends Vue {

  setTemplateContentIds: (templateContentIds: string[]) => void;

  updateTemplateContentIds(directLinkedTemplateContentIds: string[]) {
    this.setTemplateContentIds(directLinkedTemplateContentIds);
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_SAVE);
  }

}
