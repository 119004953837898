
































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class AnchoredHeading extends Vue {

  @Prop({ required: true }) text: string;
  @Prop({ required: true }) level: number;
  @Prop({ required: true }) regulationId: string;
  @Prop({ required: true }) requirementKey: string;

}
