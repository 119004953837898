import { render, staticRenderFns } from "./ProductRegulations.vue?vue&type=template&id=44b63184&scoped=true&"
import script from "./ProductRegulations.vue?vue&type=script&lang=ts&"
export * from "./ProductRegulations.vue?vue&type=script&lang=ts&"
import style0 from "./ProductRegulations.vue?vue&type=style&index=0&id=44b63184&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b63184",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VChip,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})
