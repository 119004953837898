












































































































import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { humanReadableChangeType, humanReadableImpactType, ImpactDto } from '@/features/impacts/model';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import FromRequirements from '@/features/impacts/components/FromRequirements.vue';
import { BreadcrumbCalculator } from '@/features/impacts/BreadcrumbCalculator';
import { Requirement } from '@/features/sr-model/Requirement';

@Component({
  components: { FromRequirements, RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      impactAnalysis: (state: RootState) => state.impacts.impactAnalysis,
      selectedImpact: (state: RootState) => state.impacts.selectedImpact,
      selectedImpactLoading: (state: RootState) => state.impacts.selectedImpactLoading,
    }),
  },
})
export default class ImpactDetailViewWidget extends mixins<ImpactsMixin>(ImpactsMixin) {

  selectedImpact: ImpactDto;

  readonly humanReadableImpactType = humanReadableImpactType;
  readonly humanReadableChangeType = humanReadableChangeType;

  get breadcrumbs() {
    return new BreadcrumbCalculator(this.fromRegulationRequirement).calculateBreadcrumb(this.selectedImpact.requirementIdsFrom);
  }

  get toRequirements(): Requirement[] {
    return this.toRequirementsByIds(this.selectedImpact.requirementIdsTo);
  }

  get fromRequirements(): Requirement[] {
    return this.fromRequirementsByIds(this.selectedImpact.requirementIdsFrom);
  }
}
