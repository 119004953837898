import { restClient } from '@/store/rest-client';
import { CreateSop, FILTER_DUPLICATED_ENTRIES, Sop, SopArtifact, SopArtifactAndVersion, SopDocumentHistoryEntry, SopParentArtifactMap, SopSearchPart, SopSearchPartMap } from '@/model/Sop';
import { SopServiceMock } from '@/services/sop-service-mock';
import { ReleaseState } from '@/model/ReleaseState';
import { AxiosRequestConfig } from 'axios';
import { UrlHelper } from '@/utils/UrlHelper';
import moment from 'moment';
import { checkUndefined, toMap, toMapWithValue } from '@/utils/util';

export type LoadSopsResult = {
    sopMap: SopSearchPartMap;
    orderedSopArtifactIds: string[];
    parentArtifactIds: SopParentArtifactMap;
    allLinkedSrProcessIds: string[];
}

export type SopOverallStatistics = {
    latestReleasedCount: number;
    latestReleasedStepCount: number;
    countByReleaseState: { [key: string]: number };
}

export type SopDocumentHistory = {
    released: SopDocumentHistoryEntry[];
    unreleased?: SopDocumentHistoryEntry;
}

export type SopOrderChange = {
    parentArtifactId: string;
    orderedArtifactIds: string[];
}

export interface SopVersionSummary {
    versionId: string;
    version: number;
    releaseState: ReleaseState;
}

export interface SopArtifactSummary {
    artifactId: string;
    name: string;
    latestVersions: SopVersionSummary[];
}

export interface SopArtifactSummaries {
    summaries: SopArtifactSummary[];
}

export interface SopService {

    loadSopDetail(sopVersionId: string): Promise<SopArtifactAndVersion>;

    loadSops(): Promise<LoadSopsResult>;

    statistics(): Promise<SopOverallStatistics>;

    createSop(create: CreateSop): Promise<SopArtifactAndVersion>;

    updateSop(artifact: SopArtifact, sop: Sop): Promise<SopArtifactAndVersion>;

    sopOrderChange(sopOrderChange: SopOrderChange): Promise<void>;

    deleteSop(sopVersionId: string): Promise<void>;

    changeReleaseState(sop: Sop, nextState: ReleaseState): Promise<void>;

    createNewVersion(sop: Sop): Promise<Sop>;

    exportPdf(sop: Sop): Promise<void>;

    simplifiedHtml(sop: Sop): Promise<string>;

    loadSopDocumentHistory(sopArtifactId: string): Promise<SopDocumentHistory>;

    loadSopSummaries(artifactIds: string[]): Promise<SopArtifactSummaries>;

    asCsv(sopVersionId: string): Promise<Blob>;
}

const API_SOPS = process.env.VUE_APP_QMS_BASE_URL + '/qms-api/sops';
const sopArtifactApi = (sopArtifactId = 'sop-artifact-uuid') => `${ API_SOPS }/${ sopArtifactId }`
const sopVersionApi = (sopVersionId: string) => `${ API_SOPS }/sop-artifact-uuid/versions/${ sopVersionId }`

type AllSopApiDto = { sops: SopSearchPart[]; artifacts: SopArtifact[]; allLinkedSrProcessIds: string[] };

class SopBackendService implements SopService {

    asCsv(sopVersionId: string): Promise<Blob> {
        return restClient.get<string>(`${ API_SOPS }/by-version-id/${ sopVersionId }`, { headers: { accept: 'text/csv' } })
            .then(r => r.data)
            .then(resp => new Blob([resp], { type: 'text/csv' }));
    }

    loadSops(): Promise<LoadSopsResult> {
        return restClient.get<AllSopApiDto>(API_SOPS)
            .then(t => t.data)
            .then(result => {
                const artifactMap = toMap(result.artifacts, a => a.artifactId)
                const sopMap = toMapWithValue(result.sops, sop => sop.versionId, sop => ({ ...sop, ...artifactMap[sop.artifactId] }));
                const orderedSopArtifactIds = result.sops.map(sop => sop.artifactId)
                    .filter(FILTER_DUPLICATED_ENTRIES);
                const parentArtifactIds: SopParentArtifactMap = toMapWithValue(
                    result.artifacts.filter(a => !!a.parentArtifactId),
                    sop => sop.artifactId,
                    sop => checkUndefined(sop.parentArtifactId));
                const allLinkedSrProcessIds = result.allLinkedSrProcessIds;
                return { sopMap, orderedSopArtifactIds, parentArtifactIds, allLinkedSrProcessIds };
            });
    }

    statistics(): Promise<SopOverallStatistics> {
        return restClient.get<SopOverallStatistics>(`${ API_SOPS }/statistics`).then(t => t.data);
    }

    createSop(sop: Omit<Sop, 'versionId'>): Promise<SopArtifactAndVersion> {
        return restClient.post<SopArtifactAndVersion>(API_SOPS, sop)
            .then(t => t.data);
    }

    loadSopDetail(sopVersionId: string): Promise<SopArtifactAndVersion> {
        return restClient.get<SopArtifactAndVersion>(sopVersionApi(sopVersionId))
            .then(t => t.data);
    }

    updateSop(artifact: SopArtifact, sop: Sop): Promise<SopArtifactAndVersion> {
        return Promise.all([
            restClient.put<SopArtifact>(sopArtifactApi(sop.artifactId), artifact).then(t => t.data),
            restClient.put<Sop>(sopVersionApi(sop.versionId), sop).then(t => t.data)
        ]).then(result => ({ sopArtifact: result[0], sop: result[1] }));
    }

    sopOrderChange(sopOrderChange: SopOrderChange): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (sopOrderChange.parentArtifactId) {
            config.params = { artifactId: sopOrderChange.parentArtifactId };
        }
        return restClient.put<void>(API_SOPS + '/order', sopOrderChange.orderedArtifactIds, config)
            .then();
    }

    deleteSop(sopVersionId: string): Promise<void> {
        return restClient.delete<void>(sopVersionApi(sopVersionId)).then();
    }

    changeReleaseState(sop: Sop, releaseState: ReleaseState): Promise<void> {
        return restClient.put<void>(sopVersionApi(sop.versionId) + '/release-state', { releaseState }).then();
    }

    createNewVersion(sop: Sop): Promise<Sop> {
        return restClient.post<Sop>(sopArtifactApi(sop.artifactId) + '/versions')
            .then(t => t.data);
    }

    exportPdf(sop: Sop): Promise<void> {
        return restClient.get(API_SOPS + '/v/' + sop.versionId + '/pdf', { responseType: 'blob' })
            .then(response => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const link = document.createElement('a');
                    const objectReference = UrlHelper.createObjectURL(blob);
                    link.href = objectReference;
                    link.download = `${ sop.name.replaceAll(' ', '-') }_V${ sop.version }-${ sop.releaseState }.pdf`;
                    link.click();
                    UrlHelper.revokeObjectURL(objectReference);
                }
            });
    }

    simplifiedHtml(sop: Sop): Promise<string> {
        return restClient.get<string>(API_SOPS + '/v/' + sop.versionId + '/html')
            .then(response => response.data);
    }

    loadSopDocumentHistory(sopArtifactId: string): Promise<SopDocumentHistory> {
        function convertEntry(entry: SopDocumentHistoryEntry) {
            return {
                ...entry,
                releasedAt: moment(entry.releasedAt)
            };
        }

        function convertEntryOrUndefined(entry: SopDocumentHistoryEntry | undefined) {
            if (!entry) {
                return undefined;
            }
            return convertEntry(entry);
        }

        return restClient.get<SopDocumentHistory>(API_SOPS + '/' + sopArtifactId + '/versions')
            .then(response => ({
                ...response.data,
                released: response.data.released.map(convertEntry),
                unreleased: convertEntryOrUndefined(response.data.unreleased)
            }));
    }

    loadSopSummaries(artifactIds: string[]): Promise<SopArtifactSummaries> {
        const params = { artifactIds: artifactIds.join(',') };
        return restClient.get<SopArtifactSummaries>(API_SOPS + '/artifact-summaries', { params })
            .then(r => r.data);
    }
}

export const sopService: SopService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new SopServiceMock() : new SopBackendService();
