























































import { mapGetters } from 'vuex';
import Component, { mixins } from 'vue-class-component';
import EditorHtmlWrapper from '@/components/common/EditorHtmlWrapper.vue';
import { TemplateContentActions, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { RoleGetters } from '@/store/roles';
import SopBlockEntryLayout from '@/features/sop-block/sop-block-list/SopBlockEntryLayout.vue';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';
import { UiLayoutActions } from '@/store/ui-layout';

@Component({
  components: { SopBlockEntryLayout, EditorHtmlWrapper },
  computed: {
    ...mapGetters({
      getTemplateContentByIdOrEmpty: TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY,
      getRoleByIdOrEmpty: RoleGetters.ROLE_BY_ID_OR_EMPTY
    }),
  },
})
export default class SopBlockEntryStep extends mixins<SopBlockEntryMixin>(SopBlockEntryMixin) {

  selectTemplateContentId(templateContentId: string) {
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SELECT_ID, templateContentId)
        .then(() => this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, 'SopTemplateContentWidget'));
  }

}
