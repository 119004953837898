import moment from 'moment';
import { Comment } from '@/features/comments/model';
import { CommentService } from '@/features/comments/comment-service';
import { SOP_A_ARTIFACT_ID } from '@/services/sop-service-mock';
import { ChildrenCommentCount } from '@/features/comments/store';

export class CommentServiceMock implements CommentService {

    static comments: Comment[] = [];

    public static init() {
        CommentServiceMock.comments = [
            {
                id: 'comment-0--sop-a',
                refId: SOP_A_ARTIFACT_ID,
                refType: 'SOP_ARTIFACT',
                comment: 'this is the comment 1',
                createdUserId: 'user-id-1',
                createdUserName: 'User 1',
                createdUserInitials: 'U1',
                createdAt: moment(new Date(2020, 1, 1)),
            },
            {
                id: 'comment-1--sop-a',
                refId: SOP_A_ARTIFACT_ID,
                refType: 'SOP_ARTIFACT',
                comment: 'this is the comment 2',
                createdUserId: 'user-id-2',
                createdUserName: 'User 2',
                createdUserInitials: 'U2',
                createdAt: moment(new Date(2020, 1, 1)),
            },
            {
                id: 'comment-1--sop-a--block-1',
                refId: 'sop-block-1',
                refType: 'SOP_BLOCK_ARTIFACT',
                parentRefId: SOP_A_ARTIFACT_ID,
                parentRefType: 'SOP_ARTIFACT',
                comment: 'this is the comment 1 of block 1',
                createdUserId: 'user-id-2',
                createdUserName: 'User 2',
                createdUserInitials: 'U2',
                createdAt: moment(new Date(2020, 1, 1)),
            }
        ];
    }

    loadCommentsForReference(refId: string): Promise<Comment[]> {
        return Promise.resolve(CommentServiceMock.comments.filter(c => c.refId === refId));
    }

    loadChildrenCommentCount(refId: string): Promise<ChildrenCommentCount> {
        const commentCount: ChildrenCommentCount = {};
        CommentServiceMock.comments
            .filter(c => c.parentRefId === refId)
            .forEach(c => commentCount[c.refId] = commentCount[c.refId] ? commentCount[c.refId] + 1 : 1);
        return Promise.resolve(commentCount);
    }

    createCommentForReference(comment: Comment): Promise<Comment> {
        const newComment = {
            ...comment,
            id: 'comment-' + CommentServiceMock.comments.length + '--' + comment.refId,
            createdUserId: 'user-id',
            createdAt: moment(new Date(2020, 1, 1)),
        }
        CommentServiceMock.comments = [
            ...CommentServiceMock.comments,
            newComment
        ];
        return Promise.resolve(newComment);
    }

    updateComment(comment: Comment): Promise<void> {
        const index = CommentServiceMock.comments.findIndex(c => c.id === comment.id);
        CommentServiceMock.comments.splice(index, 1, { ...comment });
        return Promise.resolve();
    }

    deleteComment(id: string): Promise<void> {
        CommentServiceMock.comments = CommentServiceMock.comments.filter(c => c.id !== id);
        return Promise.resolve();
    }
}