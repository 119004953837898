import moment from 'moment';

export type LicenseTypeView = 'VIEW_OPEN' | 'VIEW_LICENSED';
export type LicenseTypeExport = 'EXPORT_OPEN' | 'EXPORT_LICENSED';

export interface SrRegulation {
    id: string;
    number: string;
    title: string;
    licenseTypeView: LicenseTypeView;
    licenseTypeExport: LicenseTypeExport;
}

export function createLoadingRegulation(regulationId: string) {
    return {
        id: regulationId,
        number: '',
        title: 'Loading...',
    };
}

export enum RegulationState {
    INFORMATIVE = 'INFORMATIVE',
    INTRODUCING = 'INTRODUCING',
    ACTIVE = 'ACTIVE',
    EXPIRING = 'EXPIRING',
    OBSOLETE = 'OBSOLETE',
}

export interface QmsRegulation {
    regulationId: string;
    dbVersion: number;
    state: RegulationState;
    activeFrom?: moment.Moment;
    activeTo?: moment.Moment;
}

export interface QmsRegulationStatistics {
    totalRequirements: number;
    notDefinedCount: number;
    notApplicableCount: number;
    informationCount: number;
    applicableCount: number;
    notApplicableWarningCount: number;
    notApplicableWithoutWarningCount: number;
    infoWarningCount: number;
    applicableWarningCount: number;
    applicableWithoutWarningCount: number;
    unconfirmedEvidenceCount: number;
    confirmedEvidenceCount: number;
    deprecatedEvidenceCount: number;
}

export interface QmsRegulationOverallStatistics {
    regulationCount: number;
    requirementCount: number;
    evidenceCount: number;
    overallStatistics: QmsRegulationStatistics;
}

export function definedApplicabilityWithWarningCount(item: QmsRegulationStatistics) {
    return item.applicableWarningCount + item.notApplicableWarningCount + item.infoWarningCount;
}

export function definedApplicabilityWithoutWarningCount(item: QmsRegulationStatistics) {
    return item.applicableCount + item.informationCount + item.notApplicableCount - definedApplicabilityWithWarningCount(item);
}

export function evidenceWarningCount(item: QmsRegulationStatistics) {
    return item.unconfirmedEvidenceCount + item.deprecatedEvidenceCount;
}

export function evidenceCount(item: QmsRegulationStatistics) {
    return item.confirmedEvidenceCount + evidenceWarningCount(item);
}

export function createEmptyQmsRegulationStatistics(): QmsRegulationStatistics {
    return {
        totalRequirements: 0,
        notDefinedCount: 0,
        notApplicableCount: 0,
        informationCount: 0,
        applicableCount: 0,
        notApplicableWarningCount: 0,
        notApplicableWithoutWarningCount: 0,
        infoWarningCount: 0,
        applicableWarningCount: 0,
        applicableWithoutWarningCount: 0,
        unconfirmedEvidenceCount: 0,
        confirmedEvidenceCount: 0,
        deprecatedEvidenceCount: 0,
    };
}

export interface QmsRegulationSearch {
    regulation: QmsRegulation;
    statistics: QmsRegulationStatistics;
}

export function createEmptyQmsRegulation(regulationId: string): QmsRegulation {
    return {
        regulationId,
        dbVersion: 0,
        state: RegulationState.INFORMATIVE
    };
}

export type SrRegulationMap = { [key: string]: SrRegulation };
export type QmsRegulationMap = { [key: string]: QmsRegulation };
export type QmsRegulationStatisticsMap = { [key: string]: QmsRegulationStatistics };

export type CombinedRegulation = SrRegulation & QmsRegulation & QmsRegulationStatistics;