


































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RegulationDetailActions } from '@/store/regulation-detail';

@Component
export default class AnchoredHeading extends Vue {

  @Prop({ required: true }) text: string;
  @Prop({ required: true }) level: number;
  @Prop({ required: true }) requirementKey: number;

  setAnchor(event: Event) {
    this.$store.dispatch(RegulationDetailActions.ANCHORED_REQUIREMENT_KEY_UPDATE, this.requirementKey);
    event.stopPropagation();
  }

}
