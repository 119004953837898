import { createEmptyRole, NEW_ROLE_ID, Role } from '@/model/Role';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import Vue from 'vue';
import { roleService } from '@/services/role-service';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet } from '@/utils/util';

export type RoleMap = { [key: string]: Role };

export class RoleState {
    roles: RoleMap = {};
}

export enum RoleActions {
    ROLES_LOAD = 'ROLES_LOAD',
    ROLE_CREATE = 'ROLE_CREATE',
    ROLE_DELETE = 'ROLE_DELETE',
}

export enum RoleGetters {
    ROLE_BY_ID_OR_EMPTY = 'ROLE_BY_ID_OR_EMPTY'
}

export enum Mutations {
    ROLES_REPLACE = 'ROLES_REPLACE',
    ROLE_REPLACE = 'ROLE_REPLACE',
    ROLE_DELETE = 'ROLE_DELETE',
}

export function createLoading(id: string): Role {
    return {
        ...createEmptyRole(),
        id,
        name: 'Loading...'
    }
}

const getters = {
    [RoleGetters.ROLE_BY_ID_OR_EMPTY]: (state: RoleState) => (roleId: string) => {
        if (!roleId || roleId.length === 0) {
            return createEmptyRole();
        }
        return state.roles[roleId] || createLoading(roleId)
    }
}

const actions = {
    [RoleActions.ROLES_LOAD]: ({ commit, dispatch }: ActionContext<RoleState, RootState>) => {
        return roleService.loadRoles()
            .then(roles => commit(Mutations.ROLES_REPLACE, roles))
            .catch(err => UiFeedback.showError(dispatch, `Roles couldn't be fetched. Please try again.`, err));
    },
    [RoleActions.ROLE_CREATE]: ({ commit, dispatch }: ActionContext<RoleState, RootState>, role: Role) => {
        const rolePromise = role.id === NEW_ROLE_ID ?
            roleService.createRole(role) :
            roleService.updateRole(role);
        return rolePromise
            .then(role => commit(Mutations.ROLE_REPLACE, role))
            .catch(err => {
                dispatch(RoleActions.ROLES_LOAD);
                return UiFeedback.showError(dispatch, `Role couldn't be created/updated. Please try again.`, err)
            });
    },
    [RoleActions.ROLE_DELETE]: ({ commit, dispatch }: ActionContext<RoleState, RootState>, roleId: string) => {
        return roleService.deleteRole(roleId)
            .then(() => commit(Mutations.ROLE_DELETE, roleId))
            .catch(err => UiFeedback.showError(dispatch, `Role couldn't be deleted. Please try again.`, err));
    }
}

const mutations = {
    [Mutations.ROLES_REPLACE]: (state: RoleState, roles: RoleMap) =>
        safeVueSet(state, 'roles', roles),
    [Mutations.ROLE_REPLACE]: (state: RoleState, role: Role) =>
        safeVueSet(state.roles, role.id, role),
    [Mutations.ROLE_DELETE]: (state: RoleState, roleId: string) =>
        Vue.delete(state.roles, roleId)
}

export const ROLE_MODULE: Module<RoleState, RootState> = {
    state: new RoleState(),
    getters,
    actions,
    mutations
};