var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{attrs:{"direction":_vm.direction,"open-on-hover":true,"transition":_vm.direction === 'left' ? 'slide-x-reverse-transition' : 'slide-x-transition'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{attrs:{"small":"","data-test-add-sop-block-btn":_vm.index}},[_vm._v(" "+_vm._s(_vm.direction === 'left' ? 'mdi-table-row-plus-before' : 'mdi-table-row-plus-after')+" ")])]},proxy:true}]),model:{value:(_vm.fabOpen),callback:function ($$v) {_vm.fabOpen=$$v},expression:"fabOpen"}},[_c('v-tooltip',{staticClass:"fix-z-index",staticStyle:{"opacity":"1.0"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.createNewBlockArtifact({index: _vm.index, type: 'HEADER'})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-title")])],1)]}}])},[_c('span',[_vm._v("Add Title")])]),_c('v-tooltip',{staticClass:"fix-z-index",staticStyle:{"opacity":"1.0"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.createNewBlockArtifact({index: _vm.index, type: 'TIPTAP'})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-outline")])],1)]}}])},[_c('span',[_vm._v("Add Section")])]),(_vm.showAddStep)?_c('v-tooltip',{staticClass:"fix-z-index",staticStyle:{"opacity":"1.0"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-test-add-sop-step-block-btn":_vm.index,"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.createNewBlockArtifact({index: _vm.index, type: 'STEP'})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-ray-start-arrow")])],1)]}}],null,false,2112037298)},[_c('span',[_vm._v("Add Step")])]):_vm._e(),_c('v-tooltip',{staticClass:"fix-z-index",staticStyle:{"opacity":"1.0"},attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.createNewBlockArtifact({index: _vm.index, type: 'DRAWIO'})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-drawing")])],1)]}}])},[_c('span',[_vm._v("Add Diagram")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }