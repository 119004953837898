

































































import Component, { mixins } from 'vue-class-component';
import EditorWrapper from '@/components/common/EditorWrapper.vue';
import SopBlockDetailLayout from '@/features/sop-block/sop-block-detail/SopBlockDetailLayout.vue';
import SopBlockDetailMixin from '@/features/sop-block/sop-block-detail/SopBlockDetailMixin.vue';
import { mapGetters } from 'vuex';
import { RegulationDetailGetters } from '@/store/regulation-detail';

@Component({
  components: { SopBlockDetailLayout, EditorWrapper },
  computed: {
    ...mapGetters({
      selectableProducts: RegulationDetailGetters.PRODUCTS,
    }),
  },
})
export default class SopBlockDetailTiptap extends mixins<SopBlockDetailMixin>(SopBlockDetailMixin) {

}
