import { Role } from '@/model/Role';
import { RoleMap } from '@/store/roles';
import { RoleServiceMock } from '@/services/role-service-mock';
import { restClient } from '@/store/rest-client';
import { toMap } from '@/utils/util';

export interface RoleService {

    loadRoles(): Promise<RoleMap>;

    createRole(role: Omit<Role, 'id'>): Promise<Role>;

    updateRole(role: Role): Promise<Role>;

    deleteRole(roleId: string): Promise<void>;

}

const API_ROLE = '/qms-api/roles';

class RoleBackendService implements RoleService {

    loadRoles(): Promise<RoleMap> {
        return restClient.get<Role[]>(process.env.VUE_APP_QMS_BASE_URL + API_ROLE)
            .then(response => response.data)
            .then(roles => toMap(roles, c => c.id));
    }

    createRole(role: Omit<Role, 'id'>): Promise<Role> {
        return restClient.post<Role>(process.env.VUE_APP_QMS_BASE_URL + API_ROLE, role)
            .then(t => t.data);
    }

    updateRole(role: Role): Promise<Role> {
        return restClient.put<Role>(process.env.VUE_APP_QMS_BASE_URL + API_ROLE + '/' + role.id, role)
            .then(t => t.data);
    }

    deleteRole(roleId: string): Promise<void> {
        return restClient.delete<Role>(process.env.VUE_APP_QMS_BASE_URL + API_ROLE + '/' + roleId)
            .then();
    }

}

export const roleService: RoleService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new RoleServiceMock() : new RoleBackendService();