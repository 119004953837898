















































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import SidebarRight from '@/layouts/SidebarRight.vue';
import { UiLayoutActions } from '@/store/ui-layout';

export type SidebarComponent = {
  displayName: string;
  componentName: string;
  icon: string;
  onActivate?: () => void;
}

@Component({
  components: { SidebarRight },
  computed: {
    ...mapState<RootState>({
      logoSrc: (state: RootState) => state.auth.tenantLogoSrc,
      sidebarRightSelectedWidgetName: (state: RootState) => state.uiLayout.sidebarRightSelectedWidgetName
    })
  }
})
export default class CoLayout extends Vue {

  @Prop({ default: false }) leftSidebarExpandedInit: boolean;
  @Prop({ default: false }) rightSidebarExpandedInit: boolean;
  @Prop({ default: true }) contentScrollable: boolean;
  @Prop({ default: () => [] }) rightSidebarComponents: SidebarComponent[];

  leftSidebarExpanded = true;
  rightSidebarComponentIndex = -1;
  sidebarRightSelectedWidgetName: string;

  created() {
    this.leftSidebarExpanded = this.leftSidebarExpandedInit;
    if (this.rightSidebarExpandedInit) {
      this.selectComponentName(this.sidebarRightSelectedWidgetName.length > 0 ? this.sidebarRightSelectedWidgetName : this.rightSidebarComponents[0].componentName)
    }
    this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, this.activeComponentName);
  }

  get activeComponentName() {
    return this.rightSidebarComponentIndex >= 0 ? this.rightSidebarComponents[this.rightSidebarComponentIndex].componentName : '';
  }

  get rightSidebarExpanded() {
    return this.rightSidebarComponentIndex >= 0;
  }

  onSidebarTabClick(i: number) {
    this.rightSidebarComponentIndex = this.rightSidebarComponentIndex === i ? -1 : i;
    this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, this.activeComponentName);
  }

  @Watch('sidebarRightSelectedWidgetName')
  selectComponentName(componentName: string) {
    if (componentName === this.activeComponentName) {
      return;
    }
    this.rightSidebarComponentIndex = this.rightSidebarComponents.findIndex(comp => comp.componentName === componentName);
  }

  closeRightSidebar() {
    this.rightSidebarComponentIndex = -1;
    this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, '');
  }

}
