import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';

import { ImpactAnalysis, ImpactAnalysisSearch, ImpactAnalysisStatisticMap, NEW_IMPACT_ANALYSIS_ID } from '@/features/impact-analysis/model';
import { impactAnalysisService } from '@/features/impact-analysis/impact-analysis-service';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet, toMap } from '@/utils/util';
import Vue from 'vue';
import { TemplateState } from '@/store/templates';

export class ImpactAnalysisState {
    impactAnalyses: ImpactAnalysis[] = [];
    statistics: ImpactAnalysisStatisticMap = {};
}

export enum ImpactAnalysisActions {
    IMPACT_ANALYSES_LOAD = 'IMPACT_ANALYSES_LOAD',
    IMPACT_ANALYSIS_SAVE = 'IMPACT_ANALYSIS_SAVE',
    IMPACT_ANALYSIS_DELETE = 'IMPACT_ANALYSIS_DELETE',
}

enum Mutations {
    IMPACT_ANALYSES_REPLACE = 'IMPACT_ANALYSES_REPLACE',
    IMPACT_ANALYSIS_DELETE = 'IMPACT_ANALYSIS_DELETE',
}

const actions = {
    [ImpactAnalysisActions.IMPACT_ANALYSES_LOAD]: ({ commit, dispatch }: ActionContext<ImpactAnalysisState, RootState>) => {
        return impactAnalysisService.loadAllImpactAnalyses()
            .then(impactAnalyses => commit(Mutations.IMPACT_ANALYSES_REPLACE, impactAnalyses))
            .catch(err => UiFeedback.showError(dispatch, 'Impact Analyses could not be loaded, try again later.', err));
    },
    [ImpactAnalysisActions.IMPACT_ANALYSIS_SAVE]: ({ commit, dispatch }: ActionContext<ImpactAnalysisState, RootState>, impactAnalysis: ImpactAnalysis) => {
        const impactAnalysisPromise = impactAnalysis.id === NEW_IMPACT_ANALYSIS_ID ?
            impactAnalysisService.createImpactAnalysis(impactAnalysis) :
            impactAnalysisService.updateImpactAnalysis(impactAnalysis);
        return impactAnalysisPromise
            .catch(err => UiFeedback.showError(dispatch, 'something did not work', err))
            .finally(() => dispatch(ImpactAnalysisActions.IMPACT_ANALYSES_LOAD));
    },
    [ImpactAnalysisActions.IMPACT_ANALYSIS_DELETE]: ({ commit, dispatch }: ActionContext<ImpactAnalysisState, RootState>, id: string) => {
        impactAnalysisService.deleteImpactAnalysis(id)
            .then(() => commit(Mutations.IMPACT_ANALYSIS_DELETE, id))
    },
};

const mutations = {
    [Mutations.IMPACT_ANALYSES_REPLACE]: (state: ImpactAnalysisState, impactAnalysisSearch: ImpactAnalysisSearch) => {
        safeVueSet(state, 'impactAnalyses', impactAnalysisSearch.impactAnalyses);
        safeVueSet(state, 'statistics', toMap(impactAnalysisSearch.statistics, stat => stat.impactAnalysisId));
    },
    [Mutations.IMPACT_ANALYSIS_DELETE]: (state: ImpactAnalysisState, id: string) =>
        safeVueSet(state, 'impactAnalyses', state.impactAnalyses.filter(ia => ia.id !== id)),
};

export const IMPACT_ANALYSIS_MODULE: Module<ImpactAnalysisState, RootState> = {
    state: new ImpactAnalysisState(),
    getters: {},
    actions,
    mutations
};