


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export class CoHorizontalBarConfig {
  label: string;
  count: number;
  color: string;
}

@Component
export default class CoHorizontalBar extends Vue {

  @Prop()
  tooltipTitle: string;
  @Prop()
  data: CoHorizontalBarConfig[];

  entryStyle(config: CoHorizontalBarConfig) {
    return {
      'width': `${ this.percentsWidth(config.count) }%`,
      'border-bottom': '5px solid ' + config.color,
    };
  }

  percents(count: number) {
    return Math.round((count / this.totalCounts) * 100);
  }

  percentsWidth(count: number) {
    const percents = this.percents(count);
    if (percents < 3 && count > 0) {
      return 3;
    } else {
      return percents
    }
  }

  get totalCounts(): number {
    return this.data.reduce((sum, current) => sum + current.count, 0);
  }

}
