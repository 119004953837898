import { restClient } from '@/store/rest-client';

import { toMap } from '@/utils/util';
import { SrProcessContentMap, SrProcessMap, SrRoleMap, SrTemplateContentMap } from '@/store/sr';
import { Process } from '@/model/Process';
import { SrServiceMock } from '@/services/sr-service-mock';
import { SrProcessContent, SrTemplateContent } from '@/features/sr-model/SrTemplateContent';
import { SrRole } from '@/features/sr-model/SrRole';

export interface SrService {
    loadProcesses(): Promise<SrProcessMap>;

    loadTemplateContents(): Promise<SrTemplateContentMap>;

    loadProcessContents(): Promise<SrProcessContentMap>;

    loadRoles(): Promise<SrRoleMap>;
}

const API_SR_PROCESSES = '/sr-api/processes';
const API_SR_TEMPLATE_CONTENTS = '/sr-api/template-contents';
const API_SR_PROCESS_CONTENTS = '/sr-api/sop-contents';
const API_SR_ROLES = '/sr-api/roles';

class SrBackendService implements SrService {

    loadProcesses(): Promise<SrProcessMap> {
        return restClient.get<Process[]>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_SR_PROCESSES)
            .then(response => response.data)
            .then(processes => toMap(processes, p => p.id ? p.id : ''));
    }

    loadTemplateContents(): Promise<SrTemplateContentMap> {
        return restClient.get<SrTemplateContent[]>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_SR_TEMPLATE_CONTENTS)
            .then(response => response.data)
            .then(templateContents => toMap(templateContents, c => c.id));
    }

    loadProcessContents(): Promise<SrProcessContentMap> {
        return restClient.get<SrProcessContent[]>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_SR_PROCESS_CONTENTS)
            .then(response => response.data)
            .then(processContents => toMap(processContents, c => c.id));
    }

    loadRoles(): Promise<SrRoleMap> {
        return restClient.get<SrRole[]>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_SR_ROLES)
            .then(response => response.data)
            .then(roles => toMap(roles, c => c.id));
    }
}

export const srService: SrService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new SrServiceMock() : new SrBackendService();