var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticStyle:{"z-index":"100"},attrs:{"height":_vm.height,"mini-variant":"","permanent":"","clipped":"","dark":"","app":"","data-test-left-navigation":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',[_c('v-menu',{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])],1),_c('v-list-item-content')],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-subtitle',[_vm._v("Help")])],1),_c('v-list-item',[_c('v-list-item-content',[_c('a',{attrs:{"href":("/docs/" + ((_vm.$route.meta || {}).docPath || '')),"target":"_blank"}},[_vm._v(" Documentation "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)])],1)],1)],1),_c('v-menu',{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"data-test-account-item":""}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-account-outline")])],1),_c('v-list-item-content')],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_vm._v(" "+_vm._s(_vm.user.userName)),_c('br'),_c('small',[_vm._v(_vm._s(_vm.user.tenantName))])])],1),_c('v-divider'),_c('v-list-item',{attrs:{"data-test-logout-button":""},on:{"click":_vm.logout}},[_c('v-list-item-content',[_vm._v(" Log out ")])],1)],1)],1)],1)]},proxy:true}])},[_c('v-list',{staticClass:"py-0",attrs:{"elevation":"0"}},_vm._l((_vm.navigationItems),function(item){return _c('v-tooltip',{key:item.title,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":item.routerLink,"active-class":"active-item"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content')],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }