import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { qmsConfigService } from '@/services/qms-config-service';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet } from '@/utils/util';

type QmsConfig = { key: QmsConfigKey; value: string };

export type QmsConfigKey = 'QMS_PROCESS_IMAGE';
export type SaveQmsConfigAction = (qmsConfig: QmsConfig) => Promise<void>;

export class QmsConfigState {
    configs: Record<QmsConfigKey, string> = {
        QMS_PROCESS_IMAGE: ''
    };
}

export enum QmsConfigActions {
    LOAD_QMS_OVERVIEW_IMAGE = 'LOAD_QMS_OVERVIEW_IMAGE',
    SAVE_QMS_CONFIG = 'SAVE_QMS_CONFIG',
}

const getters = {}

export enum Mutations {
    QMS_CONFIG_REPLACE = 'QMS_CONFIG_REPLACE',
}

const actions = {
    [QmsConfigActions.SAVE_QMS_CONFIG]: ({ commit, dispatch }: ActionContext<QmsConfigState, RootState>, qmsConfig: QmsConfig) =>
        qmsConfigService.setStringValue(qmsConfig.key, qmsConfig.value)
            .then(newValue => commit(Mutations.QMS_CONFIG_REPLACE, { key: qmsConfig.key, value: newValue } as QmsConfig))
            .catch(err => UiFeedback.showAndThrowError(dispatch, `QMS config '${ qmsConfig.key }' could not be saved, try again later.`, err)),
    [QmsConfigActions.LOAD_QMS_OVERVIEW_IMAGE]: ({ commit, dispatch }: ActionContext<QmsConfigState, RootState>) =>
        qmsConfigService.getStringValue('QMS_PROCESS_IMAGE')
            .then(qmsProcessImage => commit(Mutations.QMS_CONFIG_REPLACE, { key: 'QMS_PROCESS_IMAGE', value: qmsProcessImage } as QmsConfig))
            .catch(err => UiFeedback.showAndThrowError(dispatch, `QMS process image could not be loaded, try again later.`, err)),
}

const mutations = {
    [Mutations.QMS_CONFIG_REPLACE]: (state: QmsConfigState, qmsConfig: QmsConfig) =>
        safeVueSet(state.configs, qmsConfig.key, qmsConfig.value),
}

export const QMS_CONFIG_MODULE: Module<QmsConfigState, RootState> = {
    state: new QmsConfigState(),
    actions,
    getters,
    mutations
};