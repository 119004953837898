
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SopBlock } from '@/features/sop-block/model';

@Component
export default class SopBlockEntryMixin extends Vue {

  @Prop() blockVersionId: string;

  get block(): SopBlock {
    return this.isDeletedBlock ?
        this.$store.state.sopDetail.blocksDeleted[this.blockVersionId] :
        this.$store.state.sopBlockList.blocks[this.blockVersionId];
  }

  get isDeletedBlock(): boolean {
    return !!this.$store.state.sopDetail.blocksDeleted[this.blockVersionId];
  }

  get blockIndex() {
    return Object.keys(this.$store.state.sopBlockList.blocks).indexOf(this.blockVersionId);
  }

}
