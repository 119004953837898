











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TemplateContent, TemplateContentValidation } from '@/features/template-content/template-content-model';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';

@Component({
  components: { TemplateContentValidationIcons }
})
export default class TemplateContentChip extends Vue {

  @Prop()
  templateContent: TemplateContent;

  @Prop({ default: () => [] })
  templateContentValidations: TemplateContentValidation[];

}
