
















import Vue from 'vue';
import Component from 'vue-class-component';
import CommentsTimeline from '@/features/comments/components/CommentsTimeline.vue';
import { mapActions, mapState } from 'vuex';
import { RootState } from '@/store';
import { CommentsWidgetActions } from '@/features/comments/store';
import { SopBlockListActions } from '@/features/sop-block/sop-block-list/store';
import { humanReadableRefType } from '@/features/comments/model';

@Component({
  components: { CommentsTimeline },
  computed: {
    ...mapState<RootState>({
      refType: (state: RootState) => humanReadableRefType(state.comments.commentRef.refType),
      parentRefType: (state: RootState) => humanReadableRefType(state.comments.commentRef.parentRefType ?? 'NA'),
      hasParent: (state: RootState) => !!state.comments.commentRef.parentRefId,
    })
  },
  methods: {
    ...mapActions({
      loadParentComments: CommentsWidgetActions.COMMENTS_LOAD_FOR_PARENT,
      removeBlockSelection: SopBlockListActions.SOP_BLOCK_LIST_REMOVE_BLOCK_SELECTION,
    })
  }
})
export default class CommentsWidget extends Vue {

  loadParentComments: () => void;
  removeBlockSelection: () => void;

  goToParentComments() {
    this.loadParentComments();
    this.removeBlockSelection();
  }

}
