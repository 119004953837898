



















































import Vue from 'vue';
import Component from 'vue-class-component';
import ProductRegulations from '@/features/product-requirements/ProductRegulations.vue';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { RegulationDetailGetters } from '@/store/regulation-detail';

@Component({
  components: { ProductRegulations },
  computed: {
    ...mapState<RootState>({
      selectedSopBlockEvent: (state: RootState) => state.productRequirements.selectedSopBlockEvent,
    }),
    ...mapGetters({
      getProductByIdOrEmpty: RegulationDetailGetters.PRODUCT_BY_ID_OR_EMPTY
    }),
  },
})
export default class ProductRequirementWidget extends Vue {
  selectedSopBlockEvent: SopBlockSelectedEvent;
}
