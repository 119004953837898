




















import { Component, Vue } from 'vue-property-decorator';
import CoLayout from '@/layouts/CoLayout.vue';
import TemplatesOverview from '@/components/templates-overview/TemplatesOverview.vue';
import { RoleActions } from '@/store/roles';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import { TemplateActions } from '@/store/templates';
import TemplateOverviewHeader from '@/components/templates-overview/TemplateOverviewHeader.vue';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import SopHierarchy from '@/features/sops/sop-hierarchiy/SopHierarchy.vue';
import { SopActions } from '@/features/sops';
import SopFilterTree from '@/features/sops/sop-filter-tree/SopFilterTree.vue';

@Component({
  components: { SopFilterTree, SopHierarchy, TemplateOverviewHeader, TemplatesOverview, CoLayout, TemplateContentDetailWidget },
})
export default class TemplatesOverviewView extends Vue {

  created() {
    this.$store.dispatch(SopActions.SOPS_LOAD);
    this.$store.dispatch(RoleActions.ROLES_LOAD);
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);
    this.$store.dispatch(TemplateActions.TEMPLATES_SEARCH);
  }

  destroyed() {
    this.$store.dispatch(TemplateActions.TEMPLATES_SEARCH_CLEAR);
  }

}
