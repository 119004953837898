










import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import RegulationToc from '@/components/common/RegulationToc.vue';
import { Requirement } from '@/features/sr-model/Requirement';

@Component({
  components: { RegulationToc },
  computed: {
    ...mapState<RootState>({
      requirements: (state: RootState) => Object.values(state.impacts.toRegulationRequirements),
      toRequirementIdToImpactId: (state: RootState) => state.impacts.toRequirementIdToImpactId,
    })
  }
})
export default class RequirementSidebar extends Vue {

  toRequirementIdToImpactId: { [key: string]: string };
  requirements: Requirement[];

  scrollToRequirementKey(requirementKey = '') {
    if (requirementKey.length === 0) {
      return;
    }
    const requirementId = this.requirements.find(req => req.key === requirementKey)?.id
    if (!requirementId) {
      return;
    }
    let impactId = this.toRequirementIdToImpactId[requirementId];
    if (!impactId) {
      impactId = requirementId;
    }
    window.location.replace('#impact=' + impactId);
  }

}

