




import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { Watch } from 'vue-property-decorator';

@Component({
  computed: {
    ...mapState<RootState>({
      users: (state: RootState) => Object.values(state.users.users),
    }),
  }
})
export default class Breadcrumbs extends Vue {

  private static INITIAL_BREADCRUMBS = [
    {
      path: '',
      text: 'Dashboard',
      disabled: false,
      to: '/dashboard',
      exact: true
    }
  ]

  items: any = Breadcrumbs.INITIAL_BREADCRUMBS;

  @Watch('$route', { immediate: true })
  onUrlChange() {
    this.createBreadcrumbItems();
  }

  private createBreadcrumbItems() {
    if (!this.$route || !this.$router || this.$route.name === 'Dashboard') {
      return;
    }

    const paths = this.$route.path.split('/');
    paths.shift(); // remove first element, which it is empty
    this.items = paths.reduce((result, path, idx) => {
      const fullPath = result[idx].path + '/' + path
      const route = (this.$router as any).matcher.match(fullPath);
      result.push({
        path: fullPath,
        to: route.path,
        text: route.meta.breadcrumb || path,
        disabled: this.$route.name === route.name,
        exact: true
      });
      return result;
    }, [...Breadcrumbs.INITIAL_BREADCRUMBS])
  }

}
