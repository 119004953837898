








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import QmsRequirementApplicabilityComponent from '@/components/common/QmsRequirementApplicabilityComponent.vue';
import { ImpactsActions } from '@/features/impacts/store';
import { QmsRequirement } from '@/services/model';

@Component({
  components: { QmsRequirementApplicabilityComponent }
})
export default class ImpactQmsRequirementApplicability extends Vue {

  @Prop() qmsRequirement: QmsRequirement;
  @Prop() editable: boolean;

  dispatchQmsRequirementChange(changingFields: Partial<QmsRequirement>) {
    this.$store.dispatch(ImpactsActions.TO_QMS_REQUIREMENT_CHANGE,
        {
          ...this.qmsRequirement,
          ...changingFields,
        } as QmsRequirement);
  }
}
