










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import QmsRequirementProposal from '@/components/common/QmsRequirementProposal.vue';
import { ImpactsActions } from '@/features/impacts/store';
import { Sop } from '@/model';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { QmsRequirement } from '@/services/model';

@Component<SopQmsRequirementProposal>({
  components: { QmsRequirementProposal },
})
export default class SopQmsRequirementProposal extends Vue {

  @Prop() qmsRequirement: QmsRequirement;

  @Prop({ default: () => [] })
  proposedSops: Sop[];
  @Prop({ default: () => [] })
  proposedTemplateContents: TemplateContent[];

  private dispatchQmsRequirementChange(changingFields: Partial<QmsRequirement>): Promise<any> {
    return this.$store.dispatch(ImpactsActions.TO_QMS_REQUIREMENT_CHANGE,
        {
          ...this.qmsRequirement,
          ...changingFields
        } as QmsRequirement);
  }

  proposedSopsCallback() {
    return this.proposedSops;
  }

  proposedTemplateContentCallback() {
    return this.proposedTemplateContents;
  }
}
