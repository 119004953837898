import { TemplateSearchPart } from '@/model/Template';
import { SopSearchPart } from '@/model/Sop';
import { TemplateContentFilter } from '@/features/template-content/template-content-store';
import { SopBlockSearchPart } from '@/features/sop-block/model';

export type TemplateContentValidation =
    'CREATED_BY_MULTIPLE_PROCESSES'
    | 'CREATED_BY_MULTIPLE_TEMPLATES'
    | 'NOT_CREATED_BY_TEMPLATE'
    | 'NOT_CREATED_BY_PROCESS';

export type TemplateContentSearchInfo = {
    reportedByTemplateVersionIds: string[];
    createdByTemplateVersionIds: string[];
    createdBySopVersionIds: string[];
    validations: TemplateContentValidation[];
}

export type TemplateContentSearchResult = {
    templateContents: TemplateContent[];
    templates: TemplateSearchPart[];
    blocks: SopBlockSearchPart[];
    sops: SopSearchPart[];
    sopToBlocks: { [key: string]: string[] };
    templateContentInfos: { [key: string]: TemplateContentSearchInfo };
}

export const emptyTemplateContentFilter = (): TemplateContentFilter => {
    return {
        filterText: '',
        filterCreatingTemplateArtifactIds: [],
        filterReportingTemplateArtifactIds: [],
        selectedSopArtifactId: '',
    }
}

export const emptyTemplateContentSearchResult = (): TemplateContentSearchResult => {
    return {
        templateContents: [],
        templates: [],
        blocks: [],
        sops: [],
        sopToBlocks: {},
        templateContentInfos: {},
    };
}

export interface TemplateContent {
    id: string;
    name: string;
    description: string;
    exampleText?: string;
    srId?: string;
}

export function createEmptyTemplateContent(): TemplateContent {
    return {
        id: '',
        name: '',
        description: ''
    };
}

export const NEW_TEMPLATE_CONTENT_ID = 'NEW_TEMPLATE_CONTENT_ID';

export function createNewTemplateContent(): TemplateContent {
    return { ...createEmptyTemplateContent(), id: NEW_TEMPLATE_CONTENT_ID };
}
