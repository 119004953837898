import {
    CombinedRegulation,
    createEmptyQmsRegulation,
    createEmptyQmsRegulationStatistics,
    createLoadingRegulation,
    QmsRegulation,
    QmsRegulationMap,
    QmsRegulationStatistics,
    QmsRegulationStatisticsMap,
    SrRegulationMap
} from '@/features/regulations/model';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { safeVueSet, toMap, toMapWithValue } from '@/utils/util';
import { UiFeedback } from '@/store/ui-feedback';
import { srRegulationService } from '@/services/sr-regulation-service';
import { qmsRegulationService } from '@/features/regulations/qms-regulation-service';
import { setterForFields } from '@/utils/VuexUtil';
import { hasUserSrViewRights } from '@/utils/UserUtils';

export class RegulationState {
    srRegulations: SrRegulationMap = {};
    qmsRegulations: QmsRegulationMap = {};
    qmsRegulationStatistics: QmsRegulationStatisticsMap = {};

    editingQmsRegulationId = '';
}

export enum RegulationGetters {
    SR_REGULATION_BY_ID_OR_LOADING = 'SR_REGULATION_BY_ID_OR_LOADING',
    QMS_REGULATION_BY_ID_OR_EMPTY = 'SR_REGULATION_BY_ID_OR_EMPTY',
    COMBINED_REGULATIONS = 'COMBINED_REGULATIONS',
    EDITING_REGULATION = 'EDITING_REGULATION',
    VIEW_ALLOWED_BY_REGULATION_ID = 'VIEW_ALLOWED_BY_REGULATION_ID',
}

const getters = {
    [RegulationGetters.SR_REGULATION_BY_ID_OR_LOADING]: (state: RegulationState) =>
        (regulationId: string) => state.srRegulations[regulationId] || createLoadingRegulation(regulationId),
    [RegulationGetters.QMS_REGULATION_BY_ID_OR_EMPTY]: (state: RegulationState) =>
        (regulationId: string) => state.qmsRegulations[regulationId] || createEmptyQmsRegulation(regulationId),
    [RegulationGetters.COMBINED_REGULATIONS]: (state: RegulationState, getters: any): CombinedRegulation[] =>
        Object.values(state.srRegulations)
            .map(srRegulation => {
                const qmsRegulation = getters[RegulationGetters.QMS_REGULATION_BY_ID_OR_EMPTY](srRegulation.id);
                const stats = state.qmsRegulationStatistics[srRegulation.id] ?? createEmptyQmsRegulationStatistics();
                return { ...srRegulation, ...qmsRegulation, ...stats };
            }),
    [RegulationGetters.EDITING_REGULATION]: (state: RegulationState) => ({
        ...state.srRegulations[state.editingQmsRegulationId],
        ...state.qmsRegulations[state.editingQmsRegulationId]
    }),
    [RegulationGetters.VIEW_ALLOWED_BY_REGULATION_ID]: (state: RegulationState, getters: any, rootState: RootState) => (regulationId: string) => {
        const licenseTypeView = state.srRegulations[regulationId].licenseTypeView;
        return hasUserSrViewRights(licenseTypeView, rootState.auth.user)
    },
};

export enum RegulationMutations {
    SR_REGULATIONS_REPLACE = 'SR_REGULATIONS_REPLACE',
    QMS_REGULATIONS_REPLACE = 'QMS_REGULATIONS_REPLACE',
    QMS_REGULATION_REPLACE = 'QMS_REGULATION_REPLACE',
    QMS_REGULATION_FIELDS_REPLACE = 'QMS_REGULATION_FIELDS_REPLACE',
    QMS_REGULATIONS_STATISTICS_REPLACE = 'QMS_REGULATIONS_STATISTICS_REPLACE',
    QMS_REGULATIONS_STATISTIC_FIELD_REPLACE = 'QMS_REGULATIONS_STATISTIC_FIELD_REPLACE',
    EDITING_QMS_REGULATION_ID_REPLACE = 'EDITING_QMS_REGULATION_ID_REPLACE',
}

const mutations = {
    [RegulationMutations.SR_REGULATIONS_REPLACE]: (state: RegulationState, srRegulations: SrRegulationMap) =>
        safeVueSet(state, 'srRegulations', srRegulations),
    [RegulationMutations.QMS_REGULATIONS_REPLACE]: (state: RegulationState, qmsRegulations: QmsRegulationMap) =>
        safeVueSet(state, 'qmsRegulations', qmsRegulations),
    [RegulationMutations.QMS_REGULATION_REPLACE]: (state: RegulationState, qmsRegulation: QmsRegulation) =>
        safeVueSet(state.qmsRegulations, qmsRegulation.regulationId, qmsRegulation),
    [RegulationMutations.QMS_REGULATION_FIELDS_REPLACE]: (state: RegulationState, fields: Partial<QmsRegulation>) => {
        const editingRegulationId = state.editingQmsRegulationId;
        if (editingRegulationId.length === 0) {
            return;
        }
        safeVueSet(state.qmsRegulations, editingRegulationId, { ...state.qmsRegulations[editingRegulationId], ...fields });
    },
    [RegulationMutations.EDITING_QMS_REGULATION_ID_REPLACE]: (state: RegulationState, regulationId: string) =>
        safeVueSet(state, 'editingQmsRegulationId', regulationId || ''),
    [RegulationMutations.QMS_REGULATIONS_STATISTICS_REPLACE]: (state: RegulationState, statistics: QmsRegulationStatisticsMap) =>
        safeVueSet(state, 'qmsRegulationStatistics', statistics),
    [RegulationMutations.QMS_REGULATIONS_STATISTIC_FIELD_REPLACE]: (state: RegulationState, [id, stats]: [string, QmsRegulationStatistics]) =>
        safeVueSet(state.qmsRegulationStatistics, id, stats),
};

export enum RegulationActions {
    REGULATIONS_LOAD = 'REGULATIONS_LOAD',
    REGULATIONS_WITH_STATISTICS_LOAD = 'REGULATIONS_WITH_STATISTICS_LOAD',
    QMS_REGULATION_SAVE_EDITING = 'QMS_REGULATION_SAVE_EDITING',
    QMS_REGULATION_STATISTICS_LOAD = 'QMS_REGUATION_STATISTICS_FIELD_REPLACE'
}

function mergeSrAndQmsRegulations(srRegulationMap: SrRegulationMap, qmsRegulationMap: QmsRegulationMap) {
    const emptyQmsRegulations = Object.keys(srRegulationMap)
        .filter(regulationId => !qmsRegulationMap[regulationId])
        .map(regulationId => createEmptyQmsRegulation(regulationId));
    const mergedQmsRegulations = { ...qmsRegulationMap, ...toMap(emptyQmsRegulations, r => r.regulationId) };
    return { emptyQmsRegulations, mergedQmsRegulations };
}

const actions = {
    [RegulationActions.QMS_REGULATION_STATISTICS_LOAD]: ({ commit, dispatch }: ActionContext<RegulationState, RootState>, regulationId: string) =>
        qmsRegulationService.loadStatistics(regulationId)
            .then(stats => commit(RegulationMutations.QMS_REGULATIONS_STATISTIC_FIELD_REPLACE, [regulationId, stats])),
    [RegulationActions.REGULATIONS_LOAD]: ({ commit, dispatch }: ActionContext<RegulationState, RootState>) => {
        return Promise.all([
            srRegulationService.loadSrRegulations()
                .catch(err => UiFeedback.showError(dispatch, 'Regulations from SR could not be fetched.', err)),
            qmsRegulationService.loadQmsRegulations()
                .catch(err => UiFeedback.showError(dispatch, 'Regulations from QMS could not be fetched.', err)),
        ]).then(([srRegulationMap, qmsRegulationMap]: [SrRegulationMap, QmsRegulationMap]) => {
            const { mergedQmsRegulations } = mergeSrAndQmsRegulations(srRegulationMap, qmsRegulationMap);
            commit(RegulationMutations.SR_REGULATIONS_REPLACE, srRegulationMap);
            commit(RegulationMutations.QMS_REGULATIONS_REPLACE, mergedQmsRegulations);
        });
    },
    [RegulationActions.REGULATIONS_WITH_STATISTICS_LOAD]: ({ commit, dispatch }: ActionContext<RegulationState, RootState>) =>
        Promise.all([
            srRegulationService.loadSrRegulations()
                .catch(err => UiFeedback.showError(dispatch, 'Regulations from SR could not be fetched.', err)),
            qmsRegulationService.loadQmsRegulationsWithStatistics()
                .catch(err => UiFeedback.showError(dispatch, 'Regulations from QMS could not be fetched.', err)),
        ]).then(([srRegulationMap, [qmsRegulationMap, qmsStatisticsMap]]: [SrRegulationMap, [QmsRegulationMap, QmsRegulationStatisticsMap]]) => {
            const { emptyQmsRegulations, mergedQmsRegulations } = mergeSrAndQmsRegulations(srRegulationMap, qmsRegulationMap);
            commit(RegulationMutations.SR_REGULATIONS_REPLACE, srRegulationMap);
            commit(RegulationMutations.QMS_REGULATIONS_REPLACE, mergedQmsRegulations);
            return Promise.all(emptyQmsRegulations.map(e => qmsRegulationService.loadStatistics(e.regulationId).then(r => [r, e.regulationId] as [QmsRegulationStatistics, string])))
                .then(results => toMapWithValue(results, r => r[1], r => r[0]))
                .then(map => commit(RegulationMutations.QMS_REGULATIONS_STATISTICS_REPLACE, { ...map, ...qmsStatisticsMap }))
        }),
    [RegulationActions.QMS_REGULATION_SAVE_EDITING]:
        ({ state, commit, dispatch }: ActionContext<RegulationState, RootState>) =>
            qmsRegulationService.saveQmsRegulation(state.qmsRegulations[state.editingQmsRegulationId])
                .then(qmsRegulation => {
                    commit(RegulationMutations.QMS_REGULATION_REPLACE, qmsRegulation);
                    commit(RegulationMutations.EDITING_QMS_REGULATION_ID_REPLACE, undefined);
                })
                .catch(err => UiFeedback.showError(dispatch, 'Regulation could not be updated. Please try again later.', err)),
};

export const REGULATIONS_MODULE: Module<RegulationState, RootState> = {
    state: new RegulationState(),
    getters,
    actions,
    mutations
};

export class RegulationHelper {

    static setterForQmsRegulationFields(...fields: Array<keyof QmsRegulation>) {
        return setterForFields<QmsRegulation>(RegulationMutations.QMS_REGULATION_FIELDS_REPLACE, fields);
    }

}