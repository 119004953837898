import { restClient } from '@/store/rest-client';
import { orDefault, toMap } from '../utils/util';
import { FileStorageServiceMock } from '@/services/file-storage-service-mock';
import { ArtifactType, FileStorage } from '@/model/FileStorage';

export type FileStorageSearch = {
    artifactType: ArtifactType;
    artifactVersionId: string;
}

export type FileDownloadResponse = {
    filename: string; // e.g. 'T123 Template 47.dotx'
    blob: Blob;
}

export type FileStorageMap = { [key: string]: FileStorage };

export interface FileStorageService {

    loadFileStorages(search: FileStorageSearch): Promise<FileStorageMap>;

    create(fileStorage: FileStorage): Promise<FileStorage>;

    deleteById(fileStorageId: string): Promise<void>;

    upload(fileStorageId: string, file: File): Promise<FileStorage>;

    download(fileStorageId: string): Promise<FileDownloadResponse>;
}

const API_STORAGE = '/qms-api/storage';
const STORAGE_API_URL = process.env.VUE_APP_QMS_BASE_URL + API_STORAGE;

class FileStorageBackendService implements FileStorageService {
    loadFileStorages(search: FileStorageSearch): Promise<FileStorageMap> {
        const params = {
            'artifact-type': search.artifactType,
            'artifact-id': search.artifactVersionId,
        }
        return restClient.get<FileStorage[]>(STORAGE_API_URL, { params })
            .then(response => response.data)
            .then(terms => toMap(terms, s => s.id));
    }

    create(fileStorage: FileStorage): Promise<FileStorage> {
        return restClient.post<FileStorage>(STORAGE_API_URL, fileStorage)
            .then(t => t.data);
    }

    deleteById(fileStorageId: string): Promise<void> {
        return restClient.delete<void>(STORAGE_API_URL + '/' + fileStorageId).then();
    }

    upload(fileStorageId: string, file: File): Promise<FileStorage> {
        const formData = new FormData();
        formData.append('file', file);
        const options = { headers: { 'Content-Type': 'multipart/form-data' } };
        return restClient.post<FileStorage>(STORAGE_API_URL + '/' + fileStorageId + '/content', formData, options)
            .then(t => t.data);
    }

    download(fileStorageId: string): Promise<FileDownloadResponse> {
        return restClient.get(STORAGE_API_URL + '/' + fileStorageId + '/content', { responseType: 'blob' })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('error while loading file storage. HTTP status code: ' + response.status);
                }
                const type = response.headers['content-type'];
                const filename = orDefault(response.headers['original-filename'], 'file');
                return {
                    filename: filename,
                    blob: new Blob([response.data], { type })
                };
            });
    }
}

export const fileStorageService: FileStorageService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new FileStorageServiceMock() : new FileStorageBackendService();