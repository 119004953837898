import Vue from 'vue';

export const orDefault = <T>(value: T | undefined, defaultValue: T): T => !value ? defaultValue : value;

export const safeVueSet = <S extends object, T>(object: S, key: keyof S & string, value: T): T => {
    return Vue.set(object, key, value);
}

export const checkUndefined = <T>(itsSave: T | undefined): T => {
    if (!itsSave) {
        throw new Error('The given value is undefined but a value was expected.');
    }
    return itsSave as T;
}

export const toMapWithValue = <V, T>(arr: T[], keyFunc: (t: T) => string, valueFunc: (t: T) => V): { [key: string]: V } => {
    const initValue: { [key: string]: V } = {};
    return arr.reduce(
        (map, entry) => {
            map[keyFunc(entry)] = valueFunc(entry);
            return map;
        }, initValue);
}

export const toMap = <T>(arr: T[], keyFunc: (t: T) => string): { [key: string]: T } => toMapWithValue<T, T>(arr, keyFunc, t => t);

export const toGroupedMap = <T>(arr: T[], keyFunc: (t: T) => string): { [key: string]: T[] } => {
    const initValue: { [key: string]: T[] } = {};
    return arr.reduce(
        (map, entry) => {
            if (!map[keyFunc(entry)]) {
                map[keyFunc(entry)] = [];
            }
            map[keyFunc(entry)].push(entry);
            return map;
        }, initValue);
}

export type ComparatorReturnType<T> = (left: T, right: T) => 0 | 1 | -1;
export const createComparator = <T>(fieldName: keyof T): ComparatorReturnType<T> => {
    return (left: T, right: T) => {
        if (left[fieldName] === right[fieldName]) {
            return 0;
        }
        return left[fieldName] > right[fieldName] ? 1 : -1;
    }
}
export const arraysEqualExactly = <T>(array1: T[], array2: T[]): boolean => {
    return array1.length === array2.length &&
        array1.every((value, index) => value === array2[index]);
}

function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');
    document.body.removeChild(textArea);
}

export const copyTextToClipboard = (text: string) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then();
}

export const openUrlInNewTab = (url: string) => {
    window?.open(url, '_blank')?.focus();
}

export type StringArrayMap = { [key: string]: string[] };

export type LoadingType = 'NOT_LOADED' | 'LOADING' | 'COMPLETED';