



































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import { RoleGetters } from '@/store/roles';
import { TemplateDetailActions, TemplateDetailHelper } from '@/store/templates/template-detail';
import { copyTextToClipboard, openUrlInNewTab } from '@/utils/util';
import { createForTemplate, FileStorage } from '@/model/FileStorage';
import { Template } from '@/model/Template';
import FileUpload from '@/components/common/FileUpload.vue';

@Component({
  components: { FileUpload },
  computed: {
    ...mapState<RootState>({
      template: (state: RootState) => state.templates.template,
      templateContents: (state: RootState) => Object.values(state.templateContents.templateContents),
      roles: (state: RootState) => [{ id: '', name: '# No Role' }, ...Object.values(state.roles.roles)],
    }),
    ...mapGetters({
      getTemplateContentByIdOrEmpty: TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY,
      getRoleByIdOrEmpty: RoleGetters.ROLE_BY_ID_OR_EMPTY
    }),
  },
  methods: {
    ...mapMutations({
      ...TemplateDetailHelper.setterForTemplateFields('name', 'description', 'documentKey', 'reviewerRoleId', 'releaserRoleId', 'location', 'createdTemplateContentIds', 'reportedTemplateContentIds'),
    })
  }
})
export default class TemplateDetailDialog extends Vue {
  template: Template;
  rules = [
    (value: string) => (!!value && value.length >= 1) || 'Minimum 1 character',
  ];
  valid = false;
  editDialog = false;

  get fileStorage(): FileStorage {
    return createForTemplate(this.template.versionId);
  }

  save() {
    this.$store.dispatch(TemplateDetailActions.TEMPLATE_DETAIL_SAVE)
        .then(() => this.editDialog = false);
  }

  cancel() {
    this.$store.dispatch(TemplateDetailActions.TEMPLATE_DETAIL_CANCEL)
        .then(() => this.editDialog = false);
  }

  copyToClipboard(text: string) {
    copyTextToClipboard(text);
  }

  openLink(url: string) {
    openUrlInNewTab(url);
  }

}
