import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { ChangeControl, ChangeControlParticipant, ChangeControlWrite, createEmptyChangeControl, createEmptyChangeControlWrite } from '@/model';
import { sopChangeControlService } from '@/services/sop-change-control-service';
import { SopDetailActions } from '@/store/sop-detail';
import { UiFeedback } from '@/store/ui-feedback';
import { setterForFields } from '@/utils/VuexUtil';
import { safeVueSet } from '@/utils/util';

export class SopChangeControlState {
    changeControl: ChangeControl = createEmptyChangeControl();
    changeControlWrite: ChangeControlWrite = createEmptyChangeControlWrite();
    all: ChangeControl[] = [];
}

export enum SopChangeControlActions {
    SOP_RELEASE_LOAD = 'SOP_RELEASE_LOAD',
    SOP_CREATE_RELEASE = 'SOP_CREATE_RELEASE',
    SOP_CANCEL_RELEASE = 'SOP_CANCEL_RELEASE',
    SOP_COMPLETE_RELEASE = 'SOP_COMPLETE_RELEASE',
    SOP_APPROVE_RELEASE = 'SOP_APPROVE_RELEASE',
    SOP_DECLINE_RELEASE = 'SOP_DECLINE_RELEASE',
    SOP_REVERT_RELEASE_DECISION = 'SOP_REVERT_RELEASE_DECISION',
}

export enum SopChangeControlMutations {
    SOP_CHANGE_CONTROL_REPLACE = 'SOP_CHANGE_CONTROL_REPLACE',
    SOP_CHANGE_CONTROL_ALL_REPLACE = 'SOP_CHANGE_CONTROL_ALL_REPLACE',
    SOP_CHANGE_CONTROL_WRITE_FIELD_REPLACE = 'SOP_CHANGE_CONTROL_WRITE_FIELD_REPLACE'
}

export class SopChangeControlWriteHelper {

    static setterForChangeControlWriteFields(...fields: Array<keyof ChangeControlWrite>) {
        return setterForFields(SopChangeControlMutations.SOP_CHANGE_CONTROL_WRITE_FIELD_REPLACE, fields);
    }
}

export enum SopChangeControlGetters {
    CURRENT_USER_IS_PARTICIPANT = 'CURRENT_USER_IS_PARTICIPANT',
    CURRENT_USER_HAS_PARTICIPATED = 'CURRENT_USER_HAS_PARTICIPATED',
    CURRENT_USER_CHANGE_CONTROL_PARTICIPANT = 'CURRENT_USER_CHANGE_CONTROL_PARTICIPANT',
    HAVE_ALL_PARTICIPANTS_APPROVED = 'HAVE_ALL_PARTICIPANTS_APPROVED',
}

class GetterHelper {
    static isParticipant(participants: ChangeControlParticipant[], userId: string) {
        return participants.map(p => p.userId).indexOf(userId) >= 0;
    }

    static hasParticipated(participants: ChangeControlParticipant[], userId: string) {
        if (!this.isParticipant(participants, userId)) {
            return false;
        }
        return participants.find(p => p.userId === userId)?.decision !== 'OPEN';
    }

    static currentUserChangeControlParticipant(authors: ChangeControlParticipant[], approvers: ChangeControlParticipant[], userId: string) {
        const author = authors.find(p => p.userId === userId);
        if (author) {
            return author;
        }
        const approver = approvers.find(p => p.userId === userId);
        if (approver) {
            return approver;
        }
        return undefined;
    }
}

type ParticipantDecisionCallback = (sopVersionId: string, comment: string) => Promise<ChangeControl>

class ActionHelper {
    static decideParticipantDecision(callback: ParticipantDecisionCallback, { rootState, commit, dispatch }: ActionContext<SopChangeControlState, RootState>, comment: string) {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        return callback(sopVersionId, comment)
            .then(changeControl => commit(SopChangeControlMutations.SOP_CHANGE_CONTROL_REPLACE, changeControl))
            .then(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId))
            .catch(err => UiFeedback.showError(dispatch, `Couldn't execute decision for current participant. Please try again.`, err))
    }
}

const getters = {
    [SopChangeControlGetters.CURRENT_USER_IS_PARTICIPANT]: (state: SopChangeControlState, getter: SopChangeControlGetters, rootState: RootState) =>
        GetterHelper.isParticipant(state.changeControl.authors, rootState.auth.user.userId)
        || GetterHelper.isParticipant(state.changeControl.approvers, rootState.auth.user.userId),
    [SopChangeControlGetters.CURRENT_USER_HAS_PARTICIPATED]: (state: SopChangeControlState, getter: SopChangeControlGetters, rootState: RootState) =>
        GetterHelper.hasParticipated(state.changeControl.authors, rootState.auth.user.userId)
        || GetterHelper.hasParticipated(state.changeControl.approvers, rootState.auth.user.userId),
    [SopChangeControlGetters.CURRENT_USER_CHANGE_CONTROL_PARTICIPANT]: (state: SopChangeControlState, getter: SopChangeControlGetters, rootState: RootState) =>
        GetterHelper.currentUserChangeControlParticipant(state.changeControl.authors, state.changeControl.approvers, rootState.auth.user.userId),
    [SopChangeControlGetters.HAVE_ALL_PARTICIPANTS_APPROVED]: (state: SopChangeControlState) =>
        !state.changeControl.authors.find(p => p.decision !== 'APPROVED')
        && !state.changeControl.approvers.find(p => p.decision !== 'APPROVED')
};

const actions = {
    [SopChangeControlActions.SOP_RELEASE_LOAD]: ({ rootState, commit, dispatch }: ActionContext<SopChangeControlState, RootState>) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        return sopChangeControlService.loadRelease(sopVersionId)
            .then(changeControl => commit(SopChangeControlMutations.SOP_CHANGE_CONTROL_REPLACE, changeControl))
            .then(() =>
                sopChangeControlService.loadAllReleases(sopVersionId)
                    .then(allReleases => commit(SopChangeControlMutations.SOP_CHANGE_CONTROL_ALL_REPLACE, allReleases)))
            .catch(err => UiFeedback.showError(dispatch, `Couldn't load release. Please try again.`, err))
    },
    [SopChangeControlActions.SOP_CREATE_RELEASE]: ({ rootState, commit, dispatch }: ActionContext<SopChangeControlState, RootState>) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        return sopChangeControlService.createRelease(sopVersionId, rootState.sopChangeControl.changeControlWrite)
            .then(changeControl => commit(SopChangeControlMutations.SOP_CHANGE_CONTROL_REPLACE, changeControl))
            .then(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId))
            .catch(err => UiFeedback.showError(dispatch, `Couldn't create release. Please try again.`, err))
    },
    [SopChangeControlActions.SOP_CANCEL_RELEASE]: ({ rootState, commit, dispatch }: ActionContext<SopChangeControlState, RootState>) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        return sopChangeControlService.cancelRelease(sopVersionId)
            .then(changeControl => commit(SopChangeControlMutations.SOP_CHANGE_CONTROL_REPLACE, changeControl))
            .then(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId))
            .catch(err => UiFeedback.showError(dispatch, `Couldn't cancel release. Please try again.`, err))
    },
    [SopChangeControlActions.SOP_COMPLETE_RELEASE]: ({ rootState, commit, dispatch }: ActionContext<SopChangeControlState, RootState>) => {
        const sopVersionId = rootState.sopDetail.sop.versionId;
        return sopChangeControlService.completeRelease(sopVersionId)
            .then(changeControl => commit(SopChangeControlMutations.SOP_CHANGE_CONTROL_REPLACE, changeControl))
            .then(() => dispatch(SopDetailActions.SOP_DETAIL_LOAD, sopVersionId))
            .catch(err => UiFeedback.showError(dispatch, `Couldn't complete release. Please try again.`, err))
    },
    [SopChangeControlActions.SOP_APPROVE_RELEASE]: (context: ActionContext<SopChangeControlState, RootState>, comment: string) => {
        return ActionHelper.decideParticipantDecision(sopChangeControlService.approveRelease.bind(sopChangeControlService), context, comment);
    },
    [SopChangeControlActions.SOP_DECLINE_RELEASE]: (context: ActionContext<SopChangeControlState, RootState>, comment: string) => {
        return ActionHelper.decideParticipantDecision(sopChangeControlService.declineRelease.bind(sopChangeControlService), context, comment);
    },
    [SopChangeControlActions.SOP_REVERT_RELEASE_DECISION]: (context: ActionContext<SopChangeControlState, RootState>, comment: string) => {
        return ActionHelper.decideParticipantDecision(sopChangeControlService.revertDecision.bind(sopChangeControlService), context, comment);
    },
};

const mutations = {
    [SopChangeControlMutations.SOP_CHANGE_CONTROL_REPLACE]: (state: SopChangeControlState, changeControl: ChangeControl) => {
        safeVueSet(state, 'changeControlWrite', { ...createEmptyChangeControlWrite() });
        safeVueSet(state, 'changeControl', changeControl)
    },
    [SopChangeControlMutations.SOP_CHANGE_CONTROL_ALL_REPLACE]: (state: SopChangeControlState, all: ChangeControl[]) => {
        state.all = all;
    },
    [SopChangeControlMutations.SOP_CHANGE_CONTROL_WRITE_FIELD_REPLACE]: (state: SopChangeControlState, changeControlWrite: Partial<ChangeControlWrite>) => {
        Object.assign(state.changeControlWrite, changeControlWrite);
    },
};

export const SOP_CHANGE_CONTROL_MODULE: Module<SopChangeControlState, RootState> = {
    state: new SopChangeControlState(),
    getters,
    actions,
    mutations
}