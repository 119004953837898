








import Component from 'vue-class-component';
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator';
import { RequirementIdsPerRegulation, RequirementSearchFilter, requirementSearchService } from '@/features/sr-requirement-search/service/requirement-search-service';
import RequirementSearchResultPanels from '@/features/sr-requirement-search/RequirementSearchResultPanels.vue';
import { RequirementSearchResult } from '@/features/sr-requirement-search/model/RequirementSearch';

@Component({
  components: { RequirementSearchResultPanels },
})
export default class RequirementSearchByIds extends Vue {

  @Prop()
  requirementIdsPerRegulation: RequirementIdsPerRegulation;
  searchResult: RequirementSearchResult = {
    requirements: [],
    regulations: []
  };

  @Watch('requirementIdsPerRegulation')
  watchDependencies(newValue: Array<any>, oldValue: Array<any>) {
    if (JSON.stringify(oldValue) == JSON.stringify(newValue)) {
      return;
    }
    this.executeSearch();
  }

  mounted() {
    this.executeSearch();
  }

  executeSearch() {
    return requirementSearchService.search(this.createSearchFilter()).then(this.updateSearchResult)
  }

  private updateSearchResult(searchResult: RequirementSearchResult) {
    this.searchResult = searchResult;
  }

  private createSearchFilter(): RequirementSearchFilter {
    return {
      requirementIdsPerRegulation: this.requirementIdsPerRegulation,
    };
  }
}

