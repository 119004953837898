import { v4 as uuidv4 } from 'uuid';

import { ImpactAnalysisService } from '@/features/impact-analysis/impact-analysis-service';
import { createEmptyStatistics, ImpactAnalysis, ImpactAnalysisSearch, ImpactAnalysisStatistics } from '@/features/impact-analysis/model';

export class ImpactAnalysisServiceMock implements ImpactAnalysisService {

    static impactAnalyses: ImpactAnalysis[] = [];

    static init() {
        ImpactAnalysisServiceMock.impactAnalyses = [
            {
                id: '429c5baa-a4b4-4ae9-8588-bb72e6e14e35',
                title: 'First Impact Analysis',
                regulationIdFrom: 'iso-1',
                regulationIdTo: 'iso-2'
            },
            {
                id: '75210d84-7a09-42d9-a2ab-501cb9898223',
                title: 'Second Impact Analysis',
                regulationIdFrom: 'iso-1',
                regulationIdTo: 'mdr-2017-745'
            },
        ];
    }

    createImpactAnalysis(impactAnalysis: Omit<ImpactAnalysis, 'id'>): Promise<ImpactAnalysis> {
        const newImpactAnalysis = {
            ...impactAnalysis,
            id: uuidv4()
        };
        ImpactAnalysisServiceMock.impactAnalyses.push(newImpactAnalysis);
        return Promise.resolve({ ...newImpactAnalysis });
    }

    loadAllImpactAnalyses(): Promise<ImpactAnalysisSearch> {
        return Promise.resolve({
            impactAnalyses: [...ImpactAnalysisServiceMock.impactAnalyses],
            statistics: ImpactAnalysisServiceMock.impactAnalyses.map(i => ({ ...createEmptyStatistics(), impactAnalysisId: i.id }) as ImpactAnalysisStatistics),
        });
    }

    loadImpactAnalysis(id: string): Promise<ImpactAnalysis> {
        const impactAnalysis = ImpactAnalysisServiceMock.impactAnalyses.find(e => e.id === id);
        return impactAnalysis ?
            Promise.resolve({ ...impactAnalysis }) :
            Promise.reject(`Can't find the impact analysis with id ${ id }`);
    }

    updateImpactAnalysis(impactAnalysis: ImpactAnalysis): Promise<ImpactAnalysis> {
        const index = ImpactAnalysisServiceMock.impactAnalyses.findIndex(e => e.id === impactAnalysis.id);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing impact analysis');
        }
        ImpactAnalysisServiceMock.impactAnalyses.splice(index, 1, { ...impactAnalysis });
        return Promise.resolve({ ...impactAnalysis });
    }

    deleteImpactAnalysis(id: string): Promise<void> {
        return Promise.resolve();
    }

}