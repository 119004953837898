



















import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import CoLayout from '@/layouts/CoLayout.vue';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import { RoleActions } from '@/store/roles';
import { TemplateDetailActions } from '@/store/templates/template-detail';
import TemplateDetailHeader from '@/components/templates-detail/TemplateDetailHeader.vue';
import TemplateDetail from '@/components/templates-detail/TemplateDetail.vue';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import GapListWidget from '@/features/gap-list/gap-list-widget/GapListWidget.vue';
import { RegulationActions } from '@/features/regulations/regulation-store';

@Component({
  components: { TemplateDetail, TemplateDetailHeader, CoLayout, GapListWidget, TemplateContentDetailWidget },
  computed: {
    ...mapState<RootState>({
      template: (state: RootState) => state.templates.template,
    }),
  }
})
export default class TemplateDetailView extends Vue {

  created() {
    this.$store.dispatch(RegulationActions.REGULATIONS_WITH_STATISTICS_LOAD);
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);
    this.$store.dispatch(RoleActions.ROLES_LOAD);
    this.$store.dispatch(TemplateDetailActions.TEMPLATE_DETAIL_LOAD, this.$route.params.id);
  }

  beforeDestroy() {
    this.$store.dispatch(TemplateDetailActions.TEMPLATE_DETAIL_RESET);
  }
}
