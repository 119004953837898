











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import { mapGetters } from 'vuex';
import QmsRequirementProposal from '@/components/common/QmsRequirementProposal.vue';
import { QmsRequirement } from '@/services/model';

@Component<SopQmsRequirementProposal>({
  components: { QmsRequirementProposal },
  computed: {
    ...mapGetters({
      proposedSopsByRequirementId: RegulationDetailGetters.PROPOSED_SOPS_BY_REQUIREMENT_ID,
      proposedTemplateContentByRequirementId: RegulationDetailGetters.PROPOSED_TEMPLATE_CONTENTS_BY_REQUIREMENT_ID,
    }),
  },
})
export default class SopQmsRequirementProposal extends Vue {

  @Prop()
  qmsRequirement: QmsRequirement;
  @Prop({ default: true })
  quickApply: boolean;

  private dispatchQmsRequirementChange(changingFields: Partial<QmsRequirement>): Promise<any> {
    return this.$store.dispatch(RegulationDetailActions.QMS_REQUIREMENT_CHANGE,
        {
          ...this.qmsRequirement,
          ...changingFields
        } as QmsRequirement);
  }
}
