




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { QmsRequirementApplicability } from '@/services/qms-requirement-service';
import { QmsGapValidation } from '@/store/regulation-detail';
import { Prop } from 'vue-property-decorator';
import { QmsRequirement } from '@/services/model';

@Component
export default class QmsRequirementApplicabilityComponent extends Vue {

  @Prop() qmsRequirement: QmsRequirement;
  @Prop() editable: boolean;
  @Prop({ default: () => () => Promise.resolve() })
  dispatchQmsRequirementChange: (changingFields: Partial<QmsRequirement>) => Promise<void>;


  set qmsApplicable(applicability: QmsRequirementApplicability) {
    this.dispatchQmsRequirementChange({
      applicability
    });
  }

  get qmsApplicable(): QmsRequirementApplicability {
    return this.qmsRequirement.applicability;
  }

  isDisabled(applicability: QmsRequirementApplicability) {
    if (this.editable) {
      return false;
    }
    return this.qmsRequirement.applicability !== applicability;
  }

  get notDefinedHasWarning() {
    return QmsGapValidation.notDefinedHasWarning(this.qmsRequirement);
  }

  get notApplicableHasWarning() {
    return QmsGapValidation.notApplicableHasWarning(this.qmsRequirement);
  }

  get reasonForNotApplicableIsMissing() {
    return QmsGapValidation.reasonForNotApplicableIsMissing(this.qmsRequirement);
  }

  get applicableHasWarning() {
    return QmsGapValidation.applicableHasWarning(this.qmsRequirement);
  }

  get applicableIsSuccessfully() {
    return QmsGapValidation.applicableIsSuccessfully(this.qmsRequirement);
  }
}
