export interface Process {
    id: string;
    name: string;
    description: string;
    templateContentIds: string[];
    sopContentIds: string[];
    termIds: string[];
}

export const EMPTY_PROCESS: Process = {
    id: '',
    name: '',
    description: '',
    templateContentIds: [],
    sopContentIds: [],
    termIds: [],
}