






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UrlHelper } from '@/utils/UrlHelper';

@Component
export default class DownloadButton extends Vue {

  @Prop({ required: true })
  download: () => Promise<Blob>;

  @Prop({ default: () => 'export.csv' })
  filename: string;

  isLoading = false;

  onClick() {
    this.isLoading = true;
    this.download()
        .then(blob => {
          const link = document.createElement('a');
          const objectReference = UrlHelper.createObjectURL(blob);
          link.href = objectReference;
          link.download = this.filename;
          link.click();
          UrlHelper.revokeObjectURL(objectReference);
        }).finally(() => this.isLoading = false);
  }
}
