import { TermService } from '@/services/term-service';
import { createEmptySopTerm, Term } from '@/model';
import { TermMap } from '@/store/sop-detail';
import { toMap } from '@/utils/util';
import { v4 as uuidv4 } from 'uuid';
import { SOP_D_ARTIFACT_ID, SopServiceMock } from '@/services/sop-service-mock';
import { TermSearchDto } from '@/store/terms';

export class TermServiceMock implements TermService {

    static terms: Array<Term> = [];
    static allTerms: Array<TermSearchDto> = [];

    public static initMock() {
        TermServiceMock.terms = [
            {
                ...createEmptySopTerm(),
                artifactId: 'B-Artifact-fe-46d5-b3f0-aae882eeda3e',
                versionId: 'Bc612aba-cdfe-46d5-b3f0-aae882eeda3e',
                sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                term: 'B-term',
                definition: 'This is term B'
            },
            {
                ...createEmptySopTerm(),
                artifactId: 'D-Artifact-fe-46d5-b3f0-aae882eeda3e',
                versionId: 'Dc612aba-cdfe-46d5-b3f0-aae882eeda3e',
                sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                term: 'D-term',
                definition: 'This is term D'
            },
            {
                ...createEmptySopTerm(),
                artifactId: 'A-Artifact-fe-46d5-b3f0-aae882eeda3e',
                versionId: 'Ac612aba-cdfe-46d5-b3f0-aae882eeda3e',
                sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                term: 'A-term',
                definition: 'This is term A'
            },
            {
                ...createEmptySopTerm(),
                artifactId: 'E-Artifact-fe-46d5-b3f0-aae882eeda3e',
                versionId: 'Ec612aba-cdfe-46d5-b3f0-aae882eeda3e',
                sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                term: 'E-term',
                definition: 'This is term E'
            },
            {
                ...createEmptySopTerm(),
                artifactId: 'C-Artifact-fe-46d5-b3f0-aae882eeda3e',
                versionId: 'Cc612aba-cdfe-46d5-b3f0-aae882eeda3e',
                sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                sopVersionReplaced: 'cc5f09ea-09ce-45e0-9757-87a588f76db4',
                term: 'C-term',
                definition: 'This is term C'
            },
            {
                ...createEmptySopTerm(),
                artifactId: 'F-Artifact-fe-46d5-b3f0-aae882eeda3e',
                versionId: 'Fc612aba-cdfe-46d5-b3f0-aae882eeda3e',
                sopArtifactId: SOP_D_ARTIFACT_ID,
                sopVersionCreated: 'dd1d6344-c733-40ac-8fc9-90093b1410d5',
                term: 'F-term',
                definition: 'This is term F'
            }
        ];
        TermServiceMock.allTerms = [
            {
                term: {
                    ...createEmptySopTerm(),
                    artifactId: 'A-Artifact-fe-46d5-b3f0-aae882eeda3e',
                    versionId: 'Ac612aba-cdfe-46d5-b3f0-aae882eeda3e',
                    sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                    sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                    term: 'A-term',
                    abbreviation: 'AT',
                    definition: 'This is term A'
                },
                latestSop: {
                    artifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                    versionId: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                    releaseState: 'RELEASED',
                    requirementType: 'QMS',
                    version: 1,
                    name: 'Sop C',
                    description: '',
                    templateContentIds: [],
                    srProcessIds: [],
                }
            },
            {
                term: {
                    ...createEmptySopTerm(),
                    artifactId: 'B-Artifact-fe-46d5-b3f0-aae882eeda3e',
                    versionId: 'Bc612aba-cdfe-46d5-b3f0-aae882eeda3e',
                    sopArtifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                    sopVersionCreated: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                    term: 'B-term',
                    definition: 'This is term B'
                },
                latestSop: {
                    artifactId: 'cf5f8123-33d1-4551-ba08-9bae241fae14',
                    versionId: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                    releaseState: 'RELEASED',
                    requirementType: 'QMS',
                    version: 1,
                    name: 'Sop C',
                    description: '',
                    templateContentIds: [],
                    srProcessIds: [],
                }
            },
            {
                term: {
                    ...createEmptySopTerm(),
                    artifactId: 'X-Artifact-fe-46d5-b3f0-aae882eeda3e',
                    versionId: 'Xc612aba-cdfe-46d5-b3f0-aae882eeda3e',
                    sopArtifactId: 'xf5f8123-33d1-4551-ba08-9bae241fae14',
                    sopVersionCreated: 'Xd1d6344-c733-40ac-8fc9-90093b1410d5',
                    term: 'X-term',
                    definition: 'This is term X'
                },
                latestSop: {
                    artifactId: 'xf5f8123-33d1-4551-ba08-9bae241fae14',
                    versionId: 'xd1d6344-c733-40ac-8fc9-90093b1410d5',
                    releaseState: 'RELEASED',
                    requirementType: 'QMS',
                    version: 1,
                    name: 'Sop X',
                    description: '',
                    templateContentIds: [],
                    srProcessIds: [],
                }
            },
        ];
    }

    private static validTerms(sopVersionId: string): Term[] {
        const sop = SopServiceMock.findSopByVersionId(sopVersionId);
        return TermServiceMock.terms
            .filter(t => t.sopArtifactId === sop.artifactId)
            .map(t => ({ ...t }))
            .filter(t => SopServiceMock.findSopByVersionId(t.sopVersionCreated).version >= sop.version)
            .filter(t => !t.sopVersionReplaced || SopServiceMock.findSopByVersionId(t.sopVersionReplaced).version < sop.version)
    }

    allTerms(): Promise<TermSearchDto[]> {
        return Promise.resolve(TermServiceMock.allTerms);
    }

    loadTerms(sopVersionId: string): Promise<TermMap> {
        return Promise.resolve(toMap(TermServiceMock.validTerms(sopVersionId), t => t.versionId));
    }

    loadDeletedTerms(sopVersionId: string): Promise<TermMap> {
        const sop = SopServiceMock.findSopByVersionId(sopVersionId);
        const validArtifactIds = TermServiceMock.validTerms(sopVersionId).map(block => block.artifactId);
        return Promise.resolve(toMap(
            TermServiceMock.terms
                .filter(term => term.sopArtifactId === sop.artifactId)
                .map(term => ({ ...term }))
                .filter(term => term.sopVersionReplaced === sopVersionId)
                .filter(term => validArtifactIds.indexOf(term.artifactId) === -1),
            s => s.versionId
        ));
    }

    createTerm(sopVersionId: string, term: Term): Promise<Term> {
        const createdTerm: Term = {
            ...term,
            artifactId: uuidv4(),
            versionId: uuidv4(),
            sopVersionCreated: sopVersionId,
            sopVersionReplaced: undefined,
            sopArtifactId: SopServiceMock.findSopByVersionId(sopVersionId).artifactId,
        };
        TermServiceMock.terms.push(createdTerm);
        return Promise.resolve({ ...createdTerm });
    }

    createTermVersion(sopVersionId: string, termVersionId: string): Promise<Term> {
        const index = TermServiceMock.terms.map(t => t.versionId).indexOf(termVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing term by its id');
        }

        const previousTerm = TermServiceMock.terms[index];
        previousTerm.sopVersionReplaced = sopVersionId;

        const newTermVersion: Term = {
            ...previousTerm,
            versionId: uuidv4(),
            version: previousTerm.version + 1,
            sopVersionCreated: sopVersionId,
            sopVersionReplaced: undefined,
        };
        TermServiceMock.terms.push(newTermVersion);
        return Promise.resolve(newTermVersion);
    }

    updateTerm(sopVersionId: string, termVersionId: string, term: Term): Promise<Term> {
        return TermServiceMock.replace(termVersionId, { ...term })
    }

    deleteTerm(sopVersionId: string, termVersionId: string): Promise<void> {
        const index = TermServiceMock.terms.map(t => t.versionId).indexOf(termVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the deleting term by its id');
        }
        TermServiceMock.terms.splice(index, 1);
        return Promise.resolve();
    }

    undoDeletedTerm(sopVersionId: string, termVersionId: string): Promise<void> {
        return TermServiceMock.replace(termVersionId, { sopVersionReplaced: undefined })
            .then();
    }

    private static replace(termVersionId: string, term: Partial<Term>) {
        const index = TermServiceMock.terms.map(t => t.versionId).indexOf(termVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing term by its id');
        }
        const sopTerm = TermServiceMock.terms[index];
        if (sopTerm.sopVersionReplaced) {
            SopServiceMock.assertSopInDraft(sopTerm.sopVersionReplaced);
        } else {
            SopServiceMock.assertSopInDraft(sopTerm.sopVersionCreated);
        }
        const updatedTerm = {
            ...sopTerm,
            ...term
        };
        TermServiceMock.terms.splice(index, 1, updatedTerm);
        return Promise.resolve({ ...updatedTerm });
    }

}