











import Vue from 'vue';
import Component from 'vue-class-component';
import { RootState } from '@/store';
import { mapGetters, mapState } from 'vuex';
import { SopDetailGetters } from '@/store/sop-detail';
import SopDetailDialog from '@/components/sop-detail/SopDetailDialog.vue';

@Component({
  components: { SopDetailDialog },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE
    })
  }
})
export default class SopPurpose extends Vue {

}
