















import Component, { mixins } from 'vue-class-component';
import SopBlockEntryLayout from '@/features/sop-block/sop-block-list/SopBlockEntryLayout.vue';
import SopBlockEntryMixin from '@/features/sop-block/sop-block-list/SopBlockEntryMixin.vue';

@Component({
  components: { SopBlockEntryLayout }
})
export default class SopBlockEntryHeader extends mixins<SopBlockEntryMixin>(SopBlockEntryMixin) {

}
