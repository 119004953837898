import { RequirementSearchFilter, RequirementSearchService } from '@/features/sr-requirement-search/service/requirement-search-service';
import { RequirementSearchResult } from '@/features/sr-requirement-search/model/RequirementSearch';
import { RequirementContentType } from '@/features/sr-model/Requirement';

export class RequirementSearchServiceMock implements RequirementSearchService {

    static requirementSearchResult: RequirementSearchResult;

    public static initMock() {
        RequirementSearchServiceMock.requirementSearchResult = {
            regulations: [
                {
                    'id': 'mdr-2017-745',
                    'number': '2017/745',
                    'title': 'Medical Device Regulation (MDR)',
                    'licenseTypeView': 'VIEW_OPEN',
                    'licenseTypeExport': 'EXPORT_OPEN',
                },
                {
                    'id': 'iso-1',
                    'number': '2020/420',
                    'title': 'Cyber Security Regulation (CSR)',
                    'licenseTypeView': 'VIEW_OPEN',
                    'licenseTypeExport': 'EXPORT_OPEN',
                }],
            requirements: [
                {
                    'regulationId': 'mdr-2017-745',
                    'searchResult': false,
                    'id': 'CHAPTER-I___________________________',
                    'key': 'CHAPTER-I',
                    'type': RequirementContentType.HEADING,
                    'interpretable': true,
                    'text': 'Heading',
                    'level': 2,
                    'requirementIds': ['CHAPTER-I_1_________________________'],
                },
                {
                    'regulationId': 'mdr-2017-745',
                    'searchResult': true,
                    'id': 'CHAPTER-I_1_________________________',
                    'key': 'CHAPTER-I_1',
                    'type': RequirementContentType.TEXT,
                    'interpretable': true,
                    'text': 'Test: ',
                    'paragraph': '1.',
                    'level': 3,
                    'requirementIds': ['CHAPTER-I_1_1_______________________'],
                    'parentId': 'CHAPTER-I___________________________'
                },
                {
                    'regulationId': 'mdr-2017-745',
                    'searchResult': true,
                    'id': 'CHAPTER-I_1_1_______________________',
                    'key': 'CHAPTER-I_1_1',
                    'type': RequirementContentType.TEXT,
                    'interpretable': true,
                    'text': 'Testing: things',
                    'paragraph': '1.',
                    'level': 4,
                    'requirementIds': [],
                    'parentId': 'CHAPTER-I_1_________________________'
                },
                {
                    'regulationId': 'mdr-2017-745',
                    'searchResult': true,
                    'id': 'CHAPTER-I_1_1_1_____________________',
                    'key': 'CHAPTER-I_1_1_1',
                    'type': RequirementContentType.TEXT,
                    'interpretable': true,
                    'text': 'Testing: things',
                    'paragraph': '1.',
                    'level': 4,
                    'requirementIds': [],
                    'parentId': 'CHAPTER-I_1_1_______________________'
                },
                {
                    'regulationId': 'iso-1',
                    'searchResult': false,
                    'id': 'CHAPTER-I___________________________',
                    'key': 'CHAPTER-I',
                    'type': RequirementContentType.HEADING,
                    'interpretable': true,
                    'text': 'Heading',
                    'level': 2,
                    'requirementIds': ['CHAPTER-I_1_________________________'],
                }]
        };
    }

    search(filter: RequirementSearchFilter): Promise<RequirementSearchResult> {
        if (!RequirementSearchServiceMock.requirementSearchResult) {
            RequirementSearchServiceMock.initMock();
        }
        const requirementIdsPerRegulation = filter.requirementIdsPerRegulation || [];
        const templateContentIds = filter.templateContentIds || [];
        const sopContentIds = filter.sopContentIds || [];
        const termIds = filter.termIds || [];
        if (requirementIdsPerRegulation.length > 0
            || templateContentIds.findIndex(e => e.startsWith('sr-template-content-')) >= 0
            || sopContentIds.findIndex(e => e.startsWith('sr-process-content-')) >= 0
            || termIds.findIndex(e => e.startsWith('sr-term-')) >= 0) {
            const searchKey = [...templateContentIds, ...sopContentIds, ...termIds, ...Object.values(requirementIdsPerRegulation).flatMap(entry => entry)];
            return Promise.resolve(
                {
                    ...RequirementSearchServiceMock.requirementSearchResult,
                    requirements: [
                        ...RequirementSearchServiceMock.requirementSearchResult.requirements,
                        {
                            'regulationId': 'iso-1',
                            'searchResult': true,
                            'id': 'CHAPTER-I_1_________________________',
                            'key': 'CHAPTER-I_1',
                            'type': RequirementContentType.TEXT,
                            'interpretable': true,
                            'text': 'Search Result START:' + searchKey.join(',') + ':END',
                            'paragraph': '1.',
                            'level': 3,
                            'requirementIds': [],
                            'parentId': 'CHAPTER-I___________________________'
                        }
                    ]
                });
        }
        return Promise.resolve({
            requirements: [],
            regulations: []
        });
    }

}
