






























import Component, { mixins } from 'vue-class-component';
import EditorWrapper from '@/components/common/EditorWrapper.vue';
import TemplateContentSelection from '@/components/common/TemplateContentSelection.vue';
import SopBlockDetailLayout from '@/features/sop-block/sop-block-detail/SopBlockDetailLayout.vue';
import SopBlockDetailMixin from '@/features/sop-block/sop-block-detail/SopBlockDetailMixin.vue';
import { mapState } from 'vuex';
import { RootState } from '@/store';

@Component({
  components: { SopBlockDetailLayout, EditorWrapper, TemplateContentSelection },
  computed: {
    ...mapState<RootState>({
      roles: (state: RootState) => [{ id: '', name: '# No Role' }, ...Object.values(state.roles.roles)]
    }),
  }
})
export default class SopBlockDetailStep extends mixins<SopBlockDetailMixin>(SopBlockDetailMixin) {

}
