










import Vue from 'vue';
import Component from 'vue-class-component';
import { templateContentService } from '@/features/template-content/template-content-service';
import { TemplateContentOverallStatistics } from '@/features/template-content/template-content-store';
import SimpleTile from '@/features/dashboard/SimpleTile.vue';
import { DoughnutChartData } from '@/features/dashboard/DoughnutChart.vue';

@Component({ components: { SimpleTile } })
export default class TemplateContentTile extends Vue {

  keyNumbers: { label: string; value: number }[] = [];
  chartData: DoughnutChartData = { title: '', entries: [] };

  created() {
    templateContentService.statistics().then(stats => this.renderData(stats));
  }

  private renderData(stats: TemplateContentOverallStatistics) {
    const linkedBoth = stats.countByLinkState['LINKED_TO_BOTH'] ?? 0;
    const missingBoth = stats.countByLinkState['MISSING_BOTH'] ?? 0;
    const missingProcess = stats.countByLinkState['MISSING_PROCESS_OUTPUT'] ?? 0;
    const missingTemplate = stats.countByLinkState['MISSING_CREATING_TEMPLATE'] ?? 0;
    this.keyNumbers = [
      { label: 'Total', value: stats.total },
    ];
    this.chartData = {
      title: 'Links to Processes and Templates',
      entries: [
        { label: `Linked to both (${ linkedBoth })`, value: linkedBoth, color: '#69c88c' },
        { label: `missing process output (${ missingProcess })`, value: missingProcess, color: '#fbd99d' },
        { label: `missing creating template (${ missingTemplate })`, value: missingTemplate, color: '#fce7c2' },
        { label: `missing both (${ missingBoth })`, value: missingBoth, color: '#f49f0a' },
      ],
    };
  }
}

