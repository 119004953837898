export type ReleaseState = 'DRAFT' | 'IN_RELEASE' | 'RELEASED' | 'OBSOLETE';
export const releaseState = (state: ReleaseState) => {
    return {
        state,
        canCreateNewVersion() {
            return state === 'RELEASED' || state == 'OBSOLETE';
        },
        isInWork() {
            return state === 'DRAFT' || state == 'IN_RELEASE';
        },
        canBeDeleted() {
            return state === 'DRAFT';
        }
    };
}