import { toFirstUpperCase } from '@/utils/StringUtils';

export type SopBlockType = 'STEP' | 'TIPTAP' | 'HEADER' | 'DRAWIO';

export interface SopBlockWrite {
    type: SopBlockType;
    name: string;
    description: string;
    testable: boolean;
    acceptanceCriterion: string;
    statisticalRequirements: string;
    sampleSize: string;
    references: string;
    productIds: string[];
    templateContentIds: string[];
    roleId: string;
}

export type SopBlockVersionState = 'CREATED' | 'UPDATED' | 'UNCHANGED' | 'DELETED';

export interface SopBlock extends SopBlockWrite {
    artifactId: string;
    versionId: string;
    version: number;
    versionState: SopBlockVersionState;
    sopArtifactId: string;
}

export type SopBlockSearchPart = Pick<SopBlock, 'artifactId' | 'versionId' | 'version' | 'name'>

export interface Product {
    productId: string;
    name: string;
}

export function createEmptyProduct(): Product {
    return { productId: '', name: '' };
}

export interface ProductRequirement {
    sopName: string;
    sopArtifactId: string;
    sopBlockArtifactId: string;
    sopBlockVersionId: string;
    sopBlockName: string;
    sopBlockDescription: string;
    sopBlockProductIds: string[];
    sopBlockTestable: boolean;
    sopBlockAcceptanceCriterion: string;
    sopBlockStatisticalRequirements: string;
    sopBlockSampleSize: string;
    sopBlockReferences: string;
}


export class SopBlockTypeUi {

    public static getDetailComponentName(type: SopBlockType) {
        return 'SopBlockDetail' + toFirstUpperCase(type.toLowerCase());
    }

    public static getEntryComponentName(type: SopBlockType) {
        return 'SopBlockEntry' + toFirstUpperCase(type.toLowerCase());
    }

}

const EMPTY_SOP_BLOCK: SopBlock = {
    artifactId: '',
    versionId: '',
    version: 1,
    versionState: 'CREATED',
    sopArtifactId: '',
    type: 'STEP',
    name: '',
    description: '',
    testable: false,
    acceptanceCriterion: '',
    statisticalRequirements: '',
    sampleSize: '',
    references: '',
    productIds: [],
    templateContentIds: [],
    roleId: '',
}

export function createEmptySopBlock(): SopBlock {
    return { ...EMPTY_SOP_BLOCK };
}

export function createEmptyNewSopBlock(type: SopBlockType): SopBlock {
    function newBlockAttributes(type: SopBlockType) {
        switch (type) {
            case 'STEP':
                return { name: 'New Step' };
            case 'TIPTAP':
                return { name: 'New Section', description: 'Edit block to change title and text.' };
            case 'HEADER':
                return { name: 'New Title' };
        }
    }

    return { ...EMPTY_SOP_BLOCK, type, ...newBlockAttributes(type) };
}