import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { CreateSop, EMPTY_SOP, FILTER_DUPLICATED_ENTRIES, Sop, SopParentArtifactMap, SopSearchPart, SopSearchPartMap } from '@/model/Sop';
import { LoadSopsResult, SopOrderChange, sopService } from '@/services/sop-service';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet } from '@/utils/util';

export type SopMap = { [key: string]: Sop };

export class SopState {
    sops: SopSearchPartMap = {};
    sopOrder: string[] = [];
    sopParentArtifactIds: SopParentArtifactMap = {};
    allLinkedSrProcessIds = [];
}

export enum SopActions {
    SOPS_LOAD = 'SOPS_LOAD',
    SOP_CREATE = 'SOP_CREATE',
    SOP_DELETE = 'SOP_DELETE',
    SOP_ORDER_CHANGE = 'SOP_ORDER_CHANGE',
}

export enum SopGetters {
    ALL_SOPS = 'ALL_SOPS',
    SELECTABLE_SOPS = 'SELECTABLE_SOPS',
    QMS_SELECTABLE_SOPS = 'QMS_SELECTABLE_SOPS',
    SELECTABLE_SOPS_BY_ARTIFACT_IDS = 'SELECTABLE_SOPS_BY_ARTIFACT_IDS',
    SOP_BY_ARTIFACT_ID = 'SOP_BY_ARTIFACT_ID',
    POTENTIAL_PARENT_SOPS = 'POTENTIAL_PARENT_SOPS',
    ORDERED_SOP_ARTIFACT_IDS_BY_PARENT_ARTIFACT_ID = 'ORDERED_SOP_ARTIFACT_IDS_BY_PARENT_ARTIFACT_ID',
    SOP_LEVEL_BY_ARTIFACT_ID = 'SOP_LEVEL_BY_ARTIFACT_ID',
}

export enum Mutations {
    SOPS_REPLACE = 'SOPS_REPLACE'
}

function notYetLoadedSop(sop: Partial<SopSearchPart>): SopSearchPart {
    return {
        ...EMPTY_SOP,
        name: 'Loading...',
        ...sop,
    }
}

const ROOT_SOP = { artifactId: null, versionId: null, name: '# Root Process' };

function getDescendantSopArtifactIdsExcludingBranch(sopArtifactId: string | undefined, excludeSopArtifactId: string, state: SopState): string[] {
    return state.sopOrder
        .filter(artifactId => artifactId !== excludeSopArtifactId)
        .filter(artifactId => state.sopParentArtifactIds[artifactId] === sopArtifactId)
        .flatMap(artifactId => [artifactId, ...getDescendantSopArtifactIdsExcludingBranch(artifactId, excludeSopArtifactId, state)]);
}

function reduceToSelectableSopArtifacts(sops: SopSearchPart[]): SopSearchPart[] {
    return sops
        .filter(sop => sop.releaseState !== 'OBSOLETE')
        .reduce((collectedArtifacts, currentSop) => {
            const existingArtifactIndex = collectedArtifacts.findIndex(value => value.artifactId === currentSop.artifactId);
            if (existingArtifactIndex < 0) {
                collectedArtifacts.push(currentSop);
            } else {
                if (collectedArtifacts[existingArtifactIndex].releaseState !== 'RELEASED') {
                    // replace NOT RELEASED version with RELEASED version
                    collectedArtifacts.splice(existingArtifactIndex, 1, currentSop);
                }
            }
            return collectedArtifacts;
        }, [] as SopSearchPart[])
}

const getters = {
    [SopGetters.ALL_SOPS]: (state: SopState) => state.sopOrder.flatMap(artifactId => Object.values(state.sops).filter(sop => sop.artifactId === artifactId)),
    [SopGetters.SELECTABLE_SOPS]: (state: SopState): SopSearchPart[] => {
        const selectableArtifacts = reduceToSelectableSopArtifacts(Object.values(state.sops))
        return state.sopOrder
            .flatMap(artifactId => selectableArtifacts.filter(sop => sop.artifactId === artifactId))
            .filter(sop => !!sop)
    },
    [SopGetters.QMS_SELECTABLE_SOPS]: (state: SopState, getters: any): SopSearchPart[] => {
        return getters[SopGetters.SELECTABLE_SOPS]
            .filter((sop: Sop) => sop.requirementType === 'QMS');
    },
    [SopGetters.SELECTABLE_SOPS_BY_ARTIFACT_IDS]: (state: SopState) => (artifactIds: string): SopSearchPart[] => {
        if (artifactIds.length == 0) {
            return [];
        }
        const selectableArtifacts = reduceToSelectableSopArtifacts(Object.values(state.sops))
        return state.sopOrder
            .flatMap(artifactId => selectableArtifacts.filter(sop => sop.artifactId === artifactId))
            .filter(sop => !!sop)
            .filter(sop => artifactIds.indexOf(sop.artifactId) >= 0)
    },
    [SopGetters.SOP_BY_ARTIFACT_ID]: (state: SopState) => (artifactId: string) => {
        const findReleasedSop = () => Object.values(state.sops).find(sop => sop.artifactId === artifactId && sop.releaseState === 'RELEASED');
        const findFirstSop = () => Object.values(state.sops).find(sop => sop.artifactId === artifactId);
        return findReleasedSop() || findFirstSop() || notYetLoadedSop({ artifactId });
    },
    [SopGetters.POTENTIAL_PARENT_SOPS]: (state: SopState) => (sopArtifactId: string) => {
        const potentialParentArtifactIds = getDescendantSopArtifactIdsExcludingBranch(undefined, sopArtifactId, state);
        const potentialParentSops = potentialParentArtifactIds.map(artifactId => Object.values(state.sops).find(sop => sop.artifactId === artifactId));
        return [ROOT_SOP, ...potentialParentSops];
    },
    [SopGetters.ORDERED_SOP_ARTIFACT_IDS_BY_PARENT_ARTIFACT_ID]: (state: SopState, getters: any) => (parentArtifactId?: string) => {
        if (parentArtifactId === '') {
            parentArtifactId = undefined;
        }
        const filter = (sop: Sop) => state.sopParentArtifactIds[sop.artifactId] === parentArtifactId;
        return getters[SopGetters.ALL_SOPS]
            .filter(filter)
            .map((sop: Sop) => sop.artifactId)
            .filter(FILTER_DUPLICATED_ENTRIES);
    },
    [SopGetters.SOP_LEVEL_BY_ARTIFACT_ID]: (state: SopState) => (artifactId: string) => {
        let sopArtifactId: string | undefined = artifactId;
        let level = 1;
        while ((sopArtifactId = state.sopParentArtifactIds[sopArtifactId])) {
            level++;
        }
        return level;
    },
}

const actions = {
    [SopActions.SOPS_LOAD]: ({ commit, dispatch }: ActionContext<SopState, RootState>) => {
        return sopService.loadSops()
            .then(sopsAndSopArtifacts => commit(Mutations.SOPS_REPLACE, sopsAndSopArtifacts))
            .catch(err => UiFeedback.showError(dispatch, `SOPs couldn't be fetched. Please try again.`, err));
    },
    [SopActions.SOP_CREATE]: ({ dispatch }: ActionContext<SopState, RootState>, createSop: CreateSop) => {
        return sopService.createSop(createSop)
            .then(() => dispatch(SopActions.SOPS_LOAD))
            .catch(err => {
                UiFeedback.showError(dispatch, `SOP couldn't be created. Please try again.`, err);
                throw err;
            });
    },
    [SopActions.SOP_DELETE]: ({ dispatch }: ActionContext<SopState, RootState>, sopVersionId: string) => {
        return sopService.deleteSop(sopVersionId)
            .then(() => dispatch(SopActions.SOPS_LOAD))
            .catch(err => {
                UiFeedback.showError(dispatch, `SOP couldn't be deleted. Please try again.`, err);
                return dispatch(SopActions.SOPS_LOAD);
            });
    },
    [SopActions.SOP_ORDER_CHANGE]: ({ dispatch }: ActionContext<SopState, RootState>, sopOrderChange: SopOrderChange) => {
        return sopService.sopOrderChange(sopOrderChange)
            .then(() => dispatch(SopActions.SOPS_LOAD))
            .catch(err => {
                UiFeedback.showError(dispatch, `Change of SOP order could not be completed. Please try again.`, err);
                return dispatch(SopActions.SOPS_LOAD);
            });
    },
}

const mutations = {
    [Mutations.SOPS_REPLACE]: (state: SopState, sops: LoadSopsResult) => {
        safeVueSet(state, 'sops', sops.sopMap);
        safeVueSet(state, 'sopOrder', sops.orderedSopArtifactIds);
        safeVueSet(state, 'sopParentArtifactIds', sops.parentArtifactIds);
        safeVueSet(state, 'allLinkedSrProcessIds', sops.allLinkedSrProcessIds);
    },
}

export const SOP_MODULE: Module<SopState, RootState> = {
    state: new SopState(),
    getters,
    actions,
    mutations
};
