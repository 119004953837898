












import Vue from 'vue';
import Component from 'vue-class-component';
import { calculateRequirementLevel, Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import { Prop } from 'vue-property-decorator';

@Component
export default class RequirementItem extends Vue {

  private static DEFAULT_PARAGRAPH_MARGIN = 2;

  @Prop() requirement: Requirement;
  @Prop() requirementsById: RequirementMap;
  @Prop({ required: false }) filterText: string;

  get defaultParagraphMargin(): string {
    return RequirementItem.DEFAULT_PARAGRAPH_MARGIN + 'rem';
  }

  get calculatedParagraphMargin(): string {
    const marginLevel = calculateRequirementLevel(this.requirement, this.requirementsById);
    return (marginLevel * RequirementItem.DEFAULT_PARAGRAPH_MARGIN).toFixed(1) + 'rem';
  }

  get requirementText(): string {
    let requirementText = this.requirement.text;
    const filterText = this.filterText;
    if (filterText && filterText.trim().length >= 3) {
      const reg = new RegExp('(' + filterText + ')', 'gi');
      requirementText = requirementText.replaceAll(reg, '<span style="background: #fbd99d">$1</span>');
    }
    return requirementText;
  }

}
