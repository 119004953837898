var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-5"},[_c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"co-title mb-3 d-inline-block"},[_vm._v("Gap List")]),_c('v-spacer'),(_vm.isBlockSelected)?_c('v-btn',{staticClass:"float-right mt-2 mr-2",attrs:{"color":"primary","outlined":"","x-small":"","data-test-back-btn":""},on:{"click":_vm.removeBlockSelection}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close-circle-outline")]),_vm._v(" Remove Block Selection ")],1):_vm._e(),(_vm.setupType === 'SOP' && Object.values(_vm.regulations).length > 0)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":"","close-on-content-click":false,"z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-test-gap-list-widget-menu-btn":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1052939733)},[_c('div',{staticClass:"d-flex flex-row-reverse pa-3"},[_c('v-btn-toggle',{staticStyle:{"z-index":"9999"},attrs:{"value":_vm.highlightMode,"dense":""},on:{"change":_vm.toggleHighlightMode}},[_c('v-tooltip',{attrs:{"top":"","z-index":"1000","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":_vm.highlightNotLinked,"small":"","data-test-highlight-not-linked-btn":""}},on),[_vm._v(" highlight sop blocks without reference ")])]}}],null,false,3173533245)},[_c('span',[_vm._v("Highlights all process blocks without reference to a regulation")])])],1)],1)]):_vm._e()],1),_c('div',[(_vm.isLoading === 'LOADING' && Object.values(_vm.regulations).length === 0)?_c('v-progress-circular',{staticClass:"d-block mx-auto",attrs:{"indeterminate":""}}):(Object.values(_vm.regulations).length === 0)?_c('div',{attrs:{"data-test-no-requirement-found-info":""}},[_vm._v(" No requirements from Standards and Regulations are assigned to this process. Assign requirements in "),_c('router-link',{attrs:{"to":"/sr-list"}},[_vm._v("applied Standards & Regulations.")])],1):_c('div',{staticClass:"info-text"},[_vm._v("The following standards and regulations contain corresponding requirements for this process.")])],1),_c('div',{staticClass:"regulations-panel mt-4"},[_c('v-expansion-panels',{attrs:{"tile":"","value":_vm.indexOfSelectedRegulation}},_vm._l((_vm.regulations),function(regulation){return _c('v-expansion-panel',{key:regulation.id,attrs:{"data-test-regulation-part":regulation.id},on:{"change":function($event){return _vm.selectRegulation(regulation.id)}}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"mx-1",attrs:{"x-small":""}},[_vm._v(_vm._s(regulation.state))]),(_vm.setupType === 'SOP' && Object.values(_vm.regulations).length > 0)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-x":"","close-on-content-click":false,"z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-test-regulation-menu-btn":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('div',{staticStyle:{"z-index":"9999","background":"#FFFFFF"}},[_c('div',{staticClass:"pa-4 pb-0 co-subheader"},[_vm._v("Highlights all process blocks referenced...")]),(_vm.setupType === 'SOP')?_c('v-radio-group',{staticClass:"mx-3",attrs:{"dense":"","value":_vm.highlightMode},on:{"change":_vm.toggleHighlightMode}},[_c('v-radio',{attrs:{"value":_vm.highlight,"label":"...by requirements of this regulation","data-test-highlight-btn":""}}),_c('v-radio',{attrs:{"value":_vm.exclusiveHighlight,"label":"...only by requirements of this regulation","data-test-exclusive-highlight-btn":""}}),_c('v-radio',{attrs:{"value":_vm.none,"label":"None","data-test-none-btn":""}})],1):_vm._e()],1)]):_vm._e()],1)]},proxy:true}],null,true)},[(_vm.selectedImpactAnalysis)?[(_vm.selectedImpactAnalysis.regulationIdFrom === regulation.id)?_c('div',{staticClass:"co-is-impact-selected-from-regulation"}):_vm._e(),(_vm.selectedImpactAnalysis.regulationIdTo === regulation.id)?_c('div',{staticClass:"co-is-impact-selected-to-regulation"}):_vm._e()]:_vm._e(),_c('span',[_vm._v(_vm._s(regulation.title))])],2),_c('v-expansion-panel-content',[(_vm.viewAllowedByRegulationId(regulation.id))?_c('div',{staticClass:"requirements"},[_c('gap-list-widget-requirements')],1):_c('div',[_c('v-chip',{staticStyle:{"height":"55px","white-space":"normal"},attrs:{"small":"","color":"yellow"}},[_vm._v(" You do not have the required licensing to view the content of this document or to use all the functionality. Please contact your administrator for the appropriate licensing if needed. ")])],1)])],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }