









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import QmsRequirementEdit from '@/components/common/QmsRequirementEdit.vue';
import { ImpactsActions } from '@/features/impacts/store';
import { SopSearchPart } from '@/model';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { QmsRequirement } from '@/services/model';

@Component<ImpactQmsRequirementEdit>({
  components: { QmsRequirementEdit },
})
export default class ImpactQmsRequirementEdit extends Vue {

  @Prop()
  qmsRequirement: QmsRequirement;

  @Prop({ default: () => [] })
  proposedSops: SopSearchPart[];
  @Prop({ default: () => [] })
  proposedTemplateContents: TemplateContent[];

  private dispatchQmsRequirementChange(changingFields: Partial<QmsRequirement>) {
    // TODO: Think about: Makes such callbacks sense? Or should the component directly communicate with the backend and/or the (SINGLE) store...
    // for example. the test cases for Impact/Qms[Edit|Applicability] are (nearly) identical, since they use the same ui component and the same backend...
    this.$store.dispatch(ImpactsActions.TO_QMS_REQUIREMENT_CHANGE,
        {
          ...this.qmsRequirement,
          ...changingFields
        } as QmsRequirement);
  }

  proposedSopsCallback() {
    return this.proposedSops;
  }

  proposedTemplateContentCallback() {
    return this.proposedTemplateContents;
  }
}
