





























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { AuthActions } from '@/store/auth';
import { UiFeedback } from '@/store/ui-feedback';
import { mapState } from 'vuex';
import { RootState } from '@/store';

type navigationItem = {
  title: string;
  routerLink: string;
  icon: string;
};

@Component({
  computed: {
    ...mapState<RootState>({
      user: (state: RootState) => state.auth.user,
    })
  }
})
export default class CoVerticalNavBar extends Vue {

  height: number = window.innerHeight - 100;

  navigationItems: navigationItem[] = [
    { title: 'Process Overview', routerLink: '/processes', icon: '$coProcessHouse' },
    { title: 'Templates', routerLink: '/templates', icon: '$coTemplate' },
    { title: 'Template Contents', routerLink: '/template-contents', icon: '$coTemplateContent' },
    { title: 'Glossary', routerLink: '/glossary', icon: '$coGlossary' },
    { title: 'List of applied Standards & Regulations', routerLink: '/sr-list', icon: '$coAppliedSr' },
    { title: 'Impact Analysis', routerLink: '/impact-analysis', icon: '$coImpactAnalysis' },
    { title: 'Roles', routerLink: '/roles', icon: 'mdi-account-multiple-outline' },
  ];

  created() {
    window.addEventListener('resize', () => this.height = window.innerHeight - 100);
  }

  logout() {
    return this.$store.dispatch(AuthActions.LOGOUT)
        .then(() => {
          if (this.$route.name !== 'Dashboard') {
            this.$router.push({ name: 'Dashboard' });
          }
        })
        .catch(error => UiFeedback.showError(this.$store.dispatch, 'Can not logout', error));
  }

}
