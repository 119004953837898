import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/sass/global.scss';
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/auth';

Vue.config.productionTip = false

firebase.initializeApp({
    apiKey: process.env.VUE_APP_GOOGLE_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_GOOGLE_FIREBASE_AUTH_DOMAIN,
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
