import { ActionContext, Module } from 'vuex';

import { RootState } from '@/store';
import { Requirement, RequirementContentType, RequirementMap } from '@/features/sr-model/Requirement';
import { Relation } from '@/features/sr-model/Relation';
import { RelationRelease } from '@/features/sr-model/RelationRelease';
import { qmsRequirementService } from '@/services/qms-requirement-service';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import { Sop } from '@/model';
import { SopGetters } from '@/features/sops';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { relationService } from '@/services/sr-relation-service';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet } from '@/utils/util';
import { createEmptyQmsRegulationStatistics, SrRegulation } from '@/features/regulations/model';
import { srRegulationService } from '@/services/sr-regulation-service';
import { RegulationActions } from '@/features/regulations/regulation-store';
import { createEmptyQmsRequirement, QmsRequirement } from '@/services/model';
import { createEmptyProduct, Product, ProductRequirement } from '@/features/sop-block/model';
import { productRequirementService } from '@/features/sop-block/product-requirement-service';
import { hasUserSrViewRights } from '@/utils/UserUtils';
import { QmsGapValidation } from '@/store/regulation-detail/QmsGapValidation';
import { productsService } from '@/features/sop-block/products-service';

export type RelationMap = { [key: string]: Relation };
export type QmsRequirementMap = { [key: string]: QmsRequirement };

export type ApplicabilityAssessmentStatus = 'ALL' | 'APPLICABLE' | 'APPLICABLE_WITH_WARNING' | 'NOT_APPLICABLE' | 'NOT_APPLICABLE_WITH_WARNING' | 'NOT_DEFINED' | 'INFORMATION';
export type ActualEvidenceStatus = 'ALL' | 'CONFIRMED' | 'GAPS' | 'DEPRECATED';

export interface Filter {
    linkedProcessIds: string[];
    requirementText: string;
    applicabilityAssessmentStatus: ApplicabilityAssessmentStatus;
    actualEvidenceStatus: ActualEvidenceStatus;
}

export function emptyFilter(): Filter {
    return {
        linkedProcessIds: [],
        requirementText: '',
        applicabilityAssessmentStatus: 'ALL',
        actualEvidenceStatus: 'ALL',
    };
}

export class RegulationDetailState {
    relationRelease: RelationRelease;
    relations: RelationMap = {};

    regulation: SrRegulation;
    requirements: RequirementMap = {};

    viewAllowed = true;

    editingRequirementId = '';
    anchoredRequirementKey = '';

    qmsRequirements: QmsRequirementMap = {};
    editQmsRequirements: boolean;

    filter: Filter = emptyFilter();

    products: Product[] = [];
    productRequirements: ProductRequirement[] = [];
}


export enum RegulationDetailGetters {
    PRODUCTS = 'PRODUCTS',
    PRODUCT_BY_ID_OR_EMPTY = 'PRODUCT_BY_ID_OR_EMPTY',
    PRODUCT_REQUIREMENTS = 'PRODUCT_REQUIREMENTS',
    FILTERED_REQUIREMENTS = 'FILTERED_REQUIREMENTS',
    ALL_HEADINGS = 'ALL_HEADINGS',
    ANCHORED_REQUIREMENT_INDEX = 'ANCHORED_REQUIREMENT_INDEX',
    QMS_REQUIREMENT_BY_REQUIREMENT_ID = 'QMS_REQUIREMENT_BY_REQUIREMENT_ID',
    PROPOSED_SOPS_BY_REQUIREMENT_ID = 'PROPOSED_SOPS_BY_REQUIREMENT_ID',
    PROPOSED_TEMPLATE_CONTENTS_BY_REQUIREMENT_ID = 'PROPOSED_TEMPLATE_CONTENTS_BY_REQUIREMENT_ID',
    REGULATION_DETAIL_STATISTICS = 'REGULATION_DETAIL_STATISTICS',
    VIEW_ALLOWED = 'VIEW_ALLOWED',
}

export enum RegulationDetailActions {
    REGULATION_DETAIL_LOAD = 'REGULATION_DETAIL_LOAD',
    PRODUCTS_LOAD = 'PRODUCTS_LOAD',
    PRODUCT_REQUIREMENT_LOAD = 'PRODUCT_REQUIREMENT_LOAD',
    REGULATION_DETAIL_RESET = 'REGULATION_DETAIL_RESET',
    REQUIREMENTS_FILTER = 'REQUIREMENTS_FILTER',
    ANCHORED_REQUIREMENT_KEY_UPDATE = 'ANCHORED_REQUIREMENT_KEY_UPDATE',
    QMS_REQUIREMENT_CREATE = 'QMS_REQUIREMENT_CREATE',
    QMS_REQUIREMENT_CHANGE = 'QMS_REQUIREMENT_CHANGE',
    QMS_REQUIREMENT_DELETE = 'QMS_REQUIREMENT_DELETE',
    QMS_REQUIREMENTS_LOAD = 'QMS_REQUIREMENTS_LOAD',
    TOGGLE_QMS_REQUIREMENTS_EDITABLE = 'TOGGLE_QMS_REQUIREMENTS_EDITABLE',
    INTERPRETATION_AS_CSV = 'INTERPRETATION_AS_CSV',
}

export enum RegulationDetailEvents {
    RELATION_RELEASE_LOADED = 'RELATION_RELEASE_LOADED',
}

export enum Mutations {
    REGULATION_REPLACE = 'REGULATION_REPLACE',
    PRODUCTS_REPLACE = 'PRODUCTS_REPLACE',
    PRODUCT_REQUIREMENT_REPLACE = 'PRODUCT_REQUIREMENT_REPLACE',
    REQUIREMENTS_REPLACE = 'REQUIREMENTS_REPLACE',
    RELATION_RELEASE_REPLACE = 'RELATION_RELEASE_REPLACE',
    RELATIONS_REPLACE = 'RELATIONS_REPLACE',
    RELATION_REPLACE = 'RELATION_REPLACE',
    FILTER_REPLACE = 'FILTER_REPLACE',
    ANCHORED_REQUIREMENT_KEY_REPLACE = 'ANCHORED_REQUIREMENT_KEY_REPLACE',
    QMS_REQUIREMENTS_REPLACE = 'QMS_REQUIREMENTS_REPLACE',
    QMS_REQUIREMENT_REPLACE = 'QMS_REQUIREMENT_REPLACE',
    QMS_REQUIREMENTS_EDITABLE_REPLACE = 'QMS_REQUIREMENTS_EDITABLE_REPLACE',
    EDITING_REQUIREMENT_ID_REPLACE = 'EDITING_REQUIREMENT_ID_REPLACE',
}

class Helper {

    static getParentIdAndAppend(state: RegulationDetailState, requirementId: string, allIds: string[]): string[] {
        if (allIds.indexOf(requirementId) >= 0) {
            return allIds;
        }
        const requirement: Requirement = state.requirements[requirementId];
        allIds.push(requirement.id);
        if (requirement.parentId) {
            Helper.getParentIdAndAppend(state, requirement.parentId, allIds);
        }
        return allIds;
    }

    static getAllHierarchyIds(requirements: Requirement[], state: RegulationDetailState) {
        return requirements
            .reduce((allIds, r) => Helper.getParentIdAndAppend(state, r.id, allIds), new Array<string>())
    }

    static hasTextMatch(state: RegulationDetailState, requirement: Requirement) {
        if (state.filter.requirementText && state.filter.requirementText.trim().length >= 3) {
            if (!requirement.text || requirement.text.toLowerCase().indexOf(state.filter.requirementText.toLowerCase()) < 0) {
                return false;
            }
        }
        return true;
    }

    static filteredByQmsStatus(filter: Filter, qmsRequirement: QmsRequirement) {
        const filteredByActualEvidenceStatus = Helper.filteredByQmsStatusActualEvidenceStatus(filter, qmsRequirement)
        const applicabilityAssessmentStatus = Helper.filteredByQmsStatusApplicabilityAssessmentStatus(filter, qmsRequirement)
        return filteredByActualEvidenceStatus && applicabilityAssessmentStatus;
    }

    static filteredByQmsStatusApplicabilityAssessmentStatus(filter: Filter, qmsRequirement: QmsRequirement) {
        if (filter.applicabilityAssessmentStatus == 'ALL') {
            return true;
        }
        if (!qmsRequirement || !!qmsRequirement.interpretedInRequirementId) {
            return false;
        }
        if (filter.applicabilityAssessmentStatus == 'APPLICABLE'
            && qmsRequirement.applicability == 'APPLICABLE') {
            return true;
        }
        if (filter.applicabilityAssessmentStatus == 'NOT_DEFINED'
            && qmsRequirement.applicability == 'NOT_DEFINED') {
            return true;
        }
        if (filter.applicabilityAssessmentStatus == 'NOT_APPLICABLE'
            && qmsRequirement.applicability == 'NOT_APPLICABLE') {
            return true;
        }
        if (filter.applicabilityAssessmentStatus == 'INFORMATION'
            && qmsRequirement.applicability == 'INFORMATION') {
            return true;
        }
        if (filter.applicabilityAssessmentStatus == 'NOT_APPLICABLE_WITH_WARNING'
            && qmsRequirement.applicability == 'NOT_APPLICABLE'
            && QmsGapValidation.notApplicableHasWarning(qmsRequirement)) {
            return true;
        }
        if (filter.applicabilityAssessmentStatus == 'APPLICABLE_WITH_WARNING'
            && qmsRequirement.applicability == 'APPLICABLE'
            && QmsGapValidation.applicableHasWarning(qmsRequirement)) {
            return true;
        }
        return false;
    }


    static filteredByQmsStatusActualEvidenceStatus(filter: Filter, qmsRequirement: QmsRequirement) {
        if (filter.actualEvidenceStatus == 'ALL') {
            return true;
        }
        if (!qmsRequirement) {
            return false;
        }
        const evidences = [...Object.values(qmsRequirement.sopEvidenceStates), ...Object.values(qmsRequirement.templateEvidenceStates)];
        if (filter.actualEvidenceStatus == 'CONFIRMED'
            && evidences.includes('IMPLEMENTED')) {
            return true;
        }
        if (filter.actualEvidenceStatus == 'DEPRECATED'
            && evidences.includes('IMPLEMENTED_BUT_NOT_REQUIRED')) {
            return true;
        }
        if (filter.actualEvidenceStatus == 'GAPS'
            && evidences.includes('NOT_IMPLEMENTED')) {
            return true;
        }
        return false;
    }


    static viewAllowed(state: RootState): boolean {
        const licenseTypeView = state.regulationDetail.regulation?.licenseTypeView;
        return hasUserSrViewRights(licenseTypeView, state.auth.user);
    }
}

const getters = {
    [RegulationDetailGetters.VIEW_ALLOWED]: (_ignored1: any, _ignored2: any, rootState: RootState) => {
        return Helper.viewAllowed(rootState);
    },
    [RegulationDetailGetters.PRODUCT_BY_ID_OR_EMPTY]: (state: RegulationDetailState, getters: any) => (productId: string): Product => {
        return state.products.find(product => product.productId === productId) || createEmptyProduct();
    },
    [RegulationDetailGetters.PRODUCTS]: (state: RegulationDetailState) => {
        return state.products;
    },
    [RegulationDetailGetters.PRODUCT_REQUIREMENTS]: (state: RegulationDetailState) => {
        return state.productRequirements;
    },
    [RegulationDetailGetters.REGULATION_DETAIL_STATISTICS]: (state: RegulationDetailState, _ignored: any, rootState: RootState) => {
        if (!state.regulation) {
            return createEmptyQmsRegulationStatistics();
        }
        return rootState.regulations.qmsRegulationStatistics[state.regulation.id] ?? createEmptyQmsRegulationStatistics();
    },
    [RegulationDetailGetters.ALL_HEADINGS]: (state: RegulationDetailState) =>
        Object.values(state.requirements).filter(e => e.type === RequirementContentType.HEADING),
    [RegulationDetailGetters.FILTERED_REQUIREMENTS]: (state: RegulationDetailState, getters: any) => {
        const viewAllowed = getters[RegulationDetailGetters.VIEW_ALLOWED];

        const filteredRequirements = Object.values(state.requirements)
            .filter((requirement: Requirement) => viewAllowed || requirement.type === RequirementContentType.HEADING)
            .filter((requirement: Requirement) => Helper.filteredByQmsStatus(state.filter, state.qmsRequirements[requirement.id]))
            .filter((requirement: Requirement) => Helper.hasTextMatch(state, requirement))
        const filteredHierarchyIds = Helper.getAllHierarchyIds(filteredRequirements, state);
        return Object.values(state.requirements).filter(r => filteredHierarchyIds.indexOf(r.id) >= 0);
    },
    [RegulationDetailGetters.ANCHORED_REQUIREMENT_INDEX]: (state: RegulationDetailState) =>
        Object.values(state.requirements).findIndex(req => req.key === state.anchoredRequirementKey),
    [RegulationDetailGetters.PROPOSED_SOPS_BY_REQUIREMENT_ID]: (state: RegulationDetailState, getters: any) => (requirementId: string): Sop[] => {
        const srProcessIds = state.relations[requirementId]?.sopContentLinkedProcessIds || []
        return getters[SopGetters.SELECTABLE_SOPS]
            .filter((sop: Sop) => sop.srProcessIds.some(srProcessId => srProcessIds.includes(srProcessId)));
    },
    [RegulationDetailGetters.PROPOSED_TEMPLATE_CONTENTS_BY_REQUIREMENT_ID]: (state: RegulationDetailState, getters: any) => (requirementId: string): TemplateContent[] => {
        const srTemplateContentIds = state.relations[requirementId]?.templateContentIds || []
        if (srTemplateContentIds.length === 0) {
            return [];
        }
        return getters[TemplateContentGetters.TEMPLATE_CONTENTS_BY_SR_TC_IDS](srTemplateContentIds);
    },
    [RegulationDetailGetters.QMS_REQUIREMENT_BY_REQUIREMENT_ID]: (state: RegulationDetailState) => (requirementId: string): QmsRequirement => {
        const qmsRequirement = state.qmsRequirements[requirementId];
        // the regulationId is required to auto-create an not yet existing qms-requiremetn in the backedn
        return qmsRequirement ? qmsRequirement : { ...createEmptyQmsRequirement(), regulationId: state.regulation ? state.regulation.id : 'not-yet-loaded-regulation-id', requirementId };
    },
}

const actions = {
    [RegulationDetailActions.INTERPRETATION_AS_CSV]: ({ state }: ActionContext<RegulationDetailState, RootState>) => {
        return productRequirementService.interpretationAsCsv(state.regulation.id);
    },
    [RegulationDetailActions.PRODUCTS_LOAD]: ({ commit }: ActionContext<RegulationDetailState, RootState>) => {
        return productsService.all().then(products => commit(Mutations.PRODUCTS_REPLACE, products))
    },
    [RegulationDetailActions.PRODUCT_REQUIREMENT_LOAD]: ({ commit }: ActionContext<RegulationDetailState, RootState>) => {
        return productRequirementService.all().then(requirements => commit(Mutations.PRODUCT_REQUIREMENT_REPLACE, requirements))
    },
    [RegulationDetailActions.REGULATION_DETAIL_LOAD]: ({ commit, dispatch }: ActionContext<RegulationDetailState, RootState>, regulationId: string) => {
        return relationService.loadReleases(regulationId)
            .then(releases => {
                const relationReleaseId = releases[0].id; // as long as we have only 1 release per regulation, this is ok
                return relationService.loadReleaseDetail(relationReleaseId)
                    .then(releaseDetail => {
                        commit(Mutations.RELATION_RELEASE_REPLACE, releaseDetail.release);
                        commit(Mutations.RELATIONS_REPLACE, releaseDetail.relations);
                        return dispatch(RegulationDetailEvents.RELATION_RELEASE_LOADED, releaseDetail.release);
                    })
                    .catch(err => UiFeedback.showError(dispatch, `Relation release ${ relationReleaseId } for regulation ${ regulationId } couldn't be fetched.`, err))
            })
            .catch(err => UiFeedback.showError(dispatch, `Relation releases for regulation ${ regulationId } couldn't be fetched.`, err));
    },
    [RegulationDetailActions.REGULATION_DETAIL_RESET]: ({ commit }: ActionContext<RegulationDetailState, RootState>) => {
        commit(Mutations.RELATION_RELEASE_REPLACE, undefined);
        commit(Mutations.RELATIONS_REPLACE, {});
        commit(Mutations.REGULATION_REPLACE, undefined);
        commit(Mutations.REQUIREMENTS_REPLACE, {});
    },
    [RegulationDetailActions.REQUIREMENTS_FILTER]: ({ commit }: ActionContext<RegulationDetailState, RootState>, filter: Filter) => {
        commit(Mutations.FILTER_REPLACE, filter);
    },
    [RegulationDetailActions.QMS_REQUIREMENTS_LOAD]: ({ commit, dispatch, state }: ActionContext<RegulationDetailState, RootState>, regulationId: string) =>
        qmsRequirementService.loadQmsRequirementsForRegulation(regulationId)
            .then(qmsRequirements => commit(Mutations.QMS_REQUIREMENTS_REPLACE, qmsRequirements))
            .catch(err => UiFeedback.showError(dispatch, `gap entries for regulation ${ state.regulation.id } couldn't be fetched. Please try again.`, err)),
    [RegulationDetailActions.QMS_REQUIREMENT_CREATE]: ({ state, dispatch }: ActionContext<RegulationDetailState, RootState>, srRequirementId: string) => {
        const emptyQmsRequirement = createEmptyQmsRequirement({
            regulationId: state.regulation.id,
            requirementId: srRequirementId,
        });
        return dispatch(RegulationDetailActions.QMS_REQUIREMENT_CHANGE, emptyQmsRequirement)
            .then(() => dispatch(RegulationDetailActions.QMS_REQUIREMENTS_LOAD, state.relationRelease.regulationId));
    },
    [RegulationDetailActions.QMS_REQUIREMENT_CHANGE]: ({ commit, dispatch }: ActionContext<RegulationDetailState, RootState>, qmsRequirement: QmsRequirement) =>
        qmsRequirementService.changeQmsRequirement(qmsRequirement)
            .then(qmsRequirement => commit(Mutations.QMS_REQUIREMENT_REPLACE, qmsRequirement))
            .then(() => dispatch(RegulationActions.QMS_REGULATION_STATISTICS_LOAD, qmsRequirement.regulationId))
            .catch(err => UiFeedback.showError(dispatch, `QmsRequirement entry for requirement id ${ qmsRequirement.requirementId } couldn't be updated.`, err)),
    [RegulationDetailActions.QMS_REQUIREMENT_DELETE]: ({ dispatch, state }: ActionContext<RegulationDetailState, RootState>, requirementId: string) => {
        return qmsRequirementService.deleteQmsRequirement(requirementId)
            .then(() => dispatch(RegulationActions.QMS_REGULATION_STATISTICS_LOAD, state.relationRelease.regulationId))
            .then(() => dispatch(RegulationDetailActions.QMS_REQUIREMENTS_LOAD, state.relationRelease.regulationId));
    },
    [RegulationDetailActions.ANCHORED_REQUIREMENT_KEY_UPDATE]: ({ commit }: ActionContext<RegulationDetailState, RootState>, requirementKey: string) => {
        commit(Mutations.ANCHORED_REQUIREMENT_KEY_REPLACE, requirementKey);
    },
    [RegulationDetailActions.TOGGLE_QMS_REQUIREMENTS_EDITABLE]: ({ state, commit }: ActionContext<RegulationDetailState, RootState>) => {
        commit(Mutations.QMS_REQUIREMENTS_EDITABLE_REPLACE, !state.editQmsRequirements);
    },
}

const eventHandlers = {
    [RegulationDetailEvents.RELATION_RELEASE_LOADED]: ({ commit, dispatch }: ActionContext<RegulationDetailState, RootState>, relationRelease: RelationRelease) => {
        return Promise.all([
            srRegulationService.loadRegulationDetail(relationRelease.regulationId)
                .then(regulationWithRequirements => {
                    commit(Mutations.REGULATION_REPLACE, regulationWithRequirements.regulation);
                    commit(Mutations.REQUIREMENTS_REPLACE, regulationWithRequirements.requirements);
                })
                .catch(err => UiFeedback.showError(dispatch, `Requirements for regulation id ${ relationRelease.regulationId } couldn't be fetched.`, err)),
            dispatch(RegulationDetailActions.QMS_REQUIREMENTS_LOAD, relationRelease.regulationId)
        ]);
    },
}


const mutations = {
    [Mutations.PRODUCT_REQUIREMENT_REPLACE]: (state: RegulationDetailState, productRequirements: ProductRequirement[]) => {
        safeVueSet(state, 'productRequirements', productRequirements);
    },
    [Mutations.PRODUCTS_REPLACE]: (state: RegulationDetailState, products: Product[]) => {
        safeVueSet(state, 'products', products);
    },
    [Mutations.REGULATION_REPLACE]: (state: RegulationDetailState, regulation: SrRegulation) => {
        safeVueSet(state, 'regulation', regulation);
        safeVueSet(state, 'editQmsRequirements', false);
        safeVueSet(state, 'editingRequirementId', '');
        safeVueSet(state, 'filter', emptyFilter());
    },
    [Mutations.REQUIREMENTS_REPLACE]: (state: RegulationDetailState, requirements: RequirementMap) => {
        safeVueSet(state, 'requirements', requirements);
    },
    [Mutations.RELATION_RELEASE_REPLACE]: (state: RegulationDetailState, relationRelease: RelationRelease) => {
        safeVueSet(state, 'relationRelease', relationRelease);
    },
    [Mutations.RELATIONS_REPLACE]: (state: RegulationDetailState, relations: RelationMap) => {
        safeVueSet(state, 'relations', relations);
    },
    [Mutations.RELATION_REPLACE]: (state: RegulationDetailState, relation: Relation) => {
        if (!relation.requirementId) {
            throw new Error('requirement has no relation');
        }
        safeVueSet(state.relations, relation.requirementId, relation);
    },
    [Mutations.FILTER_REPLACE]: (state: RegulationDetailState, filter: Filter) => {
        safeVueSet(state, 'filter', filter);
    },
    [Mutations.EDITING_REQUIREMENT_ID_REPLACE]: (state: RegulationDetailState, requirementId: string) => {
        if (state.editingRequirementId === requirementId) {
            requirementId = '';
        }
        safeVueSet(state, 'editingRequirementId', requirementId);
    },
    [Mutations.ANCHORED_REQUIREMENT_KEY_REPLACE]: (state: RegulationDetailState, requirementKey: string) => {
        safeVueSet(state, 'anchoredRequirementKey', requirementKey);
    },
    [Mutations.QMS_REQUIREMENTS_REPLACE]: (state: RegulationDetailState, qmsRequirements: QmsRequirementMap) =>
        safeVueSet(state, 'qmsRequirements', qmsRequirements),
    [Mutations.QMS_REQUIREMENT_REPLACE]: (state: RegulationDetailState, qmsRequirement: QmsRequirement) =>
        safeVueSet(state.qmsRequirements, qmsRequirement.requirementId, qmsRequirement),
    [Mutations.QMS_REQUIREMENTS_EDITABLE_REPLACE]: (state: RegulationDetailState, isEditable: boolean) => safeVueSet(state, 'editQmsRequirements', isEditable),
}

export const REGULATION_DETAIL_MODULE: Module<RegulationDetailState, RootState> = {
    state: new RegulationDetailState(),
    getters,
    actions: {
        ...actions,
        ...eventHandlers
    },
    mutations
};
