
import Vue from 'vue';
import Component from 'vue-class-component';
import { drawioService } from '@/features/drawio-integration/drawio-service';

@Component
export default class DrawioMixin extends Vue {

  svgString: string;

  svgStringWithInterFix() {
    if (this.svgString.indexOf('<defs/>') >= 0 && this.svgString.indexOf('Inter') >= 0) {
      // quick fix for https://github.com/jgraph/drawio/issues/1702
      return this.svgString.replace(
          '<defs/>',
          '<defs><style>@import url(https://fonts.googleapis.com/css?family=Inter);</style></defs>');
    }
    return this.svgString;
  }

  openDrawIo(saveSvgStringCallback: (s: string) => Promise<void>, exitDrawioCallback: () => void = () => ({})) {
    drawioService.openDiagram(
        () => this.svgString,
        s => saveSvgStringCallback(s),
        this.$refs['drawioimage'],
        exitDrawioCallback);
  }

}
