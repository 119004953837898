

































import Vue from 'vue';
import Component from 'vue-class-component';
import UserSelection from '@/components/common/UserSelection.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopChangeControlActions, SopChangeControlWriteHelper } from '@/store/sop-change-control';

@Component({
  components: { UserSelection },
  computed: {
    ...mapState<RootState>({
      changeControl: (state: RootState) => state.sopChangeControl.changeControl,
      changeControlWrite: (state: RootState) => state.sopChangeControl.changeControlWrite,
      canStartRelease: (state: RootState) => state.sopChangeControl.changeControlWrite.comment.length > 0
          && state.sopChangeControl.changeControlWrite.authorUserIds.length > 0
          && state.sopChangeControl.changeControlWrite.expectedDecider.length > 0
    })
  },
  methods: {
    ...mapMutations({
      ...SopChangeControlWriteHelper.setterForChangeControlWriteFields('comment', 'authorUserIds', 'approverUserIds', 'expectedDecider'),
    }),
    ...mapActions({
      startRelease: SopChangeControlActions.SOP_CREATE_RELEASE,
    })
  }
})
export default class ChangeControlDraft extends Vue {


}
