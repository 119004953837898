import { DrawIoIntegration } from '@/features/drawio-integration/DrawIoIntegration';

export type LoadSvgCallback = () => string;
export type SaveSvgCallback = (svg: string) => Promise<void>;
export type ExitDrawioCallback = () => void;

export interface DrawioService {

    openDiagram(
        loadSvgCallback: LoadSvgCallback,
        saveSvgCallback: SaveSvgCallback,
        instanceReference: any,
        exitDrawioCallback: ExitDrawioCallback): void;

}

class DrawioServiceIntegration implements DrawioService {

    openDiagram(
        loadSvgCallback: LoadSvgCallback,
        saveSvgCallback: SaveSvgCallback,
        instanceReference: any,
        exitDrawioCallback: ExitDrawioCallback = () => ({})): void {
        console.log('open draw.io');
        new DrawIoIntegration(loadSvgCallback, saveSvgCallback, instanceReference, exitDrawioCallback).openDrawIo();
    }

}

export const drawioService: DrawioService = new DrawioServiceIntegration();