

























































































































import Component, { mixins } from 'vue-class-component';
import CoHeader from '@/components/common/CoHeader.vue';
import { ImpactAnalysisActions } from '@/features/impact-analysis/store';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { createNewImpactAnalysis, ImpactAnalysis, ImpactAnalysisStatisticMap, isNew, NEW_IMPACT_ANALYSIS_ID } from '@/features/impact-analysis/model';
import CoHorizontalBar, { CoHorizontalBarConfig } from '@/components/common/CoHorizontalBar.vue';
import { RegulationActions, RegulationGetters } from '@/features/regulations/regulation-store';
import CoLayout from '@/layouts/CoLayout.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';
import { RegulationDetailActions } from '@/store/regulation-detail';

@Component({
  components: { CoLayout, CoHorizontalBar, CoHeader },
  computed: {
    ...mapState<RootState>({
      impactAnalyses: (state: RootState) => state.impactAnalysis.impactAnalyses,
      regulations: (state: RootState) => Object.values(state.regulations.srRegulations),
      statistics: (state: RootState) => state.impactAnalysis.statistics,
    }),
    ...mapGetters({
      regulationById: RegulationGetters.SR_REGULATION_BY_ID_OR_LOADING
    })
  }
})
export default class ImpactAnalysisOverviewView extends mixins<AuthMixin>(AuthMixin) {

  headers = [
    { text: 'Title', value: 'title' },
    { text: 'From', value: 'regulationIdFrom' },
    { text: 'To', value: 'regulationIdTo' },
    { text: 'Mapped From Requirements', value: 'statFrom' },
    { text: 'Mapped To Requirements', value: 'statTo' },
    { text: '', value: 'action' },
  ];

  valid = false;
  editDialog = false;
  deleteDialog = false;
  impactAnalysis: ImpactAnalysis = createNewImpactAnalysis();
  statistics: ImpactAnalysisStatisticMap;

  get title(): string {
    return (this.isNewImpactAnalysis ? 'New' : 'Edit') + ' Impact Analysis';
  }

  get isNewImpactAnalysis(): boolean {
    return this.impactAnalysis.id === NEW_IMPACT_ANALYSIS_ID;
  }

  created() {
    this.$store.dispatch(RegulationActions.REGULATIONS_WITH_STATISTICS_LOAD);
    this.$store.dispatch(ImpactAnalysisActions.IMPACT_ANALYSES_LOAD);
    this.$store.dispatch(RegulationDetailActions.PRODUCTS_LOAD);
  }


  edit(ia: ImpactAnalysis) {
    this.impactAnalysis = { ...ia };
    this.editDialog = true;
  }

  confirmDelete() {
    this.$store.dispatch(ImpactAnalysisActions.IMPACT_ANALYSIS_DELETE, this.impactAnalysis.id)
        .then(() => this.cancel());
  }

  deleteItem(ia: ImpactAnalysis) {
    this.impactAnalysis = { ...ia };
    this.deleteDialog = true;
  }


  isNew() {
    return isNew(this.impactAnalysis);
  }

  createNew() {
    this.editDialog = true;
  }

  confirmSave() {
    this.$store.dispatch(ImpactAnalysisActions.IMPACT_ANALYSIS_SAVE, this.impactAnalysis)
        .then(() => this.cancel());
  }

  cancel() {
    this.editDialog = false;
    this.deleteDialog = false;
    this.impactAnalysis = createNewImpactAnalysis();
  }

  goToDetail(impactAnalysis: ImpactAnalysis) {
    this.$router.push({ name: 'ImpactAnalysisDetail', params: { impactAnalysisId: impactAnalysis.id } });
  }

  statsMappedFromRequirements(impactAnalysisId: string): CoHorizontalBarConfig[] {
    return [
      {
        label: 'Mapped',
        count: this.statistics[impactAnalysisId].numberOfMappedFromRequirements,
        color: '#69c88c',
      }, {
        label: 'Not Mapped',
        count: this.statistics[impactAnalysisId].totalNumberOfFromRequirements - this.statistics[impactAnalysisId].numberOfMappedFromRequirements,
        color: '#f49f0a',
      }
    ];
  }

  statsMappedToRequirements(impactAnalysisId: string): CoHorizontalBarConfig[] {
    return [
      {
        label: 'Mapped',
        count: this.statistics[impactAnalysisId].numberOfMappedToRequirements,
        color: '#69c88c',
      }, {
        label: 'Not Mapped',
        count: this.statistics[impactAnalysisId].totalNumberOfToRequirements - this.statistics[impactAnalysisId].numberOfMappedToRequirements,
        color: '#f49f0a',
      }
    ];
  }

}
