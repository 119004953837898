import { ImpactsGetter, ImpactsState, ImpactUiEntry } from '@/features/impacts/store';
import { ImpactMap } from '@/features/impacts/model';
import { createObjectCsvStringifier as createCsvStringifier } from 'csv-writer';
import { Requirement } from '@/features/sr-model/Requirement';
import { QmsRequirement } from '@/services/model';
import { SopGetters } from '@/features/sops';
import { SopSearchPart } from '@/model';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { TemplateGetters } from '@/store/templates';
import { Template } from '@/model/Template';
import { RegulationDetailGetters } from '@/store/regulation-detail';
import { ProductRequirement } from '@/features/sop-block/model';

type CsvEntry = {
    fReqId?: string;
    fReqText?: string;
    fReqApp?: string;
    fReqRea?: string;
    fReqEvi?: string;
    tReqId?: string;
    tReqText?: string;
    tReqApp?: string;
    tReqRea?: string;
    tReqEvi?: string;
    iCha?: string;
    iChaTxt?: string;
    iImp?: string;
    iImpTxt?: string;
}

function strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}

function exportImpact(impactId: string | undefined, impacts: ImpactMap) {
    if (!impactId) {
        return {};
    }
    const impact = impacts[impactId];
    return {
        iCha: impact.changeType,
        iChaTxt: impact.changeDescription,
        iImp: impact.impactType,
        iImpTxt: impact.impactDescription,
    }
}

function evidences(qmsRequirement: QmsRequirement, state: ImpactsState, getters: any, suffix: string) {

    const processEvidences = (getters[SopGetters.SELECTABLE_SOPS_BY_ARTIFACT_IDS](qmsRequirement.targetSopArtifactIds) as SopSearchPart[])
        .map(sop => sop.name)
        .join(', ');

    const templateContentEvidences = (getters[TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING](qmsRequirement.templateContentIds) as TemplateContent[])
        .map(templateContent => templateContent.name)
        .join(', ');

    const templateEvidences = (getters[TemplateGetters.VALID_TEMPLATE_ARTIFACTS] as Template[])
        .filter(template => qmsRequirement.targetTemplateArtifactIds.includes(template.artifactId))
        .map(templateContent => templateContent.name)
        .join(', ');

    const productEvidences = (getters[RegulationDetailGetters.PRODUCT_REQUIREMENTS] as ProductRequirement[])
        .filter(product => qmsRequirement.targetSopBlockArtifactIds.includes(product.sopBlockArtifactId))
        .map(productRequirement => productRequirement.sopBlockName + ' (' + productRequirement.sopName + ')')
        .join(', ');

    return ({
        [suffix + 'ReqEviProc']: processEvidences,
        [suffix + 'ReqEviTc']: templateContentEvidences,
        [suffix + 'ReqEviTemp']: templateEvidences,
        [suffix + 'ReqEviProd']: productEvidences,
    });
}

function exportFrom(uiEntry: ImpactUiEntry, state: ImpactsState, getters: any) {
    const fromRequirementsInCorrectOrder: Requirement[] = getters[ImpactsGetter.FROM_REQUIREMENTS_BY_IDS](uiEntry.fromRequirementIds)
    return fromRequirementsInCorrectOrder.map(requirement => {
        const qmsRequirement = state.fromQmsRequirements[requirement.id].interpretedInRequirementId ? state.fromQmsRequirements[state.fromQmsRequirements[requirement.id].interpretedInRequirementId || ''] : state.fromQmsRequirements[requirement.id];
        return ({
            fReqId: requirement.id,
            fReqText: requirement.text,
            fReqInh: state.fromQmsRequirements[requirement.id].interpretedInRequirementId || '',
            fReqApp: qmsRequirement.applicability,
            fReqRea: qmsRequirement.applicabilityReason,
            fReqNot: qmsRequirement.notes,
            ...evidences(qmsRequirement, state, getters, 'f')
        });
    })
}

function exportTo(uiEntry: ImpactUiEntry, state: ImpactsState, getters: any) {
    const toRequirementsInCorrectOrder: Requirement[] = getters[ImpactsGetter.TO_REQUIREMENTS_BY_IDS](uiEntry.toRequirementIds)
    return toRequirementsInCorrectOrder.map(requirement => {
        const qmsRequirement = state.toQmsRequirements[requirement.id].interpretedInRequirementId ? state.toQmsRequirements[state.toQmsRequirements[requirement.id].interpretedInRequirementId || ''] : state.toQmsRequirements[requirement.id];
        return ({
            tReqId: requirement.id,
            tReqText: requirement.text,
            tReqInh: state.toQmsRequirements[requirement.id].interpretedInRequirementId || '',
            tReqApp: qmsRequirement.applicability,
            tReqRea: qmsRequirement.applicabilityReason,
            tReqNot: qmsRequirement.notes,
            ...evidences(qmsRequirement, state, getters, 't')
        });
    })
}

function download(content: string, fileName: string) {
    const a = document.createElement('a');
    const mimeType = 'text/csv;encoding:utf-8';

    if (URL && 'download' in a) { // html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
}

export class ImpactCsvExporter {

    static export(state: ImpactsState, getters: any) {

        const csvEntries = state.impactUiEntries.flatMap(uiEntry => {
            const expImpact = exportImpact(uiEntry.impactId, state.impacts);

            const expFrom = exportFrom(uiEntry, state, getters);
            const expTo = exportTo(uiEntry, state, getters);

            const size = Math.max(expFrom.length, expTo.length);

            const entries: CsvEntry[] = []
            for (let i = 0; i < size; i++) {
                let entry = {};
                if (expTo[i]) {
                    entry = {
                        ...entry,
                        ...expTo[i]
                    }
                }
                if (expFrom[i]) {
                    entry = {
                        ...entry,
                        ...expFrom[i]
                    }
                }
                if (i === 0 && expImpact) {
                    entry = {
                        ...entry,
                        ...expImpact,
                    }
                }
                entries.push(entry);
            }
            return entries;
        })

        const csvStringifier = createCsvStringifier({
            header: [
                { id: 'fReqId', title: 'Id From' },
                { id: 'fReqText', title: 'Text From' },
                { id: 'fReqInh', title: 'Inherited From' },
                { id: 'fReqApp', title: 'Applicability From' },
                { id: 'fReqRea', title: 'Reason From' },
                { id: 'fReqNot', title: 'Note From' },

                { id: 'fReqEviProc', title: 'Process Evidences From' },
                { id: 'fReqEviTc', title: 'Template Content Evidences From' },
                { id: 'fReqEviTemp', title: 'Template Evidences From' },
                { id: 'fReqEviProd', title: 'Process Evidences From' },

                { id: 'tReqId', title: 'Id To' },
                { id: 'tReqText', title: 'Text To' },
                { id: 'tReqInh', title: 'Inherited To' },
                { id: 'tReqApp', title: 'Applicability To' },
                { id: 'tReqRea', title: 'Reason To' },
                { id: 'tReqNot', title: 'Note To' },

                { id: 'tReqEviProc', title: 'Process Evidences To' },
                { id: 'tReqEviTc', title: 'Template Content Evidences To' },
                { id: 'tReqEviTemp', title: 'Template Evidences To' },
                { id: 'tReqEviProd', title: 'Process Evidences To' },

                { id: 'iCha', title: 'Change' },
                { id: 'iChaTxt', title: 'Change Text' },
                { id: 'iImp', title: 'Impact' },
                { id: 'iImpTxt', title: 'Impact Text' },
            ]
        });

        const header = csvStringifier.getHeaderString();
        const content = csvStringifier.stringifyRecords(csvEntries);

        // console.log(header);
        // console.log(content);

        const filename = state.impactAnalysis.regulationIdFrom + '--to--' + state.impactAnalysis.regulationIdTo;

        download(header + '\n' + content, 'ImpactAnalysis_' + filename + '.csv');

        return csvEntries;
    }
}