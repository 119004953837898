import {
    createEmptyTemplateContent,
    emptyTemplateContentFilter,
    emptyTemplateContentSearchResult,
    NEW_TEMPLATE_CONTENT_ID,
    TemplateContent,
    TemplateContentSearchResult,
    TemplateContentValidation
} from '@/features/template-content/template-content-model';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { templateContentService } from '@/features/template-content/template-content-service';
import { UiFeedback } from '@/store/ui-feedback';
import { SopSearchPart } from '@/model';
import { TemplateSearchPart } from '@/model/Template';
import { qmsRequirementService } from '@/services/qms-requirement-service';
import { RequirementIdsPerRegulation } from '@/features/sr-requirement-search/service/requirement-search-service';
import { createComparator, safeVueSet, toMap } from '@/utils/util';
import { UiLayoutActions } from '@/store/ui-layout';

export type TemplateContentMap = { [key: string]: TemplateContent };

export const FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY = 'FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY';

export interface TemplateContentOverallStatistics {
    total: number;
    countByLinkState: { [key: string]: number };
}

export interface TemplateContentFilter {
    filterText: string;
    filterCreatingTemplateArtifactIds: string[];
    filterReportingTemplateArtifactIds: string[];
    selectedSopArtifactId: string;
}

export class TemplateContentState {
    templateContents: TemplateContentMap = {};
    filter: TemplateContentFilter = emptyTemplateContentFilter();
    search: TemplateContentSearchResult = emptyTemplateContentSearchResult();
    detailWidgetTemplateContentId = '';
    detailWidgetRequirementIdsPerRegulation: RequirementIdsPerRegulation
}

export enum TemplateContentActions {
    TEMPLATE_CONTENT_FILTER = 'TEMPLATE_CONTENT_FILTER',
    TEMPLATE_CONTENT_SEARCH = 'TEMPLATE_CONTENT_SEARCH',
    TEMPLATE_CONTENT_SEARCH_CLEAR = 'TEMPLATE_CONTENT_SEARCH_CLEAR',
    TEMPLATE_CONTENT_CREATE = 'TEMPLATE_CONTENT_CREATE',
    TEMPLATE_CONTENT_DELETE = 'TEMPLATE_CONTENT_DELETE',
    TEMPLATE_CONTENT_SELECT_ID = 'TEMPLATE_CONTENT_SELECT_ID',
    TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID = 'TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID',
}

export enum TemplateContentGetters {
    FILTERED_TEMPLATE_CONTENTS = 'FILTERED_TEMPLATE_CONTENTS',
    TEMPLATE_CONTENT_BY_ID_OR_EMPTY = 'TEMPLATE_CONTENT_BY_ID_OR_EMPTY',
    SOPS_USED_BY_TEMPLATE_CONTENT_ID = 'SOPS_USED_BY_TEMPLATE_CONTENT_ID',
    TEMPLATES_CREATING_CONTENT_BY_ID = 'TEMPLATES_CREATING_CONTENT_BY_ID',
    TEMPLATES_REPORTING_CONTENT_BY_ID = 'TEMPLATES_REPORTING_CONTENT_BY_ID',
    TEMPLATE_CONTENTS_BY_IDS_OR_LOADING = 'TEMPLATE_CONTENTS_BY_IDS_OR_LOADING',
    TEMPLATE_CONTENTS_BY_SR_TC_IDS = 'TEMPLATE_CONTENTS_BY_SR_TC_IDS',
    TEMPLATE_CONTENT_VALIDATIONS_BY_ID = 'TEMPLATE_CONTENT_VALIDATIONS_BY_ID',
    ALL_CREATING_TEMPLATES = 'ALL_CREATING_TEMPLATES',
    ALL_REPORTING_TEMPLATES = 'ALL_REPORTING_TEMPLATES',
    TEMPLATE_CONTENTS_LINKED_DIRECTLY_TO_SOP = 'TEMPLATE_CONTENTS_LINKED_DIRECTLY_TO_SOP',
}

export enum Mutations {
    TEMPLATE_CONTENT_FILTER_REPLACE = 'TEMPLATE_CONTENT_FILTER_REPLACE',
    TEMPLATE_CONTENT_SEARCH_REPLACE = 'TEMPLATE_CONTENT_SEARCH_REPLACE',
    DETAIL_WIDGET_TEMPLATE_CONTENT_ID_REPLACE = 'DETAIL_WIDGET_TEMPLATE_CONTENT_ID_REPLACE',
    DETAIL_WIDGET_TEMPLATE_CONTENT_REQUIREMENT_IDS_REPLACE = 'DETAIL_WIDGET_TEMPLATE_CONTENT_REQUIREMENT_IDS_REPLACE',
}

export function createLoading(id: string): TemplateContent {
    return {
        ...createEmptyTemplateContent(),
        id,
        name: 'Loading...'
    }
}

function hasTextMatch(templateContent: TemplateContent, filter: TemplateContentFilter): boolean {
    if (filter.filterText.length == 0) {
        return true;
    }

    return (
        (!!templateContent.name && templateContent.name.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) ||
        (!!templateContent.description && templateContent.description.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0) ||
        (!!templateContent.exampleText && templateContent.exampleText.toLowerCase().indexOf(filter.filterText.toLowerCase()) >= 0)
    );
}

function getTemplateContentIdsByFilteredTemplates(searchResult: TemplateContentSearchResult, filteredTemplateArtifactIds: string[], tcToTemplates: { [p: string]: string[] }): string[] {
    if (filteredTemplateArtifactIds.length === 0) {
        return searchResult.templateContents.map(tc => tc.id);
    }

    const filteredTemplateVersionIds: string[] = searchResult.templates
        .filter(t => filteredTemplateArtifactIds.findIndex(artifactId => t.artifactId === artifactId) >= 0)
        .map(t => t.versionId);

    const addAllUnassigned = filteredTemplateArtifactIds.indexOf(FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY) >= 0;
    let allUnassignedTcIds: string[] = [];
    if (addAllUnassigned) {
        const allTemplateContentIds = searchResult.templateContents.map(tc => tc.id);
        const allUsedTemplateContentIds = Object.keys(tcToTemplates);
        allUnassignedTcIds = allTemplateContentIds.filter(tcId => allUsedTemplateContentIds.indexOf(tcId) == -1)
    }
    const allAssignedFilteredTcIds = Object.entries(tcToTemplates)
        .filter(([_, templateVersionIds]) => templateVersionIds.some(id => filteredTemplateVersionIds.includes(id)))
        .map(([tcId, _]) => tcId);

    return [...allAssignedFilteredTcIds, ...allUnassignedTcIds];
}

function getTemplateContentIdsBySapArtifactId(searchResult: TemplateContentSearchResult, sopArtefactId: string, sopsByTemplateContentId: (templateContentId: string) => SopSearchPart[]): string[] {
    if (sopArtefactId.length === 0) {
        return searchResult.templateContents.map(tc => tc.id);
    }

    const filteredTemplateContentIds: string[] = searchResult.templateContents
        .filter(tc => sopsByTemplateContentId(tc.id).some(sop => sop.artifactId === sopArtefactId))
        .map(tc => tc.id);


    return filteredTemplateContentIds;
}

function isFiltered(tc: TemplateContent, templateContentIds: string[]) {
    return templateContentIds.findIndex(tcId => tcId === tc.id) >= 0;
}

const getters = {
    [TemplateContentGetters.FILTERED_TEMPLATE_CONTENTS]: (state: TemplateContentState, getters: any, rootState: RootState) => {
        const tcCreatedToTemplates = Object.entries(state.search.templateContentInfos)
            .map(([key, info]) => [key, info.createdByTemplateVersionIds] as [string, string[]])
            .filter(([_, ids]) => ids && ids.length > 0)
            .reduce((map, [key, arr]) => {
                map[key] = arr;
                return map;
            }, {} as { [key: string]: string[] });

        const templateContentIdsCreatedByFilteredTemplates = getTemplateContentIdsByFilteredTemplates(state.search, state.filter.filterCreatingTemplateArtifactIds, tcCreatedToTemplates);
        const tcReportedToTemplates = Object.entries(state.search.templateContentInfos)
            .map(([key, info]) => [key, info.reportedByTemplateVersionIds] as [string, string[]])
            .filter(([_, ids]) => ids && ids.length > 0)
            .reduce((map, [key, arr]) => {
                map[key] = arr;
                return map;
            }, {} as { [key: string]: string[] });
        const templateContentIdsReportedByFilteredTemplates = getTemplateContentIdsByFilteredTemplates(state.search, state.filter.filterReportingTemplateArtifactIds, tcReportedToTemplates);

        const templateContentIdsUsedByFilteredSapArtifactId = getTemplateContentIdsBySapArtifactId(state.search, state.filter.selectedSopArtifactId, getters[TemplateContentGetters.SOPS_USED_BY_TEMPLATE_CONTENT_ID]);

        console.log(templateContentIdsUsedByFilteredSapArtifactId);

        return state.search.templateContents
            .filter(tc => hasTextMatch(tc, state.filter))
            .filter(tc => isFiltered(tc, templateContentIdsCreatedByFilteredTemplates))
            .filter(tc => isFiltered(tc, templateContentIdsReportedByFilteredTemplates))
            .filter(tc => isFiltered(tc, templateContentIdsUsedByFilteredSapArtifactId))
            .map(tc => ({ ...tc, creatingTemplates: tc.id, reportingTemplates: tc.id, processes: tc.id }))
    },
    [TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY]: (state: TemplateContentState) => (templateContentId: string): TemplateContent => {
        return state.templateContents[templateContentId] || createLoading(templateContentId)
    },
    [TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING]: (state: TemplateContentState, getters: any) => (templateContentIds: string[]): TemplateContent[] => {
        return templateContentIds?.map(templateContentId => getters[TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY](templateContentId)) ?? [];
    },
    [TemplateContentGetters.TEMPLATE_CONTENTS_BY_SR_TC_IDS]: (state: TemplateContentState) => (srTemplateContentIds: string[]): TemplateContent[] => {
        return Object.values(state.templateContents)
            .filter(templateContent => templateContent.srId && srTemplateContentIds.includes(templateContent.srId));
    },
    [TemplateContentGetters.SOPS_USED_BY_TEMPLATE_CONTENT_ID]: (state: TemplateContentState) => (templateContentId: string): SopSearchPart[] => {
        const templateContentToSop = state.search.templateContentInfos[templateContentId]?.createdBySopVersionIds ?? [];
        return templateContentToSop.flatMap(sopVersionId => state.search.sops.filter(sop => sop.versionId === sopVersionId) || []);
    },
    [TemplateContentGetters.TEMPLATES_CREATING_CONTENT_BY_ID]: (state: TemplateContentState) => (templateContentId: string): TemplateSearchPart[] => {
        const templateContentToTemplate = state.search.templateContentInfos[templateContentId]?.createdByTemplateVersionIds || [];
        return state.search.templates.filter(template => templateContentToTemplate.indexOf(template.versionId) >= 0);
    },
    [TemplateContentGetters.TEMPLATES_REPORTING_CONTENT_BY_ID]: (state: TemplateContentState) => (templateContentId: string): TemplateSearchPart[] => {
        const reportedContentToTemplate = state.search.templateContentInfos[templateContentId]?.reportedByTemplateVersionIds || [];
        return state.search.templates.filter(template => reportedContentToTemplate.indexOf(template.versionId) >= 0);
    },
    [TemplateContentGetters.TEMPLATE_CONTENT_VALIDATIONS_BY_ID]: (state: TemplateContentState) => (templateContentId: string): TemplateContentValidation[] => {
        return (state.search.templateContentInfos[templateContentId]?.validations ?? []);
    },
    [TemplateContentGetters.ALL_CREATING_TEMPLATES]: (state: TemplateContentState) => {
        const allDistinctCreatingTemplateVersionIds = [...new Set(
            Object.values(state.search.templateContentInfos).flatMap(templates => templates.createdByTemplateVersionIds)
        )];
        return state.search.templates
            .filter(template => allDistinctCreatingTemplateVersionIds.indexOf(template.versionId) >= 0)
            .sort(createComparator('name'));
    },
    [TemplateContentGetters.ALL_REPORTING_TEMPLATES]: (state: TemplateContentState) => {
        const allDistinctReportingTemplateVersionIds = [...new Set(
            Object.values(state.search.templateContentInfos).flatMap(templates => templates.reportedByTemplateVersionIds)
        )];
        return state.search.templates
            .filter(template => allDistinctReportingTemplateVersionIds.indexOf(template.versionId) >= 0)
            .sort(createComparator('name'));
    },
    [TemplateContentGetters.TEMPLATE_CONTENTS_LINKED_DIRECTLY_TO_SOP]: (state: TemplateContentState, getters: any, rootState: RootState) => {
        if (!rootState.sopDetail.sop) {
            return [];
        }
        return getters[TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING](rootState.sopDetail.sop.templateContentIds);
    }
}

type TemplateContentSearchReplace = { templateContents: TemplateContentMap; searchResult: TemplateContentSearchResult };

const actions = {
    [TemplateContentActions.TEMPLATE_CONTENT_FILTER]: ({ commit }: ActionContext<TemplateContentState, RootState>, filter: TemplateContentFilter) => {
        commit(Mutations.TEMPLATE_CONTENT_FILTER_REPLACE, filter);
        return Promise.resolve();
    },
    [TemplateContentActions.TEMPLATE_CONTENT_SEARCH]: ({ commit, dispatch }: ActionContext<TemplateContentState, RootState>) => {
        return templateContentService.searchTemplateContents()
            .then(templateContentsSearchResult => {
                const templateSearchReplace: TemplateContentSearchReplace = {
                    searchResult: templateContentsSearchResult,
                    templateContents: toMap(templateContentsSearchResult.templateContents, t => t.id)
                };
                commit(Mutations.TEMPLATE_CONTENT_SEARCH_REPLACE, templateSearchReplace);
            })
            .catch(err => UiFeedback.showError(dispatch, `Template content search couldn't be executed. Please try again.`, err));
    },
    [TemplateContentActions.TEMPLATE_CONTENT_SEARCH_CLEAR]: ({ commit }: ActionContext<TemplateContentState, RootState>) => {
        const templateSearchReplace: TemplateContentSearchReplace = {
            searchResult: emptyTemplateContentSearchResult(),
            templateContents: {}
        };
        return commit(Mutations.TEMPLATE_CONTENT_SEARCH_REPLACE, templateSearchReplace);
    },
    [TemplateContentActions.TEMPLATE_CONTENT_CREATE]: ({ dispatch }: ActionContext<TemplateContentState, RootState>, templateContent: TemplateContent) => {
        const templateContentPromise = templateContent.id === NEW_TEMPLATE_CONTENT_ID ?
            templateContentService.createTemplateContent(templateContent) :
            templateContentService.updateTemplateContent(templateContent);
        return templateContentPromise
            .catch(err => UiFeedback.showError(dispatch, `Template content couldn't be created/updated. Please try again.`, err))
            .finally(() => dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH));
    },
    [TemplateContentActions.TEMPLATE_CONTENT_DELETE]: ({ dispatch }: ActionContext<TemplateContentState, RootState>, templateContentId: string) => {
        return templateContentService.deleteTemplateContent(templateContentId)
            .catch(err => UiFeedback.showError(dispatch, `Template content couldn't be deleted. Please try again.`, err))
            .finally(() => dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH));
    },
    [TemplateContentActions.TEMPLATE_CONTENT_SELECT_ID]: ({ commit, dispatch }: ActionContext<TemplateContentState, RootState>, templateContentId: string) => {
        commit(Mutations.DETAIL_WIDGET_TEMPLATE_CONTENT_ID_REPLACE, templateContentId);
        if (templateContentId.length == 0) {
            return Promise.resolve();
        }
        return qmsRequirementService.findQmsRequirementsByTemplateContentId(templateContentId)
            .then(qmsRequirementMap => {
                const requirementIdsPerRegulation: RequirementIdsPerRegulation = Object.values(qmsRequirementMap)
                    .reduce((result, entry) => {
                        result[entry.regulationId] = result[entry.regulationId] || [];
                        result[entry.regulationId].push(entry.requirementId);
                        return result;
                    }, {} as RequirementIdsPerRegulation);
                return commit(Mutations.DETAIL_WIDGET_TEMPLATE_CONTENT_REQUIREMENT_IDS_REPLACE, requirementIdsPerRegulation);
            });
    },
    [TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID]: ({ dispatch }: ActionContext<TemplateContentState, RootState>, templateContentId: string) => {
        if (templateContentId.length == 0) {
            return Promise.resolve();
        }
        return dispatch(TemplateContentActions.TEMPLATE_CONTENT_SELECT_ID, templateContentId)
            .then(() => dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, 'TemplateContentDetailWidget'));
    },
}

const mutations = {
    [Mutations.TEMPLATE_CONTENT_SEARCH_REPLACE]: (state: TemplateContentState, { templateContents, searchResult }: TemplateContentSearchReplace) => {
        safeVueSet(state, 'search', searchResult);
        safeVueSet(state, 'templateContents', templateContents);
    },
    [Mutations.DETAIL_WIDGET_TEMPLATE_CONTENT_ID_REPLACE]: (state: TemplateContentState, templateContentId: string) =>
        safeVueSet(state, 'detailWidgetTemplateContentId', templateContentId),
    [Mutations.DETAIL_WIDGET_TEMPLATE_CONTENT_REQUIREMENT_IDS_REPLACE]: (state: TemplateContentState, requirementIdsPerRegulation: RequirementIdsPerRegulation) =>
        safeVueSet(state, 'detailWidgetRequirementIdsPerRegulation', requirementIdsPerRegulation),
    [Mutations.TEMPLATE_CONTENT_FILTER_REPLACE]: (state: TemplateContentState, filter: TemplateContentFilter) =>
        safeVueSet(state, 'filter', filter)
}

export const TEMPLATE_CONTENT_MODULE: Module<TemplateContentState, RootState> = {
    state: new TemplateContentState(),
    getters,
    actions,
    mutations
};