





































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopGetters } from '@/features/sops';
import { Sop } from '@/model';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import { TemplateGetters } from '@/store/templates';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';
import { QmsRequirement } from '@/services/model';
import { RegulationDetailGetters } from '@/store/regulation-detail';

@Component<QmsRequirementEdit>({
  components: { TemplateContentChip },
  computed: {
    ...mapState<RootState>({
      templateContents: (state: RootState) => Object.values(state.templateContents.templateContents)
    }),
    ...mapGetters({
      selectableSops: SopGetters.QMS_SELECTABLE_SOPS,
      selectableProductRequirements: RegulationDetailGetters.PRODUCT_REQUIREMENTS,
      selectableTemplates: TemplateGetters.VALID_TEMPLATE_ARTIFACTS,
    }),
  },
  methods: {
    ...mapActions({
      selectTemplateContentDetailWidgetId: TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID
    })
  },
})
export default class QmsRequirementEdit extends Vue {

  @Prop({ required: true })
  qmsRequirement: QmsRequirement;
  @Prop({ required: true })
  dispatchQmsRequirementChange: (changingFields: Partial<QmsRequirement>) => Promise<void>;

  @Prop({ default: () => () => [] })
  proposedSopsByRequirementId: (requirementId: string) => Sop[];
  @Prop({ default: () => () => [] })
  proposedTemplateContentByRequirementId: (requirementId: string) => TemplateContent[];

  get activePanel(): number[] {
    const activePanels = [];
    if (this.qmsRequirement.applicability === 'APPLICABLE'
        || this.qmsRequirement.applicability === 'NOT_DEFINED'
        || this.qmsRequirement.targetSopArtifactIds.length > 0
        || this.qmsRequirement.targetTemplateArtifactIds.length > 0
        || this.qmsRequirement.templateContentIds.length > 0) {
      activePanels.push(0);
    }
    if (this.qmsRequirement.applicability === 'NOT_APPLICABLE'
        || this.qmsRequirement.applicabilityReason?.length > 0) {
      activePanels.push(1);
    }
    if (this.qmsRequirement.notes?.length > 0) {
      activePanels.push(2);
    }
    return activePanels;
  }

  get proposedSops(): Sop[] {
    return this.proposedSopsByRequirementId(this.qmsRequirement.requirementId);
  }

  get proposedTemplateContents(): TemplateContent[] {
    return this.proposedTemplateContentByRequirementId(this.qmsRequirement.requirementId);
  }

  get targetSopArtifactIds(): string[] {
    return this.qmsRequirement.targetSopArtifactIds;
  }

  set targetSopArtifactIds(targetSopArtifactIds: string[]) {
    this.dispatchQmsRequirementChange({ targetSopArtifactIds });
  }

  get targetSopBlockArtifactIds(): string[] {
    return this.qmsRequirement.targetSopBlockArtifactIds;
  }

  set targetSopBlockArtifactIds(targetSopBlockArtifactIds: string[]) {
    this.dispatchQmsRequirementChange({ targetSopBlockArtifactIds });
  }

  get targetTemplateArtifactIds(): string[] {
    return this.qmsRequirement.targetTemplateArtifactIds;
  }

  set targetTemplateArtifactIds(targetTemplateArtifactIds: string[]) {
    this.dispatchQmsRequirementChange({ targetTemplateArtifactIds });
  }

  get requirementId(): string {
    return this.qmsRequirement.requirementId;
  }

  get templateContentIds(): string[] {
    return this.qmsRequirement.templateContentIds;
  }

  set templateContentIds(templateContentIds: string[]) {
    this.dispatchQmsRequirementChange({ templateContentIds });
  }
}
