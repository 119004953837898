/* eslint-disable @typescript-eslint/no-unused-vars */

import { toMap } from '@/utils/util';
import { createEmptyRequirement, Requirement, RequirementContentType, RequirementMap } from '@/features/sr-model/Requirement';
import { QmsRequirementServiceMock } from '@/services/qms-requirement-service-mock';
import { SrRegulation, SrRegulationMap } from '@/features/regulations/model';
import { RegulationDetail, SrRegulationService } from '@/services/sr-regulation-service';

const regulationMdr: SrRegulation = {
    'id': 'mdr-2017-745',
    'number': '2017/745',
    'title': 'Medical Device Regulation (MDR)',
    'licenseTypeView': 'VIEW_OPEN',
    'licenseTypeExport': 'EXPORT_OPEN',
};

const regulationIso1: SrRegulation = {
    'id': 'iso-1',
    'number': '2020/420',
    'title': 'Cyber Security Regulation (CSR)',
    'licenseTypeView': 'VIEW_OPEN',
    'licenseTypeExport': 'EXPORT_OPEN',
};

const regulationIso2: SrRegulation = {
    'id': 'iso-2',
    'number': '2022/420',
    'title': 'Weissenstein Bier Regulation (WBR)',
    'licenseTypeView': 'VIEW_OPEN',
    'licenseTypeExport': 'EXPORT_OPEN',
};

const requirementsMdr: Requirement[] = [
    {
        'id': 'CHAPTER-I___________________________',
        'key': 'CHAPTER-I',
        'type': RequirementContentType.HEADING,
        'interpretable': true,
        'text': 'Article 1',
        'level': 2,
        'requirementIds': [
            'CHAPTER-I_1_________________________',
            'CHAPTER-I_2_________________________'
        ]
    },
    {
        'id': 'CHAPTER-I_1_________________________',
        'key': 'CHAPTER-I_1',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Test: ',
        'paragraph': '1.',
        'level': 3,
        'requirementIds': [],
        'parentId': 'CHAPTER-I___________________________'
    },
    {
        'id': 'CHAPTER-I_2_________________________',
        'key': 'CHAPTER-I_2',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Test: things ',
        'paragraph': '2.',
        'level': 3,
        'requirementIds': ['CHAPTER-I_2_1_______________________', QmsRequirementServiceMock.FAIL_ON_CALL_QMS_REQUIREMENT_ID],
        'parentId': 'CHAPTER-I___________________________',
    },
    {
        'id': 'CHAPTER-I_2_1_______________________',
        'key': 'CHAPTER-I_2_1',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Testing: things',
        'paragraph': '1.',
        'level': 4,
        'requirementIds': ['CHAPTER-I_2_1_1_____________________'],
        'parentId': 'CHAPTER-I_2_________________________',
    },
    {
        'id': QmsRequirementServiceMock.FAIL_ON_CALL_QMS_REQUIREMENT_ID,
        'key': 'CHAPTER-I_2_2',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Testing: things',
        'paragraph': '1.',
        'level': 4,
        'requirementIds': [],
        'parentId': 'CHAPTER-I_2_________________________',
    },
    {
        'id': 'CHAPTER-I_2_1_1_____________________',
        'key': 'CHAPTER-I_2_1_1',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Testing: things',
        'paragraph': '1.',
        'level': 4,
        'requirementIds': [],
        'parentId': 'CHAPTER-I_2_1_______________________',
    },
    {
        'id': 'CHAPTER-X___________________________',
        'key': 'CHAPTER-X',
        'type': RequirementContentType.HEADING,
        'interpretable': true,
        'text': 'Chapter X',
        'level': 2,
        'requirementIds': ['CHAPTER-X_1_________________________'],
    },
    {
        'id': 'CHAPTER-X_1_________________________',
        'key': 'CHAPTER-X_1',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Text of CHAPTER-X',
        'paragraph': '1.',
        'level': 3,
        'requirementIds': [],
        'parentId': 'CHAPTER-X___________________________',
    },
    {
        'id': 'CHAPTER-X_2_________________________',
        'key': 'CHAPTER-X_2',
        'type': RequirementContentType.HEADING,
        'interpretable': true,
        'text': 'Heading of CHAPTER-X_2',
        'level': 3,
        'requirementIds': ['CHAPTER-X_2_1_______________________'],
        'parentId': 'CHAPTER-X___________________________',
    },
    {
        'id': 'CHAPTER-X_2_1_______________________',
        'key': 'CHAPTER-X_2_1',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Text of CHAPTER-X_2_1',
        'level': 4,
        'requirementIds': [],
        'parentId': 'CHAPTER-X_2_________________________',
    },
    {
        'id': 'CHAPTER-X_3_________________________',
        'key': 'CHAPTER-X_3',
        'type': RequirementContentType.TEXT,
        'interpretable': true,
        'text': 'Text of CHAPTER-X_3',
        'level': 1,
        'requirementIds': [],
        'parentId': 'CHAPTER-X___________________________',
    },
];

const requirementsIso1: Requirement[] = [
    { ...createEmptyRequirement(), id: 'iso-1-req-1' },
    { ...createEmptyRequirement(), id: 'iso-1-req-2' },
    { ...createEmptyRequirement(), id: 'iso-1-req-2' },
];

const requirementsIso2: Requirement[] = [
    { ...createEmptyRequirement(), id: 'iso-2-req-1' },
    { ...createEmptyRequirement(), id: 'iso-2-req-2' },
    { ...createEmptyRequirement(), id: 'iso-2-req-2' },
];


export class SrRegulationServiceMock implements SrRegulationService {

    public static requirementsMdr: Requirement[] = [];
    public static requirementsIso1: Requirement[] = [];
    public static requirementsIso2: Requirement[] = [];

    public static init() {
        SrRegulationServiceMock.requirementsMdr = requirementsMdr;
        SrRegulationServiceMock.requirementsIso1 = requirementsIso1;
        SrRegulationServiceMock.requirementsIso2 = requirementsIso2;
    }

    loadSrRegulations(): Promise<SrRegulationMap> {
        return Promise.resolve(toMap([regulationMdr, regulationIso1, regulationIso2], r => r.id));
    }

    loadRegulationDetail(regulationId: string): Promise<RegulationDetail> {
        if (regulationId == 'iso-1') {
            return Promise.resolve({ regulation: regulationIso1, requirements: toMap(SrRegulationServiceMock.requirementsIso1, r => r.id) });
        } else if (regulationId == 'iso-2') {
            return Promise.resolve({ regulation: regulationIso2, requirements: toMap(SrRegulationServiceMock.requirementsIso2, r => r.id) });
        }
        return Promise.resolve({ regulation: regulationMdr, requirements: toMap(SrRegulationServiceMock.requirementsMdr, r => r.id) });
    }

    loadFilteredRequirements(regulationId: string, applicableRequirementIds: string[], infoRequirementIds: string[]): Promise<RequirementMap> {
        return Promise.resolve(toMap(SrRegulationServiceMock.requirementsMdr
                .filter(r =>
                    applicableRequirementIds.includes(r.id) ||
                    infoRequirementIds.includes(r.id)),
            r => r.id));
    }

}

