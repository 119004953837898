





































import Vue from 'vue';
import Component from 'vue-class-component';
import { RootState } from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SopDetailActions, SopDetailGetters } from '@/store/sop-detail';
import SopDetailDialog from '@/components/sop-detail/SopDetailDialog.vue';
import { sopService } from '@/services/sop-service';
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import CoHeader from '@/components/common/CoHeader.vue';
import DownloadButton from '@/components/common/DownloadButton.vue';
import SopClipboardView from '@/components/sop-detail/SopClipboardView.vue';

@Component({
  components: { CoHeader: CoHeader, Breadcrumbs, SopDetailDialog, DownloadButton, SopClipboardView },
  computed: {
    ...mapGetters({
      sopIsEditable: SopDetailGetters.SOP_IS_EDITABLE,
      currentReleaseState: SopDetailGetters.CURRENT_RELEASE_STATE
    }),
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
      terms: (state: RootState) => state.sopDetail.terms,
      sopArtifact: (state: RootState) => state.sopDetail.sopArtifact
    }),
  },
  methods: {
    ...mapActions({
      changeStateTo: SopDetailActions.SOP_DETAIL_RELEASE_STATE_CHANGE,
      createNewVersion: SopDetailActions.SOP_DETAIL_CREATE_NEW_VERSION,
      sopAsCsv: SopDetailActions.SOP_DETAIL_AS_CSV,
    })
  }
})
export default class SopDetailHeader extends Vue {

  isPdfExportLoading = false;
  showClipboardDialog = false;

  exportAsPdf() {
    this.isPdfExportLoading = true;
    sopService.exportPdf((this as any).sop)
        .finally(() => this.isPdfExportLoading = false);
  }

  copyToClipboard() {
    const text = (this.$refs['clipboardContent'] as HTMLElement).innerHTML;

    function listener(e: any) {
      e.clipboardData.setData('text/html', text);
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
    }

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

}
