import { SopSearchPart, Term } from '@/model/Sop';
import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { termService } from '@/services/term-service';
import { UiFeedback } from '@/store/ui-feedback';
import { equalsIgnoreCase } from '@/utils/StringUtils';
import { safeVueSet } from '@/utils/util';

export type TermSearchDto = {
    term: Term;
    latestSop: SopSearchPart;
}

export class TermState {
    allTerms: TermSearchDto[] = [];
}

export enum TermActions {
    TERMS_LOAD = 'TERMS_LOAD',
}

export enum TermGetters {
    TERMS_BY_NAME_IGNORE_CASE = 'TERMS_BY_NAME_IGNORE_CASE',
}

export enum Mutations {
    ALL_TERMS_REPLACE = 'ALL_TERMS_REPLACE',
}

const getters = {
    [TermGetters.TERMS_BY_NAME_IGNORE_CASE]: (state: TermState) =>
        (termName: string, termVersionId: string) => state.allTerms
            .filter(t => equalsIgnoreCase(t.term.term, termName))
            .filter(t => t.term.versionId !== termVersionId)
}

const actions = {
    [TermActions.TERMS_LOAD]: ({ commit, dispatch }: ActionContext<TermState, RootState>) => {
        return termService.allTerms()
            .then(allTerms => commit(Mutations.ALL_TERMS_REPLACE, allTerms))
            .catch(err => UiFeedback.showError(dispatch, `Terms couldn't be fetched. Please try again.`, err));
    },
}

const mutations = {
    [Mutations.ALL_TERMS_REPLACE]: (state: TermState, allTerms: TermSearchDto[]) =>
        safeVueSet(state, 'allTerms', allTerms),
}

export const TERM_MODULE: Module<TermState, RootState> = {
    state: new TermState(),
    getters,
    actions,
    mutations
};