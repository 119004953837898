








import Vue from 'vue';
import Component from 'vue-class-component';
import { RegulationDetailActions } from '@/store/regulation-detail';
import { Prop } from 'vue-property-decorator';
import QmsRequirementApplicabilityComponent from '@/components/common/QmsRequirementApplicabilityComponent.vue';
import { QmsRequirement } from '@/services/model';

@Component({
  components: { QmsRequirementApplicabilityComponent }
})
export default class SopQmsRequirementApplicability extends Vue {

  @Prop() qmsRequirement: QmsRequirement;
  @Prop() editable: boolean;

  dispatchQmsRequirementChange(changingFields: Partial<QmsRequirement>) {
    this.$store.dispatch(RegulationDetailActions.QMS_REQUIREMENT_CHANGE,
        {
          ...this.qmsRequirement,
          ...changingFields,
        } as QmsRequirement);
  }
}
