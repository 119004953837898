import { RequirementMap } from '@/features/sr-model/Requirement';
import { createRequirementIdSorter } from '@/features/impacts/store';
import { checkUndefined } from '@/utils/util';

export class BreadcrumbCalculator {
    private allRequirementIdsInOrder: string[];

    public constructor(private requirementMap: RequirementMap,) {
        this.allRequirementIdsInOrder = Object.keys(requirementMap);
    }

    public calculateBreadcrumb(requirementIds: string[]): { [key: string]: string } {
        const ids = [...requirementIds];
        ids.sort(createRequirementIdSorter(this.requirementMap))

        const idsToCalculate = [];
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            if ((i === 0 && this.hasParent(id))
                || (i > 0 && !this.isDirectSiblingOfBefore(ids, i))) {
                idsToCalculate.push(id);
            }
        }
        const paths: { [key: string]: string } = {};
        for (const id of idsToCalculate) {
            const path = this.calculateBreadcrumbPath(id);
            const pathAlreadyExists = Object.values(paths).includes(path);
            paths[id] = pathAlreadyExists || this.directParentOccursInSelectedRequirements(id, requirementIds) ? '...' : path;
        }
        return paths;
    }

    private directParentOccursInSelectedRequirements(id: string, requirementIds: string[]): boolean {
        const parentId = this.requirementMap[id].parentId;
        return !!(parentId && requirementIds.includes(parentId));
    }

    private isDirectSiblingOfBefore(ids: string[], i: number) {
        return this.allRequirementIdsInOrder.indexOf(ids[i]) - 1 === this.allRequirementIdsInOrder.indexOf(ids[i - 1]);
    }

    private hasParent(id: string) {
        return this.requirementMap[id].parentId && this.requirementMap[id].parentId !== '';
    }

    private calculateBreadcrumbPath(requirementId: string | undefined): string {
        let path: string[] = [];
        requirementId = checkUndefined(this.requirementMap[checkUndefined(requirementId)]).parentId;
        while (requirementId && requirementId !== '') {
            const requirement = this.requirementMap[checkUndefined(requirementId)];
            const text = (requirement.paragraph ? requirement.paragraph + ' ' : '') + requirement.text;
            path = [
                this.cutToMaxLength(text),
                ...path
            ]
            requirementId = this.requirementMap[requirementId].parentId;
        }
        return path.join(' / ');
    }

    private cutToMaxLength(text: string) {
        if (text.length > 15) {
            return text.substring(0, 10) + '...';
        }
        return text;
    }
}
