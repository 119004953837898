














import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import KeyNumbers from '@/features/dashboard/KeyNumbers.vue';
import DoughnutChart, { DoughnutChartData } from '@/features/dashboard/DoughnutChart.vue';

@Component({
  components: {
    DoughnutChart,
    KeyNumbers
  }
})
export default class SimpleTile extends Vue {

  @Prop({required: true })
  keyNumbers: { label: string; value: number }[];
  @Prop({required: true})
  chartData: DoughnutChartData;
}
