




















import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters, mapState } from 'vuex';

import { RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import RequirementList from '@/components/regulations-detail/RequirementList.vue';
import CoLayout from '@/layouts/CoLayout.vue';
import RequirementSidebar from '@/components/regulations-detail/RequirementSidebar.vue';
import RegulationDetailHeader from '@/components/regulations-detail/RegulationDetailHeader.vue';
import { SopActions } from '@/features/sops';
import { Watch } from 'vue-property-decorator';
import { RootState } from '@/store';
import { TemplateActions } from '@/store/templates';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import GapStatisticWidget from '@/components/regulations-detail/GapStatisticWidget.vue';
import { RegulationActions } from '@/features/regulations/regulation-store';

@Component({
  components: { RegulationDetailHeader, RequirementSidebar, GapStatisticWidget, CoLayout, RequirementList, TemplateContentDetailWidget },
  computed: {
    ...mapState<RootState>({
      anchoredRequirementKey: (state: RootState) => state.regulationDetail.anchoredRequirementKey,
    }),
    ...mapGetters({
      requirements: RegulationDetailGetters.FILTERED_REQUIREMENTS
    })
  }
})
export default class RegulationsDetailView extends Vue {

  anchoredRequirementKey: string;

  created() {
    this.loadDetail();
    this.scrollToInitialRequirementKey();
  }

  private scrollToInitialRequirementKey() {
    const requirementKey: string = this.$route.hash;
    if (requirementKey) {
      this.$store.dispatch(RegulationDetailActions.ANCHORED_REQUIREMENT_KEY_UPDATE, requirementKey.substring(1));
    }
  }

  private loadDetail() {
    this.$store.dispatch(SopActions.SOPS_LOAD);
    this.$store.dispatch(TemplateActions.TEMPLATES_SEARCH);
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);
    this.$store.dispatch(RegulationDetailActions.PRODUCT_REQUIREMENT_LOAD);
    this.$store.dispatch(RegulationDetailActions.REGULATION_DETAIL_LOAD, this.$route.params.id);
    this.$store.dispatch(RegulationActions.QMS_REGULATION_STATISTICS_LOAD, this.$route.params.id);
    this.$store.dispatch(RegulationDetailActions.PRODUCTS_LOAD);
  }

  beforeDestroy() {
    this.$store.dispatch(RegulationDetailActions.REGULATION_DETAIL_RESET);
  }

  @Watch('anchoredRequirementKey')
  updateHashInUrl() {
    const reqKey = this.anchoredRequirementKey;
    if (!reqKey) {
      return;
    }
    window.location.replace('#' + reqKey);
  }
}
