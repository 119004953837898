var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mr-n4",attrs:{"data-test-requirement-part":_vm.srRequirement.id,"no-gutters":""}},[_c('div',{staticStyle:{"position":"relative"}},[(_vm.isRequirementInSelectedImpact(_vm.srRequirement.id))?[(_vm.isFromRequirementInSelectedImpact(_vm.srRequirement.id))?_c('div',{staticClass:"co-is-widget-entry-marked-marked-as-impact-from-requirement"}):_c('div',{staticClass:"co-is-widget-entry-marked-marked-as-impact-to-requirement"})]:_vm._e(),(_vm.isRequirementSelectedByQmsRequirementId)?_c('div',{staticClass:"co-is-widget-entry-selected"}):_vm._e(),(_vm.isRequirementMarkedBySelectedBlock(_vm.srRequirement.id))?_c('v-tooltip',{attrs:{"top":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"co-is-widget-entry-marked"},on))]}}],null,false,2663653905)},[_c('span',[_vm._v("Requirement is linked to selected process block.")])]):_vm._e()],2),_c('v-col',{staticClass:"pr-4",attrs:{"cols":"10"}},[(_vm.isHeading())?_c('anchored-heading',{attrs:{"regulation-id":_vm.selectedRegulationId,"requirementKey":_vm.srRequirement.key,"text":_vm.srRequirement.text,"level":_vm.srRequirement.level + 2}}):_c('requirement-item',{attrs:{"requirement":_vm.srRequirement,"requirements-by-id":_vm.srRequirementsById}})],1),_c('v-col',{staticClass:"ml-n2",attrs:{"cols":"2"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[(_vm.isQmsAdmin && _vm.selectedSopBlockArtifactId.length > 0 && _vm.sopRequirementType === 'QMS')?_c('v-tooltip',{key:_vm.srRequirement.id + '-marked',attrs:{"top":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{staticClass:"ma-0 ml-auto",attrs:{"on-icon":'mdi-card-remove-outline',"off-icon":'mdi-card-plus-outline',"input-value":_vm.isRequirementMarkedBySelectedBlock(_vm.srRequirement.id),"data-test-marked-checkbox":_vm.srRequirement.id,"dense":""},on:{"change":function($event){return _vm.changeRequirementMarked({ requirementId: _vm.srRequirement.id, marked: $event })}}})],1)]}}],null,false,3373784760)},[(_vm.isRequirementMarkedBySelectedBlock(_vm.srRequirement.id))?_c('span',[_vm._v("Unlink requirement from the selected process block.")]):_c('span',[_vm._v("Link requirement to the selected process block.")])]):(_vm.isRequirementMarkedByAnyBlock(_vm.srRequirement.id))?_c('v-tooltip',{key:_vm.srRequirement.id + '-highlighted',attrs:{"top":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-n1",attrs:{"data-test-marked-button":_vm.srRequirement.id,"icon":""},on:{"click":function($event){return _vm.selectQmsRequirement(_vm.srRequirement.id)}}},on),[(_vm.isRequirementSelectedByQmsRequirementId)?_c('v-icon',{staticClass:"mb-1",attrs:{"color":"primary"}},[_vm._v("mdi-card-text ")]):_c('v-icon',{staticClass:"mb-1"},[_vm._v("mdi-card-text-outline ")])],1)]}}])},[_c('span',[_vm._v("Highlight linked process blocks.")])]):_vm._e()],1),_c('v-col',{staticClass:"ml-n1 mr-1",attrs:{"cols":"4"}},[(_vm.isRequirementLinkedToImpact(_vm.srRequirement.id))?_c('v-menu',{attrs:{"bottom":"","left":"","z-index":"1000","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","z-index":"1001"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-test-impact-linked":_vm.srRequirement.id}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[(_vm.isRequirementInSelectedImpact(_vm.srRequirement.id))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-flash")]):_c('v-icon',[_vm._v("$coImpactAnalysis")])],1)]}}],null,true)},[_c('span',[_vm._v("Highlight all requirements and process blocks affected by the linked impact analysis.")])])]}}],null,false,1548822569)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.highlightImpact(_vm.impact.id)}}},[_c('a',[_vm._v("Select Impact")])])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.goToImpact()}}},[_c('a',[_vm._v("Open Impact")])])],1),_c('v-list-item',[_c('v-list-item-title',[_c('router-link',{attrs:{"to":("/impact-analysis/" + (_vm.impact.impactAnalysisId) + "#impact=" + (_vm.impact.id)),"target":"_blank"}},[_vm._v(" Open Impact Analysis "),_c('v-icon',{staticClass:"mx-1",attrs:{"right":"","x-small":""}},[_vm._v(" mdi-open-in-new ")])],1)],1)],1)],1)],1):_vm._e()],1),_c('v-col',{staticClass:"d-flex mr-n3",attrs:{"cols":"4"}},[(_vm.isRequirementImplementationChangeable(_vm.srRequirement.id))?_c('v-tooltip',{attrs:{"top":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{staticClass:"ma-0",attrs:{"disabled":!_vm.isQmsAdmin,"input-value":_vm.isRequirementImplemented(_vm.srRequirement.id),"data-test-implemented-checkbox":_vm.srRequirement.id},on:{"change":function($event){return _vm.changeRequirementImplemented({ requirementId: _vm.srRequirement.id, implemented: $event })}}})],1)]}}],null,false,753116992)},[_c('span',[_vm._v("Mark this requirement as implemented in this process.")])]):_vm._e(),(_vm.isRequirementImplementationDeletable(_vm.srRequirement.id))?_c('v-tooltip',{attrs:{"top":"","z-index":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mt-2 ml-n1 align-start",attrs:{"color":"warning","small":"","data-test-warning-deletable":""}},on),[_vm._v(" mdi-alert-outline ")])]}}],null,false,1524975628)},[_c('span',[_vm._v("This process is no longer the target process for the implementation of this requirement.")])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }