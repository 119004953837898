import { Template } from '@/model/Template';
import { SopSearchPart } from '@/model';
import { TemplateSearchServiceMock } from '@/services/template-search-service-mock';
import { restClient } from '@/store/rest-client';
import { SopBlock } from '@/features/sop-block/model';

export type TemplateSearchBySopResult = {
    templates: Template[];
    templateToBlocksVersionIds: { [key: string]: string[] };
    blocks: SopBlock[];
};

export type TemplateSearchAllResult = {
    templates: Template[];
    sops: SopSearchPart[];
    templatesToSopVersionIdsLinkedDirectly: { [key: string]: string[] };
    templatesToSopVersionIdsLinkedByTemplateContent: { [key: string]: string[] };
    templatesToSopArtifactIds: { [key: string]: string[] };
}

export const emptyTemplateSearchResult = (): TemplateSearchBySopResult => {
    return {
        blocks: [],
        templates: [],
        templateToBlocksVersionIds: {},
    }
}

export const emptyTemplateSearchAllResult = (): TemplateSearchAllResult => {
    return {
        templates: [],
        sops: [],
        templatesToSopVersionIdsLinkedDirectly: {},
        templatesToSopVersionIdsLinkedByTemplateContent: {},
        templatesToSopArtifactIds: {},
    }
}

export interface TemplateSearchService {

    searchAllTemplates(): Promise<TemplateSearchAllResult>;

    searchTemplatesForSop(sopVersionId: string): Promise<TemplateSearchBySopResult>;

}

const API_TEMPLATE_CONTENT = '/qms-api/templates/search';

class TemplateSearchServiceBackend implements TemplateSearchService {

    searchAllTemplates(): Promise<TemplateSearchAllResult> {
        return restClient.get<TemplateSearchAllResult>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT)
            .then(response => response.data);
    }

    searchTemplatesForSop(sopVersionId: string): Promise<TemplateSearchBySopResult> {
        const params = { sopVersionId };
        return restClient.get<TemplateSearchBySopResult>(process.env.VUE_APP_QMS_BASE_URL + API_TEMPLATE_CONTENT + '/by-sop-version', { params })
            .then(response => response.data);
    }
}

export const sopTemplatesService: TemplateSearchService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new TemplateSearchServiceMock() : new TemplateSearchServiceBackend();
