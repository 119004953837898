











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { Process } from '@/model/Process';

@Component({
  computed: {
    ...mapState<RootState>({
      processes: (state: RootState) => Object.values(state.sr.processes),
    }),
  }
})
export default class ProcessSelection extends Vue {

  @Prop() selectedProcessIds: string[];
  @Prop({ required: false, default: [] }) allUsedProcessIds: string[];

  set processIds(values: string[]) {
    this.$emit('selected-process-ids', values);
  }

  get processIds(): string[] {
    return this.selectedProcessIds;
  }

  itemText(process: Process): string {
    return process.name + (!this.allUsedProcessIds.includes(process.id) ? ' [not yet linked in a process]' : '');
  }

}
