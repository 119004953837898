import { restClient } from '@/store/rest-client';
import { SopBlockServiceMock } from '@/features/sop-block/sop-block-service-mock';
import { SopBlock, SopBlockWrite } from '@/features/sop-block/model';

export interface SopBlockService {

    createSopBlock(sopVersionId: string, index: number, block: SopBlockWrite): Promise<SopBlock>;

    createNewSopBlockVersion(sopVersionId: string, blockVersionId: string): Promise<SopBlock>;

    loadSopBlock(sopVersionId: string, blockVersionId: string): Promise<SopBlock>;

    updateSopBlock(sopVersionId: string, blockVersionId: string, block: SopBlockWrite): Promise<SopBlock>;

    deleteSopBlock(sopVersionId: string, blockVersionId: string): Promise<void>;

    undoDeletedSopBlock(sopVersionId: string, blockVersionId: string): Promise<void>;

    reorderBlocks(sopVersionId: string, order: string[]): Promise<void>;
}

class SopBlockBackendService implements SopBlockService {

    createSopBlock(sopVersionId: string, index: number, block: SopBlockWrite): Promise<SopBlock> {
        const dto = {
            atIndex: index,
            block,
        };
        return restClient.post<SopBlock>(this.getBlocksUrl(sopVersionId), dto)
            .then(response => response.data);
    }

    createNewSopBlockVersion(sopVersionId: string, blockVersionId: string): Promise<SopBlock> {
        return restClient.post<SopBlock>(this.getBlockVersionUrl(sopVersionId, blockVersionId))
            .then(response => response.data);
    }

    loadSopBlock(sopVersionId: string, blockVersionId: string): Promise<SopBlock> {
        return restClient.get<SopBlock>(this.getBlockVersionUrl(sopVersionId, blockVersionId))
            .then(response => response.data);
    }

    updateSopBlock(sopVersionId: string, blockVersionId: string, block: SopBlockWrite): Promise<SopBlock> {
        return restClient.put<SopBlock>(this.getBlockVersionUrl(sopVersionId, blockVersionId), block)
            .then(response => response.data);
    }

    deleteSopBlock(sopVersionId: string, blockVersionId: string): Promise<void> {
        return restClient.delete<void>(this.getBlockVersionUrl(sopVersionId, blockVersionId))
            .then();
    }

    undoDeletedSopBlock(sopVersionId: string, blockVersionId: string): Promise<void> {
        return restClient.delete<void>(this.getBlocksUrl(sopVersionId) + '/deleted/' + blockVersionId)
            .then();
    }

    reorderBlocks(sopVersionId: string, order: string[]): Promise<void> {
        return restClient.put<void>(process.env.VUE_APP_QMS_BASE_URL + '/qms-api/sops/sop-artifact-uuid/versions/' + sopVersionId + '/blocks/order', order)
            .then();
    }

    getBlocksUrl(sopVersionId: string) {
        return process.env.VUE_APP_QMS_BASE_URL + `/qms-api/sops/sop-artifact-uuid/versions/${ sopVersionId }/blocks`;
    }

    getBlockVersionUrl(sopVersionId: string, versionId?: string) {
        const blocksUrl = this.getBlocksUrl(sopVersionId);
        return `${ blocksUrl }/block-artifact-uuid/versions/${ versionId }`;
    }
}

export const sopBlockService: SopBlockService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new SopBlockServiceMock() : new SopBlockBackendService();
