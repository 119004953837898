var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sopDocumentHistory)?_c('div',{staticClass:"pa-5"},[_c('h2',{staticClass:"co-title"},[_vm._v("Document History")]),(_vm.unreleasedDocumentHistory)?_c('v-alert',{staticClass:"my-2",attrs:{"border":"left","colored-border":"","type":"info","elevation":"2"}},[_vm._v(" There exists an unreleased version "),_c('router-link',{attrs:{"to":("/processes/" + (_vm.unreleasedDocumentHistory.versionId))}},[_vm._v("V"+_vm._s(_vm.unreleasedDocumentHistory.version)+".")])],1):_vm._e(),_c('v-data-table',{staticClass:"co-table",attrs:{"headers":_vm.headers,"items":_vm.sopDocumentHistory,"items-per-page":250,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.version",fn:function(){return undefined},proxy:true},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/processes/" + (item.versionId))}},[_c('span',{attrs:{"data-test-sop-version":item.version}},[_vm._v(_vm._s('V' + item.version))])])]}},{key:"item.releasedAt",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"data-test-sop-released-at":""}},[_vm._v(_vm._s(item.releasedAt.format('DD.MM.YYYY')))])]}},{key:"item.authorDisplayNames",fn:function(ref){
var item = ref.item;
return [_c('ul',{attrs:{"data-test-sop-authors":""}},_vm._l((item.authorDisplayNames),function(authorDisplayName){return _c('li',{key:authorDisplayName,staticClass:"mb-3"},[_vm._v(" "+_vm._s(authorDisplayName)+" ")])}),0)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-pre-line"},[_vm._v(_vm._s(item.description))])]}}],null,false,474866771)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }