














import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import ChangeControlEntry from '@/components/sop-change-control/ChangeControlEntry.vue';

@Component({
  components: { ChangeControlEntry },
  computed: {
    ...mapState<RootState>({
      all: (state: RootState) => state.sopChangeControl.all.filter(cc => cc.state == 'CANCELLED')
    })
  }
})
export default class ChangeControlHistory extends Vue {

}
