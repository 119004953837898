









































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SopActions, SopGetters } from '@/features/sops';
import { ReleaseState, releaseState } from '@/model/ReleaseState';
import SopOrdering from '@/components/sop-overview/SopOrdering.vue';
import { RootState } from '@/store';
import { SopDetailActions, SopDetailHelper } from '@/store/sop-detail';
import SopDetailDialog from '@/components/sop-detail/SopDetailDialog.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';
import { SopSearchPart } from '@/model';

@Component({
  components: { TemplateContentChip, SopDetailDialog, SopOrdering },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
    }),
    ...mapGetters({
      sops: SopGetters.ALL_SOPS,
      potentialParentSops: SopGetters.POTENTIAL_PARENT_SOPS,
      sopLevelByArtifactId: SopGetters.SOP_LEVEL_BY_ARTIFACT_ID,
      templateContentById: TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY,
    })
  },
  methods: {
    ...mapActions({
      loadSop: SopDetailActions.SOP_DETAIL_LOAD,
      sopOrderChange: SopActions.SOP_ORDER_CHANGE,
      ...SopDetailHelper.setterForSopFields('name', 'description'),
      ...SopDetailHelper.setterForSopArtifactFields('parentArtifactId'),
    })
  }
})
export default class SopOverview extends mixins<AuthMixin>(AuthMixin) {

  sops: SopSearchPart[];

  editDialog = false;
  deleteDialog = false;
  orderDialog = false;
  sopVersionId?: string;
  sopParentArtifactId = '';
  newOrderedArtifactIds: string[] = [];

  get headers() {
    const baseHeaders = [
      { text: 'Name', value: 'name', width: '20%', cellClass: 'test-class-column-name' },
      { text: 'Purpose', value: 'description', width: '30%', sortable: true },
      { text: 'Type', value: 'requirementType', width: '10%' },
      { text: 'Version', value: 'version', width: '10%' },
      { text: 'Process Output', value: 'templateContentIds', width: '22%', sortable: false },
    ];
    if (this.isQmsAdmin) {
      baseHeaders.push({ text: '', value: 'actions', width: '8%', sortable: false });
    }
    return baseHeaders;
  }

  goToDetail(sopId: string): void {
    this.$router.push({ name: 'SopDetailView', params: { id: sopId } });
  }

  canBeEdited(sopReleaseState: ReleaseState): boolean {
    return releaseState(sopReleaseState).canBeDeleted();
  }

  openDeleteDialog(sopId: string) {
    this.sopVersionId = sopId;
    this.deleteDialog = true;
  }

  confirmDelete() {
    this.$store.dispatch(SopActions.SOP_DELETE, this.sopVersionId)
        .then(() => this.closeDeleteDialog());
  }

  closeDeleteDialog() {
    this.sopVersionId = undefined;
    this.deleteDialog = false;
  }

  openOrderDialog(selectedArtifactId: string) {
    this.sopParentArtifactId = this.$store.state.sops.sopParentArtifactIds[selectedArtifactId] || '';
    this.orderDialog = true;
  }

  closeOrder() {
    this.sopParentArtifactId = '';
    this.newOrderedArtifactIds = [];
    this.orderDialog = false;
  }
}

