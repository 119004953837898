import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { EMPTY_PROCESS, Process } from '@/model/Process';
import { srService } from '@/services/sr-service';
import { EMPTY_SR_PROCESS_CONTENT, EMPTY_SR_TEMPLATE_CONTENT, SrTemplateContent } from '@/features/sr-model/SrTemplateContent';
import { SrSyncService } from '@/services/sr-sync-service';
import { templateContentService } from '@/features/template-content/template-content-service';
import { TemplateContentActions } from '@/features/template-content/template-content-store';
import { EMPTY_SR_ROLE, SrRole } from '@/features/sr-model/SrRole';
import { UiFeedback } from '@/store/ui-feedback';
import { safeVueSet } from '@/utils/util';

export type SrProcessMap = { [key: string]: Process };
export type SrTemplateContentMap = { [key: string]: SrTemplateContent };
export type SrProcessContentMap = { [key: string]: SrTemplateContent };
export type SrRoleMap = { [key: string]: SrRole };

enum StateFields {
    SR_PROCESSES = 'processes',
    SR_TEMPLATE_CONTENTS = 'templateContents',
    SR_PROCESS_CONTENTS = 'processContents',
    SR_ROLES = 'roles',
}

export class SrState {
    [StateFields.SR_PROCESSES]: SrProcessMap = {};
    [StateFields.SR_TEMPLATE_CONTENTS]: SrTemplateContentMap = {};
    [StateFields.SR_PROCESS_CONTENTS]: SrProcessContentMap = {};
    [StateFields.SR_ROLES]: SrRoleMap = {};
}

export enum SrGetters {
    SR_PROCESS_BY_ID = 'SR_PROCESS_BY_ID',
    SR_TEMPLATE_CONTENT_BY_ID = 'SR_TEMPLATE_CONTENT_BY_ID',
    SR_PROCESS_CONTENT_BY_ID = 'SR_PROCESS_CONTENT_BY_ID',
    SR_ROLE_BY_ID = 'SR_ROLE_BY_ID',
    SR_VIEW_ALLOWED_BY_REGULATION_ID = 'SR_VIEW_ALLOWED_BY_REGULATION_ID',
}

export enum SrActions {
    SR_LOAD = 'SR_LOAD',
    SR_TEMPLATE_CONTENTS_LOAD = 'SR_TEMPLATE_CONTENTS_LOAD',
    SR_PROCESS_CONTENTS_LOAD = 'SR_PROCESS_CONTENTS_LOAD',
    SR_ROLES_LOAD = 'SR_ROLES_LOAD',
    SYNCHRONIZE_TEMPLATE_CONTENTS_WITH_SR = 'SYNCHRONIZE_TEMPLATE_CONTENTS_WITH_SR'
}

enum Mutations {
    SR_PROCESSES_REPLACE = 'SR_PROCESSES_REPLACE',
    SR_TEMPLATE_CONTENTS_REPLACE = 'SR_TEMPLATE_CONTENTS_REPLACE',
    SR_PROCESS_CONTENTS_REPLACE = 'SR_PROCESS_CONTENTS_REPLACE',
    SR_ROLES_REPLACE = 'SR_ROLES_REPLACE'
}

const getters = {
    [SrGetters.SR_PROCESS_BY_ID]: (state: SrState) => (processId: string) => state.processes[processId] || EMPTY_PROCESS,
    [SrGetters.SR_PROCESS_CONTENT_BY_ID]: (state: SrState) => (processContentId: string) => {
        if (!processContentId || processContentId.length === 0) {
            return EMPTY_SR_PROCESS_CONTENT;
        }
        return state[StateFields.SR_PROCESS_CONTENTS][processContentId] || { ...EMPTY_SR_PROCESS_CONTENT, id: processContentId, name: 'Loading...' }
    },
    [SrGetters.SR_TEMPLATE_CONTENT_BY_ID]: (state: SrState) => (templateContentId: string) => {
        if (!templateContentId || templateContentId.length === 0) {
            return EMPTY_SR_TEMPLATE_CONTENT;
        }
        return state[StateFields.SR_TEMPLATE_CONTENTS][templateContentId] || { ...EMPTY_SR_TEMPLATE_CONTENT, id: templateContentId, name: 'Loading...' }
    },
    [SrGetters.SR_PROCESS_CONTENT_BY_ID]: (state: SrState) => (processContentId: string) => {
        if (!processContentId || processContentId.length === 0) {
            return EMPTY_SR_TEMPLATE_CONTENT;
        }
        return state[StateFields.SR_PROCESS_CONTENTS][processContentId] || { ...EMPTY_SR_PROCESS_CONTENT, id: processContentId, name: 'Loading...' }
    },
    [SrGetters.SR_ROLE_BY_ID]: (state: SrState) => (roleId: string) => {
        if (!roleId || roleId.length === 0) {
            return EMPTY_SR_ROLE;
        }
        return state[StateFields.SR_ROLES][roleId] || { ...EMPTY_SR_ROLE, id: roleId, name: 'Loading...' }
    },
}

const actions = {
    [SrActions.SR_LOAD]: ({ commit, dispatch }: ActionContext<SrState, RootState>) =>
        srService.loadProcesses()
            .then(srProcessMap => commit(Mutations.SR_PROCESSES_REPLACE, srProcessMap))
            .catch(err => UiFeedback.showError(dispatch, 'Processes from SR could not be fetched.', err)),
    [SrActions.SR_TEMPLATE_CONTENTS_LOAD]: ({ commit, dispatch }: ActionContext<SrState, RootState>) =>
        srService.loadTemplateContents()
            .then(srTemplateContentMap => commit(Mutations.SR_TEMPLATE_CONTENTS_REPLACE, srTemplateContentMap))
            .catch(err => UiFeedback.showError(dispatch, 'Template Contents from SR could not be fetched.', err)),
    [SrActions.SR_PROCESS_CONTENTS_LOAD]: ({ commit, dispatch }: ActionContext<SrState, RootState>) =>
        srService.loadProcessContents()
            .then(srProcessContentMap => commit(Mutations.SR_PROCESS_CONTENTS_REPLACE, srProcessContentMap))
            .catch(err => UiFeedback.showError(dispatch, 'Process Contents from SR could not be fetched.', err)),
    [SrActions.SR_ROLES_LOAD]: ({ commit, dispatch }: ActionContext<SrState, RootState>) =>
        srService.loadRoles()
            .then(srRoleMap => commit(Mutations.SR_ROLES_REPLACE, srRoleMap))
            .catch(err => UiFeedback.showError(dispatch, 'Roles from SR could not be fetched.', err)),
    [SrActions.SYNCHRONIZE_TEMPLATE_CONTENTS_WITH_SR]: ({ dispatch }: ActionContext<SrState, RootState>) =>
        SrSyncService.syncTemplateContents(templateContentService, srService)
            .then(() => dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH)),
}

const mutations = {
    [Mutations.SR_PROCESSES_REPLACE]: (state: SrState, srProcessMap: SrProcessMap) => safeVueSet(state, StateFields.SR_PROCESSES, srProcessMap),
    [Mutations.SR_TEMPLATE_CONTENTS_REPLACE]: (state: SrState, srTemplateContents: SrTemplateContentMap) => safeVueSet(state, StateFields.SR_TEMPLATE_CONTENTS, srTemplateContents),
    [Mutations.SR_PROCESS_CONTENTS_REPLACE]: (state: SrState, srProcessContents: SrTemplateContentMap) => safeVueSet(state, StateFields.SR_PROCESS_CONTENTS, srProcessContents),
    [Mutations.SR_ROLES_REPLACE]: (state: SrState, srRoles: SrRoleMap) => safeVueSet(state, StateFields.SR_ROLES, srRoles)
}

export const SR_MODULE: Module<SrState, RootState> = {
    state: new SrState(),
    getters,
    actions,
    mutations
};
