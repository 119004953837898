export function equalsIgnoreCase(stringA: string, stringB: string) {
    if (stringA === stringB) {
        return true;
    }
    if (!stringA || !stringB) {
        return false;
    }
    return stringA.toLowerCase() === stringB.toLowerCase();
}

export function toFirstUpperCase(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function toHumanReadable(input: string): string {
    return input.toLocaleLowerCase().replace(/[_-]/g, ' ');
}