








import Vue from 'vue';
import Component from 'vue-class-component';
import { Requirement, RequirementContentType } from '@/features/sr-model/Requirement';
import { Prop } from 'vue-property-decorator';

function convert(headings: IndexedHeading[], parentId?: string): Item[] {
  if (!headings) {
    return [];
  }
  return headings
      .filter(e => e.parentId === parentId)
      .map(h => ({
        name: h.text,
        key: h.key,
        children: convert(headings, h.id),
        index: h.index
      }));
}

interface Item {
  key: string;
  name: string;
  children: Item[];
  index: number;
}

type IndexedHeading = Requirement & { index: number };

@Component
export default class RegulationToc extends Vue {

  @Prop()
  requirements: Requirement[];

  scrollToRequirementKey(requirementKey = '') {
    this.$emit('requirement-selected', requirementKey);
  }

  get items() {
    const headings: IndexedHeading[] = [];
    this.requirements.forEach((r: Requirement, index: number) => {
      if (r.type === RequirementContentType.HEADING) {
        headings.push({ ...r, index });
      }
    });
    return convert(headings);
  }
}

