


























import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import QmsRequirementCurrent from '@/components/common/QmsRequirementCurrent.vue';
import { RootState } from '@/store';
import QmsRequirementApplicabilityComponent from '@/components/common/QmsRequirementApplicabilityComponent.vue';
import { QmsRequirement } from '@/services/model';

@Component({
  components: { QmsRequirementApplicabilityComponent, QmsRequirementCurrent },
  computed: {
    ...mapState<RootState>({
      isImpactMode: (state: RootState) => state.impacts.isImpactMode,
      fromRegulationRequirements: (state: RootState) => state.impacts.fromRegulationRequirements,
    }),
  }
})
export default class ImpactEntryFromRequirementViewPart extends mixins<ImpactsMixin>(ImpactsMixin) {


  @Prop()
  requirement: Requirement;

  fromRegulationRequirements: RequirementMap;

  interpretedInRequirementInfo(qmsRequirement: QmsRequirement): string {
    if (!qmsRequirement.interpretedInRequirementId) {
      throw new Error('QmsRequirement has no expected interpretedInRequirementId');
    }
    const requirement = this.fromRegulationRequirements[qmsRequirement.interpretedInRequirementId];
    const infoText = ((requirement.paragraph ?? '') + ' ' + requirement.text).trim();
    return infoText.substr(0, Math.min(infoText.length, 50)) + (infoText.length > 50 ? '...' : '');
  }

}
