


























import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import ProductRequirements from '@/features/product-requirements/ProductRequirements.vue';
import { SopBlockListGetters } from '@/features/sop-block/sop-block-list/store';
import { RequirementSearchResult, RequirementSearchResultEntry } from '@/features/sr-requirement-search/model/RequirementSearch';
import { SrRegulation } from '@/features/regulations/model';
import { ProductRequirementsGetters } from '@/features/product-requirements/product-requirements-store';

@Component({
  components: { ProductRequirements },
  computed: {
    ...mapGetters({
      selectedSopBlock: SopBlockListGetters.SELECTED_SOP_BLOCK,
      hasViewRightsByRegulationId: ProductRequirementsGetters.HAS_SR_VIEW_RIGHTS_BY_REGULATION_ID,
    }),
    ...mapState<RootState>({
      searchResult: (state: RootState) => state.productRequirements.requirementSearchResult,
      selectedSopBlockVersionId: (state: RootState) => state.sopBlockList.selectedSopBlockVersionId,
    }),
  },
})
export default class ProductRegulations extends Vue {

  private searchResult: RequirementSearchResult;

  requirementsByRegulation(regulation: SrRegulation): RequirementSearchResultEntry[] {
    return this.searchResult.requirements.filter(req => req.regulationId === regulation.id);
  }
}
