






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { BaseChart } from 'vue-chartjs/types/components';
import { ChartData } from 'chart.js';
import { Doughnut } from 'vue-chartjs';

export interface DoughnutChartData {
  title: string;
  entries: { label: string; value: number; color: string }[];
}

@Component({ components: { Doughnut } })
export default class DoughnutChart extends Vue {
  @Prop({ required: true })
  chartData: DoughnutChartData;

  mounted() {
    (this.$refs['chart'] as BaseChart).renderChart(this.convertChartData(), { maintainAspectRatio: false });
  }

  @Watch('chartData')
  onChartData() {
    if (this.$refs['chart']) {
      (this.$refs['chart'] as BaseChart).renderChart(this.convertChartData(), { maintainAspectRatio: false });
    }
  }

  private convertChartData(): ChartData {
    return {
      labels: this.chartData.entries.map(d => d.label),
      datasets: [{
        label: '',
        backgroundColor: this.chartData.entries.map(d => d.color),
        data: this.chartData.entries.map(d => d.value),
      }],
    };
  }
}
