import Vue from 'vue';
import { TemplateGetters, TemplateState } from '@/store/templates/templates';
import { Template } from '@/model/Template';
import { ActionContext } from 'vuex';
import { RootState } from '@/store';
import { templateService } from '@/services/template-service';
import { releaseState, ReleaseState } from '@/model/ReleaseState';
import { UiFeedback } from '@/store/ui-feedback';
import { AuthGetters } from '@/store/auth';
import { setterForFields } from '@/utils/VuexUtil';
import { safeVueSet } from '@/utils/util';

export enum TemplateDetailActions {
    TEMPLATE_DETAIL_LOAD = 'TEMPLATE_DETAIL_LOAD',
    TEMPLATE_DETAIL_SAVE = 'TEMPLATE_DETAIL_SAVE',
    TEMPLATE_DETAIL_RELEASE_STATE_CHANGE = 'TEMPLATE_DETAIL_RELEASE_STATE_CHANGE',
    TEMPLATE_DETAIL_CREATE_NEW_VERSION = 'TEMPLATE_DETAIL_CREATE_NEW_VERSION',
    TEMPLATE_DETAIL_RESET = 'TEMPLATE_DETAIL_RESET',
    TEMPLATE_DETAIL_CANCEL = 'TEMPLATE_DETAIL_CANCEL',
}

export enum TemplateDetailEvents {
    TEMPLATE_DETAIL_CHANGED = 'TEMPLATE_DETAIL_CHANGED',
}

export enum TemplateDetailGetters {
    TEMPLATE_IS_EDITABLE = 'TEMPLATE_IS_EDITABLE',
    TEMPLATE_CURRENT_RELEASE_STATE = 'TEMPLATE_CURRENT_RELEASE_STATE',
}

export enum TemplateDetailMutations {
    TEMPLATE_DETAIL_REPLACE = 'TEMPLATE_DETAIL_REPLACE',
    TEMPLATE_DETAIL_DELETE = 'TEMPLATE_DETAIL_DELETE',
    TEMPLATE_DETAIL_FIELD_REPLACE = 'TEMPLATE_DETAIL_FIELD_REPLACE'
}

export class TemplateDetailHelper {

    static setterForTemplateFields(...fields: Array<keyof Template>) {
        return setterForFields(TemplateDetailMutations.TEMPLATE_DETAIL_FIELD_REPLACE, fields);
    }

}

export const templateDetailGetters = {
    [TemplateDetailGetters.TEMPLATE_IS_EDITABLE]: (state: TemplateState, _2: TemplateGetters, _1: RootState, rootGetters: any) =>
        state.template && state.template.releaseState === 'DRAFT' && rootGetters[AuthGetters.IS_QMS_ADMIN],
    [TemplateDetailGetters.TEMPLATE_CURRENT_RELEASE_STATE]: (state: TemplateState) => releaseState(state.template.releaseState),
};

export const templateDetailActions = {
    [TemplateDetailActions.TEMPLATE_DETAIL_LOAD]: ({ commit, dispatch }: ActionContext<TemplateState, RootState>, templateVersionId: string) =>
        templateService.loadTemplate(templateVersionId)
            .then(template => {
                commit(TemplateDetailMutations.TEMPLATE_DETAIL_REPLACE, template);
                return dispatch(TemplateDetailEvents.TEMPLATE_DETAIL_CHANGED, template);
            })
            .catch(err => UiFeedback.showError(dispatch, `Template detail for ${ templateVersionId } could not be fetched.`, err)),
    [TemplateDetailActions.TEMPLATE_DETAIL_CANCEL]: ({ state, dispatch }: ActionContext<TemplateState, RootState>) =>
        dispatch(TemplateDetailActions.TEMPLATE_DETAIL_LOAD, state.template.versionId),
    [TemplateDetailActions.TEMPLATE_DETAIL_SAVE]: ({ commit, state, dispatch }: ActionContext<TemplateState, RootState>) =>
        templateService.updateTemplate(state.template)
            .then(template => commit(TemplateDetailMutations.TEMPLATE_DETAIL_REPLACE, template))
            .catch(err => {
                UiFeedback.showError(dispatch, `Template detail for ${ state.template.versionId } could not be updated.`, err);
                return dispatch(TemplateDetailActions.TEMPLATE_DETAIL_LOAD, state.template.versionId);
            }),
    [TemplateDetailActions.TEMPLATE_DETAIL_RELEASE_STATE_CHANGE]: ({ state, dispatch }: ActionContext<TemplateState, RootState>, nextState: ReleaseState) =>
        templateService.changeReleaseState(state.template, nextState)
            .then(() => dispatch(TemplateDetailActions.TEMPLATE_DETAIL_LOAD, state.template.versionId))
            .catch(err => {
                UiFeedback.showError(dispatch, `Template couldn't change release-state`, err);
                return dispatch(TemplateDetailActions.TEMPLATE_DETAIL_LOAD, state.template.versionId);
            }),
    [TemplateDetailActions.TEMPLATE_DETAIL_CREATE_NEW_VERSION]: ({ commit, state, dispatch }: ActionContext<TemplateState, RootState>) =>
        templateService.createNewVersion(state.template)
            .then(newTemplateVersion => {
                commit(TemplateDetailMutations.TEMPLATE_DETAIL_REPLACE, newTemplateVersion);
                return newTemplateVersion;
            })
            .catch(err => {
                UiFeedback.showError(dispatch, `Template couldn't create / load release-state`, err);
                return dispatch(TemplateDetailActions.TEMPLATE_DETAIL_LOAD, state.template.versionId);
            }),
    [TemplateDetailActions.TEMPLATE_DETAIL_RESET]: ({ commit, dispatch }: ActionContext<TemplateState, RootState>) => {
        commit(TemplateDetailMutations.TEMPLATE_DETAIL_DELETE);
        return dispatch(TemplateDetailEvents.TEMPLATE_DETAIL_CHANGED);
    },
};

export const templateDetailMutations = {
    [TemplateDetailMutations.TEMPLATE_DETAIL_REPLACE]: (state: TemplateState, template: Template) =>
        safeVueSet(state, 'template', template),
    [TemplateDetailMutations.TEMPLATE_DETAIL_DELETE]: (state: TemplateState) =>
        Vue.delete(state, 'template'),
    [TemplateDetailMutations.TEMPLATE_DETAIL_FIELD_REPLACE]: (state: TemplateState, template: Partial<Template>) => {
        if (!state.template) {
            safeVueSet(state, 'template', {});
        }
        Object.assign(state.template, template);
    },
};
