

































































import Vue from 'vue';
import Component from 'vue-class-component';
import { SopDetailActions, SopDetailHelper } from '@/store/sop-detail';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopGetters } from '@/features/sops';
import EditorWrapper from '@/components/common/EditorWrapper.vue';
import RequirementSearch from '@/features/sr-requirement-search/RequirementSearch.vue';
import { Prop } from 'vue-property-decorator';
import FabButton from '@/components/common/FabButton.vue';
import FileUpload from '@/components/common/FileUpload.vue';
import { createForSop, FileStorage } from '@/model/FileStorage';
import { SopSearchPart } from '@/model';

@Component({
  components: { FabButton, FileUpload, RequirementSearch, EditorWrapper },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
      sopArtifact :(state: RootState) => state.sopDetail.sopArtifact,
      sopParentArtifactIds: (state: RootState) => state.sops.sopParentArtifactIds,
      templateContents: (state: RootState) => Object.values(state.templateContents.templateContents),
    }),
    ...mapGetters({
      potentialParentSops: SopGetters.POTENTIAL_PARENT_SOPS,
    }),
  },
  methods: {
    ...mapMutations({
      ...SopDetailHelper.setterForSopFields('name', 'description', 'sopAsPdf', 'templateContentIds'),
      ...SopDetailHelper.setterForSopArtifactFields('parentArtifactId'),
    })
  }
})
export default class SopDetailDialog extends Vue {

  @Prop({ default: true }) showName: boolean;
  @Prop({ default: true }) showSopAsPdf: boolean;

  potentialParentSops: (artifactId: string) => SopSearchPart;
  editDialog = false;

  get fileStorage(): FileStorage {
    const sopVersionId = this.$store.state.sopDetail.sop.versionId;
    return createForSop(sopVersionId);
  }

  save() {
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_SAVE)
        .then(() => this.editDialog = false);
  }

  cancel() {
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_CANCEL)
        .then(() => this.editDialog = false);
  }

}
