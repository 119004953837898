export interface Role {
    id: string;
    name: string;
    responsibility: string;
    authority: string;
    requirements: string;
    srId?: string;
}

export function createEmptyRole(): Role {
    return {
        id: '',
        name: '',
        responsibility: '',
        authority: '',
        requirements: '',
    };
}

export const NEW_ROLE_ID = 'NEW_ROLE_ID';

export function createNewRole(): Role {
    return { ...createEmptyRole(), id: NEW_ROLE_ID };
}