













import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import RequirementListEntry from '@/components/regulations-detail/RequirementListEntry.vue';
import { VirtualList } from 'vue-virtual-scroll-list';
import { RootState } from '@/store';
import { Watch } from 'vue-property-decorator';
import TimingMixin from '@/components/common/TimingMixin.vue';

@Component({
  components: { VirtualList, RequirementListEntry },
  computed: {
    ...mapState<RootState>({
      isLoaded: (state: RootState) => !!state.regulationDetail.regulation,
    }),
    ...mapGetters({
      requirements: RegulationDetailGetters.FILTERED_REQUIREMENTS,
      scrollIndex: RegulationDetailGetters.ANCHORED_REQUIREMENT_INDEX
    })
  }
})
export default class RequirementList extends mixins<TimingMixin>(TimingMixin) {

  static SHORT_CONTENT = 0;
  static NORMAL_CONTENT = 50;
  static LONG_CONTENT = 300;
  static VERY_LONG_CONTENT = 900;
  static VERY_VERY_LONG_CONTENT = 1500;
  static VERY_VERY_VERY_LONG_CONTENT = 2500;

  initialScrollIndex = 0;
  requirementComp = RequirementListEntry;

  @Watch('scrollIndex')
  scrollToIndex() {
    const scrollIndex = (this as any).scrollIndex;
    this.$store.dispatch(RegulationDetailActions.ANCHORED_REQUIREMENT_KEY_UPDATE, '');
    if (scrollIndex < 0) {
      return;
    }
    [RequirementList.SHORT_CONTENT, RequirementList.NORMAL_CONTENT, RequirementList.LONG_CONTENT, RequirementList.VERY_LONG_CONTENT, RequirementList.VERY_VERY_LONG_CONTENT, RequirementList.VERY_VERY_VERY_LONG_CONTENT]
        .forEach(timeout => this.createTimeout(() => this.scrollToIndexNow(scrollIndex), timeout));

  }

  scrollToIndexNow(scrollIndex: number) {
    if (this.$refs.vsl && scrollIndex >= 0) {
      (this.$refs.vsl as any).scrollToIndex(scrollIndex)
    }
  }

}
