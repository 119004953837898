

































import Component from 'vue-class-component';
import Vue from 'vue'
import { mapState } from 'vuex';
import { RootState } from '@/store';

@Component({
  computed: {
    ...mapState<RootState>({
      terms: (state: RootState) => state.terms.allTerms,
    })
  }
})
export default class TermsOverview extends Vue {

  headers = [
    { text: 'Term', value: 'term.term', width: '20%' },
    { text: 'Abbreviation', value: 'term.abbreviation', width: '10%' },
    { text: 'Definition', value: 'term.definition', width: '50%' },
    { text: 'Defined in Process', value: 'latestSop.name', width: '20%' }
  ];

  searchTerm = '';

}

