import { User } from '@/model/User';
import { UserService } from '@/services/user-service';
import { UserMap } from '@/store/users';
import { toMap } from '@/utils/util';

export class UserServiceMock implements UserService {

    static users: User[] = [];

    loadUsers(): Promise<UserMap> {
        return Promise.resolve(toMap(
            [...UserServiceMock.users], t => t.userId ? t.userId : ''));
    }

    syncUsers(): Promise<void> {
        return Promise.resolve();
    }

}