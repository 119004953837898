import { Module } from 'vuex';
import { RootState } from '@/store';
import 'firebase/auth';
import { authService } from '@/services/auth-service';
import { restClient } from '@/store/rest-client';
import { userService } from '@/services/user-service';
import { safeVueSet } from '@/utils/util';

export enum UserRoles {SR_LICENSED = 'sr-licensed'}

export interface User {
    email: string;
    userId: string;
    userName: string;
    userInitials: string;
    tenantId: string;
    tenantName: string;
    roles: string[];
}

export class AuthState {
    user: User;
    error: string;
    tenantLogoSrc = '';
}

export enum AuthActions {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED',
}

export enum AuthGetters {
    IS_QMS_ADMIN = 'IS_QMS_ADMIN',
    IS_LOGGED_IN = 'IS_LOGGED_IN',
    ERROR = 'ERROR',
    CURRENT_USER_NAME = 'CURRENT_USER_NAME',
}

export enum Mutations {
    REPLACE_USER = 'REPLACE_USER',
    ERROR = 'ERROR',
    REPLACE_TENANT_LOGO_SRC = 'REPLACE_TENANT_LOGO_SRC',
}

export const AUTH_MODULE: Module<AuthState, RootState> = {
    state: new AuthState(),
    getters: {
        [AuthGetters.IS_QMS_ADMIN]: state => state.user && state.user.roles.includes('qms-admin'),
        [AuthGetters.IS_LOGGED_IN]: state => !!state.user,
        [AuthGetters.ERROR]: state => state.error,
        [AuthGetters.CURRENT_USER_NAME]: state => state.user.userName,
    },
    actions: {
        [AuthActions.LOGIN]: ({ commit }, { email, password }: { email: string; password: string }) =>
            authService.loginWithEmailAndPassword(email, password)
                .then(() => userService.syncUsers())
                .then(() => commit(Mutations.ERROR, undefined))
                .catch(error => commit(Mutations.ERROR, error))
        ,
        [AuthActions.LOGOUT]: ({ commit }) => {
            commit(Mutations.REPLACE_USER, undefined);
            return Promise.all([
                restClient.delete<void>(process.env.VUE_APP_QMS_BASE_URL + '/qms-api/doc-access'),
                authService.logout()
            ]);
        },
        [AuthActions.AUTH_STATE_CHANGED]: ({ commit }, currentUser: User) => {
            commit(Mutations.REPLACE_USER, currentUser);
            if (currentUser && process.env.VUE_APP_PROD_BACKEND !== 'false') {
                return restClient.get<any>(process.env.VUE_APP_QMS_BASE_URL + '/qms-api/tenant-config/logo', { responseType: 'blob' })
                    .then(response => commit(Mutations.REPLACE_TENANT_LOGO_SRC, URL.createObjectURL(response.data)));
            }
            return Promise.resolve();
        },
    },
    mutations: {
        [Mutations.REPLACE_USER]: (state, user: User) => safeVueSet(state, 'user', user),
        [Mutations.ERROR]: (state, error: string) => safeVueSet(state, 'error', error),
        [Mutations.REPLACE_TENANT_LOGO_SRC]: (state, tenantLogoSrc: string) => safeVueSet(state, 'tenantLogoSrc', tenantLogoSrc),
    }
};
