import { render, staticRenderFns } from "./ImpactQmsRequirementApplicability.vue?vue&type=template&id=1fe75db4&scoped=true&"
import script from "./ImpactQmsRequirementApplicability.vue?vue&type=script&lang=ts&"
export * from "./ImpactQmsRequirementApplicability.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe75db4",
  null
  
)

export default component.exports