import { checkUndefined, toMap, toMapWithValue } from '@/utils/util';
import { v4 as uuidv4 } from 'uuid';
import { CreateSop, EMPTY_SOP, Sop, SopArtifact, SopArtifactAndVersion, SopParentArtifactMap } from '@/model/Sop';
import { LoadSopsResult, SopArtifactSummaries, SopArtifactSummary, SopDocumentHistory, SopOrderChange, SopOverallStatistics, SopService } from '@/services/sop-service';
import { ReleaseState } from '@/model/ReleaseState';
import moment from 'moment';
import { TemplateContentServiceMock } from '@/features/template-content/template-content-service-mock';
import { createEmptySopBlock, SopBlock } from '@/features/sop-block/model';
import { SopBlockServiceMock } from '@/features/sop-block/sop-block-service-mock';

export const SOP_A_ARTIFACT_ID = 'af5f8123-33d1-4551-ba08-9bae241fae14';
export const SOP_B_ARTIFACT_ID = 'bf5f8123-33d1-4551-ba08-9bae241fae14';
export const SOP_C_ARTIFACT_ID = 'cf5f8123-33d1-4551-ba08-9bae241fae14';
export const SOP_D_ARTIFACT_ID = 'df5f8123-33d1-4551-ba08-9bae241fae14';
export const SOP_E_ARTIFACT_ID = 'ef5f8123-33d1-4551-ba08-9bae241fae14';
export const SOP_F_ARTIFACT_ID = 'ff5f8123-33d1-4551-ba08-9bae241fae14';
export const SOP_G_ARTIFACT_ID = 'gf5f8123-33d1-4551-ba08-9bae241fae14';

export class SopServiceMock implements SopService {

    static sopArtifacts: SopArtifact[];
    static sops: Sop[];

    public static initMock() {

        SopServiceMock.sopArtifacts = [
            { requirementType: 'QMS', artifactId: SOP_A_ARTIFACT_ID },
            { requirementType: 'QMS', artifactId: SOP_B_ARTIFACT_ID, parentArtifactId: SOP_A_ARTIFACT_ID },
            { requirementType: 'QMS', artifactId: SOP_C_ARTIFACT_ID, parentArtifactId: SOP_B_ARTIFACT_ID },
            { requirementType: 'QMS', artifactId: SOP_D_ARTIFACT_ID },
            { requirementType: 'QMS', artifactId: SOP_E_ARTIFACT_ID, parentArtifactId: SOP_D_ARTIFACT_ID },
            { requirementType: 'QMS', artifactId: SOP_F_ARTIFACT_ID, parentArtifactId: SOP_D_ARTIFACT_ID },
            { requirementType: 'QMS', artifactId: SOP_G_ARTIFACT_ID },
        ];

        SopServiceMock.sops = [
            {
                ...EMPTY_SOP,
                artifactId: SOP_A_ARTIFACT_ID,
                versionId: 'ad1d6344-c733-40ac-8fc9-90093b1410d5',
                name: 'Sop A',
                description: 'Desc A',
                templateContentIds: [
                    TemplateContentServiceMock.TEMPLATE_CONTENT_1,
                    TemplateContentServiceMock.TEMPLATE_CONTENT_2
                ],
                sopBlocks: [
                    {
                        ...createEmptySopBlock(),
                        artifactId: SopBlockServiceMock.SOP_BLOCK_6_ARTIFACT_ID,
                        versionId: SopBlockServiceMock.SOP_BLOCK_6_VERSION_ID,
                        sopArtifactId: SOP_A_ARTIFACT_ID,
                        name: 'Block 6',
                        description: 'This is block 6',
                    }
                ]
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_B_ARTIFACT_ID,
                versionId: 'bd1d6344-c733-40ac-8fc9-90093b1410d5',
                name: 'Sop B',
                description: 'SOP B with linked processes',
                srProcessIds: ['b3d8253c-94bc-45d5-9185-acca3383f5d0']
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_C_ARTIFACT_ID,
                versionId: 'cd1d6344-c733-40ac-8fc9-90093b1410d5',
                releaseState: 'RELEASED',
                name: 'Sop C',
                description: 'SOP C with two linked processes, but on one process (the first one) links to a not-existing content',
                srProcessIds: ['d3d8253c-94bc-45d5-9185-acca3383f5d0', 'b3d8253c-94bc-45d5-9185-acca3383f5d0'],
                sopBlocks: [
                    {
                        ...createEmptySopBlock(),
                        artifactId: SopBlockServiceMock.SOP_BLOCK_1_ARTIFACT_ID,
                        versionId: SopBlockServiceMock.SOP_BLOCK_1_VERSION_ID,
                        sopArtifactId: SOP_C_ARTIFACT_ID,
                        name: 'Block 1',
                        description: 'This is block 1'
                    },
                    {
                        ...createEmptySopBlock(),
                        artifactId: SopBlockServiceMock.SOP_BLOCK_2_ARTIFACT_ID,
                        versionId: SopBlockServiceMock.SOP_BLOCK_2_VERSION_ID,
                        sopArtifactId: SOP_C_ARTIFACT_ID,
                        name: 'Block 2',
                        description: 'This is block 2'
                    },
                    {
                        ...createEmptySopBlock(),
                        artifactId: SopBlockServiceMock.SOP_BLOCK_3_ARTIFACT_ID,
                        versionId: SopBlockServiceMock.SOP_BLOCK_3_VERSION_ID,
                        sopArtifactId: SOP_C_ARTIFACT_ID,
                        name: 'Block 3',
                        description: 'This is block 3'
                    },
                    {
                        ...createEmptySopBlock(),
                        artifactId: SopBlockServiceMock.SOP_BLOCK_4_ARTIFACT_ID,
                        versionId: SopBlockServiceMock.SOP_BLOCK_4_VERSION_ID,
                        sopArtifactId: SOP_C_ARTIFACT_ID,
                        name: 'Block 4',
                        description: 'This is block 4'
                    }
                ],
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_C_ARTIFACT_ID,
                versionId: 'cc5f09ea-09ce-45e0-9757-87a588f76db4',
                name: 'Sop C (draft version)',
                description: 'Sop C (draft version)',
                srProcessIds: ['d3d8253c-94bc-45d5-9185-acca3383f5d0', 'b3d8253c-94bc-45d5-9185-acca3383f5d0'],
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_D_ARTIFACT_ID,
                versionId: 'dd1d6344-c733-40ac-8fc9-90093b1410d5',
                name: 'Sop D',
                description: 'SOP D with two linked processes, but one process (the first one) does not exist',
                srProcessIds: ['00000000-94bc-45d5-9185-acca3383f5d0', 'b3d8253c-94bc-45d5-9185-acca3383f5d0']
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_E_ARTIFACT_ID,
                versionId: 'ed1d6344-c733-40ac-8fc9-90093b1410d5',
                name: 'Sop E',
                description: 'SOP E',
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_F_ARTIFACT_ID,
                versionId: 'fd1d6344-c733-40ac-8fc9-90093b1410d5',
                name: 'Sop F',
                description: 'SOP F',
                sopBlocks: [
                    {
                        ...createEmptySopBlock(),
                        artifactId: SopBlockServiceMock.SOP_BLOCK_5_ARTIFACT_ID,
                        versionId: SopBlockServiceMock.SOP_BLOCK_5_VERSION_ID,
                        sopArtifactId: SOP_F_ARTIFACT_ID,
                        type: 'DRAWIO',
                        name: 'Block 5 - Diagram',
                        description: '<svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="20"/></svg>'
                    }
                ],
            },
            {
                ...EMPTY_SOP,
                artifactId: SOP_G_ARTIFACT_ID,
                versionId: 'gd1d6344-c733-40ac-8fc9-90093b1410d5',
                releaseState: 'RELEASED',
                name: 'Sop G',
                description: 'SOP G',
            },
        ];
    }

    loadSopDetail(sopVersionId: string): Promise<SopArtifactAndVersion> {
        if (!SopServiceMock.sops) {
            SopServiceMock.initMock();
        }
        const sop = checkUndefined(SopServiceMock.sops.find(sop => sop.versionId === sopVersionId));
        const sopArtifact = checkUndefined(SopServiceMock.sopArtifacts.find(artifact => artifact.artifactId === sop.artifactId));
        return Promise.resolve({ sopArtifact, sop: { ...sop } });
    }

    loadSops(): Promise<LoadSopsResult> {
        if (!SopServiceMock.sops) {
            SopServiceMock.initMock();
        }
        const sopMap = toMap([...SopServiceMock.sops.map(sop => ({ ...sop }))], sop => sop.versionId);
        const orderedSopArtifactIds = SopServiceMock.sops.map(sop => sop.artifactId).filter((item, i, ar) => ar.indexOf(item) === i);
        const parentArtifactIds: SopParentArtifactMap = toMapWithValue(
            SopServiceMock.sopArtifacts.filter(a => !!a.parentArtifactId),
            sop => sop.artifactId,
            sop => checkUndefined(sop.parentArtifactId));
        return Promise.resolve({ sopMap, orderedSopArtifactIds, parentArtifactIds, allLinkedSrProcessIds: [] });
    }

    statistics(): Promise<SopOverallStatistics> {
        return Promise.reject('not implemented yet');
    }


    createSop(create: CreateSop): Promise<SopArtifactAndVersion> {
        const newSop = {
            ...EMPTY_SOP,
            ...create,
            versionId: uuidv4(),
            artifactId: uuidv4(),
            parentArtifactId: undefined,
        }
        const newArtifact = { artifactId: newSop.artifactId, parentArtifactId: create.parentArtifactId, requirementType: create.requirementType };

        SopServiceMock.sopArtifacts.push(newArtifact);
        SopServiceMock.sops.push(newSop);

        return Promise.resolve({
            sopArtifact: { ...newArtifact },
            sop: { ...newSop },
        });
    }

    updateSop(artifact: SopArtifact, sop: Sop): Promise<SopArtifactAndVersion> {
        SopServiceMock.assertSopInDraft(sop.versionId);

        const sopIndex = SopServiceMock.sops.map(sop => sop.versionId).indexOf(sop.versionId);
        if (sopIndex < 0) {
            return Promise.reject('Can\'t find the editing SOP by its version id');
        }
        SopServiceMock.sops.splice(sopIndex, 1, { ...sop });

        const artifactIndex = SopServiceMock.sopArtifacts.map(artifact => artifact.artifactId).indexOf(artifact.artifactId);
        if (artifactIndex < 0) {
            return Promise.reject('Can\'t find the editing SOP by its version id');
        }
        SopServiceMock.sopArtifacts.splice(artifactIndex, 1, { ...artifact });

        return Promise.resolve({
            sopArtifact: { ...artifact },
            sop: { ...sop },
        });
    }

    sopOrderChange(sopOrderChange: SopOrderChange): Promise<void> {
        const newOrderedSops: Sop[] = sopOrderChange.orderedArtifactIds.flatMap(artifactId => SopServiceMock.sops.filter(sop => sop.artifactId == artifactId));
        const otherSops = SopServiceMock.sops.filter(sop => !sopOrderChange.orderedArtifactIds.includes(sop.artifactId));
        const parentSopIndex = otherSops.map(sop => sop.artifactId).indexOf(sopOrderChange.parentArtifactId);
        if (parentSopIndex < 0) {
            return Promise.reject('Can\'t find the SOP by its version id');
        }
        otherSops.splice(parentSopIndex + 1, 0, ...newOrderedSops)
        SopServiceMock.sops = otherSops;
        return Promise.resolve();
    }

    deleteSop(sopVersionId: string): Promise<void> {
        SopServiceMock.assertSopInDraft(sopVersionId);
        const index = SopServiceMock.sops.map(sop => sop.versionId).indexOf(sopVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the deleting SOP by its version id');
        }
        SopServiceMock.sops.splice(index, 1);
        return Promise.resolve();
    }

    changeReleaseState(sop: Sop, nextState: ReleaseState): Promise<void> {
        SopServiceMock.findSopByVersionId(sop.versionId).releaseState = nextState;
        return Promise.resolve();
    }

    createNewVersion(sop: Sop): Promise<Sop> {
        const releasedSop = SopServiceMock.sops.find(s => s.artifactId === sop.artifactId);
        if (!releasedSop) {
            throw new Error('Sop with following artifact not found: ' + sop.artifactId);
        }
        const sopBlocks: SopBlock[] = releasedSop.sopBlocks.map(b => ({
            ...b,
            versionId: uuidv4(),
            version: b.version + 1,
            versionState: 'UNCHANGED'
        }));
        const newSop = {
            ...releasedSop,
            versionId: uuidv4(),
            version: sop.version + 1,
            releaseState: EMPTY_SOP.releaseState,
            sopBlocks,
        };
        SopServiceMock.sops.push({ ...newSop });
        return Promise.resolve({ ...newSop });
    }

    exportPdf(): Promise<void> {
        return Promise.reject('not implemented');
    }
    
    simplifiedHtml(): Promise<string> {
        return Promise.reject('not implemented');
    }

    loadSopDocumentHistory(sopArtifactId: string): Promise<SopDocumentHistory> {
        if (sopArtifactId === 'gf5f8123-33d1-4551-ba08-9bae241fae14') {
            return Promise.resolve({
                released: [
                    { version: 1, versionId: 'b84ba4aa-9ae8-4634-acc9-45686edd5b5e', changeDescription: 'initial', authorDisplayNames: ['author-1', 'author-2'], releasedAt: moment('01.06.2021', 'DD.MM.YYYY') },
                    { version: 2, versionId: '7bf1a4f2-7923-4e35-9c63-2a00bc3ab4ea', changeDescription: 'second', authorDisplayNames: ['author-1'], releasedAt: moment() },
                    { version: 3, versionId: '29a1b101-dde6-406f-b734-0bb5ae8d3d0c', changeDescription: 'third', authorDisplayNames: ['author-3'], releasedAt: moment() }
                ],
                unreleased: { version: 4, versionId: '29a1b101-dde6-406f-b734-0bb5ae8d3d0c', changeDescription: 'third', authorDisplayNames: ['author-3'], releasedAt: moment() },
            });
        } else if (sopArtifactId === 'ff5f8123-33d1-4551-ba08-9bae241fae14') {
            return Promise.resolve({
                released: [
                    { version: 1, versionId: 'b84ba4aa-9ae8-4634-acc9-45686edd5b5e', changeDescription: 'initial', authorDisplayNames: ['author-1', 'author-2'], releasedAt: moment('01.06.2021', 'DD.MM.YYYY') },
                    { version: 2, versionId: '7bf1a4f2-7923-4e35-9c63-2a00bc3ab4ea', changeDescription: 'second', authorDisplayNames: ['author-1'], releasedAt: moment() },
                    { version: 3, versionId: '29a1b101-dde6-406f-b734-0bb5ae8d3d0c', changeDescription: 'third', authorDisplayNames: ['author-3'], releasedAt: moment() }
                ]
            });
        }
        return Promise.resolve({
            released: [],
        });
    }

    loadSopSummaries(artifactIds: string[]): Promise<SopArtifactSummaries> {
        return Promise.resolve({ summaries: artifactIds.map(id => SopServiceMock.fakeSummary(id)) });
    }

    asCsv(): Promise<Blob> {
        return Promise.resolve(new Blob());
    }

    private static fakeSummary(artifactId: string): SopArtifactSummary {
        return {
            artifactId,
            name: `sop with name ${ artifactId }`,
            latestVersions: []
        };
    }

    static findSopByVersionId(sopVersionId: string): Sop {
        const sop = SopServiceMock.sops.find(sop => sop.versionId === sopVersionId);
        if (!sop) {
            throw new Error('Sop with following version not found: ' + sopVersionId);
        }
        return sop;
    }

    static assertSopInDraft(sopVersionId: string) {
        if (this.findSopByVersionId(sopVersionId).releaseState !== 'DRAFT') {
            throw new Error(`Sop ${ sopVersionId } must be in DRAFT to be editable`);
        }
    }

}
