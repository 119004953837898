












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TemplateGetters } from '@/store/templates';

@Component({
  computed: {
    ...mapGetters({
      validTemplateArtifacts: TemplateGetters.VALID_TEMPLATE_ARTIFACTS,
    })
  }
})
export default class TemplateArtifactSelection extends Vue {

  @Prop() selectedTemplateArtifactIds: string[];

  set templateArtifactIds(values: string[]) {
    this.$emit('selected-template-artifact-ids', values);
  }

  get templateArtifactIds(): string[] {
    return this.selectedTemplateArtifactIds;
  }

}
