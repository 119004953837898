import { Product } from '@/features/sop-block/model';
import { restClient } from '@/store/rest-client';

export interface ProductsService {

    all(): Promise<Product[]>;
}

class ProductsBackendService implements ProductsService {
    all(): Promise<Product[]> {
        return restClient.get<Product[]>(`${ process.env.VUE_APP_QMS_BASE_URL }/qms-api/products`)
            .then(resp => resp.data);
    }
}

export const productsService: ProductsService = new ProductsBackendService();