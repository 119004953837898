














































import Component, { mixins } from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { Watch } from 'vue-property-decorator';
import TimingMixin from '@/components/common/TimingMixin.vue';
import CoHorizontalBar, { CoHorizontalBarConfig } from '@/components/common/CoHorizontalBar.vue';
import GapStatisticEvidence from '@/features/gap-list/components/GapStatisticEvidence.vue';
import GapStatisticApplicability from '@/features/gap-list/components/GapStatisticApplicability.vue';
import { ImpactAnalysisStatistics } from '@/features/impact-analysis/model';
import { createEmptyQmsRegulationStatistics } from '@/features/regulations/model';
import { QmsRequirement } from '@/services/model';

@Component({
  components: { GapStatisticApplicability, GapStatisticEvidence, CoHorizontalBar },
  computed: {
    ...mapState<RootState>({
      qmsRegulationStatistics: (state: RootState) => {
        if (!state.impacts.toRegulation) {
          return createEmptyQmsRegulationStatistics();
        }
        return state.regulations.qmsRegulationStatistics[state.impacts.toRegulation.id] ?? createEmptyQmsRegulationStatistics();
      },
      impactStatistics: (state: RootState) => state.impacts.statistics,
      qmsRequirements: (state: RootState) => Object.values(state.impacts.toQmsRequirements),
      totalRequirements: (state: RootState) => Object.keys(state.impacts.toRegulationRequirements).length,
      selectedWidget: (state: RootState) => state.uiLayout.sidebarRightSelectedWidgetName,
    }),
  }
})
export default class ImpactsStatisticWidget extends mixins<TimingMixin>(TimingMixin) {

  loaded = false;
  selectedWidget: string;
  qmsRequirements: QmsRequirement[];
  impactStatistics: ImpactAnalysisStatistics;

  @Watch('selectedWidget', { immediate: true })
  onSelectedWidgetChanged() {
    if (this.selectedWidget === 'ImpactsStatisticWidget') {
      this.createTimeout(() => this.loaded = true, 500);
    } else {
      this.loaded = false;
    }
  }

  get statsMappedFromRequirements(): CoHorizontalBarConfig[] {
    return [
      {
        label: 'Mapped',
        count: this.impactStatistics.numberOfMappedFromRequirements,
        color: '#69c88c',
      }, {
        label: 'Not Mapped',
        count: this.impactStatistics.totalNumberOfFromRequirements - this.impactStatistics.numberOfMappedFromRequirements,
        color: '#f49f0a',
      }
    ];
  }

  get statsMappedToRequirements(): CoHorizontalBarConfig[] {
    return [
      {
        label: 'Mapped',
        count: this.impactStatistics.numberOfMappedToRequirements,
        color: '#69c88c',
      }, {
        label: 'Not Mapped',
        count: this.impactStatistics.totalNumberOfToRequirements - this.impactStatistics.numberOfMappedToRequirements,
        color: '#f49f0a',
      }
    ];
  }
}
