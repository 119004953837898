































import Component from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { SopDetailGetters } from '@/store/sop-detail';
import SopBlockEntry from '@/features/sop-block/sop-block-list/SopBlockEntry.vue';
import draggable from 'vuedraggable';
import { RootState } from '@/store';
import { releaseState } from '@/model/ReleaseState';
import FabButton from '@/components/common/FabButton.vue';
import { SopBlockListActions, SopBlockListGetters } from '@/features/sop-block/sop-block-list/store';
import Vue from 'vue';
import AddSopBlockButtons from '@/features/sop-block/sop-block-list/AddSopBlockButtons.vue';

@Component({
  components: { AddSopBlockButtons, FabButton, draggable, SopBlockEntry },
  computed: {
    ...mapState<RootState>({
      isInWork: (state: RootState) => state.sopDetail.sop && releaseState(state.sopDetail.sop.releaseState).isInWork(),
      deletedBlockVersionIds: (state: RootState) => Object.values(state.sopDetail.blocksDeleted).map(block => block.versionId)
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE
    })
  },
  methods: {
    ...mapActions({
      reorderBlocks: SopBlockListActions.SOP_BLOCK_LIST_REORDER_BY_VERSION_IDS,
    }),
  },
})
export default class SopBlockList extends Vue {

  reorderBlocks: (blockVersionIds: string[]) => Promise<void>;

  get orderedBlockVersionIds() {
    const orderedBlockVersionIds = this.getOrderedBlockVersionIds();
    if (!orderedBlockVersionIds) {
      return [];
    }
    // workaround for issue https://github.com/SortableJS/Vue.Draggable/issues/603
    (this.$refs as any).draggable?.computeIndexes();
    return orderedBlockVersionIds;
  }

  set orderedBlockVersionIds(orderedBlockVersionIds: string[]) {
    this.reorderBlocks(orderedBlockVersionIds);
  }

  getOrderedBlockVersionIds() {
    return this.$store.getters[SopBlockListGetters.ORDERED_BLOCK_VERSION_IDS];
  }
}
