















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ChangeControl } from '@/model';
import ParticipantList from '@/components/sop-change-control/ParticipantList.vue';

@Component({
  components: { ParticipantList }
})
export default class ChangeControlEntry extends Vue {

  @Prop()
  changeControl: ChangeControl;
}
