





































import Component, { mixins } from 'vue-class-component';
import { CreateSop, RequirementType, allRequirementTypes, SopSearchPart } from '@/model';
import { SopActions, SopGetters } from '@/features/sops';
import { mapGetters } from 'vuex';
import CoHeader from '@/components/common/CoHeader.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';

@Component({
  components: { CoHeader: CoHeader },
  computed: {
    ...mapGetters({
      potentialParentSops: SopGetters.POTENTIAL_PARENT_SOPS,
    }),
  }
})
export default class NewSopDialog extends mixins<AuthMixin>(AuthMixin) {
  static emptySop: CreateSop = { name: '', description: '', requirementType: 'QMS', version: 1 };

  rules = [
    (value: string) => (!!value && value.length >= 1) || 'Minimum 1 character',
  ];

  versionNumberRule = (value: string) => {
    if (Number.parseInt(value).toString() == value.toString() && Number.parseInt(value) > 0) {
      return true;
    }
    return 'Initial Version must be a positive number.';
  };

  get allRequirementTypes(): RequirementType[] {
    return allRequirementTypes();
  }

  potentialParentSops: () => SopSearchPart;
  createDialog = false;
  valid = false;
  sop: CreateSop = { ...NewSopDialog.emptySop };

  confirmSave() {
    this.$store.dispatch(SopActions.SOP_CREATE, this.sop)
        .then(() => this.cancel());
  }

  cancel() {
    this.sop = { ...NewSopDialog.emptySop };
    this.createDialog = false;
  }

}
