
























import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import SopHierarchy from '@/features/sops/sop-hierarchiy/SopHierarchy.vue';


@Component({
  components: { SopHierarchy },
  computed: {
    ...mapState<RootState>({
      selectedSopArtifactId: (state: RootState) => state.sopDetail.sop ? state.sopDetail.sop.artifactId : '',
    })
  }
})
export default class SopNavigationTree extends Vue {

}
