



























import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopDetailActions, SopDetailGetters } from '@/store/sop-detail';
import UserSelection from '@/components/common/UserSelection.vue';
import ChangeControlDraft from '@/components/sop-change-control/ChangeControlDraft.vue';
import ChangeControlInRelease from '@/components/sop-change-control/ChangeControlInRelease.vue';
import ChangeControlReleased from '@/components/sop-change-control/ChangeControlReleased.vue';
import ChangeControlHistory from '@/components/sop-change-control/ChangeControlHistory.vue';
import ChangeDescription from '@/components/sop-change-control/ChangeDescription.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';

@Component({
  components: { ChangeDescription, ChangeControlHistory, ChangeControlReleased, ChangeControlInRelease, ChangeControlDraft, UserSelection },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop
    }),
    ...mapGetters({
      currentReleaseState: SopDetailGetters.CURRENT_RELEASE_STATE,
      editable: SopDetailGetters.SOP_IS_EDITABLE
    }),
  },
  methods: {
    ...mapActions({
      createNewVersion: SopDetailActions.SOP_DETAIL_CREATE_NEW_VERSION
    })
  }
})
export default class SopChangeControl extends mixins<AuthMixin>(AuthMixin) {

}
