import { ActionContext, Commit, Dispatch, Module } from 'vuex';

import { RootState } from '@/store';
import { Comment, RefType } from '@/features/comments/model';
import { commentService } from '@/features/comments/comment-service';
import { UiFeedback } from '@/store/ui-feedback';
import { UiLayoutActions, UiLayoutEvents } from '@/store/ui-layout';
import { checkUndefined, safeVueSet } from '@/utils/util';
import { SopDetailEvents } from '@/store/sop-detail/sop-detail-events';
import { ArtifactChangedEvent } from '@/model/Events';

export type CommentRef = {
    refId: string;
    refType: RefType;
    parentRefId?: string;
    parentRefType?: RefType;
}

function createCommentRef(refId = '', refType: RefType = 'NA'): CommentRef {
    return { refId, refType };
}

export type ChildrenCommentCount = { [key: string]: number };

export class CommentsWidgetState {
    isWidgetActive = false;
    commentRef: CommentRef = createCommentRef();
    comments: Comment[] = [];
    childrenCommentCount: ChildrenCommentCount = {};
}

export enum CommentsWidgetActions {
    COMMENTS_LOAD_AND_ACTIVATE_WIDGET = 'COMMENTS_LOAD_AND_ACTIVATE_WIDGET',
    COMMENTS_LOAD_FOR_PARENT = 'COMMENTS_LOAD_FOR_PARENT',
    COMMENTS_LOAD_FOR_REF = 'COMMENTS_LOAD_FOR_REF',
    COMMENTS_LOAD_CHILDREN_COUNT = 'COMMENTS_LOAD_CHILDREN_COUNT',
    COMMENT_CREATE = 'COMMENT_CREATE',
    COMMENT_UPDATE = 'COMMENT_UPDATE',
    COMMENT_DELETE = 'COMMENT_DELETE',
}

export enum CommentsWidgetGetters {
    REF_ID_HAS_ANY_COMMENTS = 'REF_ID_HAS_ANY_COMMENTS',
}

enum Mutations {
    COMMENTS_WIDGET_IS_ACTIVE_REPLACE = 'COMMENTS_WIDGET_IS_ACTIVE_REPLACE',
    COMMENT_REF_REPLACE = 'COMMENT_REF_REPLACE',
    COMMENTS_REPLACE = 'COMMENTS_REPLACE',
    CHILDREN_COMMENT_COUNT_REPLACE = 'CHILDREN_COMMENT_COUNT_REPLACE',
}

const getters = {
    [CommentsWidgetGetters.REF_ID_HAS_ANY_COMMENTS]: (state: CommentsWidgetState) => (childRefId: string) => {
        const commentCount = state.childrenCommentCount[childRefId] || 0;
        return commentCount > 0;
    },
};

function loadChildrenCommentCount(parentRefId: string | undefined, commit: Commit) {
    if (!parentRefId) {
        return Promise.resolve();
    }
    return commentService.loadChildrenCommentCount(parentRefId)
        .then(childrenCommentCount => commit(Mutations.CHILDREN_COMMENT_COUNT_REPLACE, childrenCommentCount));
}

function reloadComments(state: CommentsWidgetState, dispatch: Dispatch, commit: Commit) {
    return Promise.all([
        dispatch(CommentsWidgetActions.COMMENTS_LOAD_FOR_REF, state.commentRef),
        loadChildrenCommentCount(state.commentRef.parentRefId, commit)
    ]);
}

const actions = {
    [CommentsWidgetActions.COMMENTS_LOAD_AND_ACTIVATE_WIDGET]: ({ state, commit, dispatch }: ActionContext<CommentsWidgetState, RootState>, commentReference: CommentRef) => {
        commit(Mutations.COMMENT_REF_REPLACE, commentReference);
        if (state.isWidgetActive) {
            return dispatch(CommentsWidgetActions.COMMENTS_LOAD_FOR_REF, commentReference);
        }
        return dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, 'CommentsWidget');
    },
    [CommentsWidgetActions.COMMENTS_LOAD_FOR_PARENT]: ({ state, commit, dispatch }: ActionContext<CommentsWidgetState, RootState>) => {
        if (state.commentRef.refId.length === 0 || !state.commentRef.parentRefId) {
            return Promise.resolve();
        }
        return Promise.all([
            dispatch(CommentsWidgetActions.COMMENTS_LOAD_FOR_REF, createCommentRef(state.commentRef.parentRefId, state.commentRef.parentRefType)),
            loadChildrenCommentCount(state.commentRef.parentRefId, commit)
        ]);
    },
    [CommentsWidgetActions.COMMENTS_LOAD_FOR_REF]: ({ state, commit, dispatch }: ActionContext<CommentsWidgetState, RootState>, commentReference: CommentRef) => {
        return commentService.loadCommentsForReference(commentReference.refId)
            .then(comments => {
                commit(Mutations.COMMENT_REF_REPLACE, commentReference);
                commit(Mutations.COMMENTS_REPLACE, comments);
            })
            .catch(err => UiFeedback.showAndThrowError(dispatch, `Comments could not be loaded. Please try again.`, err));
    },
    [CommentsWidgetActions.COMMENTS_LOAD_CHILDREN_COUNT]: ({ commit }: ActionContext<CommentsWidgetState, RootState>, commentReference: CommentRef) => {
        return loadChildrenCommentCount(commentReference.refId, commit);
    },
    [CommentsWidgetActions.COMMENT_CREATE]: ({ state, dispatch, commit }: ActionContext<CommentsWidgetState, RootState>, comment: string) => {
        if (state.commentRef.refId.length === 0) {
            return Promise.reject('Can not create comment for unspecific reference');
        }
        return commentService.createCommentForReference({
            comment,
            refId: state.commentRef.refId,
            refType: state.commentRef.refType,
            parentRefId: state.commentRef.parentRefId,
            parentRefType: state.commentRef.parentRefType
        })
            .then(() => reloadComments(state, dispatch, commit))
            .catch(err => UiFeedback.showAndThrowError(dispatch, `Comment could not be created. Please try again.`, err));
    },
    [CommentsWidgetActions.COMMENT_UPDATE]: ({ state, dispatch, commit }: ActionContext<CommentsWidgetState, RootState>, comment: Comment) => {
        return commentService.updateComment(comment)
            .then(() => reloadComments(state, dispatch, commit))
            .catch(err => UiFeedback.showAndThrowError(dispatch, `Comment could not be updated. Please try again.`, err));
    },
    [CommentsWidgetActions.COMMENT_DELETE]: ({ state, dispatch, commit }: ActionContext<CommentsWidgetState, RootState>, comment: Comment) => {
        return commentService.deleteComment(checkUndefined(comment.id))
            .then(() => reloadComments(state, dispatch, commit))
            .catch(err => UiFeedback.showAndThrowError(dispatch, `Comment could not be deleted. Please try again.`, err));
    },
};

const eventListeners = {
    [UiLayoutEvents.SIDEBAR_RIGHT_SELECTION_CHANGED]: ({ state, commit, dispatch }: ActionContext<CommentsWidgetState, RootState>, selectedWidgetName: string) => {
        const isWidgetActive = selectedWidgetName === 'CommentsWidget'; // TODO: introduce enum for widgets
        if (isWidgetActive === state.isWidgetActive) {
            return Promise.resolve();
        }
        commit(Mutations.COMMENTS_WIDGET_IS_ACTIVE_REPLACE, isWidgetActive);
        return dispatch(CommentsWidgetActions.COMMENTS_LOAD_FOR_REF, state.commentRef);
    },
    [SopDetailEvents.SOP_DETAIL_CHANGED]: ({ state, dispatch, commit }: ActionContext<CommentsWidgetState, RootState>, sopDetailChangedEvent: ArtifactChangedEvent) => {
        if (!sopDetailChangedEvent) {
            commit(Mutations.COMMENT_REF_REPLACE, createCommentRef());
            commit(Mutations.CHILDREN_COMMENT_COUNT_REPLACE, {});
            return Promise.resolve();
        }
        const loadCommentsPromise = state.isWidgetActive
            ? dispatch(CommentsWidgetActions.COMMENTS_LOAD_FOR_REF, createCommentRef(sopDetailChangedEvent.artifactId, 'SOP_ARTIFACT'))
            : Promise.resolve();
        commit(Mutations.COMMENT_REF_REPLACE, createCommentRef(sopDetailChangedEvent.artifactId, 'SOP_ARTIFACT'));
        return Promise.all([loadCommentsPromise, loadChildrenCommentCount(sopDetailChangedEvent.artifactId, commit)]);
    },
};

const mutations = {
    [Mutations.COMMENT_REF_REPLACE]: (state: CommentsWidgetState, commentReference: CommentRef) => {
        safeVueSet(state, 'commentRef', commentReference);
        safeVueSet(state, 'comments', []);
    },
    [Mutations.COMMENTS_WIDGET_IS_ACTIVE_REPLACE]: (state: CommentsWidgetState, isWidgetActive: boolean) => {
        safeVueSet(state, 'isWidgetActive', isWidgetActive);
    },
    [Mutations.COMMENTS_REPLACE]: (state: CommentsWidgetState, comments: Comment[]) => {
        safeVueSet(state, 'comments', comments);
    },
    [Mutations.CHILDREN_COMMENT_COUNT_REPLACE]: (state: CommentsWidgetState, childrenCommentCount: ChildrenCommentCount) => {
        safeVueSet(state, 'childrenCommentCount', childrenCommentCount);
    },
};

export const COMMENT_MODULE: Module<CommentsWidgetState, RootState> = {
    state: new CommentsWidgetState(),
    getters,
    actions: { ...actions, ...eventListeners },
    mutations
};