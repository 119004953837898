















import { Component, Vue } from 'vue-property-decorator';
import CoLayout from '@/layouts/CoLayout.vue';
import TermsOverview from '@/components/terms-overview/TermsOverview.vue';
import { TermActions } from '@/store/terms';
import CoHeader from '@/components/common/CoHeader.vue';

@Component({
  components: { CoHeader, TermsOverview, CoLayout },
})
export default class TermsOverviewView extends Vue {

  created() {
    this.$store.dispatch(TermActions.TERMS_LOAD);
  }

}
