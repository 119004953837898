






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SrProcessAndRequirements from '@/components/sop-detail/SrProcessAndRequirements.vue';
import SopChangeControl from '@/components/sop-change-control/SopChangeControl.vue';
import SopDocumentHistory from '@/components/sop-detail/SopDocumentHistory.vue';
import SopTemplatesWidget from '@/components/sop-templates/SopTemplatesWidget.vue';
import GapListWidget from '@/features/gap-list/gap-list-widget/GapListWidget.vue';
import TemplateContentDetailWidget from '@/features/template-content/template-content-detail-widget/TemplateContentDetailWidget.vue';
import GapStatisticWidget from '@/components/regulations-detail/GapStatisticWidget.vue';
import CommentsWidget from '@/features/comments/components/CommentsWidget.vue';
import ImpactDetailWidget from '@/features/impacts/components/ImpactDetailWidget.vue';
import ImpactsStatisticWidget from '@/features/impacts/components/ImpactsStatisticWidget.vue';
import SopTemplateContentWidget from '@/features/template-content/sop-template-content-widget/SopTemplateContentWidget.vue';
import ProductRequirementWidget from '@/features/product-requirements/ProductRequirementWidget.vue';

@Component({
  components: {
    SopTemplatesWidget,
    GapListWidget,
    SrProcessAndRequirements,
    SopChangeControl,
    GapStatisticWidget,
    SopDocumentHistory,
    SopTemplateContentWidget,
    TemplateContentDetailWidget,
    CommentsWidget,
    ImpactDetailWidget,
    ImpactsStatisticWidget,
    ProductRequirementWidget
  },
})
export default class SidebarRight extends Vue {

  @Prop() rightSidebarComponent: string;

}
