














import Vue from 'vue';
import Component from 'vue-class-component';
import CoHeader from '@/components/common/CoHeader.vue';
import Dashboard from '@/features/dashboard/Dashboard.vue';
import { mapGetters } from 'vuex';
import { AuthGetters } from '@/store/auth';
import CoLayout from '@/layouts/CoLayout.vue';

@Component({
  components: {
    CoLayout,
    CoHeader,
    Dashboard,
  },
  computed: {
    ...mapGetters({
      name: AuthGetters.CURRENT_USER_NAME
    })
  },
})
export default class DashboardView extends Vue {

}
