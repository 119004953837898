import { ActionContext, Dispatch, Module } from 'vuex';
import { RootState } from '@/store';
import { safeVueSet } from '@/utils/util';

export enum UiFeedbackActions {
    ERROR = 'ERROR',
    INFO = 'INFO'
}

export type FeedbackType = 'ERROR' | 'INFO'

export class UiFeedback {
    type: FeedbackType;
    time: Date;
    message: string;
    detail: string;

    public static showAndThrowError(dispatch: Dispatch, message: string, error: Error = new Error()): void {
        UiFeedback.showError(dispatch, message, error);
        throw error
    }

    public static showError(dispatch: Dispatch, message: string, error: Error): Promise<any> {
        const detail = (error as any)?.response?.data?.errorMessage;
        const errorMessage: UiMessage = { message, detail, error };
        return dispatch(UiFeedbackActions.ERROR, errorMessage);
    }

    public static showMessage(dispatch: Dispatch, message: string): Promise<any> {
        const infoMessage: UiMessage = { message, detail: '', error: undefined };
        return dispatch(UiFeedbackActions.INFO, infoMessage);
    }
}

export class UiMessage {
    message: string;
    detail: string;
    error?: Error | string;
}

export class UiFeedbackState {
    current: UiFeedback = new UiFeedback();
}

export enum Mutations {
    UI_FEEDBACKS_CURRENT_REPLACE = 'UI_FEEDBACKS_CURRENT_REPLACE',
}

const getters = {}

const actions = {
    [UiFeedbackActions.ERROR]: ({ commit }: ActionContext<UiFeedbackState, RootState>, errorMessage: UiMessage) => {
        console.error(errorMessage.message, errorMessage);
        const detailFromError = typeof errorMessage.error === 'string' ? errorMessage.error : `${ errorMessage?.error?.name }: ${ errorMessage?.error?.message }`;
        const detail = errorMessage.detail ? errorMessage.detail : detailFromError;
        return commit(Mutations.UI_FEEDBACKS_CURRENT_REPLACE, {
            type: 'ERROR',
            time: new Date(),
            message: errorMessage.message,
            detail
        })
    },
    [UiFeedbackActions.INFO]: ({ commit }: ActionContext<UiFeedbackState, RootState>, errorMessage: UiMessage) => {
        return commit(Mutations.UI_FEEDBACKS_CURRENT_REPLACE, {
            type: 'INFO',
            time: new Date(),
            message: errorMessage.message
        })
    }
}

const mutations = {
    [Mutations.UI_FEEDBACKS_CURRENT_REPLACE]: (state: UiFeedbackState, uiFeedbacks: UiFeedback) =>
        safeVueSet(state, 'current', uiFeedbacks),
}

export const UI_FEEDBACK_MODULE: Module<UiFeedbackState, RootState> = {
    state: new UiFeedbackState(),
    getters,
    actions,
    mutations
};