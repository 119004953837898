




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Sop } from '@/model';
import SopClipboardSection from '@/components/sop-detail/SopClipboardSection.vue';
import { TermMap } from '@/store/sop-detail';
import { sopService } from '@/services/sop-service';

@Component({
  components: { SopClipboardSection }
})
export default class SopClipboardView extends Vue {

  @Prop({ required: true })
  sop: Sop;
  @Prop({ required: true })
  terms: TermMap;

  simplifiedSop = '';

  @Watch('sop', { immediate: true })
  loadSimplifedSop() {
    if (this.sop.versionId !== '') {
      sopService.simplifiedHtml(this.sop).then(result => this.simplifiedSop = result);
    }
  }
}
