import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { safeVueSet } from '@/utils/util';
import { sopBlockService } from '@/features/sop-block/sop-block-service';
import { UiFeedback } from '@/store/ui-feedback';
import { SopDetailEvents } from '@/store/sop-detail/sop-detail-events';
import { SopBlockListEvents, SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { SopBlockDetailEvents } from '@/features/sop-block/sop-block-detail/sop-block-detail-events';
import { setterForFields } from '@/utils/VuexUtil';
import { ArtifactChangedEvent } from '@/model/Events';
import { createEmptySopBlock, SopBlock } from '@/features/sop-block/model';

export class SopBlockDetailState {
    selectedVersionId = '';
    selectedSopBlock: SopBlock = createEmptySopBlock();
}

export enum SopBlockDetailGetter {
}

export enum SopBlockDetailActions {
    SOP_BLOCK_DETAIL_SAVE = 'SOP_BLOCK_DETAIL_SAVE',
    SOP_BLOCK_DETAIL_SAVE_AND_CLOSE = 'SOP_BLOCK_DETAIL_SAVE_AND_CLOSE',
    SOP_BLOCK_DETAIL_CANCEL = 'SOP_BLOCK_DETAIL_CANCEL',
}

export enum SopBlockDetailMutations {
    SOP_BLOCK_DETAIL_ID_REPLACE = 'SOP_BLOCK_DETAIL_ID_REPLACE',
    SOP_BLOCK_DETAIL_REPLACE = 'SOP_BLOCK_DETAIL_REPLACE',
    SOP_BLOCK_DETAIL_FIELD_REPLACE = 'SOP_BLOCK_DETAIL_FIELD_REPLACE',
    SOP_BLOCK_DETAIL_RESET = 'SOP_BLOCK_DETAIL_RESET',
}

export class SopBlockDetailHelper {

    static setterForSopBlockFields(...fields: Array<keyof SopBlock>) {
        return setterForFields<SopBlock>(SopBlockDetailMutations.SOP_BLOCK_DETAIL_FIELD_REPLACE, fields);
    }

}

const getters = {};

const actions = {
    [SopBlockDetailActions.SOP_BLOCK_DETAIL_SAVE]: ({ commit, dispatch, state, rootState }: ActionContext<SopBlockDetailState, RootState>) => {
        return sopBlockService.updateSopBlock(rootState.sopDetail.sop.versionId, state.selectedVersionId, state.selectedSopBlock)
            .then((updatedBlock) => commit(SopBlockDetailMutations.SOP_BLOCK_DETAIL_REPLACE, updatedBlock))
            .catch(err => UiFeedback.showAndThrowError(dispatch, 'Could not update sop-block with version-id: ' + state.selectedVersionId, err));
    },
    [SopBlockDetailActions.SOP_BLOCK_DETAIL_SAVE_AND_CLOSE]: ({ dispatch }: ActionContext<SopBlockDetailState, RootState>) => {
        return dispatch(SopBlockDetailActions.SOP_BLOCK_DETAIL_SAVE)
            .then(() => dispatch(SopBlockDetailActions.SOP_BLOCK_DETAIL_CANCEL));
    },
    [SopBlockDetailActions.SOP_BLOCK_DETAIL_CANCEL]: ({ commit, dispatch }: ActionContext<SopBlockDetailState, RootState>) => {
        commit(SopBlockDetailMutations.SOP_BLOCK_DETAIL_RESET);
        return dispatch(SopBlockDetailEvents.SOP_BLOCK_DETAIL_CLOSED)
    }
};

const eventListeners = {
    [SopBlockListEvents.SOP_BLOCK_SELECTED]: ({ commit, dispatch, rootState }: ActionContext<SopBlockDetailState, RootState>, blockSelectionEvent: SopBlockSelectedEvent) => {
        if (!blockSelectionEvent.forEditing) {
            return Promise.resolve();
        }
        commit(SopBlockDetailMutations.SOP_BLOCK_DETAIL_ID_REPLACE, blockSelectionEvent);
        return sopBlockService.loadSopBlock(rootState.sopDetail.sop.versionId, blockSelectionEvent.versionId)
            .then(sopBlock => commit(SopBlockDetailMutations.SOP_BLOCK_DETAIL_REPLACE, sopBlock))
            .catch(err => UiFeedback.showError(dispatch, 'Could not load sop-block with version-id: ' + blockSelectionEvent.versionId, err));
    },
    [SopDetailEvents.SOP_DETAIL_CHANGED]: ({ commit }: ActionContext<SopBlockDetailState, RootState>) => {
        commit(SopBlockDetailMutations.SOP_BLOCK_DETAIL_RESET);
        return Promise.resolve();
    },
}

const mutations = {
    [SopBlockDetailMutations.SOP_BLOCK_DETAIL_ID_REPLACE]: (state: SopBlockDetailState, blockSelectionEvent: ArtifactChangedEvent) => {
        safeVueSet(state, 'selectedVersionId', blockSelectionEvent.versionId);
    },
    [SopBlockDetailMutations.SOP_BLOCK_DETAIL_REPLACE]: (state: SopBlockDetailState, sopBlock: SopBlock) => {
        safeVueSet(state, 'selectedSopBlock', sopBlock);
    },
    [SopBlockDetailMutations.SOP_BLOCK_DETAIL_FIELD_REPLACE]: (state: SopBlockDetailState, fields: Partial<SopBlock>) => {
        if (!state.selectedSopBlock) {
            throw new Error('selectedSopBlock or selectedSopBlock.custom not set');
        }
        Object.assign(state.selectedSopBlock, fields);
    },
    [SopBlockDetailMutations.SOP_BLOCK_DETAIL_RESET]: (state: SopBlockDetailState) => {
        Object.assign(state, new SopBlockDetailState());
    },
};

export const SOP_BLOCK_DETAIL_MODULE: Module<SopBlockDetailState, RootState> = {
    state: new SopBlockDetailState(),
    getters,
    actions: { ...actions, ...eventListeners },
    mutations
};