import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { UiFeedback } from '@/store/ui-feedback';
import { emptyTemplateSearchResult, sopTemplatesService, TemplateSearchBySopResult } from '@/services/template-search-service';
import { createEmptyTemplate, Template } from '@/model/Template';
import { TemplateGetters } from '@/store/templates';
import { createComparator, safeVueSet, toMap } from '@/utils/util';
import { SopBlock } from '@/features/sop-block/model';

export class SopTemplatesState {
    result: TemplateSearchBySopResult = emptyTemplateSearchResult();
    filteredBySopBlockVersionId = '';
}

export enum SopTemplatesActions {
    SOP_TEMPLATES_LOAD = 'SOP_TEMPLATES_LOAD',
    FILTER_BY_SOP_BLOCK = 'FILTER_BY_SOP_BLOCK'
}

export enum SopTemplatesGetters {
    SOP_BLOCKS_OF_TEMPLATE = 'SOP_BLOCKS_OF_TEMPLATE',
    TEMPLATES_BY_SOP_BLOCK = 'TEMPLATES_BY_SOP_BLOCK',
    TEMPLATES_BY_ARTIFACT_IDS = 'TEMPLATES_BY_ARTIFACT_IDS',
    IS_TEMPLATE_CONTENT_LINKED_IN_ANY_SOP_BLOCK = 'IS_TEMPLATE_CONTENT_LINKED_IN_ANY_SOP_BLOCK'
}

export enum Mutations {
    TEMPLATE_SEARCH_REPLACE = 'TEMPLATE_SEARCH_REPLACE',
    FILTER_BY_SOP_BLOCK_REPLACE = 'FILTER_BY_SOP_BLOCK_REPLACE'
}


export function createLoadingArtifact(artifactId: string): Template {
    return {
        ...createEmptyTemplate(),
        versionId: artifactId,
        artifactId,
        name: 'Loading...'
    }
}

const getters = {
    [SopTemplatesGetters.SOP_BLOCKS_OF_TEMPLATE]: (state: SopTemplatesState) => (templateVersionId: string): SopBlock[] => {
        const templateToBlocksVersionId = state.result.templateToBlocksVersionIds[templateVersionId] || [];
        return templateToBlocksVersionId
            .map(blockVersionId => state.result.blocks.find(block => block.versionId === blockVersionId))
            .filter(e => !!e) as SopBlock[]
    },
    [SopTemplatesGetters.TEMPLATES_BY_SOP_BLOCK]: (state: SopTemplatesState): Template[] => {
        if (!state.filteredBySopBlockVersionId || state.filteredBySopBlockVersionId.length == 0) {
            return state.result.templates;
        }
        return Object.entries(state.result.templateToBlocksVersionIds)
            .filter(([_, value]) => value.findIndex(sopBlockVersionId => sopBlockVersionId === state.filteredBySopBlockVersionId) >= 0)
            .map((([key, _]) => key))
            .map(templateVersionId => state.result.templates.find(t => t.versionId === templateVersionId) as Template);
    },
    [SopTemplatesGetters.TEMPLATES_BY_ARTIFACT_IDS]: (state: SopTemplatesState, getters: any, rootState: any, rootGetters: any) => (templateArtifactIds: string[]): Template[] => {
        const validTemplateArtifacts = toMap((rootGetters[TemplateGetters.VALID_TEMPLATE_ARTIFACTS] as Template[]), t => t.artifactId);
        return templateArtifactIds
            .map(templateArtifactId => validTemplateArtifacts[templateArtifactId] || createLoadingArtifact(templateArtifactId))
            .sort(createComparator('name'));
    },
    [SopTemplatesGetters.IS_TEMPLATE_CONTENT_LINKED_IN_ANY_SOP_BLOCK]: (state: SopTemplatesState) => (templateContentId: string) =>
        Object.values(state.result.blocks)
            .flatMap(block => block.templateContentIds)
            .findIndex(tcId => tcId === templateContentId) >= 0,
}

const actions = {
    [SopTemplatesActions.SOP_TEMPLATES_LOAD]: ({ commit, dispatch }: ActionContext<SopTemplatesState, RootState>, sopVersionId: string) => {
        return sopTemplatesService.searchTemplatesForSop(sopVersionId)
            .then(result => commit(Mutations.TEMPLATE_SEARCH_REPLACE, result))
            .catch(err => UiFeedback.showError(dispatch, `SOP Templates couldn't be fetched. Please try again.`, err));
    },
    [SopTemplatesActions.FILTER_BY_SOP_BLOCK]: ({ commit }: ActionContext<SopTemplatesState, RootState>, sopBlockVersionId: string) =>
        commit(Mutations.FILTER_BY_SOP_BLOCK_REPLACE, sopBlockVersionId)
}

const mutations = {
    [Mutations.TEMPLATE_SEARCH_REPLACE]: (state: SopTemplatesState, result: TemplateSearchBySopResult) => {
        safeVueSet(state, 'result', result);
        safeVueSet(state, 'filteredBySopBlockVersionId', '');
    },
    [Mutations.FILTER_BY_SOP_BLOCK_REPLACE]: (state: SopTemplatesState, sopBlockVersionId: string) =>
        safeVueSet(state, 'filteredBySopBlockVersionId', sopBlockVersionId)
}

export const SOP_TEMPLATE_MODULE: Module<SopTemplatesState, RootState> = {
    state: new SopTemplatesState(),
    getters,
    actions,
    mutations
};