export default class VirtualList<T> {

    private internalElements: T[] = [];
    private allElements: T[] = [];
    private state: 'INITIAL' | 'LOADING' | 'COMPLETE' = 'INITIAL';
    private interval: number;
    private startIndex = 0;

    constructor(private readonly createInterval: (callback: TimerHandler, timeout: number) => number, private readonly evenChunkSize = 24, private readonly sleepInMs = 1) {
        if (evenChunkSize % 2 !== 0) {
            throw new Error('chuck size must be even.');
        }
    }

    get elements(): T[] {
        return this.internalElements;
    }

    update(updated: T[], startIndex = 0) {
        if (this.state === 'INITIAL' && updated.length === 0) {
            return;
        }
        this.allElements = updated;
        this.startIndex = startIndex < 0 || startIndex >= this.allElements.length ? 0 : startIndex;
        if (this.state === 'INITIAL') {
            this.startInitialLoad();
        } else if (this.state === 'COMPLETE') {
            this.internalElements = updated;
        }
    }

    private startInitialLoad() {
        this.state = 'LOADING';
        this.addChunk();
        this.interval = this.createInterval(this.addChunk.bind(this), this.sleepInMs);
    }

    private addChunk() {
        let startOfChunk = this.startIndex - (this.internalElements.length / 2) - (this.evenChunkSize / 2);
        let endOfChunk = this.startIndex + (this.internalElements.length / 2) + (this.evenChunkSize / 2);

        if (startOfChunk < 0) {
            endOfChunk = endOfChunk + Math.abs(startOfChunk);
            startOfChunk = 0;
        }

        const overflow = endOfChunk - this.allElements.length;
        if (overflow > 0) {
            startOfChunk = Math.max(startOfChunk - overflow, 0);
            endOfChunk = this.allElements.length;
        }

        this.internalElements = this.allElements.slice(startOfChunk, endOfChunk);

        if (this.internalElements.length == this.allElements.length) {
            clearInterval(this.interval)
            this.state = 'COMPLETE';
        }
    }
}
