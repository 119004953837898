import Vue from 'vue';
import Vuetify from 'vuetify';
import CoIconProcessHouse from '@/components/icons/CoIconProcessHouse.vue';
import CoIconTemplate from '@/components/icons/CoIconTemplate.vue';
import CoIconTemplateContent from '@/components/icons/CoIconTemplateContent.vue';
import CoIconGlossary from '@/components/icons/CoIconGlossary.vue';
import CoIconAppliedSR from '@/components/icons/CoIconAppliedSR.vue';
import CoIconImpactAnalysis from '@/components/icons/CoIconImpactAnalysis.vue';
import CoIconGapList from '@/components/icons/CoIconGapList.vue';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2878f0',
                accent: '#f49f0a',
                error: '#f03c3c',
                success: '#15b15d'
            },
        },
    },
    icons: {
        values: {
            coProcessHouse: { component: CoIconProcessHouse },
            coTemplate: { component: CoIconTemplate },
            coTemplateContent: { component: CoIconTemplateContent },
            coGlossary: { component: CoIconGlossary },
            coAppliedSr: { component: CoIconAppliedSR },
            coImpactAnalysis: { component: CoIconImpactAnalysis },
            coGapList: { component: CoIconGapList },
        }
    }
});

export default vuetify;
