













import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import VirtualList from '@/components/common/incremental-list/VirtualList';
import TimingMixin from '@/components/common/TimingMixin.vue';

@Component({})
export default class IncrementalList<T> extends mixins<TimingMixin>(TimingMixin) {

  @Prop({ required: true })
  entries: T[];
  @Prop({ required: true })
  keyField: keyof T;
  @Prop({ default: 20 })
  chunkSize: number;
  @Prop({ default: '' })
  scrollToKey: string;

  virtualList = new VirtualList(this.createInterval, this.chunkSize,);
  scrollPerformed = false;

  @Watch('entries', { immediate: true })
  entriesUpdated() {
    this.virtualList.update(this.entries, this.calculateScrollToIndex());
    this.performScrollToKey('auto');
  }

  @Watch('scrollToKey')
  private watchScrollToKey(newVal: string, oldVal: string) {
    if (newVal !== oldVal) {
      this.scrollPerformed = false;
    }
    this.performScrollToKey('smooth');
  }

  private performScrollToKey(behavior: ScrollBehavior) {
    if (!this.hasScrollToKey() || this.scrollPerformed) {
      return;
    }

    this.createTimeout(() => {
      const elementRef = this.$refs[this.scrollToKey] as any;
      if (!elementRef || !elementRef[0]?.scrollIntoView) {
        return;
      }
      this.scrollPerformed = true;
      const scrollOptions = { block: 'start', inline: 'nearest', behavior };
      elementRef[0].scrollIntoView(scrollOptions);
      if (behavior == 'auto') {
        this.createTimeout(() => {
          elementRef[0].scrollIntoView(scrollOptions);
        }, 1000);
      }
    }, 10);
  }

  private calculateScrollToIndex(): number {
    if (!this.hasScrollToKey()) {
      return 0;
    }
    return Math.max(this.entries.findIndex(e => this.getKey(e) === this.scrollToKey), 0);
  }

  private hasScrollToKey() {
    return this.scrollToKey.length > 0;
  }

  get listElements() {
    return this.virtualList.elements;
  }

  getKey(entry: T): string {
    return (entry as any)[this.keyField];
  }
}
