




















import Component, { mixins } from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { RootState } from '@/store';
import { QmsConfigActions, SaveQmsConfigAction } from '@/store/qms-config';
import FabButton from '@/components/common/FabButton.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';
import DrawioMixin from '@/features/drawio-integration/DrawioMixin.vue';

@Component({
  components: { FabButton },
  computed: {
    ...mapState<RootState>({
      svgString: (state: RootState) => state.qmsConfig.configs.QMS_PROCESS_IMAGE,
      showSvg: (state: RootState) => state.qmsConfig.configs.QMS_PROCESS_IMAGE.length > 850,
    })
  },
  methods: {
    ...mapActions({
      saveQmsConfig: QmsConfigActions.SAVE_QMS_CONFIG,
    })
  }
})
export default class SopOverviewImage extends mixins<AuthMixin, DrawioMixin>(AuthMixin, DrawioMixin) {

  showSvg: boolean;
  saveQmsConfig: SaveQmsConfigAction;

  created() {
    this.$store.dispatch(QmsConfigActions.LOAD_QMS_OVERVIEW_IMAGE);
  }

  saveSvgStringCallback(svgString: string): Promise<void> {
    return this.saveQmsConfig({ key: 'QMS_PROCESS_IMAGE', value: svgString });
  }

}
