



























import Vue from 'vue';
import Component from 'vue-class-component';
import StackedContent from '@/components/common/StackedContent.vue';
import GapListWidgetContent from '@/features/gap-list/gap-list-widget/GapListWidgetContent.vue';
import { Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { ImpactDto } from '@/features/impacts/model';
import ImpactDetailViewWidget from '@/features/impacts/components/ImpactDetailViewWidget.vue';

@Component({
  components: { GapListWidgetContent, StackedContent, ImpactDetailViewWidget },
  computed: {
    ...mapState<RootState>({
      selectedSopBlockArtifactId: (state: RootState) => state.gapListWidget.selectedSopBlockArtifactId,
      selectedImpact: (state: RootState) => state.impacts.selectedImpact,
      impactUiEntryLoadingState: (state: RootState) => state.impacts.impactUiEntryLoadingState,
    })
  }
})
export default class GapListWidget extends Vue {
  static readonly IMPACT_DETAIL_VIEW_WIDGET = 'ImpactDetailViewWidget';

  contentStack = ['GapListWidgetContent'];

  selectedImpact: ImpactDto;

  removeLastEntry() {
    this.contentStack.pop();
  }

  @Watch('selectedSopBlockArtifactId')
  reset() {
    this.contentStack = ['GapListWidgetContent'];
  }

  @Watch('selectedImpact')
  onImpactSelected() {
    const index = this.contentStack.indexOf(GapListWidget.IMPACT_DETAIL_VIEW_WIDGET);
    let stack = [...this.contentStack];
    if (index > 0) {
      stack = this.contentStack.slice(0, index - 1);
    }
    if (this.selectedImpact) {
      stack.push(GapListWidget.IMPACT_DETAIL_VIEW_WIDGET);
    }
    this.contentStack = stack;
  }
}
