

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapActions } from 'vuex';
import { SopBlockListActions } from '@/features/sop-block/sop-block-list/store';

@Component({
  methods: {
    ...mapActions({
      createNewBlockArtifact: SopBlockListActions.SOP_BLOCK_LIST_CREATE_NEW_ARTIFACT,
    })
  }
})
export default class AddSopBlockButtons extends Vue {

  @Prop()
  index: number;

  @Prop()
  showAddStep: boolean;

  @Prop({
    default: 'left',
    validator: function (value: any): boolean {
      return ['left', 'right'].indexOf(value) >= 0;
    }
  })
  direction: string;

  fabOpen = false;

}
