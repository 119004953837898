











import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StackedContent extends Vue {

  @Prop() components: string[];

  isShown(currentName: string) {
    return this.components[this.components.length - 1] === currentName;
  }

}
