









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import QmsRequirementEdit from '@/components/common/QmsRequirementEdit.vue';
import { QmsRequirement } from '@/services/model';

@Component<SopQmsRequirementEdit>({
  components: { QmsRequirementEdit },
  computed: {
    ...mapGetters({
      proposedSopsByRequirementId: RegulationDetailGetters.PROPOSED_SOPS_BY_REQUIREMENT_ID,
      proposedTemplateContentByRequirementId: RegulationDetailGetters.PROPOSED_TEMPLATE_CONTENTS_BY_REQUIREMENT_ID,
    }),
  },
})
export default class SopQmsRequirementEdit extends Vue {

  @Prop() qmsRequirement: QmsRequirement;

  private dispatchQmsRequirementChange(changingFields: Partial<QmsRequirement>) {
    this.$store.dispatch(RegulationDetailActions.QMS_REQUIREMENT_CHANGE,
        {
          ...this.qmsRequirement,
          ...changingFields
        } as QmsRequirement);
  }

}
