








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CreateFileStorage, FileStorageActions, FileStorageGetters } from '@/store/file-storage';
import { FileStorage } from '@/model/FileStorage';
import FileDownload from '@/components/common/FileDownload.vue';

@Component({
  components: { FileDownload }
})
export default class FileUpload extends Vue {

  file: File | null = null;

  @Prop({ required: true })
  fileStorage: FileStorage;

  @Prop({ default: null })
  accept: string | null;

  @Prop({ default: 'Upload' })
  label: string | null;

  get fileStorageId() {
    return this.$store.getters[FileStorageGetters.FILE_STORAGE_BY_NAME_OR_UNDEFINED](this.fileStorage.name)?.id;
  }

  public deleteFile() {
    return this.$store.dispatch(FileStorageActions.FILE_STORAGE_DELETE_BY_ID, this.fileStorageId);
  }

  public uploadFile(file: File) {
    const createFileStorage: CreateFileStorage = {
      file,
      fileStorage: this.fileStorage
    }
    return this.$store.dispatch(FileStorageActions.FILE_STORAGE_UPLOAD_BY_NAME, createFileStorage);
  }
}
