










import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';
import { RequirementMap } from '@/features/sr-model/Requirement';
import GapListWidgetRequirement from '@/features/gap-list/gap-list-widget/GapListWidgetRequirement.vue';

@Component({
  components: { GapListWidgetRequirement },
  computed: {
    ...mapState<RootState>({
      srRequirements: (state: RootState) => Object.values(state.gapListWidget.srRequirements),
    }),
  },
})
export default class GapListWidgetRequirements extends Vue {

  private srRequirements: RequirementMap;

}
