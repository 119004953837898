import moment from 'moment';

export type RefType = 'NA' | 'SOP_ARTIFACT' | 'SOP_BLOCK_ARTIFACT' | 'IMPACT' | 'IMPACT_ANALYSIS';

export function humanReadableRefType(refType: RefType) {
    switch (refType) {
        case 'NA':
            return '';
        case 'SOP_ARTIFACT':
            return 'Process';
        case 'SOP_BLOCK_ARTIFACT':
            return 'Block';
        case 'IMPACT':
            return 'Impact';
        case 'IMPACT_ANALYSIS':
            return 'Impact analysis';
    }
}


export interface Comment {
    id?: string;
    refId: string;
    refType: RefType;
    parentRefId?: string;
    parentRefType?: RefType;
    comment: string;
    createdUserId?: string;
    createdUserName?: string;
    createdUserInitials?: string;
    createdAt?: moment.Moment;
}
