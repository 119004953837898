






import Vue from 'vue';
import Component from 'vue-class-component';
import { SopDetailHelper } from '@/store/sop-detail';
import { FileStorageGetters } from '@/store/file-storage';
import { FileDownloadResponse, fileStorageService } from '@/services/file-storage-service';
import { Watch } from 'vue-property-decorator';
import { FileStorageConstants } from '@/model/FileStorage';
import { UrlHelper } from '@/utils/UrlHelper';

@Component({
  computed: {
    sopAsPdfStorage() {
      // we map the whole storage object here, in order that we get informed when the object gets reloaded from the backend,
      // which may have been caused because the blob-content has changed and therefore we have to reload the content
      return this.$store.getters[FileStorageGetters.FILE_STORAGE_BY_NAME_OR_UNDEFINED](FileStorageConstants.SOP_AS_PDF_NAME)
    },
  },
  methods: {
    ...SopDetailHelper.setterForSopFields('name', 'description'),
    ...SopDetailHelper.setterForSopArtifactFields('parentArtifactId'),
  }
})
export default class SopPdf extends Vue {

  blobReference = '';

  mounted() {
    this.loadPdfObject();
  }

  @Watch('sopAsPdfStorage', { immediate: true })
  loadPdfObject() {
    this.releaseObjectUrl();
    const storageId = (this as any).sopAsPdfStorage?.id
    if (!storageId) {
      return;
    }

    fileStorageService.download(storageId)
        .then(this.updatePdfObject)
  }

  private updatePdfObject(fileDownloadResponse: FileDownloadResponse) {
    this.blobReference = UrlHelper.createObjectURL(fileDownloadResponse.blob);
  }

  destroyed() {
    this.releaseObjectUrl();
  }

  private releaseObjectUrl() {
    UrlHelper.revokeObjectURL(this.blobReference);
    this.blobReference = '';
  }
}
