


























































import Component, { mixins } from 'vue-class-component';
import { RootState } from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import { TemplateDetailActions, TemplateDetailGetters } from '@/store/templates/template-detail';
import CoHeader from '@/components/common/CoHeader.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';

@Component({
  components: { CoHeader },
  computed: {
    ...mapGetters({
      currentReleaseState: TemplateDetailGetters.TEMPLATE_CURRENT_RELEASE_STATE
    }),
    ...mapState<RootState>({
      template: (state: RootState) => state.templates.template
    }),
  },
  methods: {
    ...mapActions({
      changeStateTo: TemplateDetailActions.TEMPLATE_DETAIL_RELEASE_STATE_CHANGE,
      createNewVersion: TemplateDetailActions.TEMPLATE_DETAIL_CREATE_NEW_VERSION
    })
  }
})
export default class TemplateDetailHeader extends mixins<AuthMixin>(AuthMixin) {

}
