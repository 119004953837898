












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { RootState } from '@/store';

@Component({
  computed: {
    ...mapState<RootState>({
      users: (state: RootState) => Object.values(state.users.users),
    }),
  }
})
export default class UserSelection extends Vue {

  @Prop() selectedUserIds: string[];
  @Prop({ default: 'Users' }) label: string;
  @Prop({ default: true }) multiple: boolean;

  set userIds(values: string[]) {
    this.$emit('selected-user-ids', values);
  }

  get userIds(): string[] {
    return this.selectedUserIds;
  }

}
