





















import Vue from 'vue';
import Component from 'vue-class-component';
import ProcessSelection from '@/components/common/ProcessSelection.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { SopDetailActions, SopDetailGetters, SopDetailHelper } from '@/store/sop-detail';
import RequirementSearch from '@/features/sr-requirement-search/RequirementSearch.vue';
import { SrGetters } from '@/store/sr';

@Component({
  components: { RequirementSearch, ProcessSelection },
  computed: {
    ...mapState<RootState>({
      sop: (state: RootState) => state.sopDetail.sop,
      allUsedSrProcessIds: (state: RootState) => state.sops.allLinkedSrProcessIds,
    }),
    ...mapGetters({
      templateContentIdsOfSelectedProcesses: SopDetailGetters.TEMPLATE_CONTENT_IDS_OF_SELECTED_SR_PROCESSES,
      processContentIdsOfSelectedProcesses: SopDetailGetters.PROCESS_CONTENT_IDS_OF_SELECTED_SR_PROCESSES,
      termIdsOfSelectedProcesses: SopDetailGetters.TERM_IDS_OF_SELECTED_SR_PROCESSES,
      editable: SopDetailGetters.SOP_IS_EDITABLE,
      processById: SrGetters.SR_PROCESS_BY_ID,
      linkedSrTemplateContentIds: SopDetailGetters.SR_TEMPLATE_CONTENT_IDS_OF_LINKED_TEMPLATE_CONTENTS
    }),
  },
  methods: {
    ...mapMutations({
      ...SopDetailHelper.setterForSopFields('srProcessIds'),
    })
  }
})
export default class SrProcessAndRequirements extends Vue {

  updateSrProcessIds(ids: string[]) {
    (this as any).setSrProcessIds(ids);
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_SAVE);
  }
}
