



































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ImpactsActions, ImpactsGetter, ImpactUiEntry } from '@/features/impacts/store';
import { mapGetters, mapState } from 'vuex';
import { Requirement } from '@/features/sr-model/Requirement';
import AnchoredHeading from '@/features/sr-requirement-search/AnchoredHeading.vue';
import RequirementItem from '@/components/common/RequirementItem.vue';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import { BreadcrumbCalculator } from '@/features/impacts/BreadcrumbCalculator';
import QmsRequirementCurrent from '@/components/common/QmsRequirementCurrent.vue';
import ImpactEntryToRequirement from '@/features/impacts/components/ImpactEntryToRequirement.vue';
import { RootState } from '@/store';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { SopSearchPart } from '@/model';
import QmsRequirementApplicabilityComponent from '@/components/common/QmsRequirementApplicabilityComponent.vue';
import { UiLayoutActions } from '@/store/ui-layout';
import { humanReadableChangeType, humanReadableImpactType } from '@/features/impacts/model';
import { CommentRef, CommentsWidgetActions, CommentsWidgetGetters } from '@/features/comments/store';
import { restClient } from '@/store/rest-client';
import ImpactEntryFromRequirementViewPart from '@/features/impacts/components/ImpactEntryFromRequirementViewPart.vue';

@Component({
  components: { ImpactEntryFromRequirementViewPart, QmsRequirementApplicabilityComponent, ImpactEntryToRequirement, QmsRequirementCurrent, RequirementItem, AnchoredHeading },
  computed: {
    ...mapState<RootState>({
      editQmsRequirements: (state: RootState) => state.impacts.editQmsRequirements,
      isImpactMode: (state: RootState) => state.impacts.isImpactMode,
    }),
    ...mapGetters({
      impactByImpactId: ImpactsGetter.IMPACT_BY_IMPACT_ID_OR_LOADING,
      proposedSopsByImpactId: ImpactsGetter.PROPOSED_SOPS_BY_IMPACT_ID,
      proposedTemplateContentByImpactId: ImpactsGetter.PROPOSED_TEMPLATE_CONTENTS_BY_IMPACT_ID,
      hasImpactComments: CommentsWidgetGetters.REF_ID_HAS_ANY_COMMENTS,
    })
  }
})
export default class ImpactEntry extends mixins<ImpactsMixin>(ImpactsMixin) {

  @Prop() impactUiEntry: ImpactUiEntry;
  diffDialog = false;
  diff = '';

  proposedSopsByImpactId: (impactId: string) => SopSearchPart[];
  proposedTemplateContentByImpactId: (impactId: string) => TemplateContent[];
  readonly humanReadableImpactType = humanReadableImpactType;
  readonly humanReadableChangeType = humanReadableChangeType;

  get breadcrumbs() {
    return new BreadcrumbCalculator(this.fromRegulationRequirement).calculateBreadcrumb(this.impactUiEntry.fromRequirementIds);
  }

  get toRequirements(): Requirement[] {
    return this.toRequirementsByIds(this.impactUiEntry.toRequirementIds);
  }

  get fromRequirements(): Requirement[] {
    return this.fromRequirementsByIds(this.impactUiEntry.fromRequirementIds);
  }

  editUiImpact() {
    return this.$store.dispatch(UiLayoutActions.SIDEBAR_RIGHT_SELECTION_CHANGE, 'ImpactDetailWidget')
        .then(() => this.$store.dispatch(ImpactsActions.EDIT_UI_IMPACT, this.impactUiEntry));
  }

  get proposedSops() {
    if (!this.impactUiEntry.impactId) {
      return [];
    }
    return this.proposedSopsByImpactId(this.impactUiEntry.impactId);
  }

  get proposedTemplateContent() {
    if (!this.impactUiEntry.impactId) {
      return [];
    }
    return this.proposedTemplateContentByImpactId(this.impactUiEntry.impactId);
  }

  openCommentWidget(impactId: string) {
    const commentRef: CommentRef = {
      refId: impactId,
      refType: 'IMPACT',
      parentRefId: this.$route.params.impactAnalysisId,
      parentRefType: 'IMPACT_ANALYSIS',
    };
    this.$store.dispatch(CommentsWidgetActions.COMMENTS_LOAD_AND_ACTIVATE_WIDGET, commentRef);
  }

  compare() {
    restClient.get(
        `${ process.env.VUE_APP_QMS_BASE_URL }/qms-api/impact-analyses/id/impacts/${ this.impactUiEntry.impactId }/diff`,
        { headers: { 'Content-Type': 'text/html' } }
    ).then(response => {
      this.diff = response.data;
      this.diffDialog = true;
    });
  }

}
