import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

export const restClient = axios.create({
    headers: {
        'Content-Type': 'application/json'
    }
});

if (process.env.NODE_ENV !== 'test') {
    restClient.interceptors.request.use(
        async (config) => {
            const token = await firebase.auth().currentUser?.getIdToken();
            config.headers['Authorization'] = `Bearer ${ token }`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}
