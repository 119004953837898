











import Component, { mixins } from 'vue-class-component';
import DrawioMixin from '@/features/drawio-integration/DrawioMixin.vue';
import SopBlockDetailMixin from '@/features/sop-block/sop-block-detail/SopBlockDetailMixin.vue';
import { SopBlockDetailActions, SopBlockDetailMutations } from '@/features/sop-block/sop-block-detail/store';

@Component
export default class SopBlockDetailDrawio extends mixins<SopBlockDetailMixin, DrawioMixin>(SopBlockDetailMixin, DrawioMixin) {

  get svgString() {
    return this.block.description;
  }

  mounted() {
    this.openDrawIo(
        (s: string) => {
          this.$store.commit(SopBlockDetailMutations.SOP_BLOCK_DETAIL_FIELD_REPLACE, { description: s });
          return this.$store.dispatch(SopBlockDetailActions.SOP_BLOCK_DETAIL_SAVE);
        },
        () => this.$store.dispatch(SopBlockDetailActions.SOP_BLOCK_DETAIL_CANCEL));
  }

}
