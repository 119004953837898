import { restClient } from '@/store/rest-client';
import { RequirementSearchResult } from '@/features/sr-requirement-search/model/RequirementSearch';
import { RequirementSearchServiceMock } from '@/features/sr-requirement-search/service/requirement-search-service-mock';


export type RequirementIdsPerRegulation = { [key: string]: string[] };

export class RequirementSearchFilter {
    requirementIdsPerRegulation?: RequirementIdsPerRegulation;
    templateContentIds?: string[];
    sopContentIds?: string[];
    termIds?: string[];
}

export interface RequirementSearchService {

    search(filter: RequirementSearchFilter): Promise<RequirementSearchResult>;

}

const API_REQUIREMENTS = '/sr-api/requirements';

class RequirementSearchBackendService implements RequirementSearchService {

    search(filter: RequirementSearchFilter): Promise<RequirementSearchResult> {
        return restClient.post<RequirementSearchResult>(process.env.VUE_APP_REGULATIONS_BASE_URL + API_REQUIREMENTS, filter)
            .then(t => t.data);
    }

}

export const requirementSearchService: RequirementSearchService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new RequirementSearchServiceMock() : new RequirementSearchBackendService();
