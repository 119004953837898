import { ImpactUiEntry } from '@/features/impacts/store';
import { ImpactDto, ImpactMap } from '@/features/impacts/model';

export class ImpactEntryCalculator {

    public constructor(private toRequirementsIds: string[],
                       private toRequirementIdToImpactId: { [p: string]: string },
                       private fromRequirementIds: string[],
                       private fromRequirementIdToImpactId: { [p: string]: string },
                       private impacts: ImpactMap) {
    }

    public calculateImpactEntry(): ImpactUiEntry[] {
        // create ui impact entries for each TO-site requirement (and/or existing impact with a to-requirements)
        const impactEntries = this.calculateEntriesWithToRequirements();
        // create and insert ui impacts (at correct position) for each impact without a linked to-requirement (ie. impacts of type DELETED_REQUIREMENT)
        this.addEntriesWithOnlyFromRequirements(impactEntries);
        this.addEntriesWithNonRequirements(impactEntries);
        return impactEntries;
    }

    private addEntriesWithNonRequirements(impactEntries: ImpactUiEntry[]) {
        Object.values(this.impacts)
            .filter(impact => impact.requirementIdsTo.length == 0 && impact.requirementIdsFrom.length == 0)
            .sort((uiImpact1: ImpactDto, uiImpact2: ImpactDto) => uiImpact1.id > uiImpact2.id ? -1 : 1)
            .forEach(impact => {
                impactEntries.splice(0, 0, {
                    ...this.createEmptyImpactUiEntry(),
                    uiId: impact.id,
                    impactId: impact.id,
                    fromRequirementIds: impact.requirementIdsFrom
                });
            })
    }

    private addEntriesWithOnlyFromRequirements(impactEntries: ImpactUiEntry[]) {
        Object.values(this.impacts)
            .filter(impact => impact.requirementIdsTo.length == 0 && impact.requirementIdsFrom.length > 0)
            .map(impact => ({
                ...this.createEmptyImpactUiEntry(),
                uiId: impact.id,
                impactId: impact.id,
                fromRequirementIds: impact.requirementIdsFrom
            }))
            .sort(this.compareUiImpactsByFromRequirementId.bind(this))
            .forEach(uiImpact => {
                let impactId: string | undefined = undefined;
                const startIndex = this.fromRequirementIds.indexOf(this.mostUpperFromRequirementId(uiImpact.fromRequirementIds));
                for (let i = startIndex - 1; i >= 0; i--) {
                    const previousRequirementId = this.fromRequirementIds[i];
                    impactId = this.fromRequirementIdToImpactId[previousRequirementId]
                    if (impactId) {
                        break;
                    }
                }
                if (impactId) {
                    const index = impactEntries.findIndex(impact => impact.impactId === impactId);
                    impactEntries.splice(index + 1, 0, uiImpact);
                } else {
                    impactEntries.splice(0, 0, uiImpact);
                }
            });
    }

    private calculateEntriesWithToRequirements() {
        const impactEntries: ImpactUiEntry[] = [];
        let currentImpactEntry: ImpactUiEntry = this.createEmptyImpactUiEntry();

        for (let i = 0; i < this.toRequirementsIds.length; i++) {
            const toRequirementImpactId = this.toRequirementIdToImpactId[this.toRequirementsIds[i]];
            if (!toRequirementImpactId) {
                currentImpactEntry = this.createEmptyImpactUiEntry();
                currentImpactEntry.uiId = this.toRequirementsIds[i];
                currentImpactEntry.toRequirementIds.push(this.toRequirementsIds[i]);
                impactEntries.push(currentImpactEntry);
            } else {
                if (currentImpactEntry.impactId === toRequirementImpactId) {
                    currentImpactEntry.toRequirementIds.push(this.toRequirementsIds[i]);
                } else {
                    currentImpactEntry = {
                        uiId: toRequirementImpactId,
                        impactId: toRequirementImpactId,
                        toRequirementIds: [this.toRequirementsIds[i]],
                        fromRequirementIds: this.impacts[toRequirementImpactId].requirementIdsFrom,
                    };
                    impactEntries.push(currentImpactEntry);
                }
            }
        }
        return impactEntries;
    }

    private createEmptyImpactUiEntry() {
        return {
            uiId: 'undefined',
            impactId: undefined,
            toRequirementIds: [],
            fromRequirementIds: [],
        };
    }

    private compareUiImpactsByFromRequirementId(uiImpact1: ImpactUiEntry, uiImpact2: ImpactUiEntry) {
        return this.compareFromRequirementIds(
            this.mostUpperFromRequirementId(uiImpact1.fromRequirementIds),
            this.mostUpperFromRequirementId(uiImpact2.fromRequirementIds));
    }

    private mostUpperFromRequirementId(fromRequirementIds: string[]) {
        return [...fromRequirementIds].sort(this.compareFromRequirementIds.bind(this))[0]
    }

    private compareFromRequirementIds(fromReqId1: string, fromReqId2: string) {
        return this.fromRequirementIds.indexOf(fromReqId1) > this.fromRequirementIds.indexOf(fromReqId2) ? 1 : -1;
    }
}
