
































































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RequirementItem from '@/components/common/RequirementItem.vue';
import QmsRequirementCurrent from '@/components/common/QmsRequirementCurrent.vue';
import { Requirement, RequirementMap } from '@/features/sr-model/Requirement';
import ImpactsMixin from '@/features/impacts/components/ImpactsMixin.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import { ImpactsActions, ImpactsMutations } from '@/features/impacts/store';
import ImpactQmsRequirementEdit from '@/features/impacts/components/ImpactQmsRequirementEdit.vue';
import { SopSearchPart } from '@/model';
import { TemplateContent } from '@/features/template-content/template-content-model';
import ImpactQmsRequirementProposal from '@/features/impacts/components/ImpactQmsRequirementProposal.vue';
import ImpactQmsRequirementApplicability from '@/features/impacts/components/ImpactQmsRequirementApplicability.vue';

@Component({
  components: { ImpactQmsRequirementApplicability, ImpactQmsRequirementProposal, ImpactQmsRequirementEdit, QmsRequirementCurrent, RequirementItem },
  computed: {
    ...mapState<RootState>({
      isEditingRequirement(this: ImpactEntryToRequirementEditPart, state: RootState) {
        return this.requirement.id === state.impacts.editingRequirementId;
      },
      editQmsRequirements: (state: RootState) => state.impacts.editQmsRequirements,
      isImpactMode: (state: RootState) => state.impacts.isImpactMode,
      toRegulationRequirements: (state: RootState) => state.impacts.toRegulationRequirements,
    })
  },
  methods: {
    ...mapMutations({
      editRequirement: ImpactsMutations.EDITING_REQUIREMENT_ID,
    }),
    ...mapActions({
      createQmsRequirement: ImpactsActions.QMS_REQUIREMENT_CREATE,
      deleteQmsRequirement: ImpactsActions.QMS_REQUIREMENT_DELETE,
    }),
  },
})
export default class ImpactEntryToRequirementEditPart extends mixins<ImpactsMixin>(ImpactsMixin) {

  @Prop()
  requirement: Requirement;
  @Prop({ default: () => [] })
  proposedSops: SopSearchPart[];
  @Prop({ default: () => [] })
  proposedTemplateContents: TemplateContent[];

  toRegulationRequirements: RequirementMap;

  createQmsRequirement: (srRequirementId: string) => Promise<void>;
  deleteQmsRequirement: (qmsRequirementId: string) => Promise<void>;

  get qmsRequirement() {
    return this.toQmsRequirementByRequirementId(this.requirement.id)
  }

  createInterpretation() {
    return this.createQmsRequirement(this.requirement.id);
  }

  deleteInterpretation() {
    const requirementId = this.qmsRequirement.requirementId;
    return this.deleteQmsRequirement(requirementId);
  }

  get interpretedInRequirementInfo(): string {
    if (!this.qmsRequirement.interpretedInRequirementId) {
      throw new Error('QmsRequirement has no expected interpretedInRequirementId');
    }
    const requirement = this.toRegulationRequirements[this.qmsRequirement.interpretedInRequirementId];
    const infoText = ((requirement.paragraph ?? '') + ' ' + requirement.text).trim();
    return infoText.substr(0, Math.min(infoText.length, 50)) + (infoText.length > 50 ? '...' : '');
  }
}
