



































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RootState } from '@/store';
import { mapActions, mapGetters, mapState } from 'vuex';
import { createEmptySopTerm, Term, VersionState } from '@/model';
import { SopDetailActions, SopDetailGetters } from '@/store/sop-detail';
import FabButton from '@/components/common/FabButton.vue';
import { TermActions } from '@/store/terms';
import RedundantTerms from '@/components/sop-detail/RedundantTerms.vue';
import { releaseState } from '@/model/ReleaseState';

@Component({
  components: { RedundantTerms, FabButton },
  computed: {
    ...mapState<RootState>({
      isInWork: (state: RootState) => state.sopDetail.sop && releaseState(state.sopDetail.sop.releaseState).isInWork(),
      terms: (state: RootState) => Object.values(state.sopDetail.terms),
      deletedTerms: (state: RootState) => Object.values(state.sopDetail.termsDeleted),
      sop: (state: RootState) => state.sopDetail.sop,
    }),
    ...mapGetters({
      editable: SopDetailGetters.SOP_IS_EDITABLE,
      versionState: SopDetailGetters.SOP_TERM_VERSION_STATE
    }),
  },
  methods: {
    ...mapActions({
      undoDelete: SopDetailActions.SOP_DETAIL_TERM_DELETE_UNDO
    })
  },
})
export default class SopGlossary extends Vue {

  headers = [
    { text: 'Term', value: 'term', width: '20%' },
    { text: 'Abbr.', value: 'abbreviation', width: '15%' },
    { text: 'Definition', value: 'definition', width: '55%' },
    { text: '', value: 'actions', width: '10%' },
  ];

  versionState: (term: Term) => VersionState;

  rules = [(value: string) => value.length >= 1 || 'Min. 1 characters'];
  rulesAbbreviation = [(value: string) => value.length <= 32 || 'Max. 32 characters'];
  valid = false;

  editDialog = false;
  deleteDialog = false;

  term: Term = createEmptySopTerm();

  confirmSave() {
    if (this.term.versionId) {
      this.$store.dispatch(SopDetailActions.SOP_DETAIL_TERM_SAVE, this.term)
          .then(() => this.cancel());
    } else {
      this.$store.dispatch(SopDetailActions.SOP_DETAIL_TERM_CREATE_ARTIFACT, this.term)
          .then(() => this.cancel());
    }
  }

  confirmDelete() {
    this.$store.dispatch(SopDetailActions.SOP_DETAIL_TERM_DELETE, this.term.versionId)
        .then(() => this.cancel());
  }

  cancel() {
    this.term = createEmptySopTerm();
    this.editDialog = false;
    this.deleteDialog = false;
  }

  editItem(term: Term) {
    const updateVersionIfNecessary: (t: Term) => Promise<Term> = (t: Term) => {
      if (this.$store.getters[SopDetailGetters.SOP_TERM_VERSION_STATE](t) === 'unchanged') {
        // TODO: ugly hack; move term model to store
        return this.$store.dispatch(SopDetailActions.SOP_DETAIL_TERM_CREATE_VERSION, t.versionId);
      }
      return Promise.resolve(t);
    };

    updateVersionIfNecessary(term)
        .then(t => {
          this.loadAllTerms();
          this.term = { ...t };
          this.editDialog = true;
        });
  }

  deleteItem(term: Term) {
    this.term = { ...term };
    this.deleteDialog = true;
  }

  loadAllTerms() {
    this.$store.dispatch(TermActions.TERMS_LOAD);
  }

  isTermNewOrUnchanged(term: Term) {
    return this.versionState(term) !== 'changed' && this.versionState(term) !== 'deleted';
  }

}
