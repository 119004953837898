export interface User {
    userId: string;
    displayName: string;
    email: string;
}

export function createEmptyUser(): User {
    return {
        userId: '',
        displayName: '',
        email: ''
    };
}

export const NEW_USER_ID = 'NEW_USER_ID';

export function createNewUser(): User {
    return { ...createEmptyUser(), userId: NEW_USER_ID };
}