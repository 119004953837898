import { createEmptyQmsRegulation, createEmptyQmsRegulationStatistics, QmsRegulation, QmsRegulationMap, QmsRegulationOverallStatistics, QmsRegulationSearch, QmsRegulationStatistics, QmsRegulationStatisticsMap } from '@/features/regulations/model';
import { restClient } from '@/store/rest-client';
import { toMap, toMapWithValue } from '@/utils/util';

export interface QmsRegulationService {

    loadQmsRegulations(): Promise<QmsRegulationMap>;

    loadQmsRegulationsWithStatistics(): Promise<[QmsRegulationMap, QmsRegulationStatisticsMap]>;

    loadQmsRegulation(regulationId: string): Promise<QmsRegulationSearch>;

    saveQmsRegulation(qmsRegulation: QmsRegulation): Promise<QmsRegulation>;

    loadStatistics(regulationId: string): Promise<QmsRegulationStatistics>;

    loadOverallStatistics(): Promise<QmsRegulationOverallStatistics>;

}

export const API_QMS_REGULATIONS = process.env.VUE_APP_QMS_BASE_URL + '/qms-api/qms-regulations'

class QmsRegulationsBackendService implements QmsRegulationService {

    loadQmsRegulations(): Promise<QmsRegulationMap> {
        return restClient.get<QmsRegulation[]>(API_QMS_REGULATIONS)
            .then(r => r.data)
            .then(qmsRegulations => toMap(qmsRegulations, r => r.regulationId));
    }

    loadQmsRegulationsWithStatistics(): Promise<[QmsRegulationMap, QmsRegulationStatisticsMap]> {
        return restClient.get<QmsRegulationSearch[]>(API_QMS_REGULATIONS + '/search')
            .then(response => response.data)
            .then((qmsRegulations: QmsRegulationSearch[]) => ([
                toMapWithValue(qmsRegulations, r => r.regulation.regulationId, r => r.regulation),
                toMapWithValue(qmsRegulations, r => r.regulation.regulationId, r => r.statistics)
            ]));
    }

    loadQmsRegulation(regulationId: string): Promise<QmsRegulationSearch> {
        return restClient.get<QmsRegulationSearch>(`${ API_QMS_REGULATIONS }/${ regulationId }`)
            .then(response => response.data);
    }

    saveQmsRegulation(qmsRegulation: QmsRegulation): Promise<QmsRegulation> {
        return restClient.put<QmsRegulation>(API_QMS_REGULATIONS + '/' + qmsRegulation.regulationId, qmsRegulation)
            .then(response => response.data);
    }

    loadStatistics(regulationId: string): Promise<QmsRegulationStatistics> {
        return restClient.get<QmsRegulationStatistics>(`${ API_QMS_REGULATIONS }/${ regulationId }/statistics`)
            .then(response => response.data);
    }

    loadOverallStatistics(): Promise<QmsRegulationOverallStatistics> {
        return restClient.get<QmsRegulationOverallStatistics>(`${ API_QMS_REGULATIONS }/statistics`)
            .then(response => response.data);
    }
}

class QmsRegulationsServiceMock implements QmsRegulationService {

    loadQmsRegulations(): Promise<QmsRegulationMap> {
        return Promise.resolve({});
    }

    loadQmsRegulationsWithStatistics(): Promise<[QmsRegulationMap, QmsRegulationStatisticsMap]> {
        return Promise.resolve([{}, {}]);
    }

    saveQmsRegulation(qmsRegulation: QmsRegulation): Promise<QmsRegulation> {
        return Promise.resolve(qmsRegulation);
    }

    loadQmsRegulation(regulationId: string): Promise<QmsRegulationSearch> {
        return Promise.resolve({ regulation: createEmptyQmsRegulation(regulationId), statistics: createEmptyQmsRegulationStatistics() });
    }

    loadStatistics(regulationId: string): Promise<QmsRegulationStatistics> {
        if (regulationId === 'mdr-2017-745') {
            return Promise.resolve({ ...createEmptyQmsRegulationStatistics(), confirmedEvidenceCount: 1, unconfirmedEvidenceCount: 1, applicableWarningCount: 9, totalRequirements: 11 });
        }
        return Promise.resolve(createEmptyQmsRegulationStatistics());
    }

    loadOverallStatistics(): Promise<QmsRegulationOverallStatistics> {
        return Promise.reject('not yet implemented');
    }
}

export const qmsRegulationService: QmsRegulationService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new QmsRegulationsServiceMock() : new QmsRegulationsBackendService();