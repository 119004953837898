




























import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { Watch } from 'vue-property-decorator';
import TimingMixin from '@/components/common/TimingMixin.vue';
import GapStatisticApplicability from '@/features/gap-list/components/GapStatisticApplicability.vue';
import GapStatisticEvidence from '@/features/gap-list/components/GapStatisticEvidence.vue';
import { ActualEvidenceStatus, ApplicabilityAssessmentStatus, Filter, RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';

@Component({
  components: { GapStatisticEvidence, GapStatisticApplicability },
  computed: {
    ...mapState<RootState>({
      selectedWidget: (state: RootState) => state.uiLayout.sidebarRightSelectedWidgetName,
      filter: (state: RootState) => state.regulationDetail.filter,
    }),
    ...mapGetters({
      statistics: RegulationDetailGetters.REGULATION_DETAIL_STATISTICS,
    })
  }
})
export default class GapStatisticWidget extends mixins<TimingMixin>(TimingMixin) {

  filter: Filter;
  loaded = false;
  selectedWidget: string;

  @Watch('selectedWidget', { immediate: true })
  onSelectedWidgetChanged() {
    if (this.selectedWidget === 'GapStatisticWidget') {
      this.createTimeout(() => this.loaded = true, 500);
    } else {
      this.loaded = false;
    }
  }

  applicabilitySelected(index: number) {
    const values: ApplicabilityAssessmentStatus[] = ['APPLICABLE', 'APPLICABLE_WITH_WARNING', 'NOT_APPLICABLE', 'NOT_APPLICABLE_WITH_WARNING', 'NOT_DEFINED', 'INFORMATION'];
    const selectedAssessmentStatus = values[index];
    const applicabilityAssessmentStatus = this.filter.applicabilityAssessmentStatus == selectedAssessmentStatus ? 'ALL' : selectedAssessmentStatus;
    this.applyFilter({ applicabilityAssessmentStatus })
  }

  evidenceSelected(index: number) {
    const values: ActualEvidenceStatus[] = ['CONFIRMED', 'DEPRECATED', 'GAPS'];
    const selectedEvidenceStatus = values[index];
    const actualEvidenceStatus = this.filter.actualEvidenceStatus == selectedEvidenceStatus ? 'ALL' : selectedEvidenceStatus;
    this.applyFilter({ actualEvidenceStatus })
  }

  applyFilter(filterOverride: Partial<Filter>) {
    const filter: Filter = {
      ...this.filter,
      ...filterOverride,
    }
    this.$store.dispatch(RegulationDetailActions.REQUIREMENTS_FILTER, filter);
    this.scrollToRequirementKey();
  }

  scrollToRequirementKey(requirementKey = '') {
    this.$store.dispatch(RegulationDetailActions.ANCHORED_REQUIREMENT_KEY_UPDATE, requirementKey);
  }

}
