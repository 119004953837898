






























import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapState } from 'vuex';
import { RootState } from '@/store';
import CommentEntry from '@/features/comments/components/CommentEntry.vue';
import { CommentsWidgetActions } from '@/features/comments/store';

@Component({
  components: { CommentEntry },
  computed: {
    ...mapState<RootState>({
      currentUser: (state: RootState) => state.auth.user,
      comments: (state: RootState) => state.comments.comments,
    })
  },
  methods: {
    ...mapActions({
      createComment: CommentsWidgetActions.COMMENT_CREATE,
      updateComment: CommentsWidgetActions.COMMENT_UPDATE,
      deleteComment: CommentsWidgetActions.COMMENT_DELETE,
    })
  }
})
export default class CommentsTimeline extends Vue {

  newComment = '';
}
