import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store';
import { SopDetailEvents } from '@/store/sop-detail/sop-detail-events';
import { ArtifactChangedEvent } from '@/model/Events';
import { QmsRequirementMap } from '@/store/regulation-detail';
import { emptySearchResult, RequirementSearchResult } from '@/features/sr-requirement-search/model/RequirementSearch';
import { safeVueSet } from '@/utils/util';
import { qmsRequirementService } from '@/services/qms-requirement-service';
import { RequirementIdsPerRegulation, RequirementSearchFilter, requirementSearchService } from '@/features/sr-requirement-search/service/requirement-search-service';
import { SopBlockListEvents, SopBlockSelectedEvent } from '@/features/sop-block/sop-block-list/sop-block-list-events';
import { hasUserSrViewRights } from '@/utils/UserUtils';

function emptyArtifactVersionIdentifier(): SopBlockSelectedEvent {
    return {
        sopArtifactId: '',
        forEditing: false,
        artifactId: '',
        versionId: '',
        name: '',
        description: '',
        testable: false,
        acceptanceCriterion: '',
        statisticalRequirements: '',
        sampleSize: '',
        references: '',
        productIds: [],
    }
}

export class ProductRequirementsState {
    selectedSopBlockEvent: SopBlockSelectedEvent = emptyArtifactVersionIdentifier();
    qmsRequirements: QmsRequirementMap = {};
    requirementSearchResult: RequirementSearchResult = emptySearchResult();
}


export enum ProductRequirementsActions {
}

export enum ProductRequirementsGetters {
    HAS_QMS_REQUIREMENTS_BY_SOP_BLOCK_ARTIFACT_ID = 'PROD_REQ__HAS_QMS_REQUIREMENTS_BY_SOP_BLOCK_ARTIFACT_ID',
    HAS_SR_VIEW_RIGHTS_BY_REGULATION_ID = 'PROD_REQ__HAS_SR_VIEW_RIGHTS_BY_REGULATION_ID',
}

export enum Mutations {
    QMS_REQUIREMENTS_REPLACE = 'PRODUCT_REQUIREMENTS__QMS_REQUIREMENTS_REPLACE',
    REQUIREMENT_SEARCH_RESULT_REPLACE = 'PRODUCT_REQUIREMENTS__REQUIREMENT_SEARCH_RESULT_REPLACE',
    SELECTED_SOP_BLOC_IDENTIFIER_REPLACE = 'PRODUCT_REQUIREMENTS__SELECTED_SOP_BLOC_IDENTIFIER_REPLACE',
}


const getters = {
    [ProductRequirementsGetters.HAS_SR_VIEW_RIGHTS_BY_REGULATION_ID]: (state: ProductRequirementsState, getters: any, rootState: RootState) => (regulationId: string) => {
        const srRegulation = state.requirementSearchResult.regulations.find(reg => reg.id === regulationId);
        if (!srRegulation) {
            return false;
        }
        return hasUserSrViewRights(srRegulation.licenseTypeView, rootState.auth.user);
    },
    [ProductRequirementsGetters.HAS_QMS_REQUIREMENTS_BY_SOP_BLOCK_ARTIFACT_ID]: (state: ProductRequirementsState) => (sopBlockArtifactId: string) => {
        return Object.values(state.qmsRequirements)
            .some(qmsRequirement => qmsRequirement.targetSopBlockArtifactIds.includes(sopBlockArtifactId));
    },
}

const actions = {}


const toRequirementSearchFilter = (requirements: QmsRequirementMap): RequirementSearchFilter => {
    const requirementIdsPerRegulation: RequirementIdsPerRegulation = Object.values(requirements).reduce((result, entry) => {
        (result[entry.regulationId] = result[entry.regulationId] ?? []).push(entry.requirementId);
        return result;
    }, {} as RequirementIdsPerRegulation);
    return { requirementIdsPerRegulation }
}

const eventListeners = {
    [SopBlockListEvents.SOP_BLOCK_SELECTED]: ({ state, dispatch, commit }: ActionContext<ProductRequirementsState, RootState>, sopBlockSelectedEvent: SopBlockSelectedEvent) => {
        if (!sopBlockSelectedEvent) {
            commit(Mutations.SELECTED_SOP_BLOC_IDENTIFIER_REPLACE, emptyArtifactVersionIdentifier());
            return;
        }

        commit(Mutations.SELECTED_SOP_BLOC_IDENTIFIER_REPLACE, sopBlockSelectedEvent);

        return qmsRequirementService.findQmsRequirementsByTargetSopBlockArtifactId(sopBlockSelectedEvent.artifactId)
            .then(reqs => toRequirementSearchFilter(reqs))
            .then(searchFilter => requirementSearchService.search(searchFilter))
            .then(result => {
                commit(Mutations.REQUIREMENT_SEARCH_RESULT_REPLACE, result);
            })
    },
    [SopDetailEvents.SOP_DETAIL_CHANGED]: ({ state, dispatch, commit }: ActionContext<ProductRequirementsState, RootState>, sopDetailChangedEvent: ArtifactChangedEvent) => {
        if (!sopDetailChangedEvent) {
            commit(Mutations.QMS_REQUIREMENTS_REPLACE, {});
            commit(Mutations.REQUIREMENT_SEARCH_RESULT_REPLACE, emptySearchResult());
            return;
        }
        return qmsRequirementService.findQmsRequirementsBySopVersionId(sopDetailChangedEvent.versionId)
            .then(reqs => {
                commit(Mutations.QMS_REQUIREMENTS_REPLACE, reqs);
            })
    },
}

const mutations = {
    [Mutations.QMS_REQUIREMENTS_REPLACE]: (state: ProductRequirementsState, qmsRequirements: QmsRequirementMap) =>
        safeVueSet(state, 'qmsRequirements', qmsRequirements),
    [Mutations.REQUIREMENT_SEARCH_RESULT_REPLACE]: (state: ProductRequirementsState, requirementSearchResult: RequirementSearchResult) =>
        safeVueSet(state, 'requirementSearchResult', requirementSearchResult),
    [Mutations.SELECTED_SOP_BLOC_IDENTIFIER_REPLACE]: (state: ProductRequirementsState, sopDetailChangedEvent: SopBlockSelectedEvent) =>
        safeVueSet(state, 'selectedSopBlockEvent', sopDetailChangedEvent),
}

export const PRODUCT_REQUIREMENTS_MODULE: Module<ProductRequirementsState, RootState> = {
    state: new ProductRequirementsState(),
    getters,
    actions: { ...actions, ...eventListeners },
    mutations
};