






























import { Component, Vue } from 'vue-property-decorator';
import CoLayout from '@/layouts/CoLayout.vue';
import SopOverviewImage from '@/components/sop-overview/SopOverviewImage.vue';
import CoHeader from '@/components/common/CoHeader.vue';
import NewSopDialog from '@/components/sop-overview/NewSopDialog.vue';
import { SopActions } from '@/features/sops';
import SopOverview from '@/components/sop-overview/SopOverview.vue';
import SopNavigationTree from '@/features/sops/sop-navigation-tree/SopNavigationTree.vue';
import { TemplateContentActions } from '@/features/template-content/template-content-store';


@Component({
  components: { SopOverview, NewSopDialog, CoHeader, SopOverviewImage, CoLayout, SopNavigationTree },
})
export default class SopOverviewView extends Vue {

  overview = 'OVERVIEW';
  list = 'LIST';

  toggle: string = this.overview;

  created() {
    this.$store.dispatch(SopActions.SOPS_LOAD);
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_SEARCH);
  }

}
