


































































import Vue from 'vue';
import Component from 'vue-class-component';

import { Template } from '@/model/Template';
import { FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY, TemplateContentActions, TemplateContentFilter, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { mapGetters, mapState } from 'vuex';
import { emptyTemplateContentFilter } from '@/features/template-content/template-content-model';
import SopHierarchy from '@/features/sops/sop-hierarchiy/SopHierarchy.vue';
import { RootState } from '@/store';

@Component({
  components: { SopHierarchy },
  computed: {
    ...mapState<RootState>({
      selectedSopArtifactId: (state: RootState) => state.templateContents.filter.selectedSopArtifactId,
    }),
    ...mapGetters({
      allCreatingTemplateIds: TemplateContentGetters.ALL_CREATING_TEMPLATES,
      allReportingTemplateIds: TemplateContentGetters.ALL_REPORTING_TEMPLATES,
    })
  }
})
export default class TemplateContentFilterSidebar extends Vue {

  filterUnassigned = {
    name: 'Unassigned',
    artifactId: FILTER_UNASSIGNED_TEMPLATE_CONTENT_KEY
  };

  selectedSopArtifactId = '';
  filterText = '';
  filterCreatingTemplateArtifactIds: string[] = [];
  filterReportingTemplateArtifactIds: string[] = [];

  creatingTemplates: Template[] = [];
  reportingTemplates: Template[] = [];

  changeSelection(selectedSopArtifactId: string) {
    this.applyFilter({ selectedSopArtifactId });
  }

  applyFilter(partialFilter: Partial<TemplateContentFilter> = {}) {
    const templateContentFilter: TemplateContentFilter = {
      filterText: this.filterText ? this.filterText : '',
      filterCreatingTemplateArtifactIds: this.filterCreatingTemplateArtifactIds,
      filterReportingTemplateArtifactIds: this.filterReportingTemplateArtifactIds,
      selectedSopArtifactId: this.selectedSopArtifactId,
      ...partialFilter
    };
    this.$store.dispatch(TemplateContentActions.TEMPLATE_CONTENT_FILTER, templateContentFilter);
  }

  resetFilter() {
    const emptyFilter = emptyTemplateContentFilter();

    this.filterText = emptyFilter.filterText;
    this.filterCreatingTemplateArtifactIds = emptyFilter.filterCreatingTemplateArtifactIds;
    this.filterReportingTemplateArtifactIds = emptyFilter.filterReportingTemplateArtifactIds;

    this.applyFilter();
  }

}
