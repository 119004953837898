import { render, staticRenderFns } from "./StackedContent.vue?vue&type=template&id=034b77b5&scoped=true&"
import script from "./StackedContent.vue?vue&type=script&lang=ts&"
export * from "./StackedContent.vue?vue&type=script&lang=ts&"
import style0 from "./StackedContent.vue?vue&type=style&index=0&id=034b77b5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "034b77b5",
  null
  
)

export default component.exports