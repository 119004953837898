































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { TemplateContentActions, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { RoleGetters } from '@/store/roles';
import { TemplateDetailGetters } from '@/store/templates/template-detail';
import TemplateDetailDialog from '@/components/templates-detail/TemplateDetailDialog.vue';
import { copyTextToClipboard, openUrlInNewTab } from '@/utils/util';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';
import { FileStorageGetters } from '@/store/file-storage';
import { FileStorageConstants } from '@/model/FileStorage';
import FileDownload from '@/components/common/FileDownload.vue';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';


@Component({
  components: { TemplateContentChip, TemplateContentValidationIcons, TemplateDetailDialog, FileDownload },
  computed: {
    downloadStorageId() {
      return this.$store.getters[FileStorageGetters.FILE_STORAGE_BY_NAME_OR_UNDEFINED](FileStorageConstants.TEMPLATE_VERSION)?.id
    },
    ...mapState<RootState>({
      template: (state: RootState) => state.templates.template,
    }),
    ...mapGetters({
      editable: TemplateDetailGetters.TEMPLATE_IS_EDITABLE,
      templateContentsByIds: TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING,
      getRoleByIdOrEmpty: RoleGetters.ROLE_BY_ID_OR_EMPTY,
      templateContentValidationsById: TemplateContentGetters.TEMPLATE_CONTENT_VALIDATIONS_BY_ID,
    })
  },
  methods: {
    ...mapActions({
      selectTemplateContentDetailWidgetId: TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID
    })
  },
})
export default class TemplateDetail extends Vue {

  openLink(url: string) {
    openUrlInNewTab(url);
  }

  copyToClipboard(text: string) {
    copyTextToClipboard(text);
  }

}
