












































































import Component, { mixins } from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { createNewRole, Role } from '@/model/Role';
import { RoleActions } from '@/store/roles';
import { SrGetters } from '@/store/sr';
import { createComparator } from '@/utils/util';
import AuthMixin from '@/components/common/AuthMixin.vue';

@Component({
  computed: {
    ...mapState<RootState>({
      roles: (state: RootState) => Object.values(state.roles.roles),
      srRoles: (state: RootState) => [
        { id: '', name: '# Not Linked' },
        ...Object.values(state.sr.roles).sort(createComparator('name'))
      ]
    }),
    ...mapGetters({
      srRoleById: SrGetters.SR_ROLE_BY_ID
    })
  }
})
export default class RolesOverview extends mixins<AuthMixin>(AuthMixin) {

  rules = [
    (value: string) => (!!value && value.length >= 1) || 'Minimum 1 character',
  ];
  valid = false;
  editDialog = false;
  deleteDialog = false;
  role: Role = createNewRole();

  get headers() {
    const baseHeaders = [
      { text: 'Name', value: 'name', width: '25%', sortable: true },
      { text: 'Responsibility', value: 'responsibility', width: '25%' },
      { text: 'Authority', value: 'authority', width: '25%' },
      { text: 'Competence Requirements', value: 'requirements', width: '25%' },
    ];
    if (this.isQmsAdmin) {
      baseHeaders.push({ text: '', value: 'actions', width: '10%', sortable: false });
    }
    return baseHeaders;
  }

  confirmSave() {
    this.$store.dispatch(RoleActions.ROLE_CREATE, this.role)
        .then(() => this.cancel());
  }

  confirmDelete() {
    this.$store.dispatch(RoleActions.ROLE_DELETE, this.role.id)
        .then(() => this.cancel());
  }

  cancel() {
    this.role = createNewRole();
    this.editDialog = false;
    this.deleteDialog = false;
  }

  editItem(role: Role) {
    this.role = { ...role };
    this.editDialog = true;
  }

  deleteItem(role: Role) {
    this.role = { ...role };
    this.deleteDialog = true;
  }

  createNew() {
    this.editDialog = true;
  }

}

