export  type ArtifactType = 'SOP' | 'TEMPLATE';
export  type FileStorageType = 'SOP_AS_PDF' | 'TEMPLATE_VERSION';

export class FileStorageConstants {
    public static SOP_AS_PDF_NAME = 'sop-as-pdf';
    public static TEMPLATE_VERSION = 'template-version';
}

export type FileStorage = {
    id: string;
    dbVersion: number;
    artifactType: ArtifactType;
    artifactVersionId: string;
    fileStorageType: FileStorageType;
    name: string;
}

const EMPTY_FILE_STORAGE: FileStorage = {
    id: '',
    dbVersion: 0,
    artifactType: 'SOP',
    artifactVersionId: '',
    fileStorageType: 'SOP_AS_PDF',
    name: '',
}

export function createEmptyFileStorage(): FileStorage {
    return { ...EMPTY_FILE_STORAGE };
}

export function createForSop(artifactVersionId: string): FileStorage {
    return {
        ...createEmptyFileStorage(),
        artifactType: 'SOP',
        artifactVersionId,
        fileStorageType: 'SOP_AS_PDF',
        name: FileStorageConstants.SOP_AS_PDF_NAME,
    };
}

export function createForTemplate(artifactVersionId: string): FileStorage {
    return {
        ...createEmptyFileStorage(),
        artifactType: 'TEMPLATE',
        artifactVersionId,
        fileStorageType: 'TEMPLATE_VERSION',
        name: FileStorageConstants.TEMPLATE_VERSION,
    };
}