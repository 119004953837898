var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"co-table",attrs:{"loading":_vm.regulations.length === 0,"headers":_vm.headers,"items":_vm.regulations,"items-per-page":100,"options":_vm.tableOptions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.goToDetail(item)}}},[_vm._v(_vm._s(item.title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","data-test-edit-qms-regulation-btn":item.regulationId},on:{"click":function($event){return _vm.editQmsRegulation(item.regulationId)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.applicabilityProgress",fn:function(ref){
var item = ref.item;
return [_c('co-horizontal-bar',{attrs:{"data":_vm.applicabilityData(item)}})]}},{key:"item.evidenceProgress",fn:function(ref){
var item = ref.item;
return [_c('co-horizontal-bar',{attrs:{"data":_vm.evidenceData(item)}})]}}])}),_c('v-dialog',{attrs:{"persistent":true,"max-width":"35%"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"heading"},[_vm._v(_vm._s(_vm.editingRegulation.title))])]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.regulationStates,"value":_vm.editingRegulation.state,"label":"Regulation State","outlined":""},on:{"input":_vm.setState}}),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.editingRegulation.activeFrom,"label":"Effective From","prepend-icon":"mdi-calendar","readonly":"","outlined":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.effectiveFromMenu),callback:function ($$v) {_vm.effectiveFromMenu=$$v},expression:"effectiveFromMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.editingRegulation.activeFrom,"no-title":"","scrollable":""},on:{"input":_vm.setActiveFrom}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.effectiveFromMenu = false}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.editingRegulation.activeTo,"label":"Effective To","prepend-icon":"mdi-calendar","readonly":"","outlined":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.effectiveToMenu),callback:function ($$v) {_vm.effectiveToMenu=$$v},expression:"effectiveToMenu"}},[_c('v-date-picker',{attrs:{"value":_vm.editingRegulation.activeTo,"no-title":"","scrollable":""},on:{"input":_vm.setActiveTo}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.effectiveToMenu = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveEditingQmsRegulation}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }