var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"35%"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this template?")]),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.closeDeleteDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","data-test-delete-confirm-btn":""},on:{"click":_vm.confirmDelete}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"co-table",attrs:{"headers":_vm.headers,"items":_vm.templates,"fixed-header":true,"height":"calc(100vh - 280px)","items-per-page":250,"hide-default-footer":"","item-class":function (item) { return 'test-template-item--' + item.versionId; }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.name))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.location)?_c('v-icon',_vm._g({staticClass:"ma-1 float-right",attrs:{"small":""},on:{"click":function($event){return _vm.copyToClipboard(item.location)}}},on),[_vm._v("mdi-clipboard-text")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Copy template location to clipboard: "+_vm._s(item.location))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.location)?_c('v-icon',_vm._g({staticClass:"ma-1 float-right",attrs:{"small":""},on:{"click":function($event){return _vm.openLink(item.location)}}},on),[_vm._v("mdi-open-in-new")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Open template location in new window: "+_vm._s(item.location))])])]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"color":"grey","small":"","outlined":""}},[_vm._v("v"+_vm._s(item.version)+" "+_vm._s(item.releaseState))])]}},{key:"item.reviewerRoleId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoleByIdOrEmpty(item.reviewerRoleId).name)+" ")]}},{key:"item.releaserRoleId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRoleByIdOrEmpty(item.releaserRoleId).name)+" ")]}},{key:"item.createdTemplateContentIds",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test-template-content":item.versionId}},_vm._l((_vm.templateContentsByIds(item.createdTemplateContentIds)),function(templateContent){return _c('template-content-chip',{key:templateContent.id,attrs:{"template-content":templateContent,"template-content-validations":_vm.templateContentValidationsById(templateContent.id),"data-test-created-tp":templateContent.id},on:{"click":function($event){return _vm.selectTemplateContentDetailWidgetId(templateContent.id)}}})}),1)]}},{key:"item.reportedTemplateContentIds",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-test-reported-content":item.versionId}},_vm._l((_vm.templateContentsByIds(item.reportedTemplateContentIds)),function(templateContent){return _c('template-content-chip',{key:templateContent.id,attrs:{"template-content":templateContent,"template-content-validations":_vm.templateContentValidationsById(templateContent.id),"data-test-reported-tp":templateContent.id},on:{"click":function($event){return _vm.selectTemplateContentDetailWidgetId(templateContent.id)}}})}),1)]}},{key:"item.processes",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.sopsByTemplateId(item.versionId)),function(sop){return _c('v-chip',{key:sop.versionId,staticClass:"ma-1",attrs:{"small":"","outlined":"","data-test-used-in-sop":item.versionId + '--' + sop.versionId}},[_c('router-link',{attrs:{"to":("/processes/" + (sop.versionId))}},[_vm._v(" "+_vm._s(sop.name)+" (v"+_vm._s(sop.version)+" "+_vm._s(sop.releaseState)+") ")])],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","data-test-go-to-detail-template-btn":item.versionId},on:{"click":function($event){return _vm.goToDetail(item.versionId)}}},[_vm._v("mdi-pencil")]),(_vm.canBeDeleted(item.releaseState) && _vm.isQmsAdmin)?_c('v-icon',{attrs:{"small":"","data-test-delete-template-btn":item.versionId},on:{"click":function($event){return _vm.deleteItem(item.versionId)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }