












import Component, { mixins } from 'vue-class-component';
import SopBlockDetailLayout from '@/features/sop-block/sop-block-detail/SopBlockDetailLayout.vue';
import SopBlockDetailMixin from '@/features/sop-block/sop-block-detail/SopBlockDetailMixin.vue';

@Component({
  components: { SopBlockDetailLayout },
})
export default class SopBlockDetailHeader extends mixins<SopBlockDetailMixin>(SopBlockDetailMixin) {

}
