var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn-toggle',{staticClass:"mt-1",attrs:{"active-class":"data-test-active-btn","mandatory":""},model:{value:(_vm.qmsApplicable),callback:function ($$v) {_vm.qmsApplicable=$$v},expression:"qmsApplicable"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"NOT_DEFINED","disabled":_vm.isDisabled('NOT_DEFINED'),"small":"","data-test-applicability-not-defined":""}},on),[_c('v-icon',{attrs:{"color":_vm.notDefinedHasWarning ? 'warning' : '',"small":""}},[_vm._v("mdi-help-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Not yet defined")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"NOT_APPLICABLE","disabled":_vm.isDisabled('NOT_APPLICABLE'),"small":"","data-test-applicability-not-applicable":""}},on),[_c('v-icon',{attrs:{"color":_vm.notApplicableHasWarning ? 'warning' : '',"small":""}},[_vm._v("mdi-minus-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Not applicable")]),(_vm.reasonForNotApplicableIsMissing)?_c('span',[_vm._v(" - applicability reason is missing.")]):(_vm.notApplicableHasWarning)?_c('span',[_vm._v(" - assigned evidences are not necessary.")]):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"INFORMATION","disabled":_vm.isDisabled('INFORMATION'),"small":"","data-test-applicability-information":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('span',[_vm._v("Information only")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"APPLICABLE","disabled":_vm.isDisabled('APPLICABLE'),"light":"","small":"","data-test-applicability-applicable":""}},on),[_c('v-icon',{attrs:{"color":_vm.applicableHasWarning ? 'warning' : (_vm.applicableIsSuccessfully ? 'success' : ''),"small":""}},[_vm._v("mdi-check-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Applicable")]),(_vm.applicableHasWarning)?_c('span',[_vm._v(" - at least one target process or template content is required.")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }