import { QmsConfigKey } from '@/store/qms-config';
import { restClient } from '@/store/rest-client';

export interface QmsConfigService {

    getStringValue(configKey: QmsConfigKey): Promise<string>;

    setStringValue(key: QmsConfigKey, value: string): Promise<string>;
}

const API_QMS_CONFIG = '/qms-api/qms-config';

class QmsConfigBackendService implements QmsConfigService {

    getStringValue(configKey: QmsConfigKey): Promise<string> {
        return restClient.get<string>(`${ process.env.VUE_APP_QMS_BASE_URL + API_QMS_CONFIG }/${ configKey }/string-value`)
            .then(response => response.data);
    }

    setStringValue(configKey: QmsConfigKey, value: string): Promise<string> {
        return restClient.put<string>(`${ process.env.VUE_APP_QMS_BASE_URL + API_QMS_CONFIG }/${ configKey }/string-value`, value)
            .then(response => response.data);
    }

}

class QmsConfigServiceMock implements QmsConfigService {

    getStringValue(configKey: QmsConfigKey): Promise<string> {
        return Promise.resolve('some svg stuff');
    }

    setStringValue(configKey: QmsConfigKey, value: string): Promise<string> {
        return Promise.resolve(value);
    }
}

export const qmsConfigService: QmsConfigService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new QmsConfigServiceMock() : new QmsConfigBackendService();