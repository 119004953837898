import { Template, TemplateOverallStatistics } from '@/model/Template';
import { TemplateMap } from '@/store/templates';
import { TemplateService } from '@/services/template-service';
import { toMapWithValue } from '@/utils/util';
import { v4 as uuidv4 } from 'uuid';
import { ReleaseState } from '@/model/ReleaseState';
import { EMPTY_SOP } from '@/model';

export class TemplateServiceMock implements TemplateService {

    static templates: Template[] = [];

    loadTemplates(): Promise<TemplateMap> {
        return Promise.resolve(toMapWithValue(
            [...TemplateServiceMock.templates], t => t.versionId ? t.versionId : '',
            template => ({ ...template })));
    }

    statistics(): Promise<TemplateOverallStatistics> {
        return Promise.reject('not yet implemented');
    }

    createTemplate(templateContent: Template): Promise<Template> {
        const newTemplate = {
            ...templateContent,
            versionId: uuidv4()
        }
        TemplateServiceMock.templates.push(newTemplate);
        return Promise.resolve({ ...newTemplate });
    }

    updateTemplate(templateContent: Template): Promise<Template> {
        const index = TemplateServiceMock.templates.findIndex(t => t.versionId === templateContent.versionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing template by its id');
        }
        TemplateServiceMock.templates.splice(index, 1, { ...templateContent });
        return Promise.resolve({ ...templateContent });
    }

    deleteTemplate(templateVersionId: string): Promise<void> {
        const index = TemplateServiceMock.templates.findIndex(t => t.versionId === templateVersionId);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing template by its version id');
        }
        TemplateServiceMock.templates.splice(index, 1);
        return Promise.resolve();
    }

    loadTemplate(templateVersionId: string): Promise<Template> {
        const template = TemplateServiceMock.templates.find(t => t.versionId === templateVersionId);
        if (!template) {
            return Promise.reject('Can\'t find the template by its version id');
        }
        return Promise.resolve({ ...template });
    }

    changeReleaseState(template: Template, releaseState: ReleaseState): Promise<void> {
        TemplateServiceMock.findTemplateByVersionId(template.versionId).releaseState = releaseState;
        return Promise.resolve();
    }

    createNewVersion(template: Template): Promise<Template> {
        const releasedTemplate = TemplateServiceMock.templates.find(s => s.artifactId === template.artifactId);
        if (!releasedTemplate) {
            throw new Error('Template with following artifact not found: ' + template.artifactId);
        }
        const newTemplate = {
            ...releasedTemplate,
            versionId: uuidv4(),
            version: template.version + 1,
            releaseState: EMPTY_SOP.releaseState,
        }
        TemplateServiceMock.templates.push({ ...newTemplate });
        return Promise.resolve({ ...newTemplate });
    }

    static findTemplateByVersionId(templateVersionId: string): Template {
        const template = TemplateServiceMock.templates.find(t => t.versionId === templateVersionId);
        if (!template) {
            throw new Error('Template with following version not found: ' + templateVersionId);
        }
        return { ...template };
    }

    static assertTemplateInDraft(templateVersionId: string) {
        if (this.findTemplateByVersionId(templateVersionId).releaseState !== 'DRAFT') {
            throw new Error(`Template ${ templateVersionId } must be in DRAFT to be editable`);
        }
    }

}