import { Role } from '@/model/Role';
import { RoleService } from '@/services/role-service';
import { RoleMap } from '@/store/roles';
import { toMap } from '@/utils/util';
import { v4 as uuidv4 } from 'uuid';

export class RoleServiceMock implements RoleService {

    static roles: Role[] = [];

    loadRoles(): Promise<RoleMap> {
        return Promise.resolve(toMap(
            [...RoleServiceMock.roles], t => t.id ? t.id : ''));
    }

    createRole(role: Omit<Role, 'id'>): Promise<Role> {
        const newRole = {
            ...role,
            id: uuidv4()
        }
        RoleServiceMock.roles.push(newRole);
        return Promise.resolve({ ...newRole });
    }

    updateRole(role: Role): Promise<Role> {
        const index = RoleServiceMock.roles.findIndex(t => t.id === role.id);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing role by its id');
        }
        RoleServiceMock.roles.splice(index, 1, { ...role });
        return Promise.resolve({ ...role });
    }

    deleteRole(roleId: string): Promise<void> {
        const index = RoleServiceMock.roles.findIndex(t => t.id === roleId);
        if (index < 0) {
            return Promise.reject('Can\'t find the editing role by its id');
        }
        RoleServiceMock.roles.splice(index, 1);
        return Promise.resolve();
    }
}