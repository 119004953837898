import { User } from '@/model/User';
import { UserMap } from '@/store/users';
import { restClient } from '@/store/rest-client';
import { toMap } from '@/utils/util';
import { UserServiceMock } from '@/services/user-service-mock';

export interface UserService {

    loadUsers(): Promise<UserMap>;

    syncUsers(): Promise<void>;

}

const API_USER = '/qms-api/users';

class UserBackendService implements UserService {

    loadUsers(): Promise<UserMap> {
        return restClient.get<User[]>(process.env.VUE_APP_QMS_BASE_URL + API_USER)
            .then(response => response.data)
            .then(users => toMap(users, c => c.userId));
    }

    syncUsers(): Promise<void> {
        return restClient.get<void>(process.env.VUE_APP_QMS_BASE_URL + API_USER + '/sync').then();
    }

}

export const userService: UserService = process.env.VUE_APP_PROD_BACKEND === 'false' ? new UserServiceMock() : new UserBackendService();