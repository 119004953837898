












































































import Component, { mixins } from 'vue-class-component';
import { mapActions, mapGetters } from 'vuex';
import { TemplateContentActions, TemplateContentGetters } from '@/features/template-content/template-content-store';
import { RoleGetters } from '@/store/roles';
import { TemplateActions, TemplateGetters } from '@/store/templates';
import { releaseState, ReleaseState } from '@/model/ReleaseState';
import { copyTextToClipboard, openUrlInNewTab } from '@/utils/util';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';
import AuthMixin from '@/components/common/AuthMixin.vue';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';

@Component({
  components: { TemplateContentChip, TemplateContentValidationIcons },
  computed: {
    ...mapGetters({
      templates: TemplateGetters.TEMPLATE_SEARCH_RESULT_FILTERED_BY_SOP,
      sopsByTemplateId: TemplateGetters.SOPS_USED_BY_TEMPLATE_ID,
      getRoleByIdOrEmpty: RoleGetters.ROLE_BY_ID_OR_EMPTY,
      templateContentsByIds: TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING,
      templateContentValidationsById: TemplateContentGetters.TEMPLATE_CONTENT_VALIDATIONS_BY_ID,
    })
  },
  methods: {
    ...mapActions({
      selectTemplateContentDetailWidgetId: TemplateContentActions.TEMPLATE_CONTENT_DETAIL_WIDGET_SELECT_ID
    })
  },
})
export default class TemplatesOverview extends mixins<AuthMixin>(AuthMixin) {

  headers = [
    { text: 'Name', value: 'name', width: '20%' },
    { text: 'Document Key', value: 'documentKey', width: '10%' },
    { text: 'Version', value: 'version', width: '10%', sortable: false },
    { text: 'Template Contents', value: 'createdTemplateContentIds', width: '15%' },
    { text: 'Reported Contents', value: 'reportedTemplateContentIds', width: '15%' },
    { text: 'Used in Processes', value: 'processes', width: '25%', sortable: false },
    { text: '', value: 'actions', width: '5%', sortable: false },
  ];
  deleteDialog = false;
  templateVersionId?: string;

  confirmDelete() {
    this.$store.dispatch(TemplateActions.TEMPLATE_DELETE, this.templateVersionId)
        .then(() => this.closeDeleteDialog());
  }

  closeDeleteDialog() {
    this.templateVersionId = undefined;
    this.deleteDialog = false;
  }

  goToDetail(templateId: string): void {
    this.$router.push({ name: 'TemplateDetailView', params: { id: templateId } });
  }

  canBeDeleted(templateReleaseState: ReleaseState): boolean {
    return releaseState(templateReleaseState).canBeDeleted();
  }

  deleteItem(templateId: string) {
    this.templateVersionId = templateId;
    this.deleteDialog = true;
  }

  openLink(url: string) {
    openUrlInNewTab(url);
  }

  copyToClipboard(text: string) {
    copyTextToClipboard(text);
  }

}

