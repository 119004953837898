export type ImpactAnalysisSearch = {
    impactAnalyses: ImpactAnalysis[];
    statistics: ImpactAnalysisStatistics[];
}

export type ImpactAnalysis = {
    id: string;
    title: string;
    regulationIdFrom: string;
    regulationIdTo: string;
}

export type ImpactAnalysisStatistics = {
    impactAnalysisId: string;
    numberOfMappedToRequirements: number;
    totalNumberOfToRequirements: number;
    numberOfMappedFromRequirements: number;
    totalNumberOfFromRequirements: number;
    numberOfImpact: number;
}

export type ImpactAnalysisStatisticMap = { [key: string]: ImpactAnalysisStatistics };

export const NEW_IMPACT_ANALYSIS_ID = 'NEW_IMPACT_ANALYSIS_ID';

export const createNewImpactAnalysis: () => ImpactAnalysis = () => ({
    id: NEW_IMPACT_ANALYSIS_ID,
    title: '',
    regulationIdFrom: '',
    regulationIdTo: ''
});

export const createEmptyStatistics = () => ({
    impactAnalysisId: '',
    numberOfMappedFromRequirements: 0,
    numberOfMappedToRequirements: 0,
    totalNumberOfFromRequirements: 0,
    totalNumberOfToRequirements: 0,
    numberOfImpact: 0,
}) as ImpactAnalysisStatistics

export const isNew = (impactAnalysis: ImpactAnalysis) => impactAnalysis.id === NEW_IMPACT_ANALYSIS_ID;