













































import Vue from 'vue';
import Component from 'vue-class-component';
import { TemplateContent } from '@/features/template-content/template-content-model';
import { TemplateSearchPart } from '@/model/Template';
import { mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import { SopSearchPart } from '@/model';
import RequirementSearchByIds from '@/features/sr-requirement-search/RequirementSearchByIds.vue';
import RequirementSearchResultPanels from '@/features/sr-requirement-search/RequirementSearchResultPanels.vue';
import { RequirementIdsPerRegulation } from '@/features/sr-requirement-search/service/requirement-search-service';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';

@Component({
  components: { TemplateContentValidationIcons, RequirementSearchResultPanels, RequirementSearchByIds },
  computed: {
    ...mapState<RootState>({
      templateContentId: (state: RootState) => state.templateContents.detailWidgetTemplateContentId,
      requirementIdsPerRegulation: (state: RootState) => state.templateContents.detailWidgetRequirementIdsPerRegulation,
    }),
    ...mapGetters({
      templateContentById: TemplateContentGetters.TEMPLATE_CONTENT_BY_ID_OR_EMPTY,
      sopsByTemplateContentId: TemplateContentGetters.SOPS_USED_BY_TEMPLATE_CONTENT_ID,
      templateContentValidationsById: TemplateContentGetters.TEMPLATE_CONTENT_VALIDATIONS_BY_ID,
      templatesCreatingContentById: TemplateContentGetters.TEMPLATES_CREATING_CONTENT_BY_ID,
      templatesReportingContentById: TemplateContentGetters.TEMPLATES_REPORTING_CONTENT_BY_ID,
    })
  }
})
export default class TemplateContentDetailWidget extends Vue {

  templateContentId: string;
  requirementIdsPerRegulation: RequirementIdsPerRegulation;

  templateContentById: (templateContentId: string) => TemplateContent;
  sopsByTemplateContentId: (templateContentId: string) => SopSearchPart[];
  templatesCreatingContentById: (templateContentId: string) => TemplateSearchPart[];
  templatesReportingContentById: (templateContentId: string) => TemplateSearchPart[];

  get templateContent(): TemplateContent {
    return this.templateContentById(this.templateContentId);
  }

  get usedInProcesses(): SopSearchPart[] {
    return this.sopsByTemplateContentId(this.templateContentId);
  }

  get creatingTemplates(): TemplateSearchPart[] {
    return this.templatesCreatingContentById(this.templateContentId);
  }

  get reportingTemplates(): TemplateSearchPart[] {
    return this.templatesReportingContentById(this.templateContentId);
  }

}
