














































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Mutations as RegulationDetailMutations, RegulationDetailActions, RegulationDetailGetters } from '@/store/regulation-detail';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { RootState } from '@/store';
import TemplateContentValidationIcons from '@/components/templates-overview/TemplateContentValidationIcons.vue';
import QmsRequirementCurrent from '@/components/common/QmsRequirementCurrent.vue';
import SopQmsRequirementProposal from '@/components/regulations-detail/SopQmsRequirementProposal.vue';
import { QmsRequirement } from '@/services/model';

@Component<SopQmsRequirement>({
  components: { SopQmsRequirementProposal, QmsRequirementCurrent, TemplateContentValidationIcons },
  computed: {
    ...mapState<RootState>({
      isEditingRequirement(this: SopQmsRequirement, state: RootState) {
        return this.requirementId === state.regulationDetail.editingRequirementId;
      },
    }),
    ...mapGetters({
      qmsRequirementBySrRequirementId: RegulationDetailGetters.QMS_REQUIREMENT_BY_REQUIREMENT_ID,
    }),
  },
  methods: {
    ...mapActions({
      deleteQmsRequirement: RegulationDetailActions.QMS_REQUIREMENT_DELETE,
    }),
    ...mapMutations({
      editRequirement: RegulationDetailMutations.EDITING_REQUIREMENT_ID_REPLACE
    })
  },
})
export default class SopQmsRequirement extends Vue {

  @Prop() requirementId: string;
  @Prop() editable: boolean;
  @Prop() level: number;

  deleteQmsRequirement: (qmsRequirementId: string) => Promise<void>;

  qmsRequirementBySrRequirementId: (qmsRequirementId: string) => QmsRequirement;


  deleteInterpretation() {
    const requirementId = this.qmsRequirementBySrRequirementId(this.requirementId).requirementId;
    return this.deleteQmsRequirement(requirementId);
  }
}
