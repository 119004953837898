








import Vue from 'vue';
import Component from 'vue-class-component';
import SopPurpose from '@/components/sop-detail/SopPurpose.vue';
import SopGlossary from '@/components/sop-detail/SopGlossary.vue';
import SopBlockList from '@/features/sop-block/sop-block-list/SopBlockList.vue';

@Component({
  components: { SopPurpose, SopGlossary, SopBlockList }
})
export default class SopContent extends Vue {

}
