




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FileDownloadResponse, fileStorageService } from '@/services/file-storage-service';
import { UrlHelper } from '@/utils/UrlHelper';

@Component
export default class FileDownload extends Vue {

  @Prop()
  storageId: string;

  @Prop({ default: 'Download' })
  text: string;

  downloadContent() {
    fileStorageService.download(this.storageId)
        .then(this.downloadToClient)
  }

  private downloadToClient(fileDownloadResponse: FileDownloadResponse) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      window.navigator.msSaveOrOpenBlob(fileDownloadResponse.blob);
      return;
    }

    const anchor = document.createElement('a');
    const objectURL = UrlHelper.createObjectURL(fileDownloadResponse.blob);
    anchor.href = objectURL;
    anchor.download = fileDownloadResponse.filename;
    anchor.click();

    // For Firefox it is necessary to delay revoking the ObjectURL
    setTimeout(() => UrlHelper.revokeObjectURL(objectURL), 60);
  }
}
