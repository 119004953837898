





































































































import Vue from 'vue'
import Component from 'vue-class-component';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { CombinedRegulation, definedApplicabilityWithoutWarningCount, definedApplicabilityWithWarningCount, evidenceWarningCount, RegulationState, SrRegulation } from '@/features/regulations/model';
import { RegulationActions, RegulationGetters, RegulationHelper, RegulationMutations } from '@/features/regulations/regulation-store';
import { RootState } from '@/store';
import CoHorizontalBar, { CoHorizontalBarConfig } from '@/components/common/CoHorizontalBar.vue';

@Component({
  components: { CoHorizontalBar },
  computed: {
    ...mapState<RootState>({
      editingQmsRegulationId: (state: RootState) => state.regulations.editingQmsRegulationId,
    }),
    ...mapGetters({
      regulations: RegulationGetters.COMBINED_REGULATIONS,
      editingRegulation: RegulationGetters.EDITING_REGULATION,
    })
  },
  methods: {
    ...mapActions({
      saveEditingQmsRegulation: RegulationActions.QMS_REGULATION_SAVE_EDITING,
    }),
    ...mapMutations({
      editQmsRegulation: RegulationMutations.EDITING_QMS_REGULATION_ID_REPLACE,
      ...RegulationHelper.setterForQmsRegulationFields('state', 'activeFrom', 'activeTo'),
    })
  }
})
export default class RegulationsOverview extends Vue {

  headers = [
    { text: 'Title', value: 'title' },
    { text: 'State', value: 'state' },
    { text: 'Effective From', value: 'activeFrom' },
    { text: 'Effective To', value: 'activeTo' },
    { text: 'Applicability Progress', value: 'applicabilityProgress', sortable: false },
    { text: 'Evidence Progress', value: 'evidenceProgress', sortable: false },
    { text: '', value: 'actions', width: '5%', sortable: false },
  ];

  tableOptions = {
    sortBy: ['title']
  }

  editingQmsRegulationId: string;
  editQmsRegulation: (regulationId?: string) => void;

  effectiveFromMenu = false;
  effectiveToMenu = false;

  get editDialog() {
    return this.editingQmsRegulationId.length > 0;
  }

  set editDialog(val: boolean) {
    if (!val) this.editQmsRegulation(undefined);
  }

  get regulationStates() {
    return Object.keys(RegulationState);
  }

  goToDetail(item: SrRegulation): void {
    this.$router.push({ name: 'RegulationsDetailView', params: { id: item.id } });
  }

  applicabilityData(item: CombinedRegulation): CoHorizontalBarConfig[] {
    return [
      {
        label: 'without warning',
        count: definedApplicabilityWithoutWarningCount(item),
        color: '#69c88c',
      }, {
        label: 'with warning',
        count: definedApplicabilityWithWarningCount(item),
        color: '#f49f0a',
      }, {
        label: 'not yet defined',
        count: item.notDefinedCount,
        color: '#fbd99d',
      }
    ];
  }

  evidenceData(item: CombinedRegulation): CoHorizontalBarConfig[] {
    return [
      {
        label: 'confirmed evidences',
        count: item.confirmedEvidenceCount,
        color: '#69c88c',
      }, {
        label: 'evidences with warning',
        count: evidenceWarningCount(item),
        color: '#f49f0a',
      }
    ];
  }
}

