var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"co-table",attrs:{"headers":_vm.headers,"fixed-header":true,"height":"calc(100vh - 228px)","items":_vm.sops,"items-per-page":2500,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"persistent":true,"max-width":"800px","scrollable":""},model:{value:(_vm.orderDialog),callback:function ($$v) {_vm.orderDialog=$$v},expression:"orderDialog"}},[_c('sop-ordering',{attrs:{"parent-artifact-id":_vm.sopParentArtifactId},on:{"ordered-artifact-ids-changed":_vm.closeOrder}})],1),_c('v-dialog',{attrs:{"max-width":"35%"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this Process? ")]),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.closeDeleteDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","data-test-delete-confirm-btn":""},on:{"click":_vm.confirmDelete}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h4',{class:'ml-' + ((_vm.sopLevelByArtifactId(item.artifactId) -1) * 4)},[_c('a',{attrs:{"data-test-go-to-detail-sop-link":item.versionId},on:{"click":function($event){return _vm.goToDetail(item.versionId)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"py-3 text-pre-line"},[_vm._v(_vm._s(item.description))])]}},{key:"item.requirementType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"py-3 text-pre-line"},[_vm._v(_vm._s(item.requirementType))])]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mr-1",attrs:{"color":"grey","small":"","outlined":""}},[_vm._v("v"+_vm._s(item.version)+" "+_vm._s(item.releaseState))])]}},{key:"item.templateContentIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.templateContentIds),function(templateContentId){return _c('template-content-chip',{key:templateContentId,attrs:{"template-content":_vm.templateContentById(templateContentId)}})})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","data-test-open-ordering-btn":item.versionId},on:{"click":function($event){return _vm.openOrderDialog(item.artifactId)}}},[_vm._v("mdi-format-list-numbered")]),_c('sop-detail-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":!_vm.canBeEdited(item.releaseState),"small":"","data-test-edit-sop-btn":item.versionId},on:{"click":function($event){return _vm.loadSop(item.versionId)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil ")])]}}],null,true)}),_c('v-icon',{attrs:{"disabled":!_vm.canBeEdited(item.releaseState),"small":"","data-test-delete-sop-btn":item.versionId},on:{"click":function($event){return _vm.openDeleteDialog(item.versionId)}}},[_vm._v("mdi-delete")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }