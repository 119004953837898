
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class TimingMixin extends Vue {

  timeoutHandlers: number[] = [];
  intervalHandlers: number[] = [];

  createTimeout(callback: TimerHandler, timeout: number): number {
    const timeoutHandler = setTimeout(callback, timeout);
    this.timeoutHandlers.push(timeoutHandler);
    return timeoutHandler;
  }

  createInterval(callback: TimerHandler, timeout: number): number {
    const timeoutHandler = setInterval(callback, timeout);
    this.intervalHandlers.push(timeoutHandler);
    return timeoutHandler;
  }

  beforeDestroy() {
    this.timeoutHandlers.forEach(h => clearTimeout(h));
    this.intervalHandlers.forEach(h => clearInterval(h));
  }
}
