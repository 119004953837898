

































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { RootState } from '@/store';

@Component({
  computed: {
    ...mapState<RootState>({
      sopDocumentHistory: (state: RootState) => state.sopDetail.sopDocumentHistory,
      unreleasedDocumentHistory: (state: RootState) => state.sopDetail.unreleasedDocumentHistory,
    })
  }
})
export default class SopDocumentHistory extends Vue {

  headers = [
    { text: 'Version', value: 'version' },
    { text: 'Date', value: 'releasedAt' },
    { text: 'Authors', value: 'authorDisplayNames' },
    { text: 'Description', value: 'changeDescription' },
  ];

}
