import { ImpactService, ImpactsSearchResult, LoadImpactAnalysisResponse } from '@/features/impacts/impact-service';
import { ImpactDto, ImpactMap } from '@/features/impacts/model';
import { SrRegulationServiceMock } from '@/services/sr-regulation-service-mock';
import { createEmptyRequirement, RequirementContentType } from '@/features/sr-model/Requirement';
import { checkUndefined, toMap } from '@/utils/util';
import { v4 as uuidv4 } from 'uuid';
import { createEmptyStatistics } from '@/features/impact-analysis/model';


export class ImpactServiceMock implements ImpactService {

    public static readonly IMPACT_ANALYSIS_A = 'impact-analysis-a';

    static response?: LoadImpactAnalysisResponse;

    public static initWithGivenImpactA(partial: Partial<ImpactDto> = {}) {
        ImpactServiceMock.response = {
            impactAnalysis: {
                id: this.IMPACT_ANALYSIS_A,
                title: 'Impact Analysis A',
                regulationIdFrom: 'iso-1',
                regulationIdTo: 'iso-2',
            },
            statistics: { ...createEmptyStatistics(), impactAnalysisId: this.IMPACT_ANALYSIS_A },
            impacts: {
                ...this.createImpact('impact-a', partial),
            },
        }
        this.initSr();
    }

    public static init() {
        ImpactServiceMock.response = ImpactServiceMock.createResponse();
        this.initSr();
    }

    private static initSr() {
        SrRegulationServiceMock.requirementsIso1 = [
            { ...createEmptyRequirement('iso-1-h-1', { type: RequirementContentType.HEADING, text: 'Heading iso-1-h-1', requirementIds: ['iso-1-req-1'] }), },
            { ...createEmptyRequirement('iso-1-req-1', { text: 'Requirement iso-1-req-1', parentId: 'iso-1-h-1' }), },
            { ...createEmptyRequirement('iso-1-req-2', { text: 'Requirement iso-1-req-2' }), },
            { ...createEmptyRequirement('iso-1-req-3', { text: 'Requirement iso-1-req-3' }), },
            { ...createEmptyRequirement('iso-1-req-4', { text: 'Requirement iso-1-req-4' }), },
            { ...createEmptyRequirement('iso-1-req-5', { text: 'Requirement iso-1-req-5' }), },
        ];
        SrRegulationServiceMock.requirementsIso2 = [
            { ...createEmptyRequirement('iso-2-h-1', { type: RequirementContentType.HEADING, text: 'Heading iso-2-h-1' }), },
            { ...createEmptyRequirement('iso-2-req-1', { text: 'Requirement iso-2-req-1' }), },
            { ...createEmptyRequirement('iso-2-req-2', { text: 'Requirement iso-2-req-2' }), },
            { ...createEmptyRequirement('iso-2-req-3', { text: 'Requirement iso-2-req-3' }), },
            { ...createEmptyRequirement('iso-2-req-4', { text: 'Requirement iso-2-req-4' }), },
            { ...createEmptyRequirement('iso-2-req-5', { text: 'Requirement iso-2-req-5' }), },
            { ...createEmptyRequirement('iso-2-req-6', { text: 'Requirement iso-2-req-6' }), },
        ];
    }

    static createResponse(): LoadImpactAnalysisResponse {
        return {
            impactAnalysis: {
                id: this.IMPACT_ANALYSIS_A,
                title: 'Impact Analysis A',
                regulationIdFrom: 'iso-1',
                regulationIdTo: 'iso-2',
            },
            statistics: { ...createEmptyStatistics(), impactAnalysisId: this.IMPACT_ANALYSIS_A },
            impacts: {
                ...this.createImpact('impact-a', {
                    impactType: 'NOT_DEFINED',
                    requirementIdsFrom: ['iso-1-req-2', 'iso-1-req-3'],
                    requirementIdsTo: ['iso-2-req-2'],
                }),
                ...this.createImpact('impact-b', {
                    impactType: 'NOT_DEFINED',
                    requirementIdsFrom: ['iso-1-req-4'],
                    requirementIdsTo: ['iso-2-req-4', 'iso-2-req-5'],
                }),
                ...this.createImpact('impact-c', {
                    impactType: 'NOT_DEFINED',
                    requirementIdsFrom: [],
                    requirementIdsTo: ['iso-2-req-6'],
                }),
            },
        };
    }

    loadImpactAnalysis(impactAnalysisId: string): Promise<LoadImpactAnalysisResponse> {
        const response = checkUndefined(ImpactServiceMock.response);
        return Promise.resolve({
            impacts: toMap(Object.values(response.impacts).map(i => ({ ...i })), i => i.id),
            impactAnalysis: { ...response.impactAnalysis },
            statistics: { ...response.statistics }
        });
    }

    createImpact(impact: ImpactDto): Promise<ImpactDto> {
        const newImpactDto = {
            ...impact,
            id: uuidv4(),
            dbVersion: 0,
        }
        checkUndefined(ImpactServiceMock.response).impacts[newImpactDto.id] = newImpactDto;
        return Promise.resolve({ ...newImpactDto });
    }

    updateImpact(impact: ImpactDto): Promise<ImpactDto> {
        const updatedImpactDto = {
            ...impact,
            dbVersion: impact.dbVersion + 1,
        }
        checkUndefined(ImpactServiceMock.response).impacts[updatedImpactDto.id] = updatedImpactDto;
        return Promise.resolve({ ...updatedImpactDto });
    }

    deleteImpact(impactAnalysisId: string, impactId: string): Promise<void> {
        delete checkUndefined(ImpactServiceMock.response).impacts[impactId];
        return Promise.resolve();
    }

    static createImpact(id: string, partial: Partial<ImpactDto> = {}): ImpactMap {
        return {
            [id]: {
                id,
                dbVersion: 0,
                impactAnalysisId: this.IMPACT_ANALYSIS_A,
                impactType: 'NOT_DEFINED',
                changeType: 'NOT_DEFINED',
                changeDescription: 'Change Description ' + id,
                impactDescription: 'Impact Description ' + id,
                requirementIdsFrom: [],
                requirementIdsTo: [],
                ...partial,
            }
        };
    }

    loadImpactsBySopArtifactId(sopArtifactId: string): Promise<ImpactsSearchResult> {
        const impactAnalysis = checkUndefined(ImpactServiceMock.response?.impactAnalysis);
        return Promise.resolve({
            impactAnalyses: { [impactAnalysis.id]: impactAnalysis },
            impacts: checkUndefined(ImpactServiceMock.response?.impacts)
        });
    }
}