




















































































import Component from 'vue-class-component';
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex';
import { RootState } from '@/store';
import { copyTextToClipboard, openUrlInNewTab } from '@/utils/util';
import { SopTemplatesActions, SopTemplatesGetters } from '@/store/sop-templates';
import { TemplateContentGetters } from '@/features/template-content/template-content-store';
import SopTemplateSelection from '@/components/sop-templates/SopTemplateSelection.vue';
import TemplateContentChip from '@/features/template-content/components/TemplateContentChip.vue';

@Component({
  components: { TemplateContentChip, SopTemplateSelection },
  computed: {
    ...mapState<RootState>({
      searchResult: (state: RootState) => state.sopTemplates.result,
      sopBlocks: (state: RootState) => state.sopTemplates.result.blocks,
      filteredBySopBlock: (state: RootState) => state.sopTemplates.filteredBySopBlockVersionId
    }),
    ...mapGetters({
      sopBlocksOfTemplate: SopTemplatesGetters.SOP_BLOCKS_OF_TEMPLATE,
      templatesBySopBlock: SopTemplatesGetters.TEMPLATES_BY_SOP_BLOCK,
      isTemplateContentIdLinked: SopTemplatesGetters.IS_TEMPLATE_CONTENT_LINKED_IN_ANY_SOP_BLOCK,
      templateContentsById: TemplateContentGetters.TEMPLATE_CONTENTS_BY_IDS_OR_LOADING,
    })
  },
  methods: {
    ...mapActions({
      filterBySopBlock: SopTemplatesActions.FILTER_BY_SOP_BLOCK,
    })
  }
})
export default class SopUsedTemplates extends Vue {

  headers = [
    { text: 'Key', value: 'documentKey', width: '20%' },
    { text: 'Name', value: 'name', width: '50%' },
    { text: 'Process Block', value: 'processBlockId', width: '20%' },
    { text: 'Version', value: 'version', width: '10%' },
  ];

  goToDetail(templateId: string): void {
    this.$router.push({ name: 'TemplateDetailView', params: { id: templateId } });
  }

  openLink(url: string) {
    openUrlInNewTab(url);
  }

  copyToClipboard(text: string) {
    copyTextToClipboard(text);
  }

}

