import { FileDownloadResponse, FileStorageMap, FileStorageSearch, FileStorageService } from '@/services/file-storage-service';
import { FileStorage } from '@/model/FileStorage';
import { toMap } from '@/utils/util';

export class FileStorageServiceMock implements FileStorageService {

    public static fileStorages: FileStorage[] = [];

    loadFileStorages(search: FileStorageSearch): Promise<FileStorageMap> {
        return Promise.resolve(toMap(
            FileStorageServiceMock.fileStorages
                .filter(fs => fs.artifactType === search.artifactType && fs.artifactVersionId === search.artifactVersionId)
                .map(fs => ({ ...fs }))
            , s => s.id));
    }

    create(fileStorage: FileStorage): Promise<FileStorage> {
        FileStorageServiceMock.fileStorages.push({ ...fileStorage, id: 'file-storage-' + FileStorageServiceMock.fileStorages.length, dbVersion: 0 })
        return Promise.resolve({ ...fileStorage });
    }

    deleteById(fileStorageId: string): Promise<void> {
        FileStorageServiceMock.fileStorages = FileStorageServiceMock.fileStorages
            .filter(storage => storage.id != fileStorageId);
        return Promise.resolve();
    }

    upload(fileStorageId: string, file: File): Promise<FileStorage> {
        const fileStorage = FileStorageServiceMock.fileStorages.find(fs => fs.id === fileStorageId);
        if (!fileStorage) {
            return Promise.reject('file storage does not exist');
        }
        return Promise.resolve(fileStorage);
    }

    download(fileStorageId: string): Promise<FileDownloadResponse> {
        return Promise.resolve({
            filename: 'file-from-mock.pdf',
            blob: new Blob([])
        });
    }
}