import { QmsRequirement } from '@/services/model';

export class QmsGapValidation {

    static isSopAssignedAsTargetAndImplemented(qmsRequirement: QmsRequirement, sopArtifactId: string) {
        return qmsRequirement.sopEvidenceStates[sopArtifactId] === 'IMPLEMENTED';
    }

    static isSopAssignedAsTargetAndNotImplemented(qmsRequirement: QmsRequirement, sopArtifactId: string) {
        return qmsRequirement.sopEvidenceStates[sopArtifactId] === 'NOT_IMPLEMENTED';
    }

    static isSopNotAssignedAsTargetAnymore(qmsRequirement: QmsRequirement, sopArtifactId: string) {
        return qmsRequirement.sopEvidenceStates[sopArtifactId] === 'IMPLEMENTED_BUT_NOT_REQUIRED';
    }

    static isTemplateAssignedAsTargetAndImplemented(qmsRequirement: QmsRequirement, templateArtifactId: string) {
        return qmsRequirement.templateEvidenceStates[templateArtifactId] === 'IMPLEMENTED';
    }

    static isTemplateAssignedAsTargetAndNotImplemented(qmsRequirement: QmsRequirement, templateArtifactId: string) {
        return qmsRequirement.templateEvidenceStates[templateArtifactId] === 'NOT_IMPLEMENTED';
    }

    static isTemplateNotAssignedAsTargetAnymore(qmsRequirement: QmsRequirement, templateArtifactId: string) {
        return qmsRequirement.templateEvidenceStates[templateArtifactId] === 'IMPLEMENTED_BUT_NOT_REQUIRED';
    }

    static notDefinedHasWarning(qmsRequirement: QmsRequirement) {
        return qmsRequirement.applicabilityWarnings?.includes('NOT_DEFINED')
    }

    static notApplicableHasWarning(qmsRequirement: QmsRequirement) {
        return qmsRequirement.applicabilityWarnings?.includes('NOT_APPLICABLE_WITH_EVIDENCE')
            || qmsRequirement.applicabilityWarnings?.includes('NOT_APPLICABLE_WITHOUT_REASON');
    }

    static reasonForNotApplicableIsMissing(qmsRequirement: QmsRequirement) {
        return qmsRequirement.applicabilityWarnings?.includes('NOT_APPLICABLE_WITHOUT_REASON');
    }

    static applicableHasWarning(qmsRequirement: QmsRequirement) {
        return qmsRequirement.applicabilityWarnings?.includes('APPLICABLE_WITHOUT_EVIDENCE');
    }

    static applicableIsSuccessfully(qmsRequirement: QmsRequirement) {
        if (qmsRequirement.applicability !== 'APPLICABLE') {
            return false;
        }
        return !QmsGapValidation.applicableHasWarning(qmsRequirement);
    }
}