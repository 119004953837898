















import { Component, Vue } from 'vue-property-decorator';

import CoHeader from '@/components/common/CoHeader.vue';
import RegulationsOverview from '@/features/regulations/components/RegulationsOverview.vue';
import { RegulationActions } from '@/features/regulations/regulation-store';
import CoLayout from '@/layouts/CoLayout.vue';

@Component({
  components: {
    CoLayout,
    CoHeader,
    RegulationsOverview
  },
})
export default class RegulationsOverviewView extends Vue {

  created() {
    this.$store.dispatch(RegulationActions.REGULATIONS_WITH_STATISTICS_LOAD);
  }

}
