import { render, staticRenderFns } from "./CommentsTimeline.vue?vue&type=template&id=2d5eb18a&scoped=true&"
import script from "./CommentsTimeline.vue?vue&type=script&lang=ts&"
export * from "./CommentsTimeline.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d5eb18a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VSlideXTransition,VTextarea,VTimeline,VTimelineItem})
