
import Vue from 'vue';
import Component from 'vue-class-component';
import { Doughnut, mixins } from 'vue-chartjs'

const { reactiveProp } = mixins;

@Component({
  extends: Doughnut,
  mixins: [reactiveProp],
})
export default class StatisticPie extends Vue {
  chartData: any; // reactive property, provided by mixin 'reactiveProp'

  mounted() {
    this.drawChart();
  }

  callback(e: any, tooltipItems: any) {
    this.$emit('entry-selected', tooltipItems[0]._index);
  }

  private drawChart() {
    const options = { 'onClick': this.callback };
    (this as any).renderChart(this.chartData, options);
  }

}
