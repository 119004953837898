












import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SopBlock } from '@/features/sop-block/model';
import { Canvg } from 'canvg';

@Component
export default class SopClipboardSection extends Vue {

  @Prop({ required: true })
  block: SopBlock;

  mounted() {
    this.$nextTick(async () => {
      const img = document.querySelector('#img-' + this.block.versionId) as HTMLImageElement;
      const canvas = document.querySelector('#canvas-' + this.block.versionId) as any;
      if (!canvas) {
        return;
      }
      const context = canvas.getContext('2d');

      const svgContent = this.block.description;
      const v = await Canvg.fromString(context, svgContent)
      v.start();
      img.src = canvas.toDataURL('image/png');
    });
  }

}
