import { Requirement } from '@/features/sr-model/Requirement';
import { SrRegulation } from '@/features/regulations/model';

export function emptySearchResult(): RequirementSearchResult {
    return { regulations: [], requirements: [] }
}

export interface RequirementSearchResult {
    regulations: SrRegulation[];
    requirements: RequirementSearchResultEntry[];
}

export interface RequirementSearchResultEntry extends Requirement {
    regulationId: string;
    searchResult: boolean;
}
